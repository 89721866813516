import React from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import Random from "random-seed-generator";
import { withRouter } from "react-router-dom";
import {
  saveEsoMutation, _s3SignMutation,
  getEsosQuery, countEsosQuery, locationDetails
} from "../../api";
import Spinner from "../../components/Spinner";
import { Autocomplete } from '@material-ui/lab';
import { useMutation } from "@apollo/react-hooks";
import ImageUpload from "../../components/ImageUpload"
import ApolloCacheUpdater from "apollo-cache-updater";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { Check, Block, SearchOutlined } from "@material-ui/icons";
import Accordion, { AccordionItem } from "../../components/Accordion";
import {
  Grid, Select, Button, Input, Checkbox, TextField, MenuItem, Chip, InputLabel,
  Box, FormControl, FormControlLabel, FormHelperText, Typography, CircularProgress
} from "@material-ui/core";
import LEGAL from "../../settings/enums/legal";
import FOUNDER from "../../settings/enums/founder";
import OUTREACH from "../../settings/enums/outreach";
import SERVICES from "../../settings/enums/services";
import CATEGORIES from "../../settings/enums/categories";
import GEO_ACTIVE from "../../settings/enums/geo-active";
import WOMEN_STAFF from "../../settings/enums/women-staff";
import JOBS_CREATED from "../../settings/enums/jobs-created";
import TARGET_GROUPS from "../../settings/enums/target-groups";
import TARGET_SECTORS from "../../settings/enums/target-sectors";
import SUPPORT_NEEDED from "../../settings/enums/support-needed";
import BUSINESS_STAGES from "../../settings/enums/business-stages";
import FUNDING_SOURCES from "../../settings/enums/funding-sources";
import AFRICAN_ISO from "../../settings/enums/africa-iso-codes.json";
import OPERATIONAL_COSTS from "../../settings/enums/operational-costs";
import PROGRAM_CHALLENGES from "../../settings/enums/program-challenges";
import OUTREACH_SOCIALMEDIA from "../../settings/enums/outreach-socialmedia";
import NEW_BUSINESS_CREATED from "../../settings/enums/new-business-created";
import FINANCIAL_SUPPORT_TYPE from "../../settings/enums/financial-support-type";
import ENTREPRENEUR_SUPPORTED from "../../settings/enums/entrepreneur-supported";
import EXISTING_BUSINESS_SUPPORTED from "../../settings/enums/existing-business-supported";

import './OrganizationForm.css'

const OrganizationForm = ({
  data: inputData,
  history,
  languageIndex,
  languagesTotal,
  nativeLanguage = 0,
  setNativeLanguage
}) => {
  const data = {
    ...(!!inputData && {
      ...inputData,
      // We treat geoActiveOther differently as unfortunately it is a Boolean from the DB
      geoActive:
        inputData.geoActive &&
          inputData.geoActiveOther &&
          !inputData.geoActive.includes("other")
          ? [inputData.geoActive, "other"]
          : inputData.geoActive
            ? [inputData.geoActive]
            : []
    })
  };
  const firstUpdate = React.useRef(true);
  const [otherFields, setOtherFields] = React.useState({});

  const socialUrls = inputData?.social?.reduce((acc, cur) => {
    acc[cur.id] = cur.url; // This will create an object with keys as 'linkedin', 'facebook', 'twitter' and their respective URLs as values
    return acc;
  }, {});

  const [initialValues, setInitialValues] = React.useState({
    // Presentation:
    name: "",
    logo: "",
    description: "",
    // social :
    // linkedin: socialUrls?.linkedin,
    // facebook: socialUrls?.facebook,
    // twitter: socialUrls?.twitter,
    // Contact details:
    city: "",
    country: "",
    address: "",
    location: "",
    locationName: "",
    language: "en",
    website: "",
    // contacts: [{ phone: "", email: "" }],
    contacts: [
      {
        phone: "",
        email: "",
        social: [
          { label: "linkedin", url:  "" },
          { label: "facebook", url:  "" },
          { label: "twitter", url:   "" }
        ]
      }
    ],
 
    // phone: "",
    // email: "",
    contact: "",
    mainOffice: "",
    branchOffices: "",
    // Metrics:
    established: "",
    categories: [],
    categoriesOther: [],
    legalEntity: null,
    projectDuration: "",
    programName: "",
    geoActive: [],
    otherCountriesActive: [],
    targetGroups: [],
    targetGroupsOther: [],
    growthStagesSupported: [],
    targetSectors: [],
    targetSectorsOther: [],
    services: [],
    servicesOther: [],
    entrepreneurDurationSupport: "",
    outreachStrategy: [],
    outreachStrategyOther: [],
    outreachSocialMediaStrategy: [],
    outreachSocialMediaStrategyOther: [],
    founder: null,
    founderOther: "",
    operationalCosts: null,
    fundingSources: [],
    fundingSourcesOther: [],
    fundingSourcesNumber: "",
    entrepreneurParticipationFee: false,
    entrepreneurParticipationFeeAmount: "",
    financialSuportProvided: [],
    financialSuportProvidedOther: [],
    programChallenges: [],
    programChallengesOther: [],
    supportNeeded: [],
    supportNeededOther: [],
    // Staff metrics:
    staffFullTime: "",
    staffPartTime: "",
    staffMentorsCoaches: "",
    staffVolunteersInterns: "",
    womenStaff: null,
    directedByWoman: false,
    // Result metrics:
    entrepreneursSupported: [''],
    newBusinessesCreated: [''],
    existingBusinessesSupported: [''],
    jobsCreated: [''],
    // Publication options:
    published: false,
    featured: false,
    partner: false,
    ...data
  });

  const [changingLanguage, setChangingLanguage] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const [saveEso] = useMutation(saveEsoMutation);
  const [s3Sign] = useMutation(_s3SignMutation);
  const [googleLocation] = useMutation(locationDetails);
  const [otherFieldChange, setOtherFieldChange] = React.useState([null, null]);
  const [locationData, setLocationData] = React.useState([])
  const [locationRenderFlag, setLocationRenderFlag] = React.useState(data && data.locationName ? 3 : 1)
  const [placeID, setPlaceID] = React.useState('')
  const [locationName, setlocationName] = React.useState();
  const [latlng, setLatlng] = React.useState({
    lat: -30.559482,
    lng: 22.937506
  })
  const [locationLoader, setLocationLoader] = React.useState(false)
  const [locationsearchQuery, setLocationSearchQuery] = React.useState()
  const [submitLoader, setSubmitLoader] = React.useState(false)



  const fieldsWithOtherOption = [
    "categories",
    "geoActive",
    "targetGroups",
    "targetSectors",
    "services",
    "outreachStrategy",
    "outreachSocialMediaStrategy",
    "founder",
    "fundingSources",
    "financialSuportProvided",
    "programChallenges",
    "supportNeeded"
  ];
  const getReverseGeocoding = async () => {
    let res = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${data?.place_id}&key=AIzaSyDUmjLxl_qEb35uInhGq4ncy_a5pz2YYxA`
    );
    let result = await res.json();
    setlocationName(result?.results[0]?.formatted_address);
    setLocationRenderFlag(3)
  };

  React.useEffect(() => {
    if (Object.keys(data).length !== 0) {
      if (data?.place_id) {
        getReverseGeocoding();
      }
      setInitialValues(data);
      // setSelectedLogo(data?.logo)
      setOtherFields(
        fieldsWithOtherOption.reduce(
          (acc, name) => ({
            ...acc,
            [name]:
              data[name] &&
              (data[name].includes("other") || data[name] === "other")
          }),
          {}
        )
      );
    }
    // Flag that First render happened (component did mount).
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, []);

  console.log(data,"data")

  React.useEffect(() => {
    // Set initial values again after changing current language,
    // force rerender of the whole form.
    if (!firstUpdate.current && Object.keys(data).length) {
      const handleAsync = async () => {
        await setChangingLanguage(true);
        await setInitialValues(data);
        await setChangingLanguage(false);
      };
      handleAsync();
    }
  }, [languageIndex]);

  const processMultiYearFields = fields =>
    Object.entries(fields).reduce((acc, [key, value]) => {
      console.log("🚀 ~ Object.entries ~ value:", value)
      
      const values = Array.isArray(value) && value?.map(item =>
       item && !!item[0] ? item : undefined
      );
      return {
        ...acc,
        ...(values &&
          Array.isArray(values) &&
          values.filter(v => !!v).length > 0 && {
          [key]: values.filter(v => !!v)
        })
      };
    }, {});

  const castToNumber = fields =>
    Object.entries(fields).reduce(
      (obj, [key, value]) => ({
        ...obj,
        ...(!!value && { [key]: parseInt(value, 10) })
      }),
      {}
    );

  const filterNotValidItems = fields =>
    Object.entries(fields).reduce((obj, field) => {
      if (field[1] && Array.isArray(field[1])) {
        if (field[1].length === 0 || field[1].filter(f => !!f).length === 0)
          return obj;
      }
      return { ...obj, [field[0]]: field[1] };
    }, {});

  const callbackOnChange = (fields, name) => {
    // Watch language field to update default language.
    if (name === "language") {
      setNativeLanguage(fields[name]);
    }
    // Handle other option.
    // setInitialValues(null);
    if (fieldsWithOtherOption.includes(name)) {
      // Use otherFieldChange and useEffect hook, because in a scope of this
      // function otherFields updates are not visible.
      setOtherFieldChange([
        name,
        fields[name] &&
        (fields[name].includes("other") || fields[name] === "other")
      ]);
    }
  };

  React.useEffect(() => {
    // Respond to otherFieldChange from callbackOnChange function.
    const [name, value] = otherFieldChange;
    if (name) {
      setOtherFields({
        ...otherFields,
        [name]: value
      });
    }
  }, [otherFieldChange]);

  const handleSubmit = async fields => {
    try {
      setSubmitLoader(true)
      const {
        description,
        categoriesOther,
        geoActive,
        otherCountriesActive,
        targetGroupsOther,
        targetSectorsOther,
        servicesOther,
        outreachStrategyOther,
        outreachSocialMediaStrategyOther,
        fundingSourcesOther,
        financialSuportProvidedOther,
        programChallengesOther,
        supportNeededOther,
        entrepreneursSupported,
        newBusinessesCreated,
        existingBusinessesSupported,
        jobsCreated,
        location,
        country,
        published = false, // TODO: form package should return false if undefined by default.
        featured = false, // TODO: form package should return false if undefined by default.
        partner = false, // TODO: form package should return false if undefined by default.
        staffVolunteersInterns = null,
        staffFullTime = null,
        staffMentorsCoaches = null,
        staffPartTime = null,
        fundingSourcesNumber = null,
        established = null,
        website,
        logo,
        linkedin,
        facebook,
        twitter,
        ...standardFields
      } = fields;
      const [countryName, countryCode] =
        country && typeof country === "string" && country.length
          ? country.split("__")
          : ["Algeria", "DZA"];
      
          const processedContacts = fields.contacts.map((contact) => ({
            phone: contact.phone || "",
            email: contact.email || "",
            social: contact.social
              ?.filter((social) => social.url) // Only keep filled links
              .map((social) => ({
                id: social.label,
                label: social.label,
                url: social.url
              })) || []
          }));
      

    

      const _dataObject = {
        ...(data && { id: data?._id }),
        ...filterNotValidItems(standardFields),
        ...castToNumber({
          fundingSourcesNumber,
          established,
          staffFullTime,
          staffMentorsCoaches,
          staffPartTime,
          staffVolunteersInterns
        }),
        countryName,
        countryCode,
        place_id: placeID,
        location: latlng,
        published,
        featured,
        partner,
        email: standardFields.email || null,
        ...(website && {
          website:
            !website.includes("http://") && !website.includes("https://")
              ? `http://${website}`
              : website
        }),
        // As stated previously we need to treat geoActiveOther differently
        geoActive: !!geoActive ? geoActive[0] : undefined,
        geoActiveOther:
          !!geoActive && Array.isArray(geoActive)
            ? geoActive.includes("other")
            : undefined,

        description,
        categoriesOther,
        otherCountriesActive,
        targetGroupsOther,
        targetSectorsOther,
        servicesOther,
        outreachStrategyOther,
        outreachSocialMediaStrategyOther,
        fundingSourcesOther,
        financialSuportProvidedOther,
        programChallengesOther,
        supportNeededOther,
        ...processMultiYearFields({
          entrepreneursSupported,
          newBusinessesCreated,
          existingBusinessesSupported,
          jobsCreated
        }),
        ...data && data.language && { language: data.language },
        locationName: locationName,
        // social: socialLinks,
        contacts:processedContacts

      };
console.log(_dataObject,"Object")
      const { __typename, _id, user, slug, registration_date, registered, invitition_date, invited, ...dataObject } = _dataObject
      let url = logo || null;
      if (logo && typeof logo !== "string") {
        const { dataFile, name: fileName } = logo;
        const randomString = Random.string({
          length: 5,
          pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        });

        const response = await s3Sign({
          variables: {
            filename: `media/eso/${randomString}-${fileName
              .replace(/ /g, "-")
              .replace(/[{()}]/g, "")}`,
            filetype: logo.type
          }
        });
        const { signedRequest, url: bucketUrl } = response.data._signS3;
        const options = {
          headers: {
            "Content-Type": logo.type
          }
        };
        try {
          await fetch(signedRequest, {
            method: "PUT",
            headers: options.headers,
            body: dataFile
          });
          url = bucketUrl;
        } catch (e) {
          url = null;
          enqueueSnackbar("Cannot upload image.", { variant: "error" });
        }
      }

      let DataObject = Object.keys(dataObject).filter((item) => dataObject[item] !== ("" || null || undefined)).reduce(function (acc, k) {
        acc[k] = dataObject[k];
        return acc;
      }, {})

      saveEso({
        variables: {
          data: {
            ...DataObject,
            logo: url
          }
        },
        update: (proxy, { data: { saveEso = {} } }) => {
          const mutationResult = saveEso;
          const updates = ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getEsosQuery, countEsosQuery],
            searchVariables: {},
            operator: "ANY",
            mutationResult,
            ID: "_id"
          });
          if (updates) console.log(`Eso added`); // if no errors
        }
      })
        .then(() => {
          enqueueSnackbar(
            data ? "Organization saved." : "New organization created.",
            { variant: "success" }
          );
          setSubmitLoader(false)
          history.goBack();
        })
        .catch(e => {
          if (e && e.graphQLErrors && Array.isArray(e.graphQLErrors)) {
            if (
              e.graphQLErrors.filter(
                err => err.message && err.message.includes("slug")
              ).length > 0
            ) {
              enqueueSnackbar("This name is already in use", {
                variant: "error"
              });
              setSubmitLoader(false)

            } else if (!dataObject.name)
              enqueueSnackbar("A valid organization needs at least a name", {
                variant: "error"
              });
            setSubmitLoader(false)

          } else {
            enqueueSnackbar("Could not save the organization.", {
              variant: "error"
            });
            setSubmitLoader(false)

          }
          console.log(e);
        });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Could not save the organization.", { variant: "error" });
      setSubmitLoader(false)
    }
  };

  const isTranslatable = bool =>
    languageIndex !== nativeLanguage
      ? {
        disabled: !bool,
        helperText: bool
          ? "Enter translation for selected language."
          : "This field is not translatable."
      }
      : {};

  const GridItem = ({ children, ...props }) => (
    <Grid item sm={6} {...props}>
      {children}
    </Grid>
  );

  const GridContainer = ({ children }) => (
    <Grid container spacing={4} justifyContent="flex-start">
      {children}
    </Grid>
  );

  const insertNextYear = (arrayHelpers, values) => {
    if (values && values[values.length - 1] === '') {
      enqueueSnackbar("Fill the value of Previous Year first", { variant: "warning" });
    }
    else {
      arrayHelpers.push('');
    }
  }

  const loadPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, 4000);
  })

  const handleChangeLocation = async (e) => {
    setLocationLoader(true)
    await loadPromise;
    googleLocation({
      variables: {
        locationName: locationsearchQuery
      }
    })
      .then(result => {
        setLocationData(...locationData, result?.data?.locatoinGet?.obj)
        setLocationLoader(false)
        setLocationRenderFlag(2)
      })
      .catch(err => {
        console.log(err);
      });
  }

  const selectChangeLocation = (e) => {
    let selectLocation = locationData?.filter((item) => {
      return item?.place_id === e?.place_id
    })
    setPlaceID(selectLocation[0]?.place_id)
    setLatlng({ lat: selectLocation[0]?.geometry?.location?.lat, lng: selectLocation[0]?.geometry?.location?.lng })
  }
  const handleLocationCancle = () => {
    setLocationRenderFlag(1)
    setLocationData([])
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required."),
  })

  return changingLanguage ? null : (
    <>
      {submitLoader
        ? <Spinner big></Spinner>
        : <Formik
          enableReinitialize
          initialValues={{
            ...initialValues,
            country: data?.countryName && data?.countryCode
              ? `${data?.countryName}__${data?.countryCode}` : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, handleChange: onChange, handleSubmit, setFieldValue }) => {
            const handleChange = (e) => {
              onChange(e)
              const { name, value } = e.target
              callbackOnChange(
                { ...values, [name]: value },
                name
              )
            }
            return (
              <Form>
                <Accordion>
                  <AccordionItem initiallyExpanded title="Presentation">
                    <GridContainer>
                      <GridItem>
                        <FormControl fullWidth>
                          <TextField
                            label={<span>Name <span style={{ color: 'red' }}>*</span></span>}
                            name="name"
                            id="name"
                            onChange={onChange}
                            value={values?.name || ""}
                            {...isTranslatable(true)}
                          />
                          <ErrorMessage name='name' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                        </FormControl>
                        <Box>
                          <Typography variant="subtitle1" style={{ color: "#000000", opacity: "0.5" }}>Logo</Typography>
                          <ImageUpload
                            name={'logo'}
                            value={values?.logo}
                            setFieldValue={setFieldValue}
                          />

                          {isTranslatable(false)?.helperText}
                        </Box>
                      </GridItem>
                      <GridItem>
                        <TextField
                          multiline
                          minRows={1}
                          fullWidth
                          id="description"
                          label="About text"
                          onChange={onChange}
                          name={`description[${languageIndex}]`}
                          defaultValue={values?.description?.[languageIndex] || ""}
                          {...isTranslatable(true)}
                        />
                      </GridItem>
                    </GridContainer>
                  </AccordionItem>

                  <AccordionItem title="Contact & Address">
                    <Grid container spacing={3} p={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="City"
                          fullWidth
                          name="city"
                          id="city"
                          defaultValue={values?.city}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="countrylabelID">Country</InputLabel>
                          <Select
                            labelId="countrylabelID"
                            id="countrySelect"
                            onChange={onChange}
                            defaultValue={values?.country || ""}
                            name="country"
                            label="Country"
                            {...isTranslatable(false)}
                          >
                            <MenuItem>None</MenuItem>
                            {Object.entries(AFRICAN_ISO).map(([key, value]) => (
                              <MenuItem key={value} value={`${key}__${value}`}>
                                {key}
                              </MenuItem>
                            ))}
                          </Select>
                          {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Address"
                          fullWidth
                          name="address"
                          id="address"
                          defaultValue={values?.address || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {locationRenderFlag === 1
                          ? <TextField
                            fullWidth
                            id="location"
                            name="location"
                            label="Location"
                            InputProps={{
                              endAdornment: (locationLoader
                                ? <CircularProgress color="inherit" size={20} />
                                : <Button type="button" style={{ padding: '0', boxShadow: 'none' }} variant="contained" onClick={handleChangeLocation}><SearchOutlined /></Button>
                              ),
                            }}
                            onChange={(e) => setLocationSearchQuery(e.target.value)}
                            {...isTranslatable(false)}
                          />
                          : locationRenderFlag === 2
                            ? <>
                              <FormControl variant="standard" fullWidth>
                                <Autocomplete
                                  fullWidth
                                  options={locationData}
                                  renderInput={(params) => <TextField {...params}
                                    {...isTranslatable(false)}
                                    label="Select Location" fullWidth />
                                  }
                                  getOptionLabel={(option) => option?.formatted_address}
                                  onChange={(event, newValue) => selectChangeLocation(newValue)}
                                />
                              </FormControl>
                              <Button onClick={handleLocationCancle}>Cancel</Button>
                            </>
                            : <>
                              <TextField
                                disabled
                                fullWidth
                                id="location"
                                label="Location"
                                name="locationName"
                                defaultValue={values?.locationName}
                                {...isTranslatable(false)}
                              />
                              <Button onClick={handleLocationCancle}>Edit</Button>
                            </>
                        }
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Website"
                          fullWidth
                          name="website"
                          id="websiteField"
                          defaultValue={values?.website || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Contact Person"
                          fullWidth
                          name="contact"
                          id="contact_person"
                          defaultValue={values?.contact || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Main Office"
                          fullWidth
                          name="mainOffice"
                          id="main_office"
                          defaultValue={values?.mainOffice || ""}
                          {...isTranslatable(false)}
                          onChange={onChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Branch Offices"
                          fullWidth
                          name="branchOffices"
                          id="branch_office"
                          defaultValue={values?.branchOffices || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      {/* ---------------------------Telegram Link--------------------------- */}
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          label="Linkedin Link"
                          fullWidth
                          name="linkedin"
                          id="linkedin_link"
                          defaultValue={socialUrls?.linkedin || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid> */}
                      {/* ---------------------------Facebook Link--------------------------- */}
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          label="Facebook Link"
                          fullWidth
                          name="facebook"
                          id="facebook_link"
                          defaultValue={socialUrls?.facebook || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid> */}
                      {/* ---------------------------Twitter Link--------------------------- */}
                      {/* <Grid item xs={12} >
                        <TextField
                          label="Twitter Link"
                          fullWidth
                          name="twitter"
                          id="twitter_office"
                          defaultValue={socialUrls?.twitter || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid> */}
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          label="Phone"
                          fullWidth
                          name="phone"
                          id="phone"
                          defaultValue={values?.phone || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Email"
                          fullWidth
                          name="email"
                          id="email"
                          defaultValue={values?.email || ""}
                          onChange={onChange}
                          {...isTranslatable(false)}
                        />
                      </Grid> */}

{console.log(values.contacts,)}


   <FieldArray name="contacts">
            {({ push, remove }) => (
              < >
                {(values.contacts??[]).map((contact, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                          label="Phone"
                          fullWidth
                          name={`contacts[${index}].phone`}
                          value={values.contacts[index].phone}
                          id={`phone-${index}`}
                          onChange={handleChange}
                          {...isTranslatable(false)}
                        />
                      {/* <Field
                        as={TextField}
                        label="Phone"
                        fullWidth
                        name={`contacts[${index}].phone`}
                        id={`phone-${index}`}
                        onChange={handleChange}
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                          label="Email"
                          fullWidth
                          name={`contacts[${index}].email`}
                          value={values.contacts[index].email}

                        id={`email-${index}`}
                        onChange={handleChange}
                          // {...isTranslatable(false)}
                        />
                     
                    </Grid>
                    {/* {(values.contact.social??[]).map((social, socialIndex) => (
            <Grid item xs={12}  key={socialIndex}>
              <TextField
                label={`${social.platform} Link`}
                fullWidth
                name={`contacts[${index}].socials[${socialIndex}].link`}
                value={values.contacts[index].social[socialIndex].url}
                id={`${social.platform}-${index}`}
                defaultValue={social.link}
                onChange={handleChange}
              />
            </Grid>
          ))}
                       */}

{(contact?.social?? []).map((social, socialIndex) => (
            <Grid item xs={12} key={socialIndex}>
              <TextField
                label={`${social.label} Link`}
                fullWidth
                name={`contacts[${index}].social[${socialIndex}].url`}
                value={values.contacts?.[index]?.social?.[socialIndex]?.url ?? ""}
                id={`${social.label}-${index}`}
                onChange={handleChange}
              />
            </Grid>
          ))}
                    {index > 0 && (
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    // onClick={() => {
                    //   if (values.contacts.length < 5) {
                    //     push({ phone: "", email: "" });
                    //   }
                    // }}
                    onClick={() => {
                      if (values.contacts.length < 5) {
                        push({
                          phone: "",
                          email: "",
                          social: [
                            { label: "linkedin", url: "" },
                            { label: "facebook", url: "" },
                            { label: "twitter", url: "" }
                          ]
                        });
                      }
                    }}
                    disabled={values.contacts.length >= 5}
                  >
                    Add More
                  </Button>
                </Grid>

              
              </>
            )}
          </FieldArray>




                      

                    </Grid>
                  </AccordionItem>

                  <AccordionItem title="Metrics">
                    <GridContainer>
                      <GridItem>
                        <TextField
                          fullWidth
                          type="number"
                          id="established"
                          label="Established"
                          name="established"
                          onChange={onChange}
                          defaultValue={values?.established || ""}
                          {...isTranslatable(false)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Categories</InputLabel>
                        <Select
                          multiple
                          fullWidth
                          name="categories"
                          // id="categoriesSelect"
                          onChange={handleChange}
                          // labelId="categorieslabelID"
                          value={values?.categories || []}
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={CATEGORIES[value]?.label} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(CATEGORIES).map(([key, value]) =>
                            (<MenuItem value={key} key={value?.label} >{value?.label}</MenuItem>)
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.categories && (
                          <TextField
                            fullWidth
                            label="Other category"
                            onChange={onChange}
                            {...isTranslatable(true)}
                            name={`categoriesOther[${languageIndex}]`}
                            value={values?.categoriesOther?.[languageIndex]}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel id="legalentitylabelID">Legal Entity</InputLabel>
                        <Select
                          fullWidth
                          name="legalEntity"
                          label="legalEntity"
                          id="legalentitySelect"
                          onChange={onChange}
                          labelId="legalentitylabelID"
                          defaultValue={values?.legalEntity || ""}
                          {...isTranslatable(false)}
                        >
                          <MenuItem>None</MenuItem>
                          {Object.entries(LEGAL).map(([key, value]) => (
                            <MenuItem key={value} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        <TextField
                          fullWidth
                          type="text"
                          id="project_duration"
                          name="projectDuration"
                          onChange={onChange}
                          label="Project Duration"
                          defaultValue={values?.projectDuration || ""}
                          {...isTranslatable(false)}
                        />
                      </GridItem>
                      <GridItem>
                        <TextField
                          fullWidth
                          id="program_name"
                          name="programName"
                          label="Program Name"
                          onChange={onChange}
                          defaultValue={values?.programName || ""}
                          {...isTranslatable(false)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Geo Active</InputLabel>
                        <Select
                          fullWidth
                          labelId="geoActivelabelID"
                          id="geoActiveSelect"
                          name="geoActive"
                          defaultValue={values?.geoActive || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={GEO_ACTIVE[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(GEO_ACTIVE).map(([key, value]) => (
                            <MenuItem key={value} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.geoActive && (
                          <TextField
                            fullWidth
                            label="Other geo active"
                            onChange={onChange}
                            name={`otherCountriesActive[${languageIndex}]`}
                            defaultValue={values?.otherCountriesActive &&
                              values?.otherCountriesActive[languageIndex]
                            }
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Target groups</InputLabel>
                        <Select
                          fullWidth
                          name='targetGroups'
                          id="targetGroupsSelect"
                          defaultValue={values?.targetGroups || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={TARGET_GROUPS[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(TARGET_GROUPS)?.map(
                            ([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.targetGroups &&
                          <TextField
                            fullWidth
                            id="targetGroupsOther"
                            onChange={onChange}
                            label="Other target group"
                            name={`targetGroupsOther[${languageIndex}]`}
                            defaultValue={values?.targetGroupsOther || ""}
                            {...isTranslatable(true)}
                          />
                        }
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Supported growth stages</InputLabel>
                        <Select
                          multiple
                          fullWidth
                          onChange={handleChange}
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          name="growthStagesSupported"
                          id="growthStagesSupportedSelect"
                          labelId="growthStagesSupportedlableId"
                          value={values?.growthStagesSupported || []}
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={BUSINESS_STAGES[value].label} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(BUSINESS_STAGES)?.map(
                            ([key, value]) => (
                              <MenuItem key={value.label} value={key}>
                                {value.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Target sectors</InputLabel>
                        <Select
                          fullWidth
                          labelId="targetSectors"
                          id="targetSectorsSelect"
                          name="targetSectors"
                          defaultValue={values?.targetSectors || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={TARGET_SECTORS[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(TARGET_SECTORS)?.map(([key, value]) => (
                            <MenuItem key={value} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.targetSectors && (
                          <TextField
                            fullWidth
                            onChange={onChange}
                            label="Other target sector"
                            name={`targetSectorsOther[${languageIndex}]`}
                            value={values?.targetSectorsOther &&
                              values?.targetSectorsOther[languageIndex]
                            }
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Services</InputLabel>
                        <Select
                          fullWidth
                          labelId="services"
                          id="servicesSelect"
                          name="services"
                          defaultValue={values?.services || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={SERVICES[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(SERVICES)?.map(([key, value]) => (
                            <MenuItem key={value} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.services && (
                          <TextField
                            fullWidth
                            label="Other service"
                            onChange={onChange}
                            value={values?.servicesOther &&
                              values?.servicesOther[languageIndex]
                            }
                            name={`servicesOther[${languageIndex}]`}
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <TextField
                          fullWidth
                          onChange={onChange}
                          id="entrepreneurDurationSupport"
                          name="entrepreneurDurationSupport"
                          label="Entrepreneur duration support"
                          defaultValue={values?.entrepreneurDurationSupport || ""}
                          {...isTranslatable(false)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Outreach strategy</InputLabel>
                        <Select
                          fullWidth
                          labelId="outreachStrategy"
                          id="outreachStrategySelect"
                          name="outreachStrategy"
                          defaultValue={values?.outreachStrategy || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={OUTREACH[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(OUTREACH)?.map(([key, value]) => (
                            <MenuItem key={value} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.outreachStrategy && (
                          <TextField
                            fullWidth
                            name={`outreachStrategyOther[${languageIndex}]`}
                            label="Other outreach strategy"
                            value={
                              values?.outreachStrategyOther &&
                              values?.outreachStrategyOther[languageIndex]
                            }
                            onChange={onChange}
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Outreach social media strategy</InputLabel>
                        <Select
                          fullWidth
                          labelId="outreachSocialMediaStrategy"
                          id="outreachSocialMediaStrategySelect"
                          name="outreachSocialMediaStrategy"
                          defaultValue={values?.outreachSocialMediaStrategy || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={OUTREACH_SOCIALMEDIA[value]} />
                              ))}
                            </Box>
                          )
                          }
                          {...isTranslatable(false)}
                        >
                          {Object.entries(OUTREACH_SOCIALMEDIA)?.map(
                            ([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.outreachSocialMediaStrategy && (
                          <TextField
                            fullWidth
                            name={`outreachSocialMediaStrategyOther[${languageIndex}]`}
                            label="Other outreach SocialMedia strategy"
                            value={values?.outreachSocialMediaStrategyOther &&
                              values?.outreachSocialMediaStrategyOther[languageIndex]
                            }
                            onChange={onChange}
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel id="founderlabelID">Founder</InputLabel>
                        <Select
                          fullWidth
                          label="Founder"
                          name="founder"
                          id="founderSelect"
                          onChange={onChange}
                          defaultValue={values?.founder || ""}
                          {...isTranslatable(false)}
                        >
                          <MenuItem>None</MenuItem>
                          {Object.entries(FOUNDER).map(([key, value]) => (
                            <MenuItem key={value} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.founder && (
                          <TextField
                            fullWidth
                            name="founderOther"
                            label="Other founder"
                            value={values?.founderOther || ''}
                            onChange={onChange}
                            {...isTranslatable(false)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel id="operationalCostslabelID">Operational costs</InputLabel>
                        <Select
                          fullWidth
                          label="Operational costs"
                          name="operationalCosts"
                          id="operationalCosts"
                          onChange={onChange}
                          defaultValue={values?.operationalCosts || ""}
                          {...isTranslatable(false)}
                        >
                          <MenuItem>None</MenuItem>
                          {Object.entries(OPERATIONAL_COSTS).map(
                            ([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Funding sources</InputLabel>
                        <Select
                          fullWidth
                          labelId="fundingSources"
                          id="fundingSourcesSelect"
                          name="fundingSources"
                          defaultValue={values?.fundingSources || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={FUNDING_SOURCES[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(FUNDING_SOURCES)?.map(
                            ([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.fundingSources && (
                          <TextField
                            fullWidth
                            name={`fundingSourcesOther[${languageIndex}]`}
                            label="Other funding sources"
                            value={
                              values?.fundingSourcesOther &&
                              values?.fundingSourcesOther[languageIndex]
                            }
                            onChange={onChange}
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <TextField
                          fullWidth
                          type="number"
                          onChange={onChange}
                          id="fundingSourcesNumber"
                          name="fundingSourcesNumber"
                          label="Number of funding sources"
                          defaultValue={values?.fundingSourcesNumber || ""}
                          {...isTranslatable(false)}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <FormControlLabel
                          control={<Checkbox />}
                          onChange={onChange}
                          name="entrepreneurParticipationFee"
                          label="Entrepreneur participation fee"
                          checked={values?.entrepreneurParticipationFee}
                          {...isTranslatable(false)}
                        />
                      </GridItem>
                      <GridItem>
                        <TextField
                          fullWidth
                          onChange={onChange}
                          label="Amount of entrepreneur participation fee"
                          name="entrepreneurParticipationFeeAmount"
                          id="entrepreneurParticipationFeeAmount"
                          defaultValue={values?.entrepreneurParticipationFeeAmount || ""}
                          {...isTranslatable(false)}
                        />
                      </GridItem>

                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Financial support provided</InputLabel>
                        <Select
                          fullWidth
                          labelId="financialSuportProvided"
                          id="financialSuportProvidedSelect"
                          name="financialSuportProvided"
                          defaultValue={values?.financialSuportProvided || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={FINANCIAL_SUPPORT_TYPE[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(FINANCIAL_SUPPORT_TYPE)?.map(
                            ([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.financialSuportProvided && (
                          <TextField
                            fullWidth
                            onChange={onChange}
                            label="Other financial support provided"
                            name={`financialSuportProvidedOther[${languageIndex}]`}
                            value={values?.financialSuportProvidedOther &&
                              values?.financialSuportProvidedOther[languageIndex]
                            }
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem>
                        <InputLabel>Program challenges</InputLabel>
                        <Select
                          fullWidth
                          labelId="programChallenges"
                          id="programChallengesSelect"
                          name="programChallenges"
                          defaultValue={values?.programChallenges || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={PROGRAM_CHALLENGES[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(PROGRAM_CHALLENGES)?.map(
                            ([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.programChallenges && (
                          <TextField
                            fullWidth
                            onChange={onChange}
                            label="Other program challenges"
                            name={`programChallengesOther[${languageIndex}]`}
                            value={values?.programChallengesOther &&
                              values?.programChallengesOther[languageIndex]
                            }
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>

                    <GridContainer >
                      <GridItem>
                        <InputLabel>Support needed</InputLabel>
                        <Select
                          fullWidth
                          labelId="supportNeeded"
                          id="supportNeededSelect"
                          name="supportNeeded"
                          defaultValue={values?.supportNeeded || []}
                          onChange={handleChange}
                          multiple
                          input={
                            <Input
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={selected => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}
                            >
                              {selected?.map(value => (
                                <Chip key={value} label={SUPPORT_NEEDED[value]} />
                              ))}
                            </Box>
                          )}
                          {...isTranslatable(false)}
                        >
                          {Object.entries(SUPPORT_NEEDED)?.map(
                            ([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </GridItem>
                      <GridItem>
                        {otherFields.supportNeeded && (
                          <TextField
                            fullWidth
                            onChange={onChange}
                            label="Other support needed"
                            name={`supportNeededOther[${languageIndex}]`}
                            value={
                              values?.supportNeededOther &&
                              values?.supportNeededOther[languageIndex]
                            }
                            {...isTranslatable(true)}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </AccordionItem>

                  <AccordionItem title="Staff metrics">
                    <Grid container spacing={3} p={1} alignItems="center">

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Staff full time"
                          fullWidth
                          name="staffFullTime"
                          id="staffFullTime"
                          type="number"
                          onChange={onChange}
                          defaultValue={values?.staffFullTime || ""}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Staff part time"
                          fullWidth
                          type="number"
                          name="staffPartTime"
                          id="staffPartTime"
                          onChange={onChange}
                          defaultValue={values?.staffPartTime || ""}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Staff mentors coaches"
                          fullWidth
                          type="number"
                          name="staffMentorsCoaches"
                          id="staffMentorsCoaches"
                          onChange={onChange}
                          defaultValue={values?.staffMentorsCoaches || ""}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Staff volunteers interns"
                          fullWidth
                          type="number"
                          name="staffVolunteersInterns"
                          id="staffVolunteersInterns"
                          onChange={onChange}
                          defaultValue={values?.staffVolunteersInterns || ""}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                          <InputLabel>Women staff</InputLabel>
                          <Select
                            label="Women staff"
                            onChange={onChange}
                            defaultValue={values?.womenStaff || ""}
                            name="womenStaff"
                            id="womenStaff"
                            {...isTranslatable(false)}
                          >
                            <MenuItem>None</MenuItem>
                            {Object.entries(WOMEN_STAFF).map(([key, value]) => (
                              <MenuItem key={value} value={key}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                          {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Directed by woman"
                          name="directedByWoman"
                          onChange={onChange}
                          checked={values?.directedByWoman}
                          {...isTranslatable(false)}
                        />
                        {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                      </Grid>

                    </Grid>
                  </AccordionItem>

                  <AccordionItem title="Result metrics">
                    <Grid container spacing={3} p={1} alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <FieldArray
                          name="entrepreneursSupported"
                          render={(arrayHelpers) => (
                            <>
                              <InputLabel>Entrepreneurs supported</InputLabel>
                              {values?.entrepreneursSupported?.length > 0 && (
                                values.entrepreneursSupported.map((friend, index, array) => (
                                  <React.Fragment key={index}>
                                    <GridContainer>
                                      <GridItem>
                                        <Typography variant='subtitle2' >
                                          {2018 + index}:
                                        </Typography>
                                      </GridItem>
                                      <GridItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Select
                                          onChange={onChange}
                                          label="Entrepreneurssupported"
                                          id="entrepreneursSupportedSelect"
                                          name={`entrepreneursSupported[${index}]`}
                                          value={`${values?.entrepreneursSupported[index]}` ? `${values?.entrepreneursSupported[index]}` : ''}
                                          {...isTranslatable(false)}
                                        >
                                          {Object.entries(ENTREPRENEUR_SUPPORTED).map(([key, value]) => (
                                            <MenuItem key={key} value={key} >
                                              {value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <Button
                                          type="button"
                                          disabled={array.length === 1}
                                          onClick={() => {
                                            values.entrepreneursSupported.splice(index, 1)
                                            setFieldValue('entrepreneursSupported', values.entrepreneursSupported)
                                          }} // remove a friend from the list
                                        >
                                          x
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </React.Fragment>
                                ))
                              )
                              }
                              <Button type="button" variant="outlined" onClick={() => insertNextYear(arrayHelpers, values.entrepreneursSupported)}>
                                + Next Year
                              </Button>
                              {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                              <ErrorMessage name="entrepreneursSupported">{msg => <FormHelperText>Required</FormHelperText>}</ErrorMessage>
                            </>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FieldArray
                          name="newBusinessesCreated"
                          render={(arrayHelpers) => (
                            <>
                              <InputLabel>New businesses created</InputLabel>
                              {values?.newBusinessesCreated?.length > 0 && (
                                values.newBusinessesCreated.map((friend, index, array) => (
                                  <React.Fragment key={index}>
                                    <GridContainer>
                                      <GridItem>
                                        <Typography variant='subtitle2' >
                                          {2018 + index}:
                                        </Typography>
                                      </GridItem>
                                      <GridItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Select
                                          onChange={onChange}
                                          label="Newbusinessescreated"
                                          id="newBusinessesCreatedSelect"
                                          name={`newBusinessesCreated[${index}]`}
                                          value={values?.newBusinessesCreated?.[index] ? values?.newBusinessesCreated[index] : ''}
                                          {...isTranslatable(false)}
                                        >
                                          <MenuItem>None</MenuItem>
                                          {Object.entries(NEW_BUSINESS_CREATED).map(([key, value]) => (
                                            <MenuItem key={value} value={key}>
                                              {value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <Button
                                          type="button"
                                          disabled={array.length === 1}
                                          onClick={() => {
                                            values.newBusinessesCreated.splice(index, 1)
                                            setFieldValue('newBusinessesCreated', values.newBusinessesCreated)
                                          }} // remove a friend from the list
                                        >
                                          x
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </React.Fragment>
                                ))
                              )}
                              <Button type="button" variant="outlined" onClick={() => insertNextYear(arrayHelpers, values.newBusinessesCreated || [])}>
                                + Next Year
                              </Button>
                              {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                              <ErrorMessage name="newBusinessesCreated">{msg => <FormHelperText>Required</FormHelperText>}</ErrorMessage>
                            </>
                          )}
                        />
                      </Grid>


                      <Grid item xs={12} sm={6}>
                        <FieldArray
                          name="existingBusinessesSupported"
                          render={(arrayHelpers) => (
                            <>
                              <InputLabel>Existing businesses supported</InputLabel>
                              {values?.existingBusinessesSupported?.length > 0 && (
                                values.existingBusinessesSupported.map((friend, index, array) => (
                                  <React.Fragment key={index}>
                                    <GridContainer>
                                      <GridItem>
                                        <Typography variant='subtitle2' >
                                          {2018 + index}:
                                        </Typography>
                                      </GridItem>
                                      <GridItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Select
                                          onChange={onChange}
                                          label="Existingbusinessessupported"
                                          id="existingBusinessesSupportedSelect"
                                          name={`existingBusinessesSupported[${index}]`}
                                          value={values?.existingBusinessesSupported?.[index]
                                            ? values?.existingBusinessesSupported[index] : ''
                                          }
                                          {...isTranslatable(false)}
                                        >
                                          <MenuItem>None</MenuItem>
                                          {Object.entries(EXISTING_BUSINESS_SUPPORTED).map(([key, value]) => (
                                            <MenuItem key={value} value={key}>
                                              {value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <Button
                                          type="button"
                                          disabled={array.length === 1}
                                          onClick={() => {
                                            values.existingBusinessesSupported.splice(index, 1)
                                            setFieldValue('existingBusinessesSupported', values.existingBusinessesSupported)
                                          }} // remove a friend from the list
                                        >
                                          x
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </React.Fragment>
                                ))
                              )}
                              <Button type="button" variant="outlined" onClick={() => insertNextYear(arrayHelpers, values.existingBusinessesSupported)}>
                                + Next Year
                              </Button>
                              {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                              <ErrorMessage name="existingBusinessesSupported">{msg => <FormHelperText>Required</FormHelperText>}</ErrorMessage>
                            </>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FieldArray
                          name="jobsCreated"
                          render={(arrayHelpers) => (
                            <>
                              <InputLabel>Jobs created</InputLabel>
                              {values?.jobsCreated?.length > 0 && (
                                values.jobsCreated.map((friend, index, array) => (
                                  <React.Fragment key={index}>
                                    <GridContainer>
                                      <GridItem>
                                        <Typography variant='subtitle2' >
                                          {2018 + index}:
                                        </Typography>
                                      </GridItem>
                                      <GridItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Select
                                          label="Jobscreated"
                                          id="jobsCreatedSelect"
                                          onChange={onChange}
                                          name={`jobsCreated[${index}]`}
                                          value={values?.jobsCreated?.[index] ? values?.jobsCreated[index] : ''}
                                          {...isTranslatable(false)}
                                        >
                                          <MenuItem>None</MenuItem>
                                          {Object.entries(JOBS_CREATED).map(([key, value]) => (
                                            <MenuItem key={value} value={key}>
                                              {value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <Button
                                          type="button"
                                          disabled={array.length === 1}
                                          onClick={() => {
                                            values.jobsCreated.splice(index, 1)
                                            setFieldValue('jobsCreated', values.jobsCreated)
                                          }} // remove a friend from the list
                                        >
                                          x
                                        </Button>
                                      </GridItem>
                                    </GridContainer>
                                  </React.Fragment>
                                ))
                              )}
                              <Button type="button" variant="outlined" onClick={() => insertNextYear(arrayHelpers, values.jobsCreated)}>
                                + Next Year
                              </Button>
                              {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                              <ErrorMessage name="jobsCreated">{msg => <FormHelperText>Required</FormHelperText>}</ErrorMessage>
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </AccordionItem>

                  <AccordionItem title="Publication options">
                    <Grid container spacing={3} p={1} alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Published"
                          name="published"
                          onChange={onChange}
                          checked={values?.published}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Featured"
                          name="featured"
                          onChange={onChange}
                          checked={values?.featured}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Mark as partner"
                          name="partner"
                          onChange={onChange}
                          checked={values?.partner}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                    </Grid>
                  </AccordionItem>

                  {data && (
                    <AccordionItem title="Data collecting info">
                      <Typography>
                        Survey sent: {data.surveySent ? <Check /> : <Block />}
                      </Typography>
                      <Typography>
                        Survey filled: {data.surveyFilled || "n/d"}
                      </Typography>
                      <Typography>
                        Date checked:
                        {data.dateChecked
                          ? new Date(data.dateChecked).toLocaleDateString()
                          : "n/d"}
                      </Typography>
                      <Typography>
                        Source:
                        {(data.source &&
                          data.source.map((item, index) => (
                            <React.Fragment key={index}>
                              <Chip label={item} size="small" />
                            </React.Fragment>
                          ))) ||
                          "n/d"}
                      </Typography>
                    </AccordionItem>
                  )}
                </Accordion>
                <Grid container spacing={4} justifyContent='flex-end'>
                  <Grid item sm={2} xs={3} container>
                    <Button style={{ width: '100%' }} variant="contained"
                      onClick={() => history.goBack()}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} container>
                    <Button
                      style={{ width: '100%' }}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >SAVE</Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik >}
    </>
  );
};

OrganizationForm.defaultProps = {
  languageIndex: 0
};

export default withRouter(OrganizationForm);
