import React, { useCallback } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import JobList from "../../components/JobList/JobList";
import JobFilterForm from "../../forms/JobFilter/JobFilterForm";
import { _deleteJobPostMutation, getJobListingData } from "../../api";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { useMemo } from "react";
import history from "../../history";
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from 'notistack'

const JobPortal = () => {

  const { enqueueSnackbar } = useSnackbar()


  const [sortData, setSortData] = useState(["_id:desc"]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = React.useState(0);
  const [filters, setFilters] = React.useState({});

  const [deleteJobPost] = useMutation(_deleteJobPostMutation, {})


  //Parse filters Functions:
  const parseFilters = useMemo(() => {
    const { keyword, role, country } = filters;
    if (keyword) {
      var searchKeyword = keyword
        .replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    }
    return {
      ...(keyword && { jobTitle_contains: searchKeyword }),
      ...(country && { country })
    };
  }, [filters]);

  const {
    data: { getJob: jobDataList = [], countJob: rowsInTotal } = {},
    loading,
    error
  } = useQuery(getJobListingData, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters &&
        Object.keys(filters).length &&
        Object.keys(parseFilters).length
          ? { where: parseFilters }
          : {})
      }
    }
  });

  //Clear filter function:
  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/jobList");
  }, [history]);

  //Apply filters function:
  const handleFiltersSubmit = useCallback(fields => {
    setPage(0);
    setFilters({ ...fields });
  }, []);

  //Sorting function:
  const sortFilter = useCallback((value, key) => {
    if(value == "countryName"){
      setSortData([`country:${key}`]);
    }else{
      setSortData([`${value}:${key}`]);
    }
  }, []);

  const handleRowPerChange = (value) => {
    setRowsPerPage(value)

  }

    //Delete function:
    const handleDeleteCalllback = useCallback(async (id, name) => {
      deleteJobPost({
        variables: {
          id,
        },
        refetchQueries: [{
          query: getJobListingData,
          variables: {
            params: {
              limit: rowsPerPage,
              skip: page * rowsPerPage,
              ...(filters && Object.keys(filters).length
                ? { where: parseFilters }
                : {}
              )
            },
          }
        }],
        update: (proxy) => {
          const update = ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getJobListingData], // queries you want to automatically update
            searchVariables: {},
            operator: 'ANY',
            operation: 'REMOVE',
            mutationResult: { _id: id },
            ID: '_id',
          })
          if (update) enqueueSnackbar(`Survey ${name} has been deleted.`, { variant: 'success' })
        }
      }).then(() => { }).catch((e) => {
        enqueueSnackbar('Cannot delete Survey.', { variant: 'error' })
      })
    }, [])

  return (
    <Authenticated>
      <Layout
        title="Job List"
        head={
          <JobFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <JobList 
          data={jobDataList} 
          sortFilter={sortFilter}             
          deleteCallback={handleDeleteCalllback}
          />

          {rowsInTotal > rowsPerPage && (
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          )}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default JobPortal;
