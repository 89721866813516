import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
// import Index from './pages/Index'
import Organizations from './pages/Organizations'
import OrganizationNew from './pages/OrganizationNew'
import OrganizationEdit from './pages/OrganizationEdit'
import Users from './pages/Users'
import UserNew from './pages/UserNew'
import UserEdit from './pages/UserEdit'
import Newsfeed from './pages/Newsfeed'
import NewsfeedNew from './pages/NewsfeedNew'
import NewsfeedEdit from './pages/NewsfeedEdit'
import Resources from './pages/Resources'
import ArchivedResources from './pages/ArchivedResources'
import MAndE from './pages/MAndE'
import ResourceNew from './pages/ResourceNew'
import ResourceEdit from './pages/ResourceEdit'
import Login from './pages/Login'
import ErrorPage from './pages/ErrorPage'
import AVV from './pages/AVV'
import history from './history'
import Survey from './pages/Survey'
import SurveyNew from './pages/SurveyNew'
import SurveyEdit from './pages/SurveyEdit'
import CarouselEdit from './pages/CarouselEdit'
import Carousel from './pages/DynamicCarousel'
import CarouselNew from './pages/CarouselNew'
import FAQSection from './pages/FAQSection'
import FAQNew from './pages/FAQNew'
import FAQEdit from './pages/FAQEdit'
import DynamicLanguage from './pages/DynamicLanguage'
import DynamicLanguageNew from './pages/DynamicLanguageNew'
import DynamicLanguageEdit from './pages/DynamicLanguageEdit'
import DynamicSurvey from './pages/DynamicSurvey/DynamicSurvey'
import DynamicSurveyNew from './pages/DynamicSurvey/DynamicSurveyNew'
import DynamicSurveyEdit from './pages/DynamicSurvey/DynamicSurveyEdit'
import DynamicSurveyAnalytics from './pages/DynamicSurvey/DynamicSurveyAnalytics'
import Analytics from './pages/Analytics'
import ResourceAnalyticsDetails from './components/Analytics/ResourceAnalytics/ResourceAnalyticsDetails'
import GA4AnalyticsDetails from './components/Analytics/GA4Analytics/GA4AnalyticsDetails'
import RecurringUserDetails from './components/Analytics/RecuuringUsers/RecurringUserDetails'
import RedirectLinkAnalyticsDetails from './components/Analytics/RedirectLinkAnalytics/RedirectLinkAnalyticsDetails'
import JobPortal from './pages/JobPortal/JobPortal'
import JobView from './pages/JobPortal/JobView'

/**
 * This file contains router paths for the authenticated part of the app. When
 * user is not authenticated and he requests a component that generates a
 * graphql query agains a non-public data, he gets a JS redirecton to "log in"
 * page in response. This is how the sensitive data protection works.
 */
const Routes = () =>
  <Router history={history}>
    <Switch>
      <Route exact path='/' render={Organizations} />
      {/* <Route exact path='/dashboard' render={Index} /> */}
      <Route exact path='/organizations' component={Organizations} />
      <Route exact path='/organizations/new' component={OrganizationNew} />
      <Route exact path='/organizations/:slug' component={OrganizationEdit} />
      <Route exact path='/users' component={Users} />
      <Route exact path='/users/new' component={UserNew} />
      <Route exact path='/users/:id' component={UserEdit} />
      <Route exact path='/newsfeed' component={Newsfeed} />
      <Route exact path='/newsfeed/new' component={NewsfeedNew} />
      <Route exact path='/newsfeed/:slug' component={NewsfeedEdit} />
      <Route exact path='/archived-resources' component={ArchivedResources} />
      <Route exact path='/resources' component={Resources} />
      <Route exact path='/resources/new' component={ResourceNew} />
      <Route exact path='/resources/:id' component={ResourceEdit} />
      <Route exact path='/m-e' component={MAndE} />
      <Route exact path='/analytics' component={Analytics} />
      <Route exact path='/analytics/resource' component={ResourceAnalyticsDetails} />
      <Route exact path='/analytics/ga4' component={GA4AnalyticsDetails} />
      <Route exact path='/analytics/recurringUsers' component={RecurringUserDetails} />
      <Route exact path='/analytics/redirectLink' component={RedirectLinkAnalyticsDetails} />
      <Route exact path='/survey/new' component={SurveyNew} />
      <Route exact path='/survey' component={Survey} />
      <Route exact path='/survey/:slug' component={SurveyEdit} />
      <Route exact path='/dynamicSurvey' component={DynamicSurvey} />
      <Route exact path='/dynamicSurvey/new' component={DynamicSurveyNew} />
      <Route exact path='/dynamicSurvey/:id' component={DynamicSurveyEdit} />
      <Route exact path='/dynamicSurveyAnalytics/:id' component={DynamicSurveyAnalytics} />
      <Route exact path='/carousel' component={Carousel} />
      <Route exact path='/carousel/new' component={CarouselNew} />
      <Route exact path='/carousel/:id' component={CarouselEdit} />
      <Route exact path='/language' component={DynamicLanguage} />
      <Route exact path='/language/new' component={DynamicLanguageNew} />
      <Route exact path='/language/:id' component={DynamicLanguageEdit} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/error-page/:error' component={ErrorPage} />
      <Route exact path='/avv' component={AVV} />
      <Route exact path='/FAQ' component={FAQSection} />
      <Route exact path='/FAQ/new' component={FAQNew} />
      <Route exact path='/FAQ/:id' component={FAQEdit} />
      <Route exact path='/jobList' component={JobPortal} />
      <Route exact path='/jobList/:id' component={JobView} />


      <Route render={() => <Redirect to='/error/404' />} />
    </Switch>
  </Router>

export default Routes