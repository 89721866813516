import hash from 'hash.js'


export default text => ({
  digest: hash
    .sha256()
    .update(text)
    .digest('hex'),
  algorithm: 'sha-256',
})
