import React, { useEffect, useState } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import { useLazyQuery } from "@apollo/react-hooks";
import { getJobByIdQuery } from "../../api";
import Loader from "../../components/Loader";
import { Button, ButtonGroup, FormControl, Select } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { saveJobQueriesData } from "../../api";
import { useSnackbar } from "notistack";

const JobView = ({
  match: {
    params: { id }
  }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [
    runJOBQuery,
    { data: { getJobById: data } = {}, loading, error }
  ] = useLazyQuery(getJobByIdQuery);

  useEffect(() => {
    runJOBQuery({
      variables: {
        id: id
      }
    });
  }, [id]);

  const [saveJob] = useMutation(saveJobQueriesData);
  const [approvedValue, setApprovedValue] = useState({
    id: null,
    jobStatus: null
  });

  const updateApprovedStatus = () => {
    if (approvedValue.id) {
      saveJob({
        variables: {
          data: approvedValue
        }
      })
        .then(res => {
          console.log(res);
          setApprovedValue({
            id: null,
            jobStatus: null
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      enqueueSnackbar("Please change the status of job.", { variant: "error" });
    }
  };

  return (
    <Authenticated>
      <Layout title="View Job">
        <Loader loading={loading} error={error}>
          <div className="view-job-info-main-wrapper">
            <div className="view-job-info-wrapper">
              <div className="view-job-info-logo">
                <img src={data?.image} />
              </div>
              <ul className="view-job-info-list">
                <li>Title : {data?.jobTitle}</li>
                <li>company Name : {data?.companyName}</li>
                <li>
                  Location : {data?.city},{data?.country}
                </li>
                <li>{data?.tags[0]}</li>
              </ul>
            </div>
            <div className="view-job-info-details-title">
              <h4>Job Details</h4>
              <ul>
                <li>Job Role : {data?.department}</li>
                <li>{data?.jobLevel}</li>
                <li>{data?.jobType}</li>
                <li>
                  {data?.maxSalary} - {data?.minSalary}
                </li>
                <li>{data?.salaryType}</li>
                <li>{data?.vacancies}</li>
                <li>{data?.workMode}</li>
                <li>{data?.link}</li>
                <li>{data?.remotePosition}</li>
                <li>{data?.expirationDate}</li>
                <li>{data?.jobDescription}</li>
              </ul>
            </div>
            <div className="view-job-info-requirements-title">
              <h4>Requirenments For Job</h4>
              <ul>
                <li>{data?.education}</li>
                <li>{data?.experiance}</li>
              </ul>
            </div>
            <div className="view-job-info-details-status-changes-main-div">
              <FormControl
                variant="outlined"
                className={
                  data?.jobStatus === "approved" ? "approved" : "pending"
                }
              >
                <Select
                  native
                  defaultValue={data?.jobStatus ? data.jobStatus : "pending"}
                  onChange={e => {
                    setApprovedValue({
                      id: data?._id,
                      jobStatus: e.target.value
                    });
                  }}
                >
                  <option value="pending" key="Pending">
                    Pending
                  </option>
                  <option value="approved" key="Approved">
                    Approved
                  </option>
                </Select>
              </FormControl>
              <Button
                className="view-job-info-details-update-button"
                onClick={() => updateApprovedStatus()}
              >
                Update
              </Button>
            </div>
          </div>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default JobView;
