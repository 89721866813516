import React, { useState, useCallback, useMemo } from 'react'
import Authenticated from '../../../containers/Authenticated'
import Layout from '../../Layout'
import Table from '../../Table'
import { useQuery } from '@apollo/react-hooks'
import Pagination from '../../Pagination'
import Loader from '../../Loader'
import RedirectLinkAnalyticsFilterForm from '../../../forms/Analytics/RedirectLinkAnalyticsFilterForm'
import { getAnalyticsCountQuery, getRedirectAnalyticsQuery } from '../../../api/_queries'


const RedirectLinkAnalyticsList = ({ history }) => {

    //Pagination State:
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    //Sort State:
    const [sortData, setSortData] = useState(['_id:desc']);

    //Filter State:
    const [filters, setFilters] = React.useState({});


    //pagination function:
    const handleRowPerChange = useCallback((value) => {
        setRowsPerPage(value)
    }, [])

    //sort function:
    const sortFilter = useCallback((value, key) => {
        setSortData([`${value}:${key}`]);
    }, []);

    //parseFilter:
    const parseFilters = useMemo(() => {
        const { keyword, moduleName,from_date,to_date } = filters;

        if (keyword) {
            var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
                .replace(/\!/g, "\\\\!")
                .replace(/\@/g, "\\\\@")
                .replace(/\#/g, "\\\\#")
                .replace(/\$/g, "\\\\\\$")
                .replace(/\%/g, "\\\\%")
                .replace(/\^/g, "\\\\^")
                .replace(/\&/g, "\\\\&")
                .replace(/\*/g, "\\\\*")
                .replace(/\)/g, "\\\\)")
                .replace(/\(/g, "\\\\(")
                .replace(/\[/g, "\\\\[")
                .replace(/\]/g, "\\\\]")
                .replace(/\;/g, "\\\\;")
                .replace(/\{/g, "\\\\{")
                .replace(/\}/g, "\\\\}")
                .replace(/\?/g, "\\\\?")
                .replace(/\,/g, "\\\\,");
        }
        return {
            ...keyword && { link_matches: searchKeyword },
            ...(moduleName && { moduleName_matches: moduleName }),
            ...from_date && { from_date },
            ...to_date && { to_date },
        };
    }, [filters]);

    //Filter functions:
    const handleClear = useCallback(() => {
        setFilters({});
        history.push("/analytics/redirectLink");
    }, [history]);

    const handleFiltersSubmit = useCallback((fields) => {
        setPage(0)
        setFilters({ ...fields })
    }, []);

    const { data: { getRedirectLinkAnalytics = [] } = {},
        loading, error
    } = useQuery(getRedirectAnalyticsQuery, {
        variables: {
            params: {
                sort: sortData,
                limit: rowsPerPage,
                skip: page * rowsPerPage,
                ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
                    ? { where: parseFilters, "analyticType": "redirectLink" }
                    : { "analyticType": "redirectLink" }
                ),

            },
        }
    })

    // getRedirectLinkCountQuery:
    const { data: { countAFDBAnalytics: rowsInTotal } = {},

    } = useQuery(getAnalyticsCountQuery, {
        variables: {
            params: {
                where: {
                    ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
                        ? { ...parseFilters, "analyticType": "redirectLink" }
                        : { "analyticType": "redirectLink" }
                    )
                }
            }
        }
    })
    return (
        <Authenticated>
            <Layout title='Redirectlink Analytics Data' head={
                <RedirectLinkAnalyticsFilterForm
                    filters={filters}
                    callback={handleFiltersSubmit}
                    handleClear={handleClear}
                />
            }>
                <Loader loading={loading} error={error}>
                    <Table
                        data={getRedirectLinkAnalytics?.map((item) => {
                            let userArr = item?.user?.map(item => item?.name)
                            userArr = new Set(userArr)
                            return {
                                moduleName: item?.moduleName || '',
                                moduleTitle: item?.moduleTitle || '',
                                link: item?.link || '',
                                redirectCount: item?.redirectCount || 0,
                                user: [...userArr].join(",") || ""
                            }

                        })}
                        labels={[
                            {
                                name: 'Module Name',
                                field: 'moduleName',
                            },
                            {
                                name: "Module Title",
                                field: "moduleTitle"
                            },
                            {
                                name: 'Link',
                                field: 'link',
                            },
                            {
                                name: 'Count',
                                field: 'redirectCount',
                            },
                            {
                                name: 'User',
                                field: 'user',
                            },

                        ]}
                        sortFilter={sortFilter}
                    />
                    <Pagination
                        page={page}
                        count={rowsInTotal}
                        rowsPerPage={rowsPerPage}
                        callback={newPage => setPage(newPage)}
                        handleRowPerChange={handleRowPerChange}
                    />
                </Loader>
            </Layout>
        </Authenticated>
    )
}

export default RedirectLinkAnalyticsList