import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from '@material-ui/core'
import { Check, Block, Star, StarBorder } from '@material-ui/icons'
import Table from './Table'
import ConfirmDelete from './ConfirmDelete'


const OrganizationsList = ({ data, deleteCallback, sortFilter }) => {
  return (
    <Table
      data={data.map(item => ({
        name: item.name,
        invitation: item.registered
          ? 'Accepted'
          : item.invited && !item.registered
            ? 'Pending'
            : 'Not sent',
        published: item.published ? <Check color='secondary' /> : <Block color='disabled' />,
        featured: item.featured ? <Star color='primary' /> : <StarBorder color='disabled' />,
        actions: <ButtonGroup size='small' variant='text'>
          <Button
            component={Link}
            to={`/organizations/${item.slug}`}
          >Edit</Button>
          <ConfirmDelete
            id={item._id}
            label='organization'
            name={item.name}
            deleteCallback={deleteCallback}
          />
        </ButtonGroup>,
        createdAt: item?.createdAt,
        registration_date:item?.registration_date,
        invitition_date:item?.invitition_date
      }))}
      labels={[
        {
          name: 'Name',
          field: 'name',
        },
        {
          name: 'Invitation',
          field: 'invitation',
        },
        {
          name: 'Published',
          field: 'published',
        },
        {
          name: 'Featured',
          field: 'featured',
        },
        {
          name: 'Registration Date',
          field: 'registration_date',
        },
        {
          name: 'Invitition Date',
          field: 'invitition_date',
        },
        {
          name: 'Created At',
          field: 'createdAt',
        },
        {
          name: 'Actions',
          field: 'actions',
        },
      ]}
      sortFilter={sortFilter}
    />
  )
}

export default OrganizationsList
