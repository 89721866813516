import React, { useState, useCallback, useEffect } from "react";
import "./CohortForm.css";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import ApolloCacheUpdater from "apollo-cache-updater";
import debounce from "lodash.debounce";
import * as Yup from "yup";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import BUSINESS_SECTORS from "../../settings/enums/target-sectors.json";
import SERVICES from "../../settings/enums/categories.json";
import AFRICAN_ISO from "../../settings/enums/africa-iso-codes.json";
import history from "../../history";
import LANGUAGES from "../../settings/enums/languages.json";

import Spinner from "../../components/Spinner";
import MultiImageUpload from "../../components/MultiImageUpload";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Wysiwyg from "../Wysiwyg";
import "./CohortForm.css";
import ImageUpload from "../../components/ImageUpload";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { _safeS3SignMutation } from "../../api";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { saveCohortAdminData } from "../../api/_mutations";
import { useSnackbar } from "notistack";
import maxFileSize from "../../settings/max-file-size.json";
import { graphqlError } from "../../utils";
import {
  countCohortQuery,
  getCohortsData,
  getEsosNameQuery
} from "../../api/_queries";
import { Autocomplete } from "@material-ui/lab";

const CohortForm = ({
  data,
  languageIndex,
  languagesTotal,
  isEnableReinitialize
}) => {
  const [safeS3Sign] = useMutation(_safeS3SignMutation);
  const [saveCohortAdmin, { loading }] = useMutation(saveCohortAdminData);
  const { maxSize } = maxFileSize;
  const [changingLanguage, setChangingLanguage] = useState();
  const [fileUploading, setFileUploading] = React.useState();
  const [initialValues, setInitialValues] = React.useState(data);
  const [otherInputs, setOtherInputs] = useState(() => {
    return data?.participants_eso?.other || [""];
  });

  const { enqueueSnackbar } = useSnackbar();
  const firstUpdate = React.useRef(true);

  const [
    fetchEsos,
    { data: { getEsos = [] } = {}, loading: getEsosLoading }
  ] = useLazyQuery(getEsosNameQuery);

  useEffect(() => {
    fetchEsos({
      variables: {
        params: {
          sort: ["_id:desc"],
          skip: 0,
          where: {} // Filter based on input
        }
      }
    });
  }, []);

  const {
    data: { getEsos: getEsosName = [] } = {},
    loading: getEsosLoadig,
    error
  } = useQuery(getEsosNameQuery, {
    variables: {
      params: {
        sort: ["_id:desc"],

        skip: 0,
        where: {}
      }
    }
  });

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    if (data) {
      // setType(data.category)
      setInitialValues(data);
    }
    //   else setType(resourceTypeOptions[0].value)
  }, [data, firstUpdate]);
  
  useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        await setChangingLanguage(true);

        await setInitialValues({ ...data, ...initialValues });
        await setChangingLanguage(false);
      };
      handleAsync();
    }
  }, [languageIndex]);

  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (data?.participants_eso) {
      let esoOptions = [];
      if (data.participants_eso.eso && data.participants_eso.eso.length > 0) {
        esoOptions = [...data.participants_eso.eso];
      }

      // Add "others" option if there are other values
      if (
        data.participants_eso.other &&
        data.participants_eso.other.length > 0
      ) {
        esoOptions.push({ _id: "others", name: "Others" });
      }

      return esoOptions;
    }
    return [];
  });
  // Initialize otherInputs from data if available

  useEffect(() => {
    if (data?.participants_eso && getEsosName.length) {
      let esoOptions = [];
      if (data.participants_eso.eso && data.participants_eso.eso.length > 0) {
        esoOptions = [...data.participants_eso.eso];
      }

      if (
        data.participants_eso.other &&
        data.participants_eso.other.length > 0
      ) {
        esoOptions.push({ _id: "others", name: "Others" });
      }
      setSelectedOptions(esoOptions);
      setOtherInputs(data.participants_eso.other || [""]);
    }
  }, [data, getEsosName]);

  const handleSearch = useCallback(
    debounce(query => {
      fetchEsos({
        variables: {
          params: {
            sort: ["_id:desc"],
            skip: 0,
            where: query ? { name_contains: query } : {} // Filter based on input
          }
        }
      });
    }, 300), // Wait 300ms before calling API
    []
  );

  const handleSubmit = async fields => {
    const esoIds = selectedOptions
      .filter(option => option._id !== "others") // Exclude "Others"
      .map(option => option._id); // Extract IDs

    const otherValues = selectedOptions.some(option => option._id === "others")
      ? otherInputs.filter(input => input.trim() !== "") // Only keep non-empty values
      : [];

    const dataObject = {
      ...fields,
      language: Object.keys(LANGUAGES)[languageIndex],
      participants_eso: {
        eso: esoIds, // Array of selected ESO IDs
        other: otherValues // Array of "Others" custom values
      }
    };
    let allImages;
    if (Array.isArray(fields?.fileUrl)) {
      allImages = await fields?.fileUrl.reduce(async (arr, file) => {
        let attachmentUrl = file || null;
        if (attachmentUrl) {
          if (typeof attachmentUrl === "string")
            return [...(await arr), attachmentUrl];
          const { dataFile, name: filename } = file;
          const { size } = dataFile;
          if (size > maxSize) {
            // toast.error(translations.messagesNewMessageErrorFileBig)
            return null;
          }
          try {
            const response = await safeS3Sign({
              variables: {
                filename: filename,
                filetype: file.type
              }
            });
            const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
            const options = {
              headers: {
                "Content-Type": dataFile.type
              }
            };
            try {
              setFileUploading(true);
              await fetch(signedRequest, {
                method: "PUT",
                headers: options.headers,
                body: dataFile
              });
              setFileUploading(false);
              attachmentUrl = bucketUrl;
            } catch (e) {
              attachmentUrl = null;
              console.log(e);
              setFileUploading(false);
            }
          } catch (e) {
            const errMsg = graphqlError(e, {
              // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
              // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
            });
            if (errMsg) {
              // toast.error(errMsg)
            } else {
              // toast.error('Cannot uplaod file(s)')
              console.log(e);
            }
            return [...(await arr)];
          }
          return [...(await arr), attachmentUrl];
        }
      }, []);
      if (allImages && allImages.length > 0) {
        dataObject.fileUrl = allImages;
      }

      if (Array.isArray(dataObject.Reviews)) {
        dataObject.Reviews = await dataObject.Reviews.reduce(
          async (accPromise, review) => {
            console.log(review, "review");
            const acc = await accPromise;
            let photoUrl = review.photo || null;

            if (photoUrl) {
              if (typeof photoUrl === "string") {
                console.log("Existing photo URL:", photoUrl);
                return [...acc, { ...review, photo: photoUrl }];
              }

              const { dataFile, name: filename } = review.photo;
              const { size } = dataFile;

              if (size > maxSize) {
                console.error("File size is too large");
                return [...acc, { ...review, photo: null }];
              }

              try {
                console.log("Requesting S3 signed URL for:", filename);
                const response = await safeS3Sign({
                  variables: {
                    filename: filename,
                    filetype: review.photo.type
                  }
                });

                const {
                  signedRequest,
                  url: bucketUrl
                } = response.data._safeSignS3;
                console.log("Received S3 URL:", bucketUrl);

                const options = { headers: { "Content-Type": dataFile.type } };

                try {
                  console.log("Uploading to S3...");
                  await fetch(signedRequest, {
                    method: "PUT",
                    headers: options.headers,
                    body: dataFile
                  });

                  photoUrl = bucketUrl;
                  console.log(
                    "Upload successful! Updated photo URL:",
                    photoUrl
                  );
                } catch (uploadError) {
                  console.error("Upload failed:", uploadError);
                  photoUrl = null;
                }
              } catch (signError) {
                console.error("Error signing request:", signError);
                photoUrl = null;
              }
            }

            return [...acc, { ...review, photo: photoUrl }];
          },
          Promise.resolve([])
        );

        console.log(
          "Final Reviews Payload:",
          JSON.stringify(dataObject.Reviews, null, 2)
        );
      }

      saveCohortAdmin({
        variables: {
          data: {
            ...(data && data._id && { _id: data._id }),
            ...dataObject
          }
        },
        update: (proxy, { data: { saveCohortAdmin = {} } }) => {
          if (!data || !data._id) {
            const mutationResult = saveCohortAdmin;
            ApolloCacheUpdater({
              proxy,
              queriesToUpdate: [getCohortsData, countCohortQuery],
              searchVariables: {
                skip: 0
              },
              mutationResult: {
                ...mutationResult
              },
              ID: "_id"
            });
          }
        }
      })
        .then(() => {
          enqueueSnackbar("The Cohort has been saved.", {
            variant: "success"
          });

          history.replace("/cohortList");
        })
        .catch(e => {
          enqueueSnackbar("There was an error saving the cohort", {
            variant: "error"
          });
        });
    }
  };

  const formFields = {
    sectors: initialValues?.sectors || [],
    services: initialValues?.services || [],
    country: initialValues?.country || "",
    fileUrl: initialValues?.fileUrl?.map(file => file.url) || [],
    fb_url: initialValues?.fb_url || "",
    x_url: initialValues?.x_url || "",
    in_url: initialValues?.in_url || "",
    email: initialValues?.email || "",
    phone: initialValues?.phone || "",
    name: initialValues?.name || [],
    year: initialValues?.year || new Date(),
    details: initialValues?.details || [],
    impact_metrics: initialValues?.impact_metrics || [],
    disaggregations: initialValues?.disaggregations || [],
    Reviews:
      initialValues?.Reviews?.map(({ __typename, ...rest }) => rest) || [],

    // fromDate:initialValues?.fromDate || "",

    fromDate: initialValues?.fromDate
      ? new Date(initialValues.fromDate).toISOString().split("T")[0]
      : "",

    toDate: initialValues?.toDate
      ? new Date(initialValues.toDate).toISOString().split("T")[0]
      : "",
    name_eso: initialValues?.name_eso || [],
    participants_eso: {
      eso: initialValues?.participants_eso?.eso || [], // Array of ESO IDs
      other: initialValues?.participants_eso?.other || [] // Array of "Others" values
    }
  };

  let validationSchema = Yup.object().shape({
    sectors: Yup.array().min(1, "This field is required."),
    services: Yup.array().min(1, "This field is required."),
    country: Yup.string().required("This field is required."),
    fileUrl: Yup.array().min(1, "This field is required."),
    fb_url: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(/^(https|http):\/\/?[a-z0-9]/, "This is not a valid URL.")
      .required("This field is required."),
    x_url: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(/^(https|http):\/\/?[a-z0-9]/, "This is not a valid URL.")
      .required("This field is required."),
    in_url: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(/^(https|http):\/\/?[a-z0-9]/, "This is not a valid URL.")
      .required("This field is required."),
    email: Yup.string()
      .email("This is not a valid email address.")
      .required("This field is required."),
    phone: Yup.string()
      // .matches(
      //   // /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]{7,14}$/,
      //   "Invalid phone number format"
      // )
      .matches(
        /^(?:\d{10}|\d{11}|\d{15})$/,
        // /^[0-9]{1,4}[-\s./0-9]{7,14}$/,

        "Invalid phone number format"
      )
      .test(
        "no-plus-sign",
        "Invalid phone number format: '+' is not allowed",
        value => !value || !value.includes("+")
      )
      .required("Phone number is required"),
    name: Yup.array()
      .of(Yup.string())
      .test("name-ele", "This field is required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      }),
    year: Yup.date()
      .required("Year is required") // Make the field required
      .nullable(),

    // details: Yup.string()
    //   .max(300, "Details cannot exceed 300 characters")
    //   .required("Details are required")
    //   ,

    details:Yup.array()
    .of(Yup.string().max(300, "Detail field must not exceed 300 characters"))
    .min(1, "Detail is required")
    ,

   

    impact_metrics: 
    Yup.array()
      .of(Yup.string())
      .test("name-ele", "Impact metrics are required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      })
    ,
    disaggregations: Yup.array()
      .of(Yup.string())
      .test("name-ele", "Disaggregations are required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      })
    ,
    fromDate: Yup.date()
      .required("From date is required") // Make the field required
      .nullable(),
    toDate: Yup.date()
      .required("To date is required") // Make the field required
      .nullable(),
    name_eso: Yup.array()
    .of(Yup.string())
    .test("name-ele", "This field is required", function(value) {
      if (value && value[languageIndex]) return true;
      else return false;
    }),
    Reviews: Yup.array().of(
      Yup.object().shape({
        review: Yup.array()
          .of(Yup.string().max(300, "Each review must be at most 300 characters"))
          .when("$hasReviews", {
            is: true, // Apply validation only if reviews exist
            then: Yup.array()
              .of(
                Yup.string()
                  .max(300, "Review must be at most 300 characters")
                  .required("Review is required if a review exists")
              )
              .min(1, "At least one review is required if reviews exist"),
            otherwise: Yup.array().of(
              Yup.string().max(300, "Each review must be at most 300 characters")
            )
          })
      })
    ),
    participants_eso: Yup.object().shape({
      eso: Yup.array()
        .of(Yup.mixed()) // Ensure it's an array of mixed values
        .min(1, "At least one ESO must be selected")
        .required("This field is required"),
      other: Yup.array()
        .of(Yup.string().trim().required("Custom ESO value cannot be empty"))
        .when("eso", {
          is: (eso = []) => eso.includes("others"), // Validate if "others" is selected
          then: schema => schema.required("This field is required"),
          otherwise: schema => schema.notRequired(),
        }),
    }),
  });

  return (
    <div>
      <Formik
        enableReinitialize={isEnableReinitialize}
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, handleChange, errors,touched,setFieldTouched }) => {
          return (
            <Form>
              {console.log(errors, "errors")}
              <Grid container spacing={6} justifyContent="space-between">
                <Grid
                  item
                  md={6}
                  lg={4}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-mutiple-name-label">
                      Sectors <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      multiple
                      name="sectors"
                      onChange={handleChange}
                      value={values?.sectors || []}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {selected.map(value => (
                            <Chip
                              key={value}
                              label={BUSINESS_SECTORS[value]?.toUpperCase()}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {Object.entries(BUSINESS_SECTORS).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="sectors">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-mutiple-name-label">
                      Services <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      multiple
                      fullWidth
                      name="services"
                      label="Services offered"
                      onChange={handleChange}
                      value={values?.services || []}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {selected.map(value => (
                            <Chip
                              key={value}
                              label={SERVICES[value]?.label?.toUpperCase()}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {Object.entries(SERVICES).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="services">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>
                      Country <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                    >
                      {Object.entries(AFRICAN_ISO).map(([key, value]) => (
                        <MenuItem value={`${key}__${value}`} key={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="country">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <Box style={{ marginBottom: "5%", marginTop: "5%" }}>
                    <label>
                      Upload image <span style={{ color: "red" }}>*</span>
                    </label>
                    <MultiImageUpload
                      name={"fileUrl"}
                      value={values?.fileUrl}
                      setValue={setFieldValue}
                    />
                    <ErrorMessage name="fileUrl">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </Box>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Facebook URL <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`fb_url`}
                      value={values?.fb_url}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="fb_url">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Twitter URL <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`x_url`}
                      value={values?.x_url}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="x_url">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          LinkedIN URL <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`in_url`}
                      value={values?.in_url}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="in_url">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      name="email"
                      label={
                        <span>
                          Email address <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="email"
                      onChange={handleChange}
                      value={values && values.email ? values.email : ""}
                      fullWidth
                    />
                    <ErrorMessage name="email">
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      name="phone"
                      label={
                        <span>
                          Phone <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      value={values?.phone}
                      onChange={handleChange}
                      fullWidth
                    />
                    <ErrorMessage name="phone">
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={6}
                  lg={7}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl fullWidth>
                    <TextField
                      label={<span>Name</span>}
                      type="text"
                      name={`name[${languageIndex}]`}
                      value={values?.name[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="name">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>
                      From Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      fullWidth
                      type="date"
                      name="fromDate"
                      onChange={handleChange}
                      value={values.fromDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      // inputProps={{ max: new Date().toISOString().slice(0, 10) }}
                    />
                    <ErrorMessage name="fromDate">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <label>
                      To Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      fullWidth
                      type="date"
                      name="toDate"
                      onChange={handleChange}
                      value={values.toDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <ErrorMessage name="toDate">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="year-picker-dialog"
                        label="Year"
                        views={["year"]} // Only show year view
                        value={values?.year}
                        onChange={date => {
                          if (date instanceof Date && !isNaN(date)) {
                            setFieldValue(
                              "year",
                              date.getFullYear().toString()
                            ); // Store only the year as a string
                          } else {
                            setFieldValue("year", ""); // Reset if the date is invalid
                          }
                        }}
                        // onChange={date => setFieldValue("year", date)}
                        format="yyyy" // Display only the year
                        KeyboardButtonProps={{
                          "aria-label": "change year"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label={<span>Name of ESO</span>}
                      type="text"
                      // name={`name_eso`}
                      // value={values?.name_eso}
                      name={`name_eso[${languageIndex}]`}
                      value={values?.name_eso[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="name_eso">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: "20px" }}>
                    <label>
                      Name of participant ESOs{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Autocomplete
                      multiple
                    
                      loading={getEsosLoading}
                      options={[
                        ...selectedOptions, // Ensure previously selected ESOs are included
                        ...getEsos.filter(
                          option =>
                            !selectedOptions.some(
                              selected => selected._id === option._id
                            )
                        ),
                        ...(!selectedOptions.some(
                          option => option._id === "others"
                        )
                          ? [{ _id: "others", name: "Others" }]
                          : [])
                      ]}
                      getOptionLabel={option => option.name}
                      value={selectedOptions}
                      onChange={(event, newValue) => {
                        setSelectedOptions(newValue);
                        // const esoIds = newValue
                        //   .filter(option => option._id !== "others")
                        //   .map(option => option._id);

                        // // Update Formik values without triggering a reset
                        // setFieldValue("participants_eso.eso", esoIds, false);
                        const esoIds = newValue.map(option => option._id);

                        // If "others" is selected, ensure `other` is an array
                        if (esoIds.includes("others")) {
                          setFieldValue("participants_eso.other", [""]); // Start with an empty input
                        } else {
                          setFieldValue("participants_eso.other", []); // Reset when "others" is removed
                        }
                      
                        // Update Formik value
                        setFieldValue("participants_eso.eso", esoIds);
                      }}
                      onInputChange={(_, value) => handleSearch(value)}
                      filterSelectedOptions
                      renderInput={params => (
                        <TextField 
                        error={
                          touched.participants_eso?.eso &&
                          Boolean(errors.participants_eso?.eso)
                        }
                        helperText={
                          touched.participants_eso?.eso && errors.participants_eso?.eso
                        }
                        placeholder="Select ESO"
                        {...params} variant="outlined" />
                      )}
                      renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                          <Chip
                            key={option._id}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                    
                     
                  </FormControl>
                    
                 
                  {selectedOptions.some(option => option._id === "others") && (
                     
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      {otherInputs.map((input, index) => (
                        <div className="add-more-div">
                        <React.Fragment key={index}>
                          <TextField
                          fullWidth
                            value={input}
                            error={
                              touched.participants_eso?.other &&
                              Boolean(errors.participants_eso?.other?.[index])
                            }
                            helperText={
                              touched.participants_eso?.other &&
                              errors.participants_eso?.other?.[index]
                            }
                           
                            onChange={e => {
                              const newInputs = [...otherInputs];
                              newInputs[index] = e.target.value;
                              setOtherInputs(newInputs);
                              // Update Formik values without triggering a reset
                              setFieldValue(
                                "participants_eso.other",
                                newInputs.filter(input => input.trim() !== ""),
                                false
                              );
                              setFieldTouched(`participants_eso.other[${index}]`, true);
                            }}
                            variant="outlined"
                            placeholder="Enter custom value"
                            style={{ marginTop: 8 }}
                          />
                          {index>0 &&(
                          <Button
                           className="remove-button"
                            onClick={() => {
                              const newInputs = otherInputs.filter(
                                (_, i) => i !== index
                              );
                              setOtherInputs(newInputs);
                              // Update Formik values without triggering a reset
                              setFieldValue(
                                "participants_eso.other",
                                newInputs.filter(input => input.trim() !== ""),
                                false
                              );
                            }}
                            style={{color:"red"}}
                          >
                             <DeleteOutlineOutlined />
                          </Button>
                          )}
                        </React.Fragment>
                        </div>
                      ))}
                      <Button
                        onClick={() => {
                          setOtherInputs([...otherInputs, ""]);
                        }}
                        style={{ marginTop: "8px", marginBottom:"8px" }}
                        variant="contained"
                        color="primary"
                      >
                        Add More
                      </Button>
                    </FormControl>
                  )}
                 
                  <FormControl fullWidth>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      maxRows={4}
                      placeholder="Add Details"
                      name={`details[${languageIndex}]`}
                      value={values?.details[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name={"details"}>
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "5px" }}>
                   

<Wysiwyg
                                     key={languageIndex}
                                      name={`impact_metrics[${languageIndex}]`}
                                      label='Imapct Metrics'
                                      placeholder={'Imapct Metrics'}
                                      setValue={setFieldValue}
                                      onChange={handleChange}
                                      value={(values?.impact_metrics.length && values.impact_metrics[languageIndex]) || ''}
                                      // setEditorValues={true}
                                    />
                    <ErrorMessage name={"impact_metrics"}>
                      {msg => (
                        <div
                          className="form-error"
                          style={{ color: "red", paddingTop: "50px" }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: "50px" }}>

                     <Wysiwyg
                                     key={languageIndex}
                                      name={`disaggregations[${languageIndex}]`}
                                      label='Disaggregations'
                                      placeholder={'Disaggregations'}
                                      setValue={setFieldValue}
                                      onChange={handleChange}
                                      value={(values?.disaggregations.length && values.disaggregations[languageIndex]) || ''}
                                      // setEditorValues={true}
                                    />
                    <ErrorMessage name={"disaggregations"}>
                      {msg => (
                        <div
                          className="form-error"
                          style={{ color: "red", paddingTop: "50px" }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FieldArray
                    name="Reviews"
                    render={arrayHelpers => (
                      <div className="add-review-div">
                        <button
                          style={{ margintTop: "50px", cursor: "pointer" }}
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              name: "",
                              position: "",
                              review: "",
                              photo: null
                            })
                          }
                        >
                          Add Review
                        </button>
                        {values.Reviews.map((review, index) => (
                          <div
                            key={index}
                            className="p-4 border rounded mb-4 space-y-2 add-review-main-div"
                          >
                            <FormControl fullWidth>
                              <TextField
                                label={<span>Name</span>}
                                type="text"
                                // value={review.name[languageIndex]}
                                value={values.Reviews[index].name[languageIndex] || ""}
                                name={`Reviews[${index}].name[${languageIndex}]`}
                                onChange={handleChange}
                              />
                              <ErrorMessage name={`Reviews[${index}].name`}>
                                {msg => (
                                  <FormHelperText style={{ color: "red" }}>
                                    {msg}
                                  </FormHelperText>
                                )}
                              </ErrorMessage>
                            </FormControl>

                            <FormControl fullWidth>
                              <TextField
                                label={<span>Position</span>}
                                type="text"
                                onChange={handleChange}
                                // value={review.position[languageIndex]}
                                value={values.Reviews[index].position[languageIndex] || ""}

                                name={`Reviews[${index}].position[${languageIndex}]`}
                              />
                              <ErrorMessage name={`Reviews[${index}].position`}>
                                {msg => (
                                  <FormHelperText style={{ color: "red" }}>
                                    {msg}
                                  </FormHelperText>
                                )}
                              </ErrorMessage>
                            </FormControl>

                            <FormControl fullWidth>
                              <TextareaAutosize
                                aria-label="minimum height"
                                minRows={4}
                                maxRows={4}
                                placeholder="Add Review"
                                onChange={handleChange}
                                // value={review.review[languageIndex]}
                                value={values.Reviews[index].review[languageIndex] || ""}

                                name={`Reviews[${index}].review[${languageIndex}]`}
                              />
                              <ErrorMessage name={`Reviews[${index}].review`}>
                                {msg => (
                                  <FormHelperText style={{ color: "red" }}>
                                    {msg}
                                  </FormHelperText>
                                )}
                              </ErrorMessage>
                            </FormControl>

                            <div>
                              <label className="block text-sm font-medium">
                                Photo
                              </label>
                              <ImageUpload
                                name={`Reviews[${index}].photo`}
                                value={values.Reviews[index].photo} // Pass the current value from Formik
                                setFieldValue={setFieldValue}
                                onChange={e =>
                                  setFieldValue(
                                    `Reviews[${index}].photo`,
                                    e.currentTarget.files[0]
                                  )
                                }
                              />
                            </div>

                            <button
                              className="remove-button"
                              onClick={() => arrayHelpers.remove(index)} // Remove this set
                            >
                              <DeleteOutlineOutlined />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5} justifyContent="flex-end">
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {loading ? <Spinner color="secondary" size={25} /> : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CohortForm;
