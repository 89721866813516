import React from 'react'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'

const AVV = () => {
  return (
    <Authenticated>
      <Layout title='AVV'>
        <h3>{'Participants EN'}</h3>
        <iframe className="airtable-embed" title='EN' src="https://airtable.com/embed/shr6g9voZQgmOlOdg?backgroundColor=yellow&viewControls=on" frameBorder="0" width="100%" height="533" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe>

        <h3>{'Participants FR'}</h3>
        <iframe className="airtable-embed" title='FR' src="https://airtable.com/embed/shrGLDsAcy5tv9mku?backgroundColor=blue&viewControls=on" frameBorder="0" width="100%" height="533" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe>

        <h3>{'Participants PT'}</h3>
        <iframe className="airtable-embed" title='PT' src="https://airtable.com/embed/shr9z8fr7xCVUXj5Y?backgroundColor=green&viewControls=on" frameBorder="0" width="100%" height="533" style={{ background: 'transparent', border: '1px solid #ccc' }}></iframe>
      </Layout>
    </Authenticated>
  )
}

export default AVV
