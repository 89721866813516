import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'

const CarouselFilterForm = (props) => {
  const {
    handleClear,
    callback = () => { },
  } = props


  return (
    <Formik
      initialValues={{ keyword: '', isActive: '' }}
      onSubmit={(values) => callback(values)}
    >
      {({
        values,
        errors,
        resetForm,
        handleChange,
      }) => {
        return <Form name="filter_newsfeed" >
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="keyword"
                value={values.keyword}
                onChange={handleChange}
                label="Search Carousel by Title"
              />
              <ErrorMessage name='keyword'>
                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
              </ErrorMessage>
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth>
                <InputLabel>Active</InputLabel>
                <Select
                  name='isActive'
                  value={values.isActive}
                  onChange={handleChange}
                >
                  <MenuItem value="" key="">None</MenuItem>
                  {["Active", "Deactive"].map((key) =>
                    <MenuItem value={key} key={key} >{key}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div style={{ marginTop: 25 }}>
            <Grid container spacing={4} justifyContent='flex-start'>
              <Grid item xs={3} sm={2}>
                <Button style={{ width: '100%' }} color="primary"
                  variant="contained" type='submit'>
                  APPLY
                </Button>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button style={{ width: '100%' }} type="reset"
                  color="inherit" variant="contained"
                  onClick={() => {
                    resetForm();
                    handleClear()
                  }}
                >
                  Clear Filter
                </Button>
              </Grid>
            </Grid>
          </div>
        </Form>
      }}
    </Formik>
  );
};

export default CarouselFilterForm
