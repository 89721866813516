import React, { useState } from 'react'
import ApolloCacheUpdater from 'apollo-cache-updater'
import { useSnackbar } from 'notistack'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import NewsList from '../components/NewsList'
import { getNewsQuery, _deleteNewsMutation } from '../api'
import LANGUAGES from '../settings/enums/languages.json'
import NewsFeddFilter from '../forms/newsFeedFilter'
import Pagination from '../components/Pagination'

const Newsfeed = ({ history, location: { search } }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [deleteNews] = useMutation(_deleteNewsMutation)
  const [sortData, setSortData] = useState(['_id:desc']);
  const [filters, setFilters] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [page, setPage] = React.useState(0) // Page numeration starts at 0.
  const parseFilters = () => {
    const { keyword, type, countryCode } = filters;
    if (keyword) {
      var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    }
    return {
      ...(keyword && { title_matches: searchKeyword }),
      ...(type && { type_matches: type }),
      ...countryCode && countryCode?.length && { country: countryCode }
    };
  };

  const { data: { getNews: news = [], countNewsFeed } = {}, loading, error } = useQuery(getNewsQuery, {
    variables: {
      params: {
        // authorDoesNotExists: true
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters && {
          where: parseFilters()
        })
      },
    }
  })


  const sortFilter = (value, key) => {
    setSortData([`${value}:${key}`]);
  };
  const handleRowPerChange = (value) => {
    setRowsPerPage(value)

  }

  const data = news.map(item => {
    const firstAvaliableLanguage = item.languages && Array.isArray(item.languages) && item.languages.length > 0 ? item.languages[0] : LANGUAGES.en
    const firstAvaliableLanguageIndex = Object.values(LANGUAGES).findIndex(lang => lang === firstAvaliableLanguage)
    return {
      ...item,
      slug: item.slug[0],
      title: item.title[firstAvaliableLanguageIndex],
      allTitle: item.title
    }
  })

  const deleteNewsCallback = (id) => {

    deleteNews({
      variables: {
        id,
      },
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getNewsQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        if (update) enqueueSnackbar('The news has been deleted.', { variant: 'success' })
      }
    })
      .then(() => { })
      .catch((e) => {
        enqueueSnackbar('Cannot delete news.', { variant: 'error' })
        console.log(e);
      })
  }


  const handleClear = filters => {
    setFilters();
    history.push("/newsfeed");
  };

  const handleFiltersSubmit = fields => {
    setPage(0)
    setFilters({ ...fields })
  };

  const rowsInTotal = countNewsFeed && countNewsFeed

  return (
    <Authenticated>
      <Layout title='Newsfeed'
        head={
          <NewsFeddFilter
            filters={filters}
            callback={handleFiltersSubmit}
            data={data}
            handleClear={handleClear}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <NewsList
            data={data}
            deleteCallback={deleteNewsCallback}
            sortFilter={sortFilter}
          />
          {rowsInTotal > rowsPerPage &&
            <Pagination
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default Newsfeed
