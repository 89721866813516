import React from 'react'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import LanguageSwitch from '../components/LanguageSwitch'
import multivalues from '../settings/multivalues.json'
import FAQForm from '../forms/FAQForm'


const FAQNew = () => {
  const [languageIndex, setLanguageIndex] = React.useState(0)

  return (
    <Authenticated>
      <Layout title='Create FAQ'>
        <LanguageSwitch
          languageIndex={languageIndex}
          languages={multivalues.languages}
          setLanguageIndex={setLanguageIndex}
        />
        <FAQForm
          languageIndex={languageIndex}
          languagesTotal={Object.keys(multivalues.languages).length}
        />
      </Layout>
    </Authenticated>
  )
}

export default FAQNew
