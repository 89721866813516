import React, { useEffect, useState } from "react";
import Authenticated from "../../containers/Authenticated";
import download from "downloadjs";
import Layout from "../../components/Layout";
import { useLazyQuery } from "@apollo/react-hooks";
import { getJobByIdQuery } from "../../api";
import Loader from "../../components/Loader";
import { Button, ButtonGroup, FormControl, Select } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { saveJobQueriesData } from "../../api";
import { useSnackbar } from "notistack";
import "./JobPortal.css"
import { _s3SignDownloadMutation } from "../../api/_mutations";
import userInteraction from "../../utils/userInteraction";
import history from "../../history";
import moment from "moment"

const JobView = ({
  match: {
    params: { id }
  }
}) => {
  
  const { enqueueSnackbar } = useSnackbar();
const [s3SignDownload, { loading:downloadLoading }] = useMutation(_s3SignDownloadMutation);
const [addInteraction] = userInteraction();
  const [isDownloading, setIsDownloading] = React.useState(false);


  const downloadCallback = async (url) => {
       const path = url.replace('https://', '')
       const parts = path.split('/')
       const filename = parts.slice(1).join('/')
       const response = await s3SignDownload({
         variables: {
           filename,
         },
       })
       const { signedRequest } = response.data._signS3Download
       setIsDownloading(parts[parts.length - 1].split('-').slice(1).join("-"))
  
       try {
         fetch(signedRequest).then(response => {
           return response.blob().then(raw => raw)
         })
          .then(async data => {
            download(data, [parts[parts.length - 1].split('-').slice(1).join("-")])
             setIsDownloading(false)
          })
          .catch((err) => {
            console.log(err,"errerr")
          });
       } catch (e) {
         console.log(e,"error")
         setIsDownloading(false)
       }
     }
  
     const addDownload = (resourceId) => addInteraction(resourceId, 'download')
  
     const recordDownload = async (e) => {
      e.preventDefault()
      if (data?.document) {
        await addDownload(data?._id)
      }
      downloadCallback(data?.document)
    }
  const [
    runJOBQuery,
    { data: { getJobById: data } = {}, loading, error }
  ] = useLazyQuery(getJobByIdQuery);

  useEffect(() => {
    runJOBQuery({
      variables: {
        id: id
      }
    });
  }, [id]);

  const [saveJob] = useMutation(saveJobQueriesData);
  const [approvedValue, setApprovedValue] = useState({
    id: null,
    jobStatus: null
  });

  const updateApprovedStatus = () => {
    if (approvedValue.id) {
      saveJob({
        variables: {
          data: approvedValue
        }
      })
        .then(res => {
          console.log(res);
          setApprovedValue({
            id: null,
            jobStatus: null
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      enqueueSnackbar("Please change the status of job.", { variant: "error" });
    }
  };

  function downloadFile(fileUrl, fileName) {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
  
    // Set the download attribute with the desired file name
    link.download = fileName;
  
    // Append the link to the body (necessary for some browsers)
    document.body.appendChild(link);
  
    // Programmatically click the link to trigger the download
    link.click();
  
    // Remove the link after download
    document.body.removeChild(link);
  }
  

  return (
    <Authenticated>
      <Layout title="View Job">
        <Loader loading={loading} error={error}>
        <div className="job-view-main-section">
          <div className="view-job-info-main-wrapper">
            <div className="view-job-info-wrapper">
           { data?.image &&(
              <div className="view-job-info-logo">
                <img src={data?.image} />
              </div>
            )}
              <ul className="view-job-info-list">
                <li>Title : {data?.jobTitle ? data?.jobTitle.charAt(0).toUpperCase() + data?.jobTitle.slice(1)  : "-"}</li>
                <li>Company Name : {data?.companyName ? data?.companyName.charAt(0).toUpperCase()+ data?.companyName.slice(1) :"-"}</li>{console.log(data,"datadata")}
                <li>
                  Location : { data?.city.charAt(0).toUpperCase()+ data?.city.slice(1)  } {(data?.city && data?.country)&& " , "} {(!data?.city && !data?.country)&& " - "} {data?.country == "others" ? data?.otherCountry?.charAt(0).toUpperCase()+ data?.otherCountry?.slice(1) : data?.country.charAt(0).toUpperCase() + data?.country.slice(1) }
                </li>
                <li>Tags: <ol >{data?.tags && data?.tags.map((item, index) => <li k>{item.charAt(0).toUpperCase()+ item.slice(1) }</li>)}</ol></li>
              </ul>
            </div>
            <div className="view-job-info-details-title">
              <h4>Job Details</h4>
              <ul>

                <li>Department: {data?.department?.map(department => department.charAt(0).toUpperCase()+ department.slice(1))}</li>


                <li>Job Level : {data?.jobLevel ?data?.jobLevel.charAt(0).toUpperCase() + data?.jobLevel.slice(1) :"-" }</li>
                <li>Job Type: {data?.jobType ? data?.jobType.charAt(0).toUpperCase() + data?.jobType.slice(1):"-"}</li>
                <li>
                  Salary : ${data?.minSalary} - ${data?.maxSalary}
                </li>
                
                <li>Salary Type : {data?.salaryType ? data?.salaryType.charAt(0).toUpperCase() + data?.salaryType.slice(1)  :"-"}</li>
                <li>No. of vacancies: {data?.vacancies ? data?.vacancies :"-"}</li>
                <li>Work Mode: {data?.workMode ? (data?.workMode === "work_from_office" ? "Work From Office" : data?.workMode.charAt(0).toUpperCase() + data?.workMode.slice(1) ) : '-'}</li>
                {/* <li>Link :   onClick={() => downloadFile(data?.link, 'downloaded-file')}</li> */}
            {data?.document && (<><li>Document: </li><a
                  download
                  onClick={(e) => recordDownload(e)}

                  style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                >

                  {data?.document}
                </a></>
  )}    
                <li>Job Expires On  {""}
                <span className="job-interview-listing-date-span">
                  {data?.expirationDate &&
                    `${moment.utc(data.expirationDate).format("Do MMM, YYYY")} at ${moment(data.expirationTime).format("HH:mm A")} GMT`}
                </span>
                </li>
                <li>Job Description : {data?.jobDescription ?<span dangerouslySetInnerHTML={{__html:data?.jobDescription}} />:"-"}</li>
              </ul>
            </div>
            <div className="view-job-info-requirements-title">
              <h4>Requirenments For Job</h4>
              <ul>
                <li>Education : {data?.education ? data?.education.charAt(0).toUpperCase()  + data?.education.slice(1)  :"-"}</li>
                <li>Experience: {data?.experiance ? data?.experiance : "-"}</li>
              </ul>
            </div>
            <div className="view-job-info-details-status-changes-main-div">
              <FormControl
                variant="outlined"
                className={
                  data?.jobStatus === "approved" ? "approved" : "pending"
                }
              >
                <Select
                  native
                  defaultValue={data?.jobStatus ? data.jobStatus : "pending"}
                  onChange={e => {
                    setApprovedValue({
                      id: data?._id,
                      jobStatus: e.target.value
                    });
                  }}
                >
                  <option value="pending" key="Pending">
                    Pending
                  </option>
                  <option value="approved" key="Approved">
                    Approved
                  </option>
                </Select>
              </FormControl>
              <Button
                className="view-job-info-details-update-button"
                onClick={() => updateApprovedStatus()}
              >
                Update
              </Button>
              <Button
                className="view-job-info-details-update-button"
                onClick={() => history.push("/jobList")}
              >
                Cancel
              </Button>
            </div>
          </div>

          <div className="job-posters-bio-details-main-wrapper">
           <h3> Details of Job Poster</h3>
           <ul >
            <li>
              Job poster : {data?.author?.name}
            </li>
            <li>
              Email : {data?.author?.email}
            </li>
            <li>
              Phone : {data?.author?.phone}
            </li>
            <li>Social Media: <ol >{data?.author?.social && data?.author?.social.map((item, index) => 
            item?.url&&(
              <li k>{item.label?.charAt(0).toUpperCase()+ item.label?.slice(1) } - { <a
                    href={item?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item?.url}
                    </a>
                  }</li>)
                   )}</ol></li>
           
            </ul>
          </div>
         
        </div>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default JobView;
