import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import NewsForm from '../forms/NewsForm'
import LanguageSwitch from '../components/LanguageSwitch'
import multivalues from '../settings/multivalues.json'
import { getNewsBySlugQuery } from '../api'

const NewsfeedEdit = ({ match: { params: { slug } } }) => {
  const { data: { getNewsBySlugAdmin: data } = {}, loading, error } = useQuery(getNewsBySlugQuery, {
    variables: {
      slug,
    }
  })
  const [languageIndex, setLanguageIndex] = React.useState(0)

  return (
    <Authenticated>
      <Layout title='Edit News'>
        <Loader loading={loading} error={error}>
          <Typography variant='h4' gutterBottom>{data && data.title && Array.isArray(data.title) && data.title.length > 0 && data.title[0]}</Typography>
          {!loading && data &&
            <>
              <LanguageSwitch
                languageIndex={languageIndex}
                setLanguageIndex={setLanguageIndex}
                languages={multivalues.languages}
              />
              <NewsForm
                languageIndex={languageIndex}
                data={data}
                languagesTotal={Object.keys(multivalues.languages).length}
              />
            </>
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default NewsfeedEdit
