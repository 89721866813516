import React, { useCallback, useMemo, useState } from 'react'
import Authenticated from '../containers/Authenticated'
import FAQList from '../components/FAQList'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from 'notistack'
import { _deleteFAQMutation, countFAQQuery, getFAQQuery } from '../api'
import Pagination from '../components/Pagination'
import { useMutation, useQuery } from '@apollo/react-hooks'
import FAQFiltersForm from '../forms/FAQFiltersForm'

const FAQSection = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = React.useState(0)
  const [sortData, setSortData] = useState(['_id:desc']);
  const [filters, setFilters] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [deleteFAQ] = useMutation(_deleteFAQMutation, {})

  //parseFilter:
  const parseFilters = useMemo(() => {
    const { keyword } = filters;

    if (keyword) {
      var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    }
    return {
      ...keyword && { question_matches: searchKeyword },
    };
  }, [filters]);

  // getFAQdataQuery:
  const { data: { getFAQ: FAQDataList = [], countFAQ: rowsInTotal } = {},
  loading, error, refetch
} = useQuery(getFAQQuery, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
        ? { where: parseFilters }
        : {}
        )
      },
    }
  })

  //pagination function:
  const handleRowPerChange = useCallback((value) => {
    setRowsPerPage(value)
  }, [])

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/FAQ");
  }, [history]);

  const handleFiltersSubmit = useCallback((fields) => {
    setPage(0)
    setFilters({ ...fields })
  }, []);

  const data = useMemo(() => (
    FAQDataList.map(item => item)
  ), [FAQDataList])

  //sort function:
  const sortFilter = useCallback((value, key) => {
    setSortData([`${value}:${key}`]);
  }, []);

  //delete function:
  const deleteFAQCallback = useCallback(async (id, name) => {
    deleteFAQ({
      variables: {
        id,
      },
      refetchQueries: [{
        query: getFAQQuery,
        variables: {
          params: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            ...(filters && Object.keys(filters).length
              ? { where: parseFilters }
              : {}
            )
          },
        }
      }],
      update: (proxy, { data: { _deleteEso: deleteEso = {} } }) => {
        const updateOne = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getFAQQuery, countFAQQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        let updateTwo = true
        let userId
        if (deleteEso && typeof deleteEso === 'string') {
          userId = deleteEso.split('___').length > 1 ? deleteEso.split('___')[1] : null
        }

        if (userId) {
          updateTwo = ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getFAQQuery], // queries you want to automatically update
            searchVariables: {},
            operator: 'ANY',
            operation: 'REMOVE',
            mutationResult: { _id: userId },
            ID: '_id',
          })
        }
        if (updateOne && updateTwo) enqueueSnackbar(`Carousel ${name} has been deleted.`, { variant: 'success' })
      }
    }).then(() => { }).catch((e) => {
      enqueueSnackbar('Cannot delete Carousel.', { variant: 'error' })
    })
  }, [])

  return (
    <Authenticated>
      <Layout title='FAQ'
        head={
          <FAQFiltersForm
            filters={filters}
            callback={handleFiltersSubmit}
            data={data}
            handleClear={handleClear}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <FAQList
            data={data}
            deleteCallback={deleteFAQCallback}
            sortFilter={sortFilter}
          />
     
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default FAQSection;