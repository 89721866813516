import React from 'react'
import PropTypes from 'prop-types'
import {
  Fade,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDropDown } from '@material-ui/icons'
import User from './User'


const UserPanel = ({ username, email, avatarUrl, userActions }) => {
  const [anchorEl, setAnchorEl] = React.useState()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.userWrapper}>
        <User name={username} avatarUrl={avatarUrl} big />
      </div>
      <div className={classes.dropdown} onClick={e => setAnchorEl(e.currentTarget)}>
        <Typography
          color="inherit"
          aria-owns={anchorEl ? 'user-menu' : null}
          aria-haspopup="true"
        >{email}</Typography>
        <ArrowDropDown />
      </div>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Fade}
      >
        {userActions.map((item, index) =>
          <MenuItem
            onClick={() => {
              setAnchorEl({ anchorEl: null })
              item.callback()
            }}
            key={index}
          >{item.label}</MenuItem>
        )}
      </Menu>
    </div>
  )
}

UserPanel.propTypes = {
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  userActions: PropTypes.arrayOf(PropTypes.object).isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    backgroundColor: theme.palette.grey[800],
    color: 'white',
  },
  userWrapper: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  dropdown: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  }
}))

export default UserPanel
