import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography, Button, Grid, TextField, FormControl } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Modal from '../components/Modal'
import CancelInvitation from '../components/CancelInvitation'
import { _inviteEsoMutation } from '../api'
import { graphqlError } from '../utils'
import LANGUAGES from '../settings/enums/languages.json'
import { Form, Formik, ErrorMessage } from 'formik'
import * as Yup from "yup";

const InviteUserForm = ({
  organizationNativeLanguage,
  organizationId,
  organizationName,
  defaultEmail,
  invited,
  registered,
  userEmail,
  userAccount,
  revokeOrganizationInvitation,
  languageIndex,
}) => {
  const [inviteEso] = useMutation(_inviteEsoMutation)
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = React.useState(false)
  const [cachedInvited, setCachedInvited] = React.useState()
  const [cachedUserEmail, setCachedUserEmail] = React.useState()

  React.useEffect(() => setCachedInvited(invited), [invited])
  React.useEffect(() => setCachedUserEmail(userEmail), [userEmail])

  // TODO: Consider moving it to translations.
  const defaultMessage = languageIndex === 0
    ? `Hello! You have been invited to manage "${organizationName}" on ENNOVA website. To join the platform and manage your organization, which is already there, use the link below:`
    : `FR Hello! You have been invited to manage "${organizationName}" on ENNOVA website. To join the platform and manage your organization, which is already there, use the link below:`



  const validationSchema = Yup.object().shape({
    message: Yup.string().required("This field is required."),
    email: Yup.string().email("This is not a valid email address.").required("This field is required."),
  })

  const ModalForm = ({ again }) =>
    <Modal
      trigger={
        <Button
          variant='contained'
          size='small'
          color='primary'
        >{again ? 'Send again' : 'Invite user'}</Button>
      }
      isOpen={isOpen}
      title='Invite user'
    >
      <Typography gutterBottom>
        Enter email address of a person who should become an administrator of "{organizationName}" data on the TIL platform.
      </Typography>
      <Grid container direction='column' alignItems='center'>

        <Formik
          initialValues={{
            email: defaultEmail || cachedUserEmail || '',
            message: defaultMessage || ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleSend}
        >
          {({ values, handleChange, errors }) => {
            return <Form>
              <FormControl fullWidth>
                <TextField
                  name='email'
                  label={<span>Email address <span style={{ color: 'red' }}>*</span></span>}
                  type='email'
                  onChange={handleChange}
                  value={values && values.email ? values.email : ""}
                  fullWidth
                />
                <ErrorMessage name='email'>{msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  name='message'
                  label={<span>Invitation message <span style={{ color: 'red' }}>*</span></span>}
                  value={values && values.message ? values.message : ''}
                  onChange={handleChange}
                />
                <ErrorMessage name='message'>{msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
              </FormControl>
              <Button
                type='submit'>
                Send invitation
              </Button>
            </Form>
          }}
        </Formik>
      </Grid>
    </Modal>

  const handleSend = fields => {
    const { email, message } = fields || {}
    try {
      // use UI language or organization native language
      let emailLanguage = LANGUAGES.en
      const uILanguage = Object.values(LANGUAGES).filter((_, idx) => languageIndex === idx)[0]
      if (uILanguage !== emailLanguage) {
        emailLanguage = uILanguage
      } else {
        emailLanguage = organizationNativeLanguage || LANGUAGES.en
      }
      inviteEso({
        variables: {
          organizationId,
          email,
          message,
          language: emailLanguage
        }
      }).then(() => {
        enqueueSnackbar(`Invitation sent to ${fields.email}.`, { variant: 'success' })
        setIsOpen(false)
        setCachedInvited(true)
        setCachedUserEmail(fields.email)
      })
        .catch((e) => {
          const errorMessage = graphqlError(e)
          if (errorMessage) {
            enqueueSnackbar(errorMessage, { variant: 'error' })
          } else enqueueSnackbar('Cannot send an invitation.', { variant: 'error' })
          console.log(e);
        })
    } catch (err) {
      enqueueSnackbar('Cannot send an invitation.', { variant: 'error' })
    }
  }
  return (
    <Grid container spacing={1} alignItems='center' justifyContent='flex-end' style={{ marginBottom: 8 }}>
      <Grid item>
        {registered
          ? <Typography align='right'>Associated user account: {userAccount}</Typography>
          : cachedInvited && !registered
            ? <Typography align='right'>Invitation sent.</Typography>
            : <Typography align='right'>No associated user account.</Typography>
        }
      </Grid>
      <Grid item>
        {registered
          ? null
          : cachedInvited && !registered
            ? <>
              <ModalForm again />
              <CancelInvitation
                id={organizationId}
                name={organizationName}
                callback={args => revokeOrganizationInvitation(args)}
              />
            </>
            : <ModalForm />
        }
      </Grid>
    </Grid>
  )
}

export default InviteUserForm
