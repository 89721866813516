import React, { useMemo,useEffect } from 'react'
import * as Yup from 'yup'
import history from '../history'
import isUrl from 'is-valid-http-url'
import { graphqlError } from '../utils'
import { useSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import ApolloCacheUpdater from 'apollo-cache-updater'
import {
  Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Chip, Typography,
  Box, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField
} from '@material-ui/core'
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik'
import MultiImageUpload from '../components/MultiImageUpload'
import SERVICES from '../settings/enums/categories.json'
import NEWS_TYPE from '../settings/enums/news-type.json'
import SECTORS from '../settings/enums/target-sectors.json'
import LEGACY_SERVICES from '../settings/enums/services.json'
import BUSINESS_STAGE from '../settings/enums/business-stages'
import AFRICAN_ISO from '../settings/enums/new-africa-iso-code.json'
import PERSONAL_INTERESTS from '../settings/enums/personal-interest.json'
import { saveNewsMutation, getNewsQuery, _getNewsLinkContentMutation, _safeS3SignMutation } from '../api'
import Wysiwyg from './Wysiwyg'
import './NewsForm.css'

const backwardCompatibility = (cats = []) => cats.filter(cat => cat === 'all' || (!Object.keys(LEGACY_SERVICES).includes(cat) && !Object.keys(BUSINESS_STAGE).includes(cat))) // guard agains legacy categories in existing news

const addLabel = obj => Object.entries(obj).reduce((o, [k, v]) => ({
  ...o,
  [k]: {
    label: v
  }
}), {})

const CATEGORIES = {
  ...addLabel(PERSONAL_INTERESTS),
  ...SERVICES,
  ...addLabel(SECTORS),
}

const newsTypeOptions = Object.values(NEWS_TYPE).filter((value) => value !== "survey").map(value => ({
  label: value,
  value,
}))


const NewsForm = ({
  data,
  languageIndex,
  languagesTotal
}) => {
  const { enqueueSnackbar } = useSnackbar()
  
  const fetchedUrls = React.useRef([])
  const firstUpdate = React.useRef(true)

  const [safeS3Sign] = useMutation(_safeS3SignMutation)
  const [saveNews] = useMutation(saveNewsMutation)
  const [getContent] = useMutation(_getNewsLinkContentMutation)

  const [initialValues, setInitialValues] = React.useState(data || {})
  const [changingLanguage, setChangingLanguage] = React.useState()
  const [fileUploading, setFileUploading] = React.useState(false)
  const [flagSchemas, setFlagSchemas] = React.useState(data && data.type ? data.type : 'announcement')
  const [showLocation, setShowLocation] = React.useState(data && data.online ? data.online : false)

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    }
    if (data) {
      setInitialValues(data)
    }
  }, [data, firstUpdate])

  React.useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        setChangingLanguage(true)
        setInitialValues({ ...data, ...initialValues })
        setChangingLanguage(false)
      }
      handleAsync()
    }
  }, [languageIndex]) 

  const isTranslatable = bool =>
    languageIndex !== 0
      ? {
        disabled: !bool,
        helperText: bool
          ? "Enter translation for selected language."
          : "This field is not translatable."
      }
      : {};

  // validation schema for announcement
  let announcementSchema = Yup.object().shape({
    categories: Yup.array().min(1, 'This field is required.'),
    title: Yup.array().of(Yup.string()).test('title-ele',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }),
  })

  // validation schema for announcement
  let linkSchema = Yup.object().shape({
    categories: Yup.array().min(1, 'This field is required.'),
    title: Yup.array().of(Yup.string()).test('title-ele',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }),
    url: Yup.array().test('url-ele',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }).of(Yup.string().matches(
        /^(https?):\/\/[-\w]+(\.\w[-\w]*)+([/?#].*)?$/,
        'This is not a valid URL.'
      )).required('This field is required.'),
  })

  // validation schema for event
  const eventSchema = useMemo(() => {
    return Yup.object().shape({
      categories: Yup.array().min(1, 'This field is required.'),
      title: Yup.array().of(Yup.string()).test('title-ele',
        'This field is required',
        function (value) {
          if (value && value[languageIndex]) return true
          else return false
        }),
      url: Yup.array().test('url-ele',
        'This field is required',
        function (value) {
          if (value && value[languageIndex]) return true
          else return false
        }).of(Yup.string().matches(
          /^(https?):\/\/[-\w]+(\.\w[-\w]*)+([/?#].*)?$/,
          'This is not a valid URL.'
        )).required('This field is required.'),
      dateFrom: Yup.date().required('This field is required.'),
      ...!showLocation ? {
        country: Yup.string().required('This field is required.'),
        city: Yup.string().required('This field is required.')
      } : {},
    })
  })

  const getLinkContent = (url, setValues, values) => {
    getContent({
      variables: {
        url: url.includes('http://') || url.includes('https://') ? url : `http://${url}`
      },
      skip: fetchedUrls.current.includes(url),
    }).then((res) => {
      const { data: { _getNewsLinkContent: response } = {} } = res || {}
      const { title, content, images } = response

      setValues({
        ...values && { ...values },
        title: title ? title.map((_, idx) => languageIndex === idx ? title[0] : values.title[idx]) : '',
        content: content ? content.map((_, idx) => languageIndex === idx ? content[0] : values.content[idx]) : '',
        ...!!images && { images }
      })
    }).catch(e => {
      enqueueSnackbar('Cannot get the content of this link', { variant: 'error' })
      console.log(e);
    })
    fetchedUrls.current = [...fetchedUrls.current, url]
  }

  const handleSubmit = async ({
    title,
    content,
    ...standardFields
  }) => {
    try {
      let dataform = {}
      dataform['type'] = standardFields.type
      dataform['title'] = standardFields.title
      dataform['categories'] = standardFields.categories
      dataform['images'] = standardFields.images ? standardFields.images : undefined
      dataform['content'] = standardFields.content ? standardFields.content : undefined
      dataform['published'] = standardFields.published ? standardFields.published : undefined
      dataform['dateFrom'] = standardFields.dateFrom
      dataform['dateTo'] = standardFields.dateTo ? standardFields.dateTo : undefined

      if (standardFields?.type === 'link') {
        dataform['url'] = standardFields.url
      }
      if (standardFields?.type === 'event') {
        dataform['url'] = standardFields.url
        dataform['online'] = standardFields.online ? standardFields.online : undefined
        dataform['country'] = standardFields.country ? standardFields.country : undefined
        dataform['city'] = standardFields.city ? standardFields.city : undefined
        dataform['additionalCountries'] = standardFields.additionalCountries ? standardFields.additionalCountries.map((item) => AFRICAN_ISO[item]) : []
      }
      let dataObject = {
        ...dataform,
        title,
        content,
      }
      // set url properly before sending to backend
      dataObject.url = !standardFields.url ? ['', ''] : standardFields.url
      let allImages;
      if (Array.isArray(dataObject.images)) {
        allImages = await dataObject.images.reduce(async (arr, file) => {
          let attachmentUrl = file || null
          if (attachmentUrl) {
            if (typeof attachmentUrl === 'string') return [...await arr, attachmentUrl]
            else {
              try {
                const { dataFile, name: filename } = file
                const response = await safeS3Sign({
                  variables: {
                    filename: filename,
                    filetype: file.type,
                  },
                })
                const { signedRequest, url: bucketUrl } = response.data._safeSignS3
                try {
                  setFileUploading(true)
                  await fetch(signedRequest, {
                    method: 'PUT',
                    body: dataFile,
                    headers: {
                      'Content-Type': file.type,
                    },
                  })
                  setFileUploading(false)
                  attachmentUrl = bucketUrl
                } catch (e) {
                  attachmentUrl = null
                  setFileUploading(false)
                }
              } catch (e) {
                const errMsg = graphqlError(e, {
                  // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
                  // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
                })
                if (errMsg) {
                  enqueueSnackbar(errMsg, { variant: 'error' })
                } else {
                  enqueueSnackbar('Cannot uplaod file(s)', { variant: 'error' })
                }
                return [...await arr]
              }
              return [...await arr, attachmentUrl]
            }
          }
        }, [])
      }
      dataObject['images'] = allImages
      saveNews({
        variables: {
          data: {
            ...(data && data._id) && { id: data._id },
            ...dataObject,
          }
        },
        update: (proxy, { data: { saveNews = {} } }) => {
          if (!data || !data._id) {
            const mutationResult = saveNews;
            const updates = ApolloCacheUpdater({
              proxy,
              queriesToUpdate: [getNewsQuery],
              searchVariables: {},
              operator: 'ANY',
              mutationResult,
              ID: '_id',
            })
            if (updates) console.log(`News added`) // if no errors
          }
        },
      })
        .then(() => {
          enqueueSnackbar(
            data && data._id ? 'News saved.' : 'News created.',
            { variant: 'success' },
          )
          history.push('/newsfeed')
        })
        .catch(e => {
          const errMsg = graphqlError(e, {
            NEWS_TITLE_UNIQUE: 'The news title(s) is(are) not unique',
            NEWS_SLUG_UNIQUE: 'The news title(s) is(are) not unique',
          })
          if (errMsg) {
            enqueueSnackbar(
              errMsg,
              { variant: 'error' },
            )
          } else {
            enqueueSnackbar(
              'Error.',
              { variant: 'error' },
            )
            console.log(e)
          }
        })
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Could not save the news.', { variant: 'error' })
    }
  } 
  

  return changingLanguage
    ? null
    : <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{
        categories: initialValues?.categories ? backwardCompatibility(initialValues?.categories) : [],
        images: initialValues?.images ? initialValues?.images : '',
        published: initialValues?.published ? initialValues?.published : false,
        type: initialValues?.type ? initialValues?.type : 'announcement',
        title: initialValues?.title ? initialValues.title : '',
        content: initialValues?.content ? initialValues.content : '',
        dateFrom: initialValues?.dateFrom ? initialValues.dateFrom.slice(0, 10) : '',
        dateTo: initialValues?.dateTo ? initialValues.dateTo.slice(0, 10) : '',
        city: initialValues?.city ? initialValues?.city : '',
        country: initialValues?.country ? initialValues?.country : '',
        online: initialValues?.online ? initialValues?.online : false,
        url: initialValues?.url ? initialValues.url : ''
      }}
      validationSchema={flagSchemas === 'announcement' ? announcementSchema : flagSchemas === 'link' ? linkSchema : flagSchemas === 'event' ? eventSchema : announcementSchema}
    >
      {({ values, handleChange, handleSubmit, setFieldValue, setValues, errors }) => {
        console.log("🚀 ~ file: NewsForm.js:439 ~ errors:", errors)
      
    
        return <>
          <Form onSubmit={handleSubmit} >
            <Grid container spacing={6} justifyContent='space-between'>
              <Grid item md={6} lg={4} container direction='column' alignItems='flex-start'>
                <FormControl fullWidth>
                  <InputLabel >Categories <span style={{ color: 'red' }}>*</span></InputLabel>
                  <Select
                    multiple
                    name='categories'
                    onChange={handleChange}
                    {...isTranslatable(false)}
                    value={values?.categories}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={CATEGORIES[value]?.label} />
                        ))}
                      </Box>
                    )}
                  >
                    {Object.entries(CATEGORIES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value?.label}</MenuItem>)
                    })}
                  </Select>
                  {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                  <ErrorMessage name='categories' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                </FormControl>
                <Box style={{ marginBottom: '5%' }}>
                  <MultiImageUpload
                    name={'images'}
                    value={values?.images}
                    setValue={setFieldValue}
                  />
                  {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="published"
                      checked={values?.published}
                      onChange={handleChange}
                      {...isTranslatable(false)}
                    />
                  }
                  label="Published"
                />
                {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
              </Grid>
              <Grid item md={6} lg={7} container direction='column' alignItems='flex-start'>
                <FormControl component="fieldset" >
                  <Typography component="legend">Type</Typography>
                  <RadioGroup aria-label="type" name="type"
                    value={values.type}
                    onClick={(e) => setFlagSchemas(e.target.value)}
                    onChange={handleChange}
                    row
                  >
                    {
                      newsTypeOptions.map((key, val) => {
                        return (<FormControlLabel
                          {...isTranslatable(false)}
                          value={key?.value}
                          control={<Radio color='default' />}
                          label={key?.label} key={val} />)
                      })
                    }
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label={<span>Title <span style={{ color: 'red' }}>*</span></span>}
                    type="text"
                    name={`title[${languageIndex}]`}
                    value={(values?.title && Array.isArray(values.title)
                      && values.title[languageIndex]) || ''}
                    onChange={handleChange}
                    {...isTranslatable(true)}
                  />
                  <ErrorMessage name='title' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                </FormControl>
                <FormControl fullWidth>
                  
                <Wysiwyg
                 key={languageIndex}
                  name={`content[${languageIndex}]`}
                  label='Content'
                  placeholder={'Content'}
                  setValue={setFieldValue}
                  onChange={handleChange}
                  value={(values?.content.length && values.content[languageIndex]) || ''}
                />

                
                  
                </FormControl>
                {values?.type === 'event' &&
                  <>
                 <div style={{paddingTop:"45px",width:"100%"}}>
                    <Grid container spacing={2}>
                      <Grid item sm={6}>
                        <TextField
                          label={<span>From <span style={{ color: 'red' }}>*</span></span>}
                          type="date"
                          name='dateFrom'
                          fullWidth
                          defaultValue={values.dateFrom}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...isTranslatable(false)}
                        />
                        <ErrorMessage name='dateFrom' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          label="To"
                          type="date"
                          name='dateTo'
                          fullWidth
                          onChange={handleChange}
                          defaultValue={values.dateTo}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...isTranslatable(false)}
                        />
                      </Grid>
                    </Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="online"
                          checked={values.online}
                          onChange={(e) => {
                            setShowLocation(e.target.checked)
                            handleChange(e)
                          }}
                          {...isTranslatable(false)}
                        />
                      }
                      label="Online only event"
                    />
                    {values.online === false &&
                      <>
                        <FormControl fullWidth>
                          <InputLabel>Country <span style={{ color: 'red' }}>*</span></InputLabel>
                          <Select
                            labelId="country"
                            name='country'
                            defaultValue={values.country}
                            onChange={handleChange}
                            {...isTranslatable(false)}
                          >
                            {
                              Object.entries(AFRICAN_ISO).map(([key, value]) => {
                                return (<MenuItem value={value} key={value} >{key}</MenuItem>)
                              })
                            }
                          </Select>
                          {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                        </FormControl>
                        <ErrorMessage name='country' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                        <TextField
                          label={<span>City <span style={{ color: 'red' }}>*</span></span>}
                          name='city'
                          type="text"
                          fullWidth
                          value={values.city}
                          onChange={handleChange}
                          {...isTranslatable(false)}
                        />
                        <ErrorMessage name='city' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                        <FormControl fullWidth>
                          <InputLabel id="additionalCountries">Additional Countries</InputLabel>
                          <Select
                            multiple
                            name='additionalCountries'
                            onChange={handleChange}
                            value={values?.additionalCountries || []}
                            renderValue={selected => (
                              <Box sx={{
                                display: "flex", flexWrap: "wrap", gap: 5
                              }}>
                                {selected?.map(value => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            {...isTranslatable(false)}
                          >
                            {Object.entries(AFRICAN_ISO).map(([key]) => (
                              <MenuItem key={key} value={key} >
                                {key}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    }
                  </div>
                  </>
                }
                {
                 
                  (['link', 'event'].includes(values?.type)) &&
                  <>
                   <div className='newsform-link' style={{width:"100%",paddingTop:"45px"}}>
                    <TextField
                      label={<span>URL <span style={{ color: 'red' }}>*</span></span>}
                      name={`url[${languageIndex}]`}
                      type="text"
                      fullWidth
                      value={(values?.url && Array.isArray(values.url)
                        && values.url[languageIndex]) || ''}
                      onChange={handleChange}
                      onBlur={(e) => {
                        const url = e.target.value
                        if (values.type === 'link' && !fetchedUrls.current.includes(url)) {
                          const fullUrl = url.includes('http://') || url.includes('https://') ? url : `http://${url}`
                          if (isUrl(fullUrl)) getLinkContent(url, setValues, values)
                        }
                      }}
                      {...isTranslatable(true)}
                    />
                    <ErrorMessage name='url' >
                      {msg => <FormHelperText style={{ color: 'red' }}>
                        {Array.isArray(msg) ? msg[languageIndex] : msg}
                      </FormHelperText>
                      }
                    </ErrorMessage>
                  </div>
                  </>
                }
                {
                  values?.type === 'link' &&
                  <div className='news-form-wrapper'>

                  <Grid container spacing={2} >
                  <Grid item sm={6}>
                    <TextField
                      label={<span>From <span style={{ color: 'red' }}>*</span></span>}
                      type="date"
                      name='dateFrom'
                      fullWidth
                      defaultValue={values.dateFrom}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...isTranslatable(false)}
                    />
                    <ErrorMessage name='dateFrom' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      label="To"
                      type="date"
                      name='dateTo'
                      fullWidth
                      onChange={handleChange}
                      defaultValue={values.dateTo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...isTranslatable(false)}
                    />
                  </Grid>
                </Grid>
                </div>
                }
                {
                  values?.type === 'announcement' &&
                  <div className='news-form-wrapper'>
                  <Grid container spacing={2} >
                  <Grid item sm={6}>
                    <TextField
                      label={<span>From <span style={{ color: 'red' }}>*</span></span>}
                      type="date"
                      name='dateFrom'
                      fullWidth
                      defaultValue={values.dateFrom}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...isTranslatable(false)}
                    />
                    <ErrorMessage name='dateFrom' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      label="To"
                      type="date"
                      name='dateTo'
                      fullWidth
                      onChange={handleChange}
                      defaultValue={values.dateTo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...isTranslatable(false)}
                    />
                  </Grid>
                </Grid>
                </div>
                }
              </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="flex-end">
              <Grid container item sm={2} xs={3} >
                <Button style={{ width: '100%' }} variant="contained" color="inherit" onClick={() => history.goBack()}>
                  Cancel
                </Button>
              </Grid>
              <Grid container item sm={2} xs={3} >
                <Button style={{ width: '100%' }} variant="contained" color="primary" type='submit'>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      }
      }
    </Formik>
}

NewsForm.defaultProps = {
  languageIndex: 0,
}

export default withRouter(NewsForm)
