import React from 'react'
import Table from './Table'
import ConfirmDelete from './ConfirmDelete'
import { ButtonGroup } from '@material-ui/core'
// import RESOURCE_STATUS from '../settings/enums/resource-status.json'

const customMessage = 'Are you sure you want to continue?'

// const STATUS = Object.keys(RESOURCE_STATUS).reduce((obj, status) => ({
//   ...obj,
//   [status]: status,
// }), {})

const buildActionButtons = (actions) => actions.map((action, index) => <ConfirmDelete
  key={`${action.item._id}_${index}`}
  id={action.item._id}
  name={action.item.title}
  deleteCallback={action.callback}
  buttonLabel={action.buttonLabel}
  label={action.label}
  customMessage={customMessage}
/>)
const ResourceList = ({ data, callback }) => {
  return (
    <Table
      data={data.map(item => ({
        author: item.author?.name,
        name: item.name,
        category: item.category,
        status: 'Deleted by ESO',
        actions: <ButtonGroup size='small' variant='text'>
          {buildActionButtons([
            {
              item,
              label: item.name,
              callback: () => {
                callback({ id: item._id, name: item.name, action: 'delete' })
              },
              buttonLabel: 'Delete',
            },
          ])}
        </ButtonGroup>,
      }))}
      labels={[
        {
          name: 'Name',
          field: 'name',
        },
        {
          name: 'Category',
          field: 'category',
        },
        {
          name: 'Author',
          field: 'author',
        },
        {
          name: 'Status',
          field: 'status',
        },
        {
          name: 'Actions',
          field: 'actions',
        },
      ]}
    />
  )
}

export default ResourceList
