const processMultiLangFields = (fields, data, languageIndex, languagesTotal) => {
  return Object.entries(fields).reduce((acc, [name, value]) => ({
    ...acc,
    [name]: Array.apply(null, Array(languagesTotal)).map((item, index) => {
      return index === languageIndex && value
        ? value
        : (data && data[name] && data[name][index])
          ? data[name][index]
          : ''
    }),
  }), {})
}

export default processMultiLangFields
