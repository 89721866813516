import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Edit, DragIndicator, DeleteForeverOutlined } from '@material-ui/icons';

const DraggableListDynamicSurvey = ({ questionGroupList, questionsList, onDragEnd, handleEditQuestion,handleDeleteQuestion }) => {
  return (
    <div className="question-list-wrapper">
      <DragDropContext onDragEnd={onDragEnd}>
        {questionGroupList.map((group, index) => (
          <Droppable droppableId={group.groupId} key={group._id}>
            {(provided) => (
              <div
                className="question-group"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className="group-header">
                  <h3>Group-{index + 1}</h3>
                  <p>{group.groupName[0]} | {group.groupName[1]}</p>
                </div>
                <div className="question-list">
                  {questionsList.filter(question => question.groupId === group.groupId)
                    .sort((a, b) => a.serialNo - b.serialNo)
                    .map((question, qIndex) => (
                      <Draggable
                        key={question.uniqueID}
                        draggableId={question.uniqueID}
                        index={qIndex}
                      >
                        {(provided) => (
                          <div
                            className="question-list-section"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="question-list-block">
                              <span className="drag-handle">
                                <DragIndicator />
                              </span>
                              <h4>Q.{question.serialNo}</h4>
                              <p>{question.question[0]} | {question.question[1]}</p>
                            </div>
                            <div className="question-controls">
                              <span
                                className="edit-btn"
                                type="button"
                                onClick={() => handleEditQuestion(question.uniqueID)}
                              >
                                <Edit />
                              </span>
                              <span
                                className="edit-btn"
                                type="button"
                                onClick={() => handleDeleteQuestion(question.uniqueID)}
                              >
                                <DeleteForeverOutlined />
                              </span>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </div>
  );
};

export default DraggableListDynamicSurvey;
