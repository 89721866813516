import React, { useState, useEffect } from 'react'
import Table from '../Table'
import { Link } from 'react-router-dom'
import ConfirmDelete from '../ConfirmDelete'
import { saveCarouselMutation } from '../../api';
import { useMutation } from "@apollo/react-hooks";
import { Check, Block } from '@material-ui/icons';
import { Button, ButtonGroup, FormControl, Select } from '@material-ui/core'
import './CarouselList.css'

const CarouselList = ({ data, deleteCallback, sortFilter }) => {
  const [activeValue, setActiveValue] = useState({
    id: null,
    title: "",
    isActive: null,
  });

  const [saveCarousel] = useMutation(saveCarouselMutation)

  useEffect(() => {
    if (activeValue.id) {
      updateActiveStatus();
    }
  }, [activeValue]);

  const updateActiveStatus = () => {
    saveCarousel({
      variables: {
        data: activeValue
      }
    }).then(res => {
      console.log(res, "res");
    }).catch(err => {
      console.log(err);
    });
  };

  return (
    <Table
      data={data.map(item => ({
        title: item?.title && Array.isArray(item.title)
          ? item.title.filter((ele) => ele !== '').join(" | ")
          : item?.title || '',
        languages: item?.languages &&
          Array.isArray(item.languages) &&
          item.languages.join("|"),
        isActive: item.isActive ? <Check color='secondary' /> : <Block color='disabled' />,
        actions:
          <div
            style={{ display: "flex", alignItems: "baseline" }}
            className="isActiveWrapper"
          >
            <FormControl variant="outlined" className={item?.isActive ? "active" : "deactive"}>
              <Select
                native
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                value={item?.isActive ? item.isActive : false}
                onChange={(e) => {
                  setActiveValue({
                    id: item?._id,
                    isActive: JSON.parse(e.target.value),
                    title: item?.title,
                  })
                }}
              >
                <option value={true} key="Active">Active</option>
                <option value={false} key="Deactive">Deactive</option>
              </Select>
            </FormControl>
            <ButtonGroup size='small' variant='text'>
              <Button
                component={Link}
                to={`/carousel/${item._id}`}
              >
                Edit
              </Button>
              <ConfirmDelete
                id={item._id}
                label='carousel'
                name={item?.title &&
                  Array.isArray(item.title)
                  ? item.title.join(" | ") : item?.title || ''}
                deleteCallback={deleteCallback}
              />
            </ButtonGroup>
          </div>,
        createdAt: item?.createdAt,
        createdBy: item?.createdBy,
      }))}
      labels={[
        {
          name: 'Title',
          field: 'title',
        },
        {
          name: "Lang",
          field: "languages"
        },
        {
          name: 'Active',
          field: 'isActive',
        },
        {
          name: 'Created At',
          field: 'createdAt',
        },
        {
          name: 'Created By',
          field: 'createdBy',
        },
        {
          name: 'Actions',
          field: 'actions',
        },
      ]}
      sortFilter={sortFilter}
    />
  )
}

export default CarouselList
