import React from 'react'
import { Form, Formik } from 'formik';
import {
    Button, Grid, Select, Input, FormControl,
    MenuItem, Box, Chip, TextField, InputLabel
} from '@material-ui/core'
import AFRICA_ISO_CODE from '../settings/enums/africa-iso-codes.json'

const ResourceFilter = ({
    filters,
    handleClear,
    callback = () => { },
}) => {

    return (
        <Formik
            initialValues={{ keyword: '', countryCode: [] }}
            onSubmit={(values) => {
                callback({
                    ...values,
                    countryCode: values.countryCode.map((item) => AFRICA_ISO_CODE[item])
                })
            }}
        >
            {({ values, handleChange, handleSubmit, resetForm }) => (
                <>
                    <Form>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={4}>
                                <TextField
                                    name='keyword'
                                    label='Search Resource by Name'
                                    fullWidth
                                    value={values.keyword}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        multiple
                                        fullWidth
                                        displayEmpty
                                        id="countrySelect"
                                        name="countryCode"
                                        className="select-row"
                                        onChange={handleChange}
                                        labelId="countrylabelID"
                                        value={values?.countryCode ? values?.countryCode : []}
                                        input={<Input id="select-multiple-chip" label="Chip" />}
                                        renderValue={selected => (
                                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                                {selected.length > 0 &&
                                                    selected?.map((value) => <Chip key={value} label={value} />)
                                                }
                                            </Box>
                                        )}
                                    >
                                        <MenuItem disabled value=''>Country</MenuItem>
                                        {Object.entries(AFRICA_ISO_CODE).map(([key, value]) =>
                                            (<MenuItem value={key} key={key}>{key}</MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <div style={{ marginTop: 25 }}>
                            <Grid container spacing={4} alignItems="flex-start">
                                <Grid item sm={2} xs={3}>
                                    <Button
                                        style={{ width: '100%' }}
                                        variant='contained'
                                        color='primary'
                                        type='button'
                                        onClick={() => handleSubmit()}
                                    >APPLY</Button>
                                </Grid>
                                <Grid item sm={3} xs={4}>
                                    <Button
                                        style={{ width: '100%' }}
                                        size='medium'
                                        variant='contained'
                                        type='reset'
                                        onClick={() => {
                                            resetForm()
                                            handleClear(filters)
                                        }}
                                    >
                                        Clear Filter
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                </>)}
        </Formik>
    );
};

export default ResourceFilter;
