import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'

import Layout from '../components/Layout'
import Loader from '../components/Loader'
import { getLanguageByIdQuery } from '../api'
import DynamicLangForm from '../forms/DynamicLangForm'
import Authenticated from '../containers/Authenticated'

const DynamicLanguageEdit = ({ match: { params: { id } } }) => {

    const [runLanguageQuery, { data: { getLanguageById: data } = {}, loading, error }] = useLazyQuery(getLanguageByIdQuery)

    useEffect(() => {
        runLanguageQuery({
            variables: {
                _id: id
            }
        })
    }, [id])

    return (
        <Authenticated>
            <Layout title='Edit Language'>
                <Loader loading={loading} error={error}>
                    {data &&
                        <DynamicLangForm
                            data={data}
                        />
                    }
                </Loader>
            </Layout>
        </Authenticated>
    )
}

export default DynamicLanguageEdit
