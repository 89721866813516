import React from 'react';
import { CSVLink } from 'react-csv';

export default ({ data, filename, children, key, setExportReady }) => {
  const ref = React.useRef()
  const [downloaded, setDownloaded] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => {
      ref.current.link.click()
      setDownloaded(true)
      setExportReady(0)

    }, 1000)
  }, [])
  return <React.Fragment><CSVLink
    data={data}
    filename={filename}
    ref={ref}
  >
    {children}
  </CSVLink>
    <span>{!downloaded ? '...Exporting' : ''}</span>
  </React.Fragment>
}