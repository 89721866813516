import React from "react";
import Table from "../Table";
import { Link } from "react-router-dom";
// import ConfirmDelete from "../ConfirmDelete";
import { Switch, Button, ButtonGroup } from "@material-ui/core";
import { withStyles, createStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/react-hooks";
import { saveLanguageMutation } from "../../api";
import { useSnackbar } from 'notistack'

const AntSwitch = withStyles((theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);


const LanguageList = (props) => {
  const { enqueueSnackbar } = useSnackbar()

  const { data, sortFilter } = props

  const [saveLanguage, { loading: langLoading }] = useMutation(saveLanguageMutation)

  const updateActiveStatus = (item, e) => {
    !langLoading && saveLanguage({
      variables: {
        data: {
          id: item?._id,
          isActive: e.target.checked
        }
      }
    }).then(res => {
      enqueueSnackbar('The language has been updated, Please wait for a minute changes are going to live', { variant: 'success', autoHideDuration: 30000 })
    }).catch(err => {
      enqueueSnackbar('There was an error saving the language', { variant: 'error' })
      console.log(err);
    });
  };

  return (
    <Table
      data={data.map(item => ({
        langIndex: item.langIndex,
        langName: item.langName,
        langCode: item.langCode,
        createdAt: item?.createdAt,
        isActive: (
          item.langCode === "en" ? <></> : <AntSwitch checked={item.langIndex === 0 ? true : item.isActive}
            onChange={(e) => item.langIndex === 0 ? () => { } : updateActiveStatus(item, e)}
            name="isActive" />
        ),
        actions: (
          item.langCode === "en" ? <></> : <div
            style={{ display: "flex", alignItems: "baseline" }}
            className="isApprovedWrapper"
          >
            <ButtonGroup size="small" variant="text">
              <Button component={Link} to={`/language/${item._id}`}>
                Edit
              </Button>
            </ButtonGroup>
          </div>
        )
      }))}
      labels={[
        {
          name: "LanguageIndex",
          field: "langIndex"
        },
        {
          name: "Title",
          field: "langName"
        },
        {
          name: "LanguageCode",
          field: "langCode"
        },
        {
          name: "Created At",
          field: "createdAt"
        },
        {
          name: "Activate",
          field: "isActive"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ]}
      sortFilter={sortFilter}
    />
  );
};

export default LanguageList;
