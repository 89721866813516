import React from 'react'
import Layout from '../components/Layout'
import ResourceForm from '../forms/ResourceForm'
import multivalues from '../settings/multivalues.json'
import Authenticated from '../containers/Authenticated'
import LanguageSwitch from '../components/LanguageSwitch'

const ResourceNew = () => {

  const [languageIndex, setLanguageIndex] = React.useState(0)

  return (
    <Authenticated>
      <Layout title='Create Resource'>
        <LanguageSwitch
          languageIndex={languageIndex}
          setLanguageIndex={setLanguageIndex}
          languages={multivalues.languages}
        />
        <ResourceForm
          languageIndex={languageIndex}
          languagesTotal={Object.keys(multivalues.languages).length}
        />
      </Layout>
    </Authenticated>
  )
}

export default ResourceNew
