import React from 'react'
import PropTypes from 'prop-types'
import { Fade, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import ReactLoading from 'react-loading'

const Loader = ({ loading, error, small, medium, children, color }) => {
  const theme = useTheme()
  return (
    loading
      ? <ReactLoading type='spinningBubbles'
        color={color ? color : theme.palette.grey[300]}
        width={small && 16 || medium && 24}
        height={small && 16 || medium && 24}
      />
      : error
        ? <Typography color='error'>Error! Could not load a data from server.</Typography>
        : <Fade in><div>{children}</div></Fade>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Loader
