import React, { useCallback, useMemo, useState } from 'react'
import { getSurveyQuery, countSurveyQuery } from '../api'
import { useSnackbar } from 'notistack'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import SurveyList from '../components/SurveyList'
import Pagination from '../components/Pagination'
import ApolloCacheUpdater from "apollo-cache-updater";
import Authenticated from '../containers/Authenticated'
import SurveyFilterForm from '../forms/SurveyFilterForm'
import { _deleteSurveyMutation } from '../api'
import { useMutation, useQuery } from '@apollo/react-hooks'

const Survey = ({ history }) => {

  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = React.useState(0)
  const [sortData, setSortData] = useState(['_id:desc']);
  const [filters, setFilters] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [deleteSurvey] = useMutation(_deleteSurveyMutation, {})

  const parseFilters = useMemo(() => {
    const { keyword, category, published, dateFrom, createdAt } = filters;
    if (keyword) {
      var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
      .replace(/\!/g, "\\\\!")
      .replace(/\@/g, "\\\\@")
      .replace(/\#/g, "\\\\#")
      .replace(/\$/g, "\\\\\\$")
      .replace(/\%/g, "\\\\%")
      .replace(/\^/g, "\\\\^")
      .replace(/\&/g, "\\\\&")
      .replace(/\*/g, "\\\\*")
      .replace(/\)/g, "\\\\)")
      .replace(/\(/g, "\\\\(")
      .replace(/\[/g, "\\\\[")
      .replace(/\]/g, "\\\\]")
      .replace(/\;/g, "\\\\;")
      .replace(/\{/g, "\\\\{")
      .replace(/\}/g, "\\\\}")
      .replace(/\?/g, "\\\\?")
      .replace(/\,/g, "\\\\,");
    }
    return {
      ...keyword && { title_matches: searchKeyword },
      ...category && { categories_in: category },
      ...dateFrom && { dateFrom: dateFrom },
      ...createdAt && { createdAt: createdAt },
      ...published && (published === 'Published'
        ? { published: true }
        : published === 'Unpublished'
          ? { published: false } : {}
      ),
    };
  }, [filters]);

  const { data: { getSurvey: survey = [], countSurvey: rowsInTotal } = {},
    loading, error
  } = useQuery(getSurveyQuery, {
    variables: {
      params: {
        // authorDoesNotExists: true
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
          ? { where: parseFilters }
          : {}
        )
      },
    }
  })

  const handleRowPerChange = useCallback((value) => {
    setRowsPerPage(value)
  }, [])

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/survey");
  }, [history]);

  const handleFiltersSubmit = useCallback((fields) => {
    setPage(0)
    setFilters({ ...fields })
  }, []);

  const data = useMemo(() => (
    survey.map(item => {
      return {
        ...item,
        slug: item.slug[0],
      }
    })
  ), [survey])


  const sortFilter = useCallback((value, key) => {
    setSortData([`${value}:${key}`]);
  }, []);

  const handleDeleteCalllback = useCallback(async (id, name) => {
    deleteSurvey({
      variables: {
        id,
      },
      refetchQueries: [{
        query: getSurveyQuery,
        variables: {
          params: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            ...(filters && Object.keys(filters).length
              ? { where: parseFilters }
              : {}
            )
          },
        }
      }],
      update: (proxy, { data: { _deleteEso: deleteEso = {} } }) => {
        const updateOne = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getSurveyQuery, countSurveyQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        let updateTwo = true
        let userId
        if (deleteEso && typeof deleteEso === 'string') {
          userId = deleteEso.split('___').length > 1 ? deleteEso.split('___')[1] : null
        }

        if (userId) {
          updateTwo = ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getSurveyQuery], // queries you want to automatically update
            searchVariables: {},
            operator: 'ANY',
            operation: 'REMOVE',
            mutationResult: { _id: userId },
            ID: '_id',
          })
        }
        if (updateOne && updateTwo) enqueueSnackbar(`Survey ${name} has been deleted.`, { variant: 'success' })
      }
    }).then(() => { }).catch((e) => {
      enqueueSnackbar('Cannot delete Survey.', { variant: 'error' })
    })
  }, [])

  return (
    <Authenticated>
      <Layout
        title='Survey'
        head={
          <SurveyFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <SurveyList
            data={data} sortFilter={sortFilter}
            deleteCallback={handleDeleteCalllback}
          />
          {rowsInTotal > rowsPerPage &&
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default Survey