import React from 'react'
import Layout from '../components/Layout'
import Authenticated from '../containers/Authenticated'
import DynamicLangForm from '../forms/DynamicLangForm'

const DynamicLanguageNew = () => {

    return (
        <Authenticated>
            <Layout title='Create Language'>
                <DynamicLangForm />
            </Layout>
        </Authenticated>
    )
}

export default DynamicLanguageNew
