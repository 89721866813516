import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../Table";
import { Button, ButtonGroup,FormControl,Select } from "@material-ui/core";
import ConfirmDelete from "../ConfirmDelete";
import { useMutation } from "@apollo/react-hooks";
import { saveJobQueriesData } from "../../api";
import moment from "moment"

const JobList = ({
  data,
  deleteCallback,
  sortFilter
}) => {

  const [saveJob, { loading }] = useMutation(saveJobQueriesData);
  const [approvedValue, setApprovedValue] = useState({
    id: null,
    jobStatus: null
  });

  useEffect(() => {
    if (approvedValue.id) {
      updateApprovedStatus();
    }
  }, [approvedValue]);

  const updateApprovedStatus = () => {
    saveJob({
      variables: {
        data: approvedValue
      }
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Table
      data={
        data.map(item => ({
          jobTitle:
          item?.jobTitle && item?.jobTitle || "",
          

          jobType:
          item?.jobType &&  item?.jobType || "",

          countryName:
          item?.country &&  (item?.country == "others" ? item?.otherCountry : item?.country) || "",
            
          
          expirationDate:
          item?.expirationDate
          ? `${moment.utc(item.expirationDate).format("Do MMM, YYYY")} at ${moment(item.expirationTime).format("HH:mm A")} GMT`
          : "",
         
          // (() => {
          //   const expirationDate = new Date(item?.expirationDate); // Parse the date
          //   const options = {
          //     day: "2-digit",
          //     month: "short",
          //     year: "numeric"
          //   };
          //   const formattedDate = expirationDate.toLocaleDateString(
          //     "en-US",
          //     options
          //   );
          //   const hours = expirationDate
          //     .getUTCHours()
          //     .toString()
          //     .padStart(2, "0"); // UTC hours
          //   const minutes = expirationDate
          //     .getUTCMinutes()
          //     .toString()
          //     .padStart(2, "0"); // UTC minutes
          //   return `${formattedDate} at ${hours}:${minutes} GMT`;
          // })() || "",

        actions: (
            <div
              style={{ display: "flex", alignItems: "baseline" }}
              className="isApprovedWrapper"
            >
              <FormControl variant="outlined" className={item?.jobStatus==="approved" ? "approved" : "pending"}>
                <Select
                  native
                //   disabled={loading}
                  defaultValue={item?.jobStatus ? item.jobStatus : "pending"}
                  onChange={(e) => {
                    setApprovedValue({
                      id: item?._id,
                      jobStatus: e.target.value,
                    })
                  }}
                >
                  <option value="pending" key="Pending">Pending</option>
                  <option value="approved" key="Approved">Approved</option>
                </Select>
              </FormControl>
              <ButtonGroup size="small" variant="text">
                <Button component={Link} to={`/jobList/${item._id}`}>
                  View
                </Button>
  
                <ConfirmDelete
                  id={item._id}
                  name={item.jobTitle}
                  deleteCallback={deleteCallback}
                />
              </ButtonGroup>
            </div>
          ),
        createdAt: item?.createdAt
      }))}
      labels={[
        {
          name: "Title",
          field: "jobTitle"
        },
        {
          name: "Job Type",
          field: "jobType"
        },
        {
          name: "Country",
          field: "countryName"
        },
        {
          name: "Expire At",
          field: "expirationDate"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ]}
  
      sortFilter={sortFilter}
    />
  );
};

export default JobList;
