import React, { useEffect } from 'react'
import Authenticated from '../../containers/Authenticated'
import DynamicSurveyForm from '../../forms/DynamicSurveyForms/DynamicSurveyForm'
import { useLazyQuery } from '@apollo/react-hooks'
import { getDynamicSurveyByIdQuery } from '../../api'
import Loader from '../../components/Loader'
import Layout from '../../components/Layout'
import multivalues from '../../settings/multivalues.json'


const DynamicSurveyEdit = ({ match: { params: { id } } }) => {


  const [dynamicSurveyByIdQuery, { data: { getDynamicSurveyByID: data } = {}, loading, error }] = useLazyQuery(getDynamicSurveyByIdQuery)

  useEffect(() => {
    dynamicSurveyByIdQuery({
      variables: {
        id
      }
    })
  }, [id])

  return (
    <Authenticated>
      <Layout title='Edit DynamicSurvey'>
        <Loader loading={loading} error={error}>
          {data &&
              <DynamicSurveyForm
                data={data}
                languagesTotal={Object.keys(multivalues.languages).length}
              />
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default DynamicSurveyEdit