export {
  connectionQuery,
  storeQuery,
  authQuery,
  currentUserQuery,
  _currentAdminQuery,
  getEsosQuery,
  getEsoBySlugQuery,
  getUsersQuery,
  countEsosQuery,
  countUsersQuery,
  getEsosExportQuery,
  getNewsQuery,
  getSurveyQuery,
  countSurveyQuery,
  getNewsBySlugQuery,
  getResourcesQuery,
  getResourceByIdQuery,
  countResourcesQuery,
  getMaeQuery,
  countArchivedResourcesQuery,
  getArchivedResourcesQuery,
  getCustomIndicatorsQuery,
  getUsersEditQuery,
  _s3SignMutationImg,
  saveOwnUserMutation,
  getCarouselQuery,
  countCarouselQuery,
  getCarouselByIdQuery,
  getFAQByIdQuery,
  getFAQQuery,
  countFAQQuery,
  getLanguageByIdQuery,
  getLanguageQuery,
  countLanguageQuery,
  getDynamicSurveyQuery,
  getDynamicSurveyByIdQuery,
  getDynamicSurveyAnalyticsQuery,
  getResourceAnalyticsQuery,
  getGA4AnalyticsQuery,
  getAnalyticsCountQuery,
  getRecurringUserQuery,
  getRecurringUsersCountQuery,
  getJobListingData,
  getJobByIdQuery
} from './_queries'

export {
  loginMutation,
  storeMutation,
  saveEsoMutation,
  _s3SignMutation,
  _deleteUserMutation,
  _deleteEsoMutation,
  _inviteEsoMutation,
  _revokeEsoInvitationMutation,
  saveNewsMutation,
  _deleteNewsMutation,
  _getNewsLinkContentMutation,
  _safeS3SignMutation,
  saveResourceMutation,
  deleteResourceMutation,
  deleteArchivedResourceMutation,
  addCustomIndicatorMutation,
  deleteCustomIndicatorMutation,
  editUserDetails,
  addUserDetails,
  inviteUserByAdmin,
  importESO,
  locationDetails,
  _deleteSurveyMutation,
  _deleteCarouselMutation,
  saveCarouselMutation,
  importUser,
  saveFAQMutation,
  _deleteFAQMutation,
  saveLanguageMutation,
  _deleteDynamicSurveyMutation,
  saveDynamicSurveyMutation,
  exportDynamicSurveyAnalyticsMutation,
  _sendNotificationSurveyMutation,
  saveJobQueriesData,
  _deleteJobPostMutation
} from './_mutations'

export { defaults, resolvers } from './local-state'
