import React from 'react'
import { Formik, Form } from 'formik';
import { Button, Grid,TextField } from '@material-ui/core'

const RecurringUserFilterForm = (props) => {
    const {
        handleClear,
        callback = () => { },
      } = props
  return (
    <Formik
    initialValues={{ keyword: ''}}
    onSubmit={(values) => callback(values)}
  >
    {({
      values,
      resetForm,
      handleChange,
    }) => {
      return <Form name="filter_newsfeed" >
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={4}>
            <TextField
              fullWidth
              name="keyword"
              value={values.keyword}
              onChange={handleChange}
              label="Search Name"
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 25 }}>
          <Grid container spacing={4} justifyContent='flex-start'>
            <Grid item xs={3} sm={2}>
              <Button style={{ width: '100%' }} color="primary"
                variant="contained" type='submit'>
                APPLY
              </Button>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Button style={{ width: '100%' }} type="reset"
                color="inherit" variant="contained"
                onClick={() => {
                  resetForm();
                  handleClear()
                }}
              >
                Clear Filter
              </Button>
            </Grid>
          </Grid>
        </div>
      </Form>
    }}
  </Formik>
  )
}

export default RecurringUserFilterForm