import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import SurveyForm from '../forms/SurveyForm'
import { useLazyQuery } from '@apollo/react-hooks'
import { getSurveySlugQuery } from '../api/_queries'
import multivalues from '../settings/multivalues.json'
import Authenticated from '../containers/Authenticated'
import LanguageSwitch from '../components/LanguageSwitch'


const SurveyEdit = ({ match: { params: { slug } } }) => {

  const [languageIndex, setLanguageIndex] = React.useState(0)
  const [surveySlugQuery, { data: { getSurveyBySlug: data } = {}, loading, error }] = useLazyQuery(getSurveySlugQuery)

  useEffect(() => {
    surveySlugQuery({
      variables: {
        slug
      }
    })
  }, [slug])

  // const getKeyByValue = useMemo(() => {
  //   const currentLang = [Object.keys(multivalues.languages)
  //     .find(key => multivalues.languages[key] === languageIndex)]
  //   if (data && data.languages?.length) {
  //     if (data.languages.includes(currentLang[0])) {
  //       return data.languages
  //     } else {
  //       return [...data.languages, ...currentLang]
  //     }
  //   } else {
  //     return currentLang
  //   }
  // }, [multivalues.languages, languageIndex, data])

  return (
    <Authenticated>
      <Layout title='Edit Survey'>
        <Loader loading={loading} error={error}>
          {data &&
            <>
              <LanguageSwitch
                languageIndex={languageIndex}
                setLanguageIndex={setLanguageIndex}
                languages={multivalues.languages}
              />
              <SurveyForm
                languageIndex={languageIndex}
                languagesTotal={Object.keys(multivalues.languages).length}
                data={data}
              />
            </>
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default SurveyEdit
