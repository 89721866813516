import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { parse, stringify } from 'querystringify'
import ApolloCacheUpdater from "apollo-cache-updater";
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import OrganizationList from '../components/OrganizationList'
import Pagination from '../components/Pagination'
import Loader from '../components/Loader'
import { getUsersQuery, countUsersQuery, getEsosQuery, countEsosQuery, getEsosExportQuery, _deleteEsoMutation } from '../api'
import OrganizationFiltersForm from '../forms/OrganizationFiltersForm'



const transformData = data => {
  let csvData = data.reduce((arr, item) => {
    arr[0] = Object.entries(item).reduce((obj, [key, value]) => ({
      ...obj,
      ...(!obj[key]) && {
        [key]: Object.keys(obj).length
      }
    }), {})
    let shell = Array(Object.keys(item).length).join(".").split(".") // array of empty strings
    Object.entries(item).forEach(function ([key, value]) {
      shell[arr[0][key]] = value
    })
    return [
      ...arr,
      shell
    ]
  }, [{}])
  let shell = Array(Object.keys(csvData[0]).length)
  Object.entries(csvData[0]).forEach(function ([key, value]) {
    shell[csvData[0][key]] = key
  })
  csvData[0] = [...shell]
  return csvData
}


const Organizations = ({ history, location: { search } }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [exportReady, setExportReady] = React.useState(0)
  const [csvData, setCSVData] = React.useState([])
  const [deleteEso] = useMutation(_deleteEsoMutation)
  const [page, setPage] = React.useState(0) // Page numeration starts at 0.
  const [filters, setFilters] = React.useState()
  const [sortData, setSortData] = useState(['_id:desc']);
const [clearFilterLoading,setClearFilterLoading]=useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const sortFilter = (value, key) => {
    setSortData([`${value}:${key}`]);
  };


  React.useEffect(() => {
    const parsedFilters = parse(search)
    if (parsedFilters) {
      setFilters(parsedFilters)
    }
  }, [search])


  const parseFilters = () => {
    const {
      country,
      stage,
      service,
      category,
      keyword,
      published,
      featured,
      language,
      targetGroups,
      targetSectors,
      legalEntity,
      geoActive,
      outreachStrategy,
      outreachSocialMediaStrategy,
      financialSuportProvided,
      founder,
      fundingSources,
      programChallenges,
      source,
      supportNeeded,
      womenStaff,
      directedByWoman,
      operationalCosts,
      partner
    } = filters
    /* eslint-disable camelcase */
    if (keyword) {
      var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
      .replace(/\!/g, "\\\\!")
      .replace(/\@/g, "\\\\@")
      .replace(/\#/g, "\\\\#")
      .replace(/\$/g, "\\\\\\$")
      .replace(/\%/g, "\\\\%")
      .replace(/\^/g, "\\\\^")
      .replace(/\&/g, "\\\\&")
      .replace(/\*/g, "\\\\*")
      .replace(/\)/g, "\\\\)")
      .replace(/\(/g, "\\\\(")
      .replace(/\[/g, "\\\\[")
      .replace(/\]/g, "\\\\]")
      .replace(/\;/g, "\\\\;")
      .replace(/\{/g, "\\\\{")
      .replace(/\}/g, "\\\\}")
      .replace(/\?/g, "\\\\?")
      .replace(/\,/g, "\\\\,");
    }
    return {
      ...country && { countryCode: country },
      ...stage && { growthStagesSupported_in: stage },
      ...service && { services_in: service },
      ...category && { categories_in: category },
      ...targetGroups && { targetGroups_in: targetGroups },
      ...targetSectors && { targetSectors_in: targetSectors },
      ...language && { language_in: language },
      ...legalEntity && { legalEntity_in: legalEntity },
      ...geoActive && { geoActive_in: geoActive },
      ...outreachStrategy && { outreachStrategy_in: outreachStrategy },
      ...outreachSocialMediaStrategy && { outreachSocialMediaStrategy_in: outreachSocialMediaStrategy },
      ...financialSuportProvided && { financialSuportProvided_in: financialSuportProvided },
      ...founder && { founder_in: founder },
      ...fundingSources && { founder_in: fundingSources },
      ...programChallenges && { programChallenges_in: programChallenges },
      ...source && { source_in: source },
      ...supportNeeded && { supportNeeded_in: supportNeeded },
      ...operationalCosts && { operationalCosts_in: operationalCosts },
      ...womenStaff && { womenStaff_in: womenStaff },
      ...directedByWoman && { directedByWoman },
      ...keyword && { name_matches: searchKeyword },
      ...featured && featured === 'Featured' && { featured: true },
      ...featured && featured === 'Unfeatured' && { featured_ne: false },
      ...published && published === 'Published' && { published: true },
      ...published && published === 'Unpublished' && { published: false },
      ...partner && partner === 'Is a partner' && { partner: true },
      ...partner && featured === 'Is not a partner' && { partner_ne: false },
    }
  }

  const {
    data: { getEsos: data = [], countEsos = 1 } = {},
    loading,
    error,
    refetch
  } = useQuery(getEsosQuery, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage, ...filters && {
          where: parseFilters(),
        },
      },
    },
  })

  const enableExport = data && data.length > 0
  const exportText = filters && data.length > 0 && typeof filters === 'object' &&
    Object.keys(filters).filter(key => !!filters[key]).length > 0
    ? 'EXPORT RESULTS'
    : 'EXPORT DB'

  const importText = 'IMPORT ESO'

  const { data: { getEsos: dataForCsv = [] } = {} } = useQuery(getEsosExportQuery, {
    variables: {
      params: {
        sort: ['_id:desc'],
        limit: -1,
        ...filters && {
          where: parseFilters(),
        },
      },
    },
    skip: csvData.length && exportReady
  })

  if (dataForCsv.length > 0 && enableExport && exportReady) {
    setCSVData(transformData(dataForCsv.map(item => {
      item.user = item.user ? item.user._id : null
      const { __typename, location, logo, ...rest } = item
      return rest
    })))
  }


  const handleFiltersSubmit = fields => {
    const newFiltersQueryString = stringify(fields)
    setExportReady(0)
    setPage(0)
    setCSVData([])
    history.push(`/organizations?${newFiltersQueryString}`)
  }


  const deleteOrganizationCallback = (id, name) => {
    deleteEso({
      variables: {
        id,
      },
      update: (proxy, { data: { _deleteEso: deleteEso = {} } }) => {
        const updateOne = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getEsosQuery, countEsosQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        let updateTwo = true
        let userId
        if (deleteEso && typeof deleteEso === 'string') {
          userId = deleteEso.split('___').length > 1 ? deleteEso.split('___')[1] : null
        }

        if (userId) {
          updateTwo = ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getUsersQuery, countUsersQuery], // queries you want to automatically update
            searchVariables: {},
            operator: 'ANY',
            operation: 'REMOVE',
            mutationResult: { _id: userId },
            ID: '_id',
          })
        }
        if (updateOne && updateTwo) enqueueSnackbar(`Organization ${name} has been deleted.`, { variant: 'success' })
      }
    })
      .then(() => { })
      .catch((e) => {
        enqueueSnackbar('Cannot delete organization.', { variant: 'error' })
        console.log(e);
      })
  }


  const rowsInTotal = countEsos
  const handleRowPerChange = (value) => {
    setRowsPerPage(value)

  }
  const handleClear = filters => {
    if (Object.keys(filters).length > 0) {
      setFilters();
      setClearFilterLoading(true)
    }
    else {
      setClearFilterLoading(true)
      setFilters({});
    }

    history.push("/organizations");
    setTimeout(()=>{
      setClearFilterLoading(false)
    },1000)

  };

  return (
    <Authenticated>
      <Layout
        title='Organisations'
        head={
          <Loader
            loading={loading || clearFilterLoading}
            error={error}>
            <OrganizationFiltersForm
              filters={filters}
              callback={handleFiltersSubmit}
              enableExport={enableExport}
              exportText={exportText}
              importText={importText}
              refetch={refetch}
              computeExport={() => {
                setExportReady(exportReady + 1)
              }}
              setExportReady={setExportReady}
              handleClear={(filter) => handleClear(filter)}
              exportReady={exportReady}
              csvData={csvData}
            />
          </Loader>
        }
      >
        <Loader loading={loading} error={error}>
          <OrganizationList
            data={data}
            deleteCallback={deleteOrganizationCallback}
            sortFilter={sortFilter}
          />
          {rowsInTotal > rowsPerPage &&
            <Pagination
              count={rowsInTotal}
              handleRowPerChange={handleRowPerChange}
              rowsPerPage={rowsPerPage}
              page={page}
              callback={newPage => setPage(newPage)}
            />
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}


export default withRouter(Organizations)