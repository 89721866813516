import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "../../components/Loader";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import CohortFilterForm from "../../forms/CohortFilterForm/CohortFilterForm";
import history from "../../history";
import CohortList from "../../components/CohortList/CohortList";
import { countCohortQuery, getCohortsData } from "../../api/_queries";
import "./CohortPage.css";
import { deleteCohortMutation } from "../../api/_mutations";
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from "notistack";
import { NearMeSharp } from "@material-ui/icons";
import Pagination from "../../components/Pagination";


const CohortPage = () => {
    const { enqueueSnackbar } = useSnackbar()
  

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState(["_id:desc"]);


  const [deleteCohort] = useMutation(deleteCohortMutation, {})




   const parseFilters = useMemo(() => {
      const { name, services, country} = filters;
      if (name) {
        var searchKeyword = name
          .replace(/\\/g, "\\\\\\\\")
          .replace(/\!/g, "\\\\!")
          .replace(/\@/g, "\\\\@")
          .replace(/\#/g, "\\\\#")
          .replace(/\$/g, "\\\\\\$")
          .replace(/\%/g, "\\\\%")
          .replace(/\^/g, "\\\\^")
          .replace(/\&/g, "\\\\&")
          .replace(/\*/g, "\\\\*")
          .replace(/\)/g, "\\\\)")
          .replace(/\(/g, "\\\\(")
          .replace(/\[/g, "\\\\[")
          .replace(/\]/g, "\\\\]")
          .replace(/\;/g, "\\\\;")
          .replace(/\{/g, "\\\\{")
          .replace(/\}/g, "\\\\}")
          .replace(/\?/g, "\\\\?")
          .replace(/\,/g, "\\\\,");
      }
      return {
        ...(name && { name_contains: searchKeyword }),
        ...(services && {services_contains:  services }),
        ...(country && { country_contains :country})
      };
    }, [filters]);

  const {
    data: { getCohorts: cohortsList = [], countCohorts: rowsInTotal } = {},
    loading,
    error
  } = useQuery(getCohortsData, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters &&
          Object.keys(filters).length &&
          Object.keys(parseFilters).length
            ? { where: parseFilters }
            : {})
      },
      fetchPolicy:'no-cache'
    }
  });


  //Delete function:
  const handleDeleteCalllback = useCallback(async (id, name) => {
    deleteCohort({
      variables: {
        id,
      },
      refetchQueries: [{
        query: getCohortsData,
        variables: {
          params: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            ...(filters && Object.keys(filters).length
                ? { where: parseFilters }
                : {}
              )
          },
        }
      }],
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getCohortsData,countCohortQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        if (update) enqueueSnackbar(`Cohort ${name} has been deleted.`, { variant: 'success' })
      }
    }).then(() => { }).catch((e) => {
      enqueueSnackbar('Cannot delete Cohort.', { variant: 'error' })
    })
  }, [])
  //Sorting function:
  const sortFilter = useCallback((value, key) => {
    if (value == "country") {
      setSortData([`country:${key}`]);
    } else {
      setSortData([`${value}:${key}`]);
    }
  }, []);

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/cohortList");
  }, [history]);

  //Apply filters function:
  const handleFiltersSubmit = useCallback(fields => {
    setPage(0);
    setFilters({ ...fields });
  }, []);

  const handleRowPerChange = (value) => {
    setRowsPerPage(value)

  }
  return (
    <Authenticated>
      <Layout
        title="Cohort List"
        head={
          <CohortFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <CohortList
            data={cohortsList}
            sortFilter={sortFilter}
            deleteCallback={handleDeleteCalllback}
          />

          {rowsInTotal > rowsPerPage && (
          <Pagination
            page={page}
            count={rowsInTotal}
            rowsPerPage={rowsPerPage}
            callback={newPage => setPage(newPage)}
            handleRowPerChange={handleRowPerChange}
          />
        )} 
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default CohortPage;
