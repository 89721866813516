import React, { useCallback } from 'react'
import * as Yup from 'yup'
import history from '../history'
import { useSnackbar } from 'notistack'
import Loader from '../components/Loader'
import { useMutation } from '@apollo/react-hooks'
import { Formik, Form, ErrorMessage } from 'formik'
import ApolloCacheUpdater from 'apollo-cache-updater'
import {
  Grid, Button, Checkbox, FormControl, FormControlLabel, Box,
  FormHelperText, InputLabel, MenuItem, Select, TextField, Chip, RadioGroup, Typography, Radio
} from '@material-ui/core'
import SERVICES from '../settings/enums/categories.json'
import { getSurveyQuery, countSurveyQuery } from '../api'
import SECTORS from '../settings/enums/target-sectors.json'
import { graphqlError } from '../utils'
import PERSONAL_INTERESTS from '../settings/enums/personal-interest.json'
import { saveSurveyMutation, _safeS3SignMutation } from '../api/_mutations'
import MultiImageUpload from '../components/MultiImageUpload'
const SurveyForm = (props) => {
  const { data, languageIndex } = props

  const { enqueueSnackbar } = useSnackbar()
  const [saveSurvey, { loading }] = useMutation(saveSurveyMutation)
  const [safeS3Sign, { loading: s3Loading }] = useMutation(_safeS3SignMutation)
  const [fileUploading, setFileUploading] = React.useState(false)

  const addLabel = obj => Object.entries(obj).reduce((o, [k, v]) => ({
    ...o,
    [k]: { label: v }
  }), {})

  const CATEGORIES = {
    // ...BUSINESS_STAGE,
    ...addLabel(PERSONAL_INTERESTS),
    ...SERVICES,
    ...addLabel(SECTORS),
  }

  const isTranslatable = bool => languageIndex !== 0
    ? {
      disabled: !bool,
      helperText: bool
        ? "Enter translation for selected language."
        : "This field is not translatable."
    }
    : {};

  // validation schema
  const validationSchema = Yup.object().shape({
    categories: Yup.array().min(1, 'This field is required.'),
    title: Yup.array().of(Yup.string()).test('title-ele',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }),
    url: Yup.string().matches(
      // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      /^(https?):\/\/[-\w]+(\.\w[-\w]*)+([/?#].*)?$/,
      'This is not a valid URL.'
    ).required('This field is required.'),
    dateFrom: Yup.date().min(data?.dateFrom
      ? new Date(data?.dateFrom).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10))
      .required('This field is required.'),
    dateTo: Yup.date().required('This field is required.'),
  })

  const handleSubmit = useCallback(async (fields) => {
    const { categories, published, title, content, url, dateFrom, dateTo, images, openAs } = fields;

    const dataObject = {
      categories, published,
      dateFrom, dateTo,
      images, openAs,
      url,
      title,
      content
    }

    let allImages
    if (Array.isArray(images)) {
      allImages = await images.reduce(async (arr, file) => {
        let attachmentUrl = file || null
        if (attachmentUrl) {
          if (typeof attachmentUrl === 'string') return [...await arr, attachmentUrl]
          const { dataFile, name: filename } = file
          try {
            const response = await safeS3Sign({
              variables: {
                filename,
                filetype: file.type,
              },
            })
            const { signedRequest, url: bucketUrl } = response.data._safeSignS3
            const options = {
              headers: {
                'Content-Type': file.type,
              },
            }
            try {
              setFileUploading(true)
              await fetch(signedRequest, {
                method: 'PUT',
                headers: options.headers,
                body: dataFile,
              })
              setFileUploading(false)
              attachmentUrl = bucketUrl
            } catch (e) {
              attachmentUrl = null
              console.log(e)
              setFileUploading(false)
            }
          } catch (e) {
            const errMsg = graphqlError(e, {
              // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
              // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
            })
            if (errMsg) {
              // toast.error(errMsg)
            } else {
              // toast.error('Cannot uplaod file(s)')
              console.log(e)
            }
            return [...await arr]
          }
          return [...await arr, attachmentUrl]
        }
      }, [])
      if (allImages && allImages.length > 0) {
        dataObject.images = allImages
      }
    }

    try {
      saveSurvey({
        variables: {
          data: {
            ...(data && data._id) ? { id: data._id } : {},
            ...dataObject
          }
        },
        update: (proxy, { data: { saveSurvey = {} } }) => {
          if (!data || !data._id) {
            const mutationResult = saveSurvey
            ApolloCacheUpdater({
              proxy,
              ID: '_id',
              operator: 'ANY',
              searchVariables: { skip: 0 },
              queriesToUpdate: [getSurveyQuery, countSurveyQuery],
              mutationResult: { ...mutationResult },
            })
          }
        },
      }).then((res) => {
        if (res?.data?.saveSurvey?.status === 201) {
          enqueueSnackbar(`This URL is not supported iframe`, { variant: 'error' })
        }
        else {
          enqueueSnackbar('The survey has been saved.', { variant: 'success' })
          history.replace('/survey')
        }
      }).catch(e => {
        console.error(e)
        enqueueSnackbar('There was an error saving the survey', { variant: 'error' })
      })
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Could not save the survey.', { variant: 'error' })
    }
  },[])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        openAs: data?.openAs ? data.openAs : 'url',
        images: data?.images ? data.images : [],
        url: data?.url ? data.url : '',
        categories: data?.categories ? data.categories : [],
        published: data?.published ? data.published : false,
        title: data?.title ? data.title : [],
        content: data?.content ? data.content : [],
        dateFrom: data?.dateFrom ? data.dateFrom.slice(0, 10) : '',
        dateTo: data?.dateTo ? data.dateTo.slice(0, 10) : undefined,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue }) => {
        return <Form>
          <Grid container spacing={6} justifyContent='space-between'>
            <Grid item md={6} lg={4} container direction='column' alignItems='flex-start'>
              <FormControl fullWidth>
                <InputLabel>Categories <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  multiple
                  name='categories'
                  onChange={handleChange}
                  value={values?.categories}
                  {...isTranslatable(false)}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={CATEGORIES[value]?.label} />
                      ))}
                    </Box>
                  )}
                >
                  {Object.entries(CATEGORIES).map(([key, value]) => {
                    return (<MenuItem value={key} key={key} >{value?.label}</MenuItem>)
                  })}
                </Select>
                {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                <ErrorMessage name='categories'>
                  {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>

              <Box style={{ marginBottom: '5%' }}>
                <MultiImageUpload
                  name={'images'}
                  value={values?.images}
                  setValue={setFieldValue}
                />
                {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    name="published"
                    checked={values?.published}
                    {...isTranslatable(false)}
                  />
                }
                onChange={handleChange}
                value={values?.published || false}
                label="Published"
              />
              {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
            </Grid>
            <Grid item md={6} lg={7} container direction='column' alignItems='flex-start'>
              <InputLabel>Title <span style={{ color: 'red' }}>*</span></InputLabel>
              <TextField
                fullWidth
                type="text"
                name={`title[${languageIndex}]`}
                onChange={handleChange}
                value={(values?.title.length && values.title[languageIndex]) || ''}
                {...isTranslatable(true)}
              />
              <ErrorMessage name='title' >
                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
              </ErrorMessage>
              <FormControl fullWidth>
                <TextField
                  multiline
                  minRows={5}
                  type="text"
                  label="Content"
                  name={`content[${languageIndex}]`}
                  onChange={handleChange}
                  value={(values?.content.length && values.content[languageIndex]) || ''}
                  {...isTranslatable(true)}
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <InputLabel>From <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    type="date"
                    name='dateFrom'
                    onChange={handleChange}
                    value={values?.dateFrom}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      min: data?.dateFrom
                        ? new Date(data?.dateFrom).toISOString().slice(0, 10)
                        : new Date().toISOString().slice(0, 10)
                    }}
                    {...isTranslatable(false)}
                  />
                  
                  <ErrorMessage name='dateFrom'>
                    {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                  </ErrorMessage>
                </Grid>
                <Grid item sm={6}>
                  <InputLabel>To <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    fullWidth
                    type="date"
                    name='dateTo'
                    onChange={handleChange}
                    value={values.dateTo}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ min: values?.dateFrom || new Date().toISOString().slice(0, 10) }}
                    {...isTranslatable(false)}
                  />
                  <ErrorMessage name='dateTo'>
                    {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                  </ErrorMessage>
                </Grid>
              </Grid>
              <InputLabel>URL <span style={{ color: 'red' }}>*</span></InputLabel>
              <TextField
                fullWidth
                type="text"
                name={`url`}
                onChange={handleChange}
                value={values?.url || ''}
                {...isTranslatable(false)}
              />
              <ErrorMessage name='url'>
                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
              </ErrorMessage>

              <FormControl component="fieldset">
                <Typography component="legend">Open As</Typography>
                <RadioGroup row name="openAs" value={values?.openAs} onChange={handleChange}>
                  {[{ label: 'Url', value: 'url' }, { label: 'Iframe', value: 'iframe' }].map(({ label, value }) => (<FormControlLabel
                    key={value}
                    value={value}
                    label={label}
                    labelPlacement="end"
                    {...isTranslatable(false)}
                    control={<Radio color="secondary" />}
                  />
                  ))}
                </RadioGroup>
                {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
              </FormControl>

            </Grid>
          </Grid>
          <Grid container spacing={4} justifyContent="flex-end">
            <Grid container item sm={2} xs={3} >
              <Button style={{ width: '100%' }} variant="contained" color="inherit"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid container item sm={2} xs={3} >
              <Button style={{ width: '100%' }} variant="contained"
                color='primary' type='submit'
              >
                <Loader loading={loading || s3Loading || fileUploading} small>
                  Save
                </Loader>
              </Button>
            </Grid>
          </Grid>
        </Form>
      }}
    </Formik>
  )
}

export default SurveyForm