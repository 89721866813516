import React from 'react'
import { ResourceAnalyticsList } from './ResourceAnalyticsList';

const ResourceAnalyticsDetails = ({ history }) => {


    return (
        <ResourceAnalyticsList history={history} />
    )
}

export default ResourceAnalyticsDetails