import React from 'react'
import Authenticated from '../../containers/Authenticated'
import Layout from '../../components/Layout'
import DynamicSurveyForm from '../../forms/DynamicSurveyForms/DynamicSurveyForm'

const DynamicSurveyNew = () => {

    return (
        <Authenticated>
            <Layout title='Create Dynamic survey'>
                <DynamicSurveyForm />
            </Layout>
        </Authenticated>
    )
}

export default DynamicSurveyNew