import React from 'react'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import MAndEContainer from '../containers/MAndE'
import '../styles/MandE/mande.css'

const MAndE = () => {
  return (
    <Authenticated>
      <Layout title='M&E Dashboard'>
        {/* <Loader loading={loading} error={error}> */}
          <h3>{'M&E'}</h3>
          <MAndEContainer />

        {/* </Loader> */}
      </Layout>
    </Authenticated>
  )
}

export default MAndE
