import React from 'react'
import Table from './Table'
import { Link } from 'react-router-dom'
import ConfirmDelete from './ConfirmDelete'
import { Check, Block } from '@material-ui/icons'
import { Button, ButtonGroup } from '@material-ui/core'

const SurveyList = ({ data, deleteCallback, sortFilter }) => {
  return (
    <Table
      data={data.map(item => ({
        title: item?.title && Array.isArray(item.title)
          ? item.title.filter((ele) => ele !== '').join(" | ")
          : item?.title || '',
        languages: item?.languages &&
          Array.isArray(item.languages) &&
          item.languages.join("|"),
        published: item.published ? <Check color='secondary' /> : <Block color='disabled' />,
        actions:
          <>
            <ButtonGroup size='small' variant='text'>
              <Button
                component={Link}
                to={`/survey/${item.slug}`}
              >
                Edit
              </Button>
              <ConfirmDelete
                id={item._id}
                label='survey'
                name={item?.title &&
                  Array.isArray(item.title)
                  ? item.title.join(" | ") : item?.title || ''}
                deleteCallback={deleteCallback}
              />
            </ButtonGroup>
          </>,
        createdAt: item?.createdAt,
        dateFrom: item?.dateFrom,
        dateTo: item?.dateTo
      }))}
      labels={[
        {
          name: 'Title',
          field: 'title',
        },
        {
          name: "Lang",
          field: "languages"
        },
        {
          name: 'Published',
          field: 'published',
        },
        {
          name: 'Created At',
          field: 'createdAt',
        },
        {
          name: 'From',
          field: 'dateFrom',
        },
        {
          name: 'To',
          field: 'dateTo',
        },
        {
          name: 'Actions',
          field: 'actions',
        },
      ]}
      sortFilter={sortFilter}
    />
  )
}

export default SurveyList
