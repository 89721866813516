import React, { useState } from "react";
import { useSnackbar } from 'notistack'
import {
  inviteUserByAdmin
  
} from "../api";
import ConfirmDelete from "./ConfirmDelete";
import { Link, useLocation } from "react-router-dom";
import {
  Table as MUITable, Button, ButtonGroup, Typography, CircularProgress,
  TableBody, TableCell, TableHead, TableRow, Tooltip,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { Add, UnfoldMore } from "@material-ui/icons";
import AFIRCA_COUNTRIES from "../settings/enums/new-africa-iso-code.json";
import '../styles/Table/table.css'

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

const labels = [
  {
    id: "Name",
    name: "Name",
    field: "name"
  },
  {
    id: "Email",
    name: "Email",
    field: "email"
  },
  {
    id: "Country",
    name: "Country",
    field: "country"
  },
  {
    id: "Type",
    name: "Type",
    field: "type"
  },
  {
    id: "Associated",
    name: "Associated",
    field: "esoName"
  },
  {
    id: 'Service Interests',
    name: 'Service Interests',
    field: 'serviceInterests',
  },
  {
    id: "Gender",
    name: "Gender",
    field: "gender"
  },
  {
    id: "Created At",
    name: "Created At",
    field: "createdAt"
  },
  {
    id: "Last Login",
    name: "Last Login",
    field: "loginDate"
  },
  {
    id: "Actions",
    name: "Actions",
    field: "actions"
  }
]

const UserList = ({ data, deleteUserCallback, sortFilter, refetch }) => {

  let pathDetails = useLocation();
  const [order, setOrder] = useState("asc");
  const [value, setValue] = useState("name");
  const [inviteId, setInviteId] = useState(null)

  const [inviteUser, { loading: inviteuserLoading }] = useMutation(inviteUserByAdmin);
  const { enqueueSnackbar } = useSnackbar()

  const handleInviteuser = (_id) => {
    setInviteId(_id)
    inviteUser({
      variables: {
        id: _id
      },
    }).then((response) => {
      refetch();
      enqueueSnackbar('Invitation Sent Successfully!', { variant: 'success' })
    }).catch((e) => {
      console.error(e);
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    })
  }

  const ascd = key => {
    if (key === "esoName") {
      setValue("eso");
    } else {
      setValue(key);
    }
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    sortFilter(value, order)
  };

  const showDate = d => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    if (d) {
      const datestring =
        d.getDate() +
        " " +
        months[d.getMonth()] +
        " " +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes();
      return datestring;
    }
    return "-";
  };


  let userTableData = data.map(item => ({
    ...item,
    ...(item.eso && item.eso.name && { esoName: item.eso.name }),
    actions: (
      <span style={{ display: 'flex' }}>
        <ButtonGroup size='small' variant='text'>
          <Button
            size="small"
            variant="text"
            component={Link}
            to={`/users/${item._id}`}
          >
            Edit
          </Button>
          <ConfirmDelete
            id={item._id}
            label="user"
            name={item.email}
            deleteCallback={deleteUserCallback}
          />
        </ButtonGroup>
        {item.createdByAdmin && item.invitationStatus === 'Pending' &&
          <Button
            size="small"
            type="button"
            color='primary'
            variant="contained"
            disabled={inviteuserLoading && inviteId === item._id}
            onClick={() => handleInviteuser(item._id)}
          >
            {(inviteuserLoading && inviteId === item._id) && <CircularProgress color='inherit'
              size={26} style={{ padding: '4px' }} />}
            Invite
          </Button>
        }
      </span>
    )
  }))

  return (
    <MUITable>
      <TableHead>
        <TableRow>
          {labels.map((label, index) => (
            <TableCell key={index}>
              <div style={{
                display: 'flex', alignItems: 'center',
                justifyContent: label.field === "actions" ? 'center' : ''
              }}>
                {label.name}
                {pathDetails.pathname.includes("/users")
                  ? (<kbd
                    onClick={() => {
                      ascd(label.field);
                    }}
                  >
                    {label.field === "actions" ? null : <UnfoldMore />}
                  </kbd>)
                  : null}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {userTableData.length > 0
          ? userTableData.map((row, index) => {
            return (
              <TableRow key={index}>
                {labels.map((label, index) => {
                  if (label.field === "country") {
                    return (
                      <TableCell key={index}>
                        {row["type"] === "eso"
                          ? row?.eso?.countryName
                          : getKeyByValue(AFIRCA_COUNTRIES, row[label.field])
                        }
                      </TableCell>
                    );
                  }
                  if (label.field === "serviceInterests") {
                    return (
                      <TableCell key={index}>{row[label.field]
                        ? <>
                          {row[label.field].slice(0, 2).join(', ')}
                          {row[label.field].length > 2 && <Tooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  {row[label.field].join(', ')}
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <Button className="add-more">
                              <Add />{row[label.field].length - 2} more
                            </Button>
                          </Tooltip>}
                        </>
                        : ''}
                      </TableCell>
                    )
                  }
                  if (label.field === "createdAt") {
                    return (
                      <TableCell key={index}>
                        {showDate(new Date(row[label.field]))}
                      </TableCell>
                    )
                  }
                  if (label.field === "loginDate") {
                    return (
                      <TableCell key={index}>
                        {row[label.field] == null ? ' - ' : showDate(new Date(row[label.field]))}
                      </TableCell>
                    )
                  }
                  return <TableCell key={index}>{row[label.field]}</TableCell>;
                })}
              </TableRow>
            )
          })
          : <div style={{ display: "flex", justifyContent: "center" }}>No Data Found</div>
        }
      </TableBody>
    </MUITable>
  );
};

export default UserList;
