import React from 'react'
import { withRouter } from 'react-router-dom'
import ApolloCacheUpdater from 'apollo-cache-updater'
import {
  Input,
  Button, Grid,
  CircularProgress as Spinner,
  Select,
  MenuItem,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  FormLabel,
  Box,
  Chip,
  FormHelperText,
  TextField
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/react-hooks'
import { ErrorMessage, Form, Formik, } from 'formik'
import Wysiwyg from './Wysiwyg'
import { graphqlError } from '../utils'
import BUSINESS_SECTORS from '../settings/enums/target-sectors.json'
import SERVICES from '../settings/enums/categories.json'
import STAGES from '../settings/enums/business-stages.json'
import BUSINESS_RESOURCE_FUNCTIONS from '../settings/enums/business-resource-functions.json'
import RESOURCE_TYPE from '../settings/enums/category-type.json'
import history from '../history'
import { saveResourceMutation, getResourcesQuery, countResourcesQuery, _safeS3SignMutation } from '../api'
import LANGUAGES from '../settings/enums/languages.json'
import AFRICAN_ISO from '../settings/enums/africa-iso-codes.json'
import RESOURCE_TARGET from '../settings/enums/resource-target.json'
import maxFileSize from '../settings/max-file-size.json'
import * as Yup from "yup";
import MultiImageUpload from '../components/MultiImageUpload'

const FUNCTIONS = { // it could be more than one in the future
  ...BUSINESS_RESOURCE_FUNCTIONS,
}

const { maxSize } = maxFileSize
const typeFields = {
  haveAttachment: [
    'business',
    'funding'
  ],
  havePrice: [
    'product',
    'funding'
  ],
  haveContact: [
    'funding',
    'product',
  ],
  haveGallery: [
    'funding',
    'product'
  ],
  haveDeadline: [
    'funding'
  ],
  haveWorkingLanguages: [
    'funding'
  ],
  haveLocation: [
    'funding',
    'product',
    'business'
  ],
  haveServices: [
    'funding',
    'product'
  ],
  haveStages: [
    'funding',
    'product'
  ],
  haveFunctions: [
    'business',
    'product'
  ]
}
const addToSet = (currentObj, additionalKey) => ({ ...currentObj, ...additionalKey })

const resourceTypeOptions = Object.entries(RESOURCE_TYPE).map(([key, value]) => ({
  label: value,
  value: key,
})).filter(option => option.value !== 'mentorship')


const ResourceForm = ({
  data,
  languageIndex,
  languagesTotal
}) => {
  console.log("🚀 ~ file: ResourceForm.js:101 ~ languageIndex:", languageIndex)
  const firstUpdate = React.useRef(true)
  const [type, setType] = React.useState(resourceTypeOptions[0].value)
  const [newAttachment, setNewAttachment] = React.useState()
  const [fileUploading, setFileUploading] = React.useState()
  const [initialValues, setInitialValues] = React.useState(data)
  const [changingLanguage, setChangingLanguage] = React.useState()

  const { enqueueSnackbar } = useSnackbar()
  const [safeS3Sign] = useMutation(_safeS3SignMutation)
  const [saveResource, { loading }] = useMutation(saveResourceMutation)

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    }
    if (data) {
      setType(data.category)
      setInitialValues(data)
    } else setType(resourceTypeOptions[0].value)
  }, [data, firstUpdate])

  React.useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        await setChangingLanguage(true)
        await setInitialValues({ ...data, ...initialValues })
        await setChangingLanguage(false)
      }
      handleAsync()
    }
  }, [languageIndex])



  const handleSubmit = async (fields) => {
    const { description, country, city, address, images = [], attachment, deadline, additionalCountries, ...rest } = fields

    const [countryName, countryCode] = country && typeof country === 'string' && country.length ? country.split('__') : [null, null]
    const dataObject = {
      ...(initialValues._id) && { _id: data._id },
      ...rest,
      countryCode,
      countryName,
      city: city,
      address: address,
      // languages: Object.keys(LANGUAGES),
      // language: Object.keys(LANGUAGES)[languageIndex],
      description,
      deadline: deadline ? deadline : null,
      additionalCountries: additionalCountries.map((item) => AFRICAN_ISO[item])
    }
    let allImages
    if (Array.isArray(images)) {
      allImages = await images.reduce(async (arr, file) => {
        let attachmentUrl = file || null
        if (attachmentUrl) {
          if (typeof attachmentUrl === 'string') return [...await arr, attachmentUrl]
          const { dataFile, name: filename } = file
          const { size } = dataFile
          if (size > maxSize) {
            // toast.error(translations.messagesNewMessageErrorFileBig)
            return null
          }
          try {
            const response = await safeS3Sign({
              variables: {
                filename: filename,
                filetype: file.type,
              },
            })
            const { signedRequest, url: bucketUrl } = response.data._safeSignS3
            const options = {
              headers: {
                'Content-Type': dataFile.type,
              },
            }
            try {
              setFileUploading(true)
              await fetch(signedRequest, {
                method: 'PUT',
                headers: options.headers,
                body: dataFile,
              })
              setFileUploading(false)
              attachmentUrl = bucketUrl
            } catch (e) {
              attachmentUrl = null
              console.log(e)
              setFileUploading(false)
            }
          } catch (e) {
            const errMsg = graphqlError(e, {
              // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
              // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
            })
            if (errMsg) {
              // toast.error(errMsg)
            } else {
              // toast.error('Cannot uplaod file(s)')
              console.log(e)
            }
            return [...await arr]
          }
          return [...await arr, attachmentUrl]
        }
      }, [])
      if (allImages && allImages.length > 0) {
        dataObject.images = allImages
      }

      if (attachment) {
        const file = attachment
        let attachmentUrl = file || null
        if (attachmentUrl) {
          if (typeof attachmentUrl === 'string') {
            dataObject.fileUrl = attachmentUrl
          } else {
            const { dataFile, name: filename } = file
            try {
              const response = await safeS3Sign({
                variables: {
                  filename: filename,
                  filetype: file.type,
                },
              })
              const { signedRequest, url: bucketUrl } = response.data._safeSignS3
              const options = {
                headers: {
                  'Content-Type': dataFile.type,
                },
              }
              try {
                setFileUploading(true)
                await fetch(signedRequest, {
                  method: 'PUT',
                  headers: options.headers,
                  body: dataFile,
                })
                setFileUploading(false)
                attachmentUrl = bucketUrl
              } catch (e) {
                attachmentUrl = null
                console.log(e)
                setFileUploading(false)
              }
            } catch (e) {
              const errMsg = graphqlError(e, {
                // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
                // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
              })
              if (errMsg) {
                // toast.error(errMsg)
              } else {
                // toast.error('Cannot uplaod file(s)')
                console.log(e)
              }

            }
            dataObject.fileUrl = attachmentUrl
          }
        }
      }

      saveResource({
        variables: {
          data: {
            ...dataObject,
          },
        },
        update: (proxy, { data: { saveResourceAdmin = {} } }) => {
          if (!data || !data._id) {
            const mutationResult = saveResourceAdmin
            ApolloCacheUpdater({
              proxy,
              queriesToUpdate: [getResourcesQuery],
              searchVariables: {
                skip: 0
              },
              mutationResult: {
                ...mutationResult,
              },
              ID: '_id',
            })
            ApolloCacheUpdater({
              proxy,
              queriesToUpdate: [countResourcesQuery],
              searchVariables: {},
              operator: 'ANY',
              mutationResult: {
                ...mutationResult,
              },
              ID: '_id',
            })
          }
        },
      })
        .then(() => {
          enqueueSnackbar('The resource has been saved.', { variant: 'success' })
          history.replace('/resources')
        })
        .catch(e => {
          console.log(e)
          enqueueSnackbar('There was an error saving the resource', { variant: 'error' })
        })
    }
  }

  const isTranslatable = bool =>
    languageIndex !== 0 ? {
      disabled: !bool,
      helperText: bool ? 'Enter translation for selected language.' : 'This field is not translatable.',
    } : {}

  let formFields = {
    category: initialValues?.category || type || '',
    sectors: initialValues?.sectors || [],
    name: initialValues?.name || [],
    description: initialValues?.description || [],
    targets: initialValues?.targets || [],
    additionalCountries: initialValues?.additionalCountries
      ? initialValues?.additionalCountries.map((value) =>
        Object.entries(AFRICAN_ISO).find(([label, code]) => code === value)[0])
      : [],
    fromDate:initialValues?.fromDate || null,
    toDate:initialValues?.toDate || null

  }

  

  let requiredFields = {
    name: Yup.array().of(Yup.string()).test('name-ele',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }),
    category: Yup.string().required("This field is required."),
    sectors: Yup.array().min(1, `This field is required.`),
    targets: Yup.array().min(1, `This field is required.`),
  }

  const conditionalBlocks = {
    hasAttachment: typeFields.haveAttachment.includes(type),
    hasPrice: typeFields.havePrice.includes(type),
    hasGallery: typeFields.haveGallery.includes(type),
    hasContact: typeFields.haveContact.includes(type),
    hasDeadline: typeFields.haveDeadline.includes(type),
    hasWorkingLanguages: typeFields.haveWorkingLanguages.includes(type),
    hasLocation: typeFields.haveLocation.includes(type),
    hasServices: typeFields.haveServices.includes(type),
    hasStages: typeFields.haveStages.includes(type),
    hasFunctions: typeFields.haveFunctions.includes(type),
  }

  if (conditionalBlocks.hasAttachment) {
    formFields = addToSet(formFields, { 'attachment': '' })
    if (type === 'business') {
      if (newAttachment || !initialValues?.fileUrl?.url) {
        requiredFields = addToSet(requiredFields, { attachment: Yup.object().required('This field is required.') })
      }
    }
  }
  if (conditionalBlocks.hasPrice) {
    formFields = addToSet(formFields, {
      ...type === 'funding'
        ? { amount: initialValues?.amount || '' }
        : { price: initialValues?.price || '' }
    })

    requiredFields = addToSet(requiredFields, {
      ...type === 'funding'
        ? { amount: Yup.string().required('This field is required.') }
        : { price: Yup.string().required('This field is required.') }
    })
  }
  if (conditionalBlocks.hasGallery) {
    formFields = addToSet(formFields, { images: initialValues?.images || [] })
  }
  if (conditionalBlocks.hasDeadline) {
    formFields = addToSet(formFields, { deadline: initialValues?.deadline || '' })
  }
  if (conditionalBlocks.hasWorkingLanguages) {
    formFields = addToSet(formFields, { workingLanguages: initialValues?.workingLanguages || [] })
  }
  if (conditionalBlocks.hasContact) {
    formFields = addToSet(formFields, {
      'contact': initialValues?.contact || '', 'email': initialValues?.email || "",
      'phone': initialValues?.phone || "", 'website': initialValues?.website || ""
    })
  }
  if (conditionalBlocks.hasLocation) {
    formFields = addToSet(formFields, {
      'country': initialValues?.countryName && initialValues.countryCode ? `${initialValues.countryName}__${initialValues.countryCode}` : '',
      'city': initialValues?.city || '', 'address': initialValues?.address || ''
    })
  }
  if (conditionalBlocks.hasServices) {
    formFields = addToSet(formFields, { services: initialValues?.services || [] })
  }
  if (conditionalBlocks.hasStages) {
    formFields = addToSet(formFields, { stages: initialValues?.stages || [] })
  }
  if (conditionalBlocks.hasFunctions) {
    formFields = addToSet(formFields, { functions: initialValues?.functions || [] })
    requiredFields = addToSet(requiredFields, { functions: Yup.array().min(1, `This field is required.`) })
  }
  return changingLanguage ? null :
    <Formik
      initialValues={formFields}
      validationSchema={Yup.object().shape(requiredFields)}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, handleChange, errors }) => {
        return <Form>
          <Grid container spacing={6} justifyContent='space-between'>
            <Grid item md={6} lg={4} container direction='column' alignItems='flex-start'>
              <FormControl fullWidth>
                <InputLabel id="demo-mutiple-name-label">Sectors <span style={{ color: 'red' }}>*</span></InputLabel>
                <Select
                  multiple
                  name='sectors'
                  onChange={handleChange}
                  value={values?.sectors || []}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={BUSINESS_SECTORS[value]?.toUpperCase()} />
                      ))}
                    </Box>
                  )}
                >
                  {Object.entries(BUSINESS_SECTORS).map(([key, value]) =>
                    <MenuItem key={key} value={key}>
                      {value?.toUpperCase()}
                    </MenuItem>
                  )}
                </Select>
                <ErrorMessage name="sectors" >
                  {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>


              {conditionalBlocks.hasFunctions &&
                <FormControl fullWidth>
                  <InputLabel id="demo-mutiple-name-label">Functions <span style={{ color: 'red' }}>*</span></InputLabel>
                  <Select
                    multiple
                    name='functions'
                    label='Functions'
                    onChange={handleChange}
                    value={values?.functions || []}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={FUNCTIONS[value]?.toUpperCase()} />
                        ))}
                      </Box>
                    )}
                  >
                    {Object.entries(FUNCTIONS).map(([key, value]) => (
                      <MenuItem key={key} value={key} >
                        {value?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage name="functions" >
                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              }
              {conditionalBlocks.hasServices &&
                <><FormControl fullWidth>
                  <InputLabel id="demo-mutiple-name-label">Services offered</InputLabel>
                  <Select
                    multiple
                    fullWidth
                    name='services'
                    label='Services offered'
                    onChange={handleChange}
                    value={values?.services || []}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={SERVICES[value]?.label?.toUpperCase()} />
                        ))}
                      </Box>
                    )}
                  >
                    {Object.entries(SERVICES).map(([key, value]) =>
                      <MenuItem key={key} value={key}>
                        {value?.label?.toUpperCase()}
                      </MenuItem>
                    )}
                  </Select></FormControl></>}

              {conditionalBlocks.hasStages &&
                <><FormControl fullWidth>
                  <InputLabel id="demo-mutiple-name-label">Business stage target</InputLabel>
                  <Select
                    multiple
                    name='stages'
                    label='Business stage target'
                    onChange={handleChange}
                    value={values?.stages || []}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={STAGES[value]?.label?.toUpperCase()} />
                        ))}
                      </Box>
                    )}
                  >
                    {Object.entries(STAGES).map(([key, value]) =>
                      <MenuItem key={key} value={key}>
                        {value?.label?.toUpperCase()}
                      </MenuItem>
                    )}
                  </Select></FormControl></>
              }<FormControl fullWidth component="fieldset" margin="normal">
                <FormLabel component="legend" focused={false} >
                  For <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <FormGroup name={`targets`}
                  defaultValue={values?.targets}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('targets', [...values?.targets || [], e.target.value])
                    } else {
                      let index = values.targets.indexOf(e.target.value)
                      if (index !== -1) {
                        values.targets.splice(index, 1)
                        setFieldValue('targets', values.targets)
                      }
                    }
                  }}
                >
                  {Object.entries(RESOURCE_TARGET).map(([key, value], i) => (
                    <FormControlLabel
                      key={i}
                      control={<Checkbox
                        type="checkbox"
                        checked={values?.targets.includes(key)}
                      />}
                      value={key}
                      label={value}
                    />
                  ))}
                </FormGroup>
                <ErrorMessage name="targets" >
                  {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                </ErrorMessage>
              </FormControl>
              {
                conditionalBlocks.hasAttachment && <Grid container>
                  {
                    initialValues?.fileUrl && !newAttachment
                      ? <div style={{ display: 'flex' }}>
                        <div>{initialValues?.fileUrl?.url.split('/').pop()}</div>
                        {!isTranslatable(false).disabled && <div style={{ fontWeight: 'bold', marginLeft: 10, cursor: 'pointer' }} onClick={() => setNewAttachment(true)}>x</div>}
                        <InputFile
                          name='attachment'
                          type='hidden'
                          setValue={setFieldValue}
                          value={!newAttachment && initialValues?.fileUrl?.url}
                        />
                      </div>
                      :
                      <div style={{ border: '1px solid grey', padding: '10px' }}>
                        <label>File <span style={{ color: 'red', marginRight: '10px' }}>*</span></label>
                        <InputFile
                          name='attachment'
                          type='file'
                          setValue={setFieldValue}
                          addon={fileUploading && <Spinner />}
                          disabled={fileUploading}
                        />
                      </div>

                  }
                  <ErrorMessage name="attachment" >
                    {msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}
                  </ErrorMessage>
                </Grid>
              }
              {conditionalBlocks.hasGallery &&
                <Box style={{ marginBottom: '5%', marginTop: '5%' }}>
                  <label>Upload banner image:</label>
                  <MultiImageUpload
                    name={'images'}
                    value={values?.images}
                    setValue={setFieldValue}
                  />
                </Box>
              }
              {conditionalBlocks.hasLocation &&
                <>
                  <h3>Location</h3>
                  <FormControl fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                      name='country'
                      value={values.country}
                      onChange={handleChange}
                    >
                      {Object.entries(AFRICAN_ISO).map(([key, value]) =>
                        <MenuItem value={`${key}__${value}`} key={key} >
                          {key}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {/* TODO: [michal][location][city] do we need autocomplete here? */}
                  <FormControl fullWidth>
                    <TextField
                      label="City"
                      name='city'
                      value={values?.city}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Address"
                      name='address'
                      value={values?.address}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="additionalCountries">Additional Countries</InputLabel>
                    <Select
                      multiple
                      name='additionalCountries'
                      onChange={handleChange}
                      value={values?.additionalCountries || []}
                      renderValue={selected => (
                        <Box sx={{
                          display: "flex", flexWrap: "wrap", gap: 5
                        }}>
                          {selected?.map(value => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {Object.entries(AFRICAN_ISO).map(([key]) => (
                        <MenuItem key={key} value={key} >
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </>
              }
            </Grid>
            <Grid item md={6} lg={7} container direction='column' alignItems='flex-start'>
              <RadioGroup row aria-label="category" name="category"
                value={values?.category}
                onChange={(e, value) => {
                  if (value && type !== value) { setType(value) };
                  setFieldValue('category', value)
                }}
              >
                {resourceTypeOptions.map(({ label, value }) => (<FormControlLabel
                  value={value}
                  control={<Radio color="default"
                    disabled={!!initialValues?._id}
                  />}
                  label={label}
                  labelPlacement="end"
                  key={value}
                />
                ))}
              </RadioGroup>

              <ErrorMessage name="category" >
                {msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}
              </ErrorMessage>
              <TextField
                fullWidth
                name={`name[${languageIndex}]`}
                label={<span>Name <span style={{ color: 'red' }}>*</span></span>}
                // defaultValue={initialValues?.name}
                defaultValue={(initialValues?.name?.length && initialValues.name[languageIndex]) || ''}

                onChange={handleChange}
              />
              <ErrorMessage name="name" >
                {msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}
              </ErrorMessage>
              <FormControl style={{ marginBottom: '50px' }} fullWidth>
                <label>Description</label>
                <Wysiwyg
                  name={`description[${languageIndex}]`}
                  label='Description'
                  placeholder={'Description'}
                  setValue={setFieldValue}
                  value={(values?.description.length && values.description[languageIndex]) || ''}
                />
              </FormControl>
              {isTranslatable(true)?.helperText}
              {conditionalBlocks.hasPrice &&
                <>
                  <FormControl fullWidth>
                    <TextField
                      name={type === 'funding' ? 'amount' : 'price'}
                      label={type === 'funding' ? <span>Amount <span style={{ color: 'red' }}>*</span></span> : <span>Price <span style={{ color: 'red' }}>*</span></span>}
                      value={type === 'funding' ? values?.amount : values?.price}
                      onChange={handleChange}
                    />
                    <ErrorMessage name={type === 'funding' ? 'amount' : 'price'} >
                      {msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}
                    </ErrorMessage>
                  </FormControl>
                </>
              }

              {values.category==="product" &&(
                 <div className='news-form-wrapper'>
                 <Grid container spacing={2} >
                 <Grid item sm={6}>
                   <TextField
                     label={<span>From <span style={{ color: 'red' }}>*</span></span>}
                     type="date"
                     name='fromDate'
                     fullWidth
                     value={values.fromDate? new Date(values.fromDate).toISOString().split('T')[0]:null}
                     onChange={handleChange}
                     InputLabelProps={{
                       shrink: true,
                     }}
                     {...isTranslatable(false)}
                   />
                   <ErrorMessage name='dateFrom' >{msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}</ErrorMessage>
                 </Grid>
                 <Grid item sm={6}>
                   <TextField
                     label="To"
                     type="date"
                     name='toDate'
                     fullWidth
                     onChange={handleChange}
                     value={ values.toDate?new Date(values.toDate).toISOString().split('T')[0]:null}
                     InputLabelProps={{
                       shrink: true,
                     }}
                     {...isTranslatable(false)}
                   />
                 </Grid>
               </Grid>
               </div>
              )}
              <Grid container>
                {conditionalBlocks.hasWorkingLanguages &&
                  <FormControl fullWidth>
                    <InputLabel>Working languages</InputLabel>
                    <Select
                      multiple
                      fullWidth
                      name='workingLanguages'
                      label='Working languages'
                      value={values?.workingLanguages || []}
                      onChange={handleChange}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={LANGUAGES[value]?.toUpperCase()} />
                          ))}
                        </Box>
                      )}
                    >
                      {Object.entries(LANGUAGES).map(([key, value]) =>
                        <MenuItem key={key} value={key}>
                          {value?.toUpperCase()}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                }
                {conditionalBlocks.hasDeadline && <Grid item sm={6}>
                  <Input
                    fullWidth
                    name='deadline'
                    placeholder='Deadline'
                    type='date'
                    onChange={handleChange}
                    value={values && values.deadline && new Date(values.deadline).toISOString().slice(0, 10)}
                  />
                </Grid>}
                {conditionalBlocks.hasContact && <Grid container>
                  <h3>Contact Information</h3>
                  <FormControl fullWidth>
                    <TextField
                      name='contact'
                      label='Contact person'
                      value={values?.contact}
                      onChange={handleChange}

                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      name='email'
                      label='Email'
                      value={values?.email}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      name='phone'
                      label='Phone'
                      value={values?.phone}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      name='website'
                      label='website'
                      onChange={handleChange}
                      value={values?.website}
                    />
                  </FormControl>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={5} justifyContent='flex-end'>
            <Grid container item sm={2} xs={3} justifyContent='flex-end'>
              <Button
                style={{ width: '100%' }}
                variant='contained'
                onClick={() => history.goBack()}
              >Cancel
              </Button>
            </Grid>
            <Grid container item sm={2} xs={3} justifyContent='flex-end'>
              <Button
                style={{ width: '100%' }}
                variant='contained'
                color='primary'
                type='submit'
              >{loading || fileUploading ?
                <Spinner color='secondary' size={25} /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </Form >

      }}
    </Formik >
}

ResourceForm.defaultProps = {
  languageIndex: 0,
}

export default withRouter(ResourceForm)

// TODO: [michal][input][file] I had to manually copy/paste the Input component
// from the latest version of the package.
// I was afraid to update the whole package because I guess it could break some of the existing forms
// The problem with the current version is that ones you select a file for upload it gives you back only a string like:
//  C:\fakepath/{filename}
const InputFile = ({
  name,
  type,
  value,
  accept,
  setValue,
}) =>
  <input
    className='form-input'
    name={name}
    type={type}
    onChange={e => {
      if (type === 'file') {
        const fileReader = new FileReader()
        const { name: fileName, type: fileType } = e.target.files[0]
        const dataFile = e.target.files[0]
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
          const data = fileReader.result
          setValue(name, {
            name: fileName,
            type: fileType.split('/')[0],
            data,
            dataFile,
          })
        }
      } else {
        setValue(name, e.target.value)
      }
    }}
    accept={accept}
    value={type !== 'file' ? value : undefined}
  />
