import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import Loader from '../components/Loader'
import UserPanel from '../components/UserPanel'
import history from '../history'
import { JWT } from '../apollo/_config'
import { _currentAdminQuery } from '../api'


const UserPanelContainer = () => {
  const { data: { _currentAdmin: currentUser = { name: '', email: '' } } = {}, loading, error } = useQuery(_currentAdminQuery)
  const { enqueueSnackbar } = useSnackbar()
  return <Loader loading={loading} error={error}>
    <UserPanel
      username={currentUser.name}
      email={currentUser.email}
      userActions={[
        {
          label: 'Logout',
          callback: () => {
            localStorage.removeItem(JWT.LOCAL_STORAGE.TOKEN.NAME)
            localStorage.removeItem(JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME)
            history.push('/login')
            enqueueSnackbar('You have been logged out', { variant: 'success' })
          },
        },
      ]}
    />
  </Loader>
}

export default UserPanelContainer
