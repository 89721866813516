import React from 'react'
import { CSVLink } from "react-csv";
import {
  Button, FormControl, InputLabel,
  MenuItem, Select, TextField, Grid,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack';
import { useMutation } from "@apollo/react-hooks";
import { getUsersQuery, importUser } from "../api";
import USER_TYPES from "../settings/user-types.json";
import SERVICES from '../settings/enums/categories.json'
import CSVDownload from '../components/CustomDownloadLink'
import AFRICAN_ISO from '../settings/enums/new-africa-iso-code.json'
import * as XLSX from 'xlsx';

const UserFilter = ({
  exportText,
  exportReady,
  csvData,
  computeExport,
  enableExport,
  filters,
  refetch,
  handleClear,
  callback = () => { },
  data,
  esoDropdownData,
  setExportReady
}) => {
  const [importuser] = useMutation(importUser);
  const { enqueueSnackbar } = useSnackbar();
  // initialValues for formik
  let initialFormValue = {
    country: filters?.country ? filters.country : '',
    service: filters?.service ? filters.service : '',
    eso: filters?.eso ? filters.eso : '',
    keyword: filters?.keyword ? filters.keyword : '',
    email: filters?.email ? filters.email : '',
    userType: filters?.userType ? filters.userType : ''
  }

  const hiddenFileInput = React.useRef(null);

  const uploadCSV = () => {
    hiddenFileInput.current.click();
  };

  const importCsv = async (event) => {
    const file = event.target.files[0];
    
    if (
      file.type === "text/csv" ||
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      let fileReader = new FileReader();
  
      fileReader.onload = () => {
        let data;
        if (file.type === "text/csv") {
          data = fileReader.result?.toString();
        } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          const workbook = XLSX.read(fileReader.result, { type: 'binary' });
          const sheetname = workbook.SheetNames[0];  // Assuming you want the first sheet
          const worksheet = workbook.Sheets[sheetname];
          data = XLSX.utils.sheet_to_csv(worksheet);  // Convert the XLSX data to CSV format
          }
        importuser({
          variables: {
            data: data
          },
          refetchQueries: [
            { query: getUsersQuery }
          ]
        })
          .then(async (result) => {
            const { success, response } = result.data.saveBulkUser
            if (success) {
              enqueueSnackbar("User CSV Import SuccessFully", { variant: 'success' })
            } else {
              enqueueSnackbar("Failed to Upload CSV. Check the download file", { variant: 'error' })
              const linkSource = "data:text/csv;base64," + response;
              const downloadLink = document.createElement("a");
              const fileName = "Failed-to-upload-USER.csv";
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.style.display = "none";
              downloadLink.click();
              downloadLink.remove();
            }
            await refetch()
          })
          .catch(async (e) => {
            console.log(e);
            enqueueSnackbar("Error on Import USER CSV", { variant: 'error' })
          });
      };
  
      if (file.type === "text/csv") {
        fileReader.readAsText(file);
      } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        fileReader.readAsBinaryString(file);  // Read XLSX as binary
      }
    }
  };
  const samplecsvData = [
    [
      "first_name",
      "last_name",
      "email",
      "type",
      "phone",
      "gender",
      "country",
      "city",
      "twitter",
      "linkedin",
      "facebook"
    ],
    ["Test", "entrepreneuriat", "contact@if-entrepreneuriat.org", "entrepreneur", "+243 859 717 259", "male", "Democratic Republic of the Congo", "Kinshasa", "https://twitter.com", "https://in.linkedin.com/", "https://www.facebook.com/"]
  ];

  return (
    <>
      <Formik
        initialValues={initialFormValue}
        onSubmit={(values) => {
          callback(values)
        }}
      >
        {({ values, handleChange, handleSubmit, resetForm }) => (
          <Form>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name='country'
                    value={values.country}
                    onChange={handleChange}
                  >
                    {Object.entries(AFRICAN_ISO).map(([key, value]) => {
                      return (<MenuItem value={value} key={value} >{key}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Services</InputLabel>
                  <Select
                    name='service'
                    value={values.service}
                    onChange={handleChange}
                  >
                    {Object.entries(SERVICES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value.label}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel >Eso</InputLabel>
                  <Select
                    label="eso"
                    name='eso'
                    value={values.eso}
                    onChange={handleChange}
                  >
                    {
                      esoDropdownData.map((key, value) => {
                        return (
                          <MenuItem value={key?._id} key={value}>{key?.name}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <TextField
                  fullWidth
                  label="Search User by Name"
                  type="text"
                  name='keyword'
                  value={values.keyword}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  fullWidth
                  label="Search User by Email"
                  name='email'
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>User Type</InputLabel>
                  <Select
                    label="userType"
                    name='userType'
                    value={values.userType}
                    onChange={handleChange}
                  >
                    {[{ label: 'Admin', value: USER_TYPES.ADMIN },
                    { label: 'Entrepreneur', value: USER_TYPES.ENTREPRENEUR },
                    { label: 'Ecosystem-Player', value: USER_TYPES.ECOSYSTEMPLAYER },
                    { label: 'Eso', value: USER_TYPES.ESO },
                    { label: 'User', value: USER_TYPES.USER }]
                      .map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ marginTop: 25 }}>
              <Grid container spacing={2} alignItems="flex-start">
                <Button
                  size="large"
                  type='button'
                  color='primary'
                  variant='contained'
                  style={{ marginRight: 20 }}
                  onClick={() => handleSubmit()}>
                  APPLY
                </Button>
                <Button
                  type='reset'
                  size="large"
                  color='inherit'
                  variant='contained'
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    resetForm()
                    handleClear(filters)
                  }}>
                  Clear Filter
                </Button>
               
                <Button
                  size="large"
                  variant="contained"
                  onClick={uploadCSV}
                  style={{ marginRight: 20 }}
                >
                  IMPORT USER
                </Button>
                <input
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ref={hiddenFileInput}
                  onChange={importCsv}
                  onClick={(event) => {
                    event.target.value = null
                  }}
                  style={{ display: "none" }}
                />
                <CSVLink
                  data={samplecsvData}
                  filename={"USER_CSV_format.csv"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    style={{ marginLeft: 20, marginRight: 20 }}
                  >
                    Download Sample CSV
                  </Button>
                </CSVLink>
                <Button
                  download
                  size="medium"
                  target="_blank"
                  rel="noreferrer"
                  variant="contained"
                  href={`USER_CSV_Format.xlsx`}
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  Sample User XLSX
                </Button>
              </Grid>
              {exportReady > 0 && enableExport && (
                <CSVDownload
                  filename={`data_export_on_${new Date()
                    .toDateString()
                    .replace(/ /g, "_")}.csv`}
                  data={csvData}
                  target="_blank"
                  style={{ marginRight: 20 }}
                  setExportReady={setExportReady}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserFilter;
