import { createTheme } from '@material-ui/core/styles'


/**
 * SeiDUAL Material UI theme. Overrides of the default theme object:
 * https://material-ui.com/customization/default-theme/
 */
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffda35',
    },
    secondary: {
      main: '#69bf35',
    },
  },
  sidebarWidth: {
    sm: 180,
    md: 260,
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: 8,
      },
    },
  },
})

export default theme
