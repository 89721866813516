import React from 'react'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import OrganizationForm from '../forms/OrganizationForm'

const OrganizationNew = () =>
  <Authenticated>
    <Layout title='Create new ESO'>
      <OrganizationForm />
    </Layout>
  </Authenticated>

export default OrganizationNew
