import React, { useCallback, useState } from 'react'
import * as Yup from 'yup'
import history from '../history'
import { toast } from "react-toastify";
import { useSnackbar } from 'notistack'
import { graphqlError } from '../utils'
import { Formik, Form, ErrorMessage } from 'formik'
import ApolloCacheUpdater from 'apollo-cache-updater'
import {
    Grid, Button, FormControl, FormHelperText,
    InputLabel, TextField, Typography, CircularProgress, Box,
} from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { saveFAQMutation, _s3SignMutation } from '../api/_mutations'
import { getFAQQuery, countFAQQuery, _currentAdminQuery } from '../api'
import { makeStyles } from '@material-ui/styles'
import { CloseSharp } from '@material-ui/icons'



const useStyles = makeStyles((theme) => ({
    delete: {
        border: 'none',
        fontSize: '14px',
        cursor: 'pointer',
        padding: '5px 8px',
        lineHeight: 'normal',
        display: 'flex',
        alignItems: 'center',
    },
    filename: {
        fontSize: 11,
        maxWidth: '100%',
        marginBottom: 10,
        overflow: 'hidden',
        textAlign: 'center',
        lineHeight: 'normal',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
    },
}))


const FAQForm = (props) => {
    const { data, languageIndex } = props
    const classes = useStyles()

    const { enqueueSnackbar } = useSnackbar()
    const [saveFAQ, { loading: FAQLoading }] = useMutation(saveFAQMutation)
    const [s3Sign] = useMutation(_s3SignMutation);
    const [loading, setLoading] = useState(false)
    const [docRender, setDocRender] = useState(false)

    const { data: { _currentAdmin: currentUser = { name: '' } } = {} } = useQuery(_currentAdminQuery)
    const isTranslatable = bool => languageIndex !== 0
        ? {
            disabled: !bool,
            helperText: bool
                ? "Enter translation for selected language."
                : "This field is not translatable."
        }
        : {};

    // validation schema
    const validationSchema = Yup.object().shape({
        docs: Yup.mixed(),
        question: Yup.array().of(Yup.string().nullable()).min(1, 'This field is required'),
        description: Yup.array().of(Yup.string().nullable()).min(1, 'This field is required'),
        url: Yup.string().matches(
            /^(https?:\/\/)/,
            'URL should start with http:// or https://'
        ).matches(
            /^(https|http):\/\/?[a-z0-9]/,
            'This is not a valid URL.'
        ),
        iframeURL: Yup.string().matches(
            /^(https?:\/\/)/,
            'URL should start with http:// or https://'
        ).matches(
            /^(https|http):\/\/?[a-z0-9]/,
            'This is not a valid URL.'
        )
    })

    const handleFileUpload = (dataFile, setFieldValue) => {
        setFieldValue('docs', dataFile)
    }

    const handleSubmit = useCallback(async (fields) => {
        const { question, description, url, docs, iframeURL } = fields;
        const dataObject = {
            docs,
            url,
            question,
            description,
            iframeURL,
        }

        if (docs) {
            const file = docs
            let attachmentUrl = file || null
            if (attachmentUrl) {
                if (typeof attachmentUrl === 'string') {
                    dataObject.docs = attachmentUrl
                } else {
                    const { name: filename } = file
                    try {
                        setLoading(true)
                        const response = await s3Sign({
                            variables: {
                                filename: `FAQDOCS/${currentUser._id}__${filename.replace(/ /g, '-').replace(/[{()}]/g, '')}`,
                                filetype: file.type,
                            },
                        })
                        const { signedRequest, url: bucketUrl } = response.data._signS3
                        const options = {
                            headers: {
                                'Content-Type': file.type,
                            },
                        }
                        try {
                            await fetch(signedRequest, {
                                method: 'PUT',
                                headers: options.headers,
                                body: file,
                            })
                            attachmentUrl = bucketUrl
                            setLoading(false)
                        } catch (e) {
                            attachmentUrl = null
                            console.error(e)
                            setLoading(false)
                        }
                    } catch (e) {
                        const errMsg = graphqlError(e, {
                            // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
                            // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
                        })
                        if (errMsg) {
                            toast.error(errMsg)
                        } else {
                            toast.error('Cannot uplaod file(s)')
                            console.error(e)
                        }
                    }
                    dataObject.docs = attachmentUrl
                }
            }
        }

        try {
            saveFAQ({
                variables: {
                    data: {
                        ...(data && data._id) ? { id: data._id } : {},
                        ...dataObject
                    }
                },
                update: (proxy, { data: { saveFAQ = {} } }) => {
                    if (!data || !data._id) {
                        const mutationResult = saveFAQ
                        ApolloCacheUpdater({
                            proxy,
                            ID: '_id',
                            operator: 'ANY',
                            searchVariables: { skip: 0 },
                            queriesToUpdate: [getFAQQuery, countFAQQuery],
                            mutationResult: { ...mutationResult },
                        })
                    }
                },
            }).then(() => {
                enqueueSnackbar('The FAQ has been saved.', { variant: 'success' })
                history.replace('/FAQ')
            }).catch(e => {
                console.error(e)
                enqueueSnackbar('There was an error saving the FAQ', { variant: 'error' })
            })
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Could not save the FAQ.', { variant: 'error' })
        }
    })

    const showName = (url) => {
        if (url.length >= 0) {
            let part = url.split("/")
            return part[part.length - 1]
        }
        else {
            return null
        }

    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                url: data?.url ? data.url : '',
                docs: data?.docs ? data.docs : '',
                question: data?.question ? data.question : [],
                description: data?.description
                    ? data.description
                    : [],
                iframeURL: data?.iframeURL ? data.iframeURL : ""
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ values, handleChange, setFieldValue }) => {
                return <Form>
                    <Grid container spacing={6} justifyContent='space-between'>
                        <Grid item md={6} lg={4} container direction='column' alignItems='flex-start'>
                            {
                                !values.docs || docRender ? (<FormControl fullWidth>
                                    <Typography variant="subtitle1" style={{ color: "#000000", opacity: "0.5" }}>
                                        Upload Document or Image
                                    </Typography>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            type='file'
                                            name='docs'
                                            onChange={(e) => handleFileUpload(e.target.files[0], setFieldValue)}
                                        />
                                    </Box>
                                </FormControl>) : <><div className={classes.filename}>{showName(values.docs)}</div>
                                    <button
                                        type='button'
                                        className={classes.delete}
                                        onClick={() => { setDocRender(true); setFieldValue(values.docs, "") }}
                                    >
                                        <CloseSharp /> Delete
                                    </button></>}
                        </Grid>
                        <Grid item md={6} lg={7} container direction='column' alignItems='flex-start'>
                            <InputLabel>Question <span style={{ color: 'red' }}>*</span></InputLabel>
                            <TextField
                                fullWidth
                                type="text"
                                name={`question[${languageIndex}]`}
                                onChange={handleChange}
                                value={values?.question && Array.isArray(values.question)
                                    && values.question[languageIndex]}
                                {...isTranslatable(true)}
                            />
                            <ErrorMessage name='question' >
                                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                            </ErrorMessage>

                            <InputLabel>Description <span style={{ color: 'red' }}>*</span></InputLabel>
                            <TextField
                                fullWidth
                                multiline
                                minRows={5}
                                type="text"
                                name={`description[${languageIndex}]`}
                                onChange={handleChange}
                                value={values?.description && Array.isArray(values.description)
                                    && values.description[languageIndex]}
                                {...isTranslatable(true)}
                            />
                            <ErrorMessage name='description' >
                                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                            </ErrorMessage>

                            <InputLabel>Site URL</InputLabel>
                            <TextField
                                fullWidth
                                type="text"
                                name="url"
                                onChange={handleChange}
                                value={values?.url}
                                {...isTranslatable(false)}
                            />
                            <ErrorMessage name='url'>
                                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                            </ErrorMessage>
                            <InputLabel>Video URL</InputLabel>
                            <TextField
                                fullWidth
                                type="text"
                                name="iframeURL"
                                onChange={handleChange}
                                value={values?.iframeURL}
                                {...isTranslatable(false)}
                            />
                            <ErrorMessage name='iframeURL'>
                                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                            </ErrorMessage>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} justifyContent="flex-end">
                        <Grid container item sm={2} xs={3} >
                            <Button style={{ width: '100%' }} variant="contained" color="inherit"
                                onClick={() => history.goBack()}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid container item sm={2} xs={3} >
                            <Button style={{ width: '100%' }} variant="contained" color='primary'
                                type={loading || FAQLoading ? 'button' : 'submit'}
                            >
                                {(loading || FAQLoading) &&
                                    <CircularProgress color='secondary' size={28} style={{ padding: '2px' }} />
                                } Save
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            }}
        </Formik>
    )
}

export default FAQForm
