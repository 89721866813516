import ALLOWED_ERRORS from '../settings/_allowed-errors.json'

const allowedKeyErrors = Object.values(ALLOWED_ERRORS)

// TODO: [rico] this works but it is kind of a mess
// needs refactoring

export default (e, override) => {
  if (!e) return null
  let message, field, key
  if (e && e.graphQLErrors && e.graphQLErrors.filter(error => error.message && allowedKeyErrors.filter(key => error.message.includes(key)).length > 0).length > 0) {
    const errorSet = e.graphQLErrors.filter(error => error.message && allowedKeyErrors.filter(key => error.message.includes(key)).length > 0)
    message = errorSet[0].message.split('|')[0]
    field = errorSet[0].message.split('|')[1]
    if (field) {
      field = field.trim()
      key = Object.entries(ALLOWED_ERRORS).filter(([key, value]) => value === field)[0][0]
    } else {
      key = e.graphQLErrors.map(error => {
        if (error.message && allowedKeyErrors.filter(key => message.includes(key)).length > 0) {
          return Object.entries(ALLOWED_ERRORS).filter(([key, value]) => message.includes(value))[0]
        } else return null
      }).filter(e => !!e)[0][0]
    }
    if (override && key && override[key]) {
      message = override[key]
    }
  }
  return message
}
