import React from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks'
import history from '../history'
import { currentUserQuery, loginMutation } from '../api'
// import { NotificationManager } from 'react-notifications'
import Spinner from '../components/Spinner'
import hash from 'hash.js'
// import { toast } from "react-toastify";
import { useSnackbar } from 'notistack'


const hashString = text => ({
  digest: hash
    .sha256()
    .update(text)
    .digest('hex'),
  algorithm: 'sha-256',
})

const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'

const useAuth = () => {
  const { data: { currentUser } = {}, loading } = useQuery(currentUserQuery)
  const [submitLogin] = useMutation(loginMutation)
  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  
  if (loading) return <Spinner big />
  const logout = (callback, { cacheOnly = false } = {}) => {
    if (cacheOnly) {
      client.clearStore()
    } else {
      client.resetStore()
    }
    if (callback && isFunction(callback)) {
      callback(null, 'success')
    } else history.push('/')
    // NotificationManager.success('You have been logged out.')
  }
  const login = ({ email, username, password }, callback) => {
    submitLogin({
      variables: {
        userCredentials: {
          username: email || username,
          password: hashString(password).digest,
        },
      },
    }).then((res) => {
      if (callback && isFunction(callback)) {
        callback(null, res)
      } else history.push('/')
      enqueueSnackbar('You have been logged in.', { variant: 'success' })

      // NotificationManager.success('You have been logged in.')
    }).catch(e => {
      // toast.error("Could not log in")
      enqueueSnackbar('Could not log in', { variant: 'error' })

      // NotificationManager.error('Error.')
      if (!!callback && isFunction(callback)) callback(e)
    })
  }
  return {
    user: currentUser,
    loading,
    login,
    logout,
    isLoggedIn: !!currentUser && !!Object.keys(currentUser).length,
  }
}

export default useAuth