import React, { useState, useCallback, useMemo } from 'react'
import Authenticated from '../../../containers/Authenticated'
import Layout from '../../Layout'
import Table from '../../Table'
import { getAnalyticsCountQuery, getResourceAnalyticsQuery } from '../../../api'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Pagination from '../../Pagination'
import Loader from '../../Loader'
import { ResourceAnalyticFilterForm } from '../../../forms/Analytics/ResourceAnalyticFilterForm'

export const ResourceAnalyticsList = ({ history }) => {
    //Pagination State:
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    //Sort State:
    const [sortData, setSortData] = useState(['_id:desc']);

    //Filter State:
    const [filters, setFilters] = React.useState({});


    //pagination function:
    const handleRowPerChange = useCallback((value) => {
        setRowsPerPage(value)
    }, [])

    //sort function:
    const sortFilter = useCallback((value, key) => {
        setSortData([`${value}:${key}`]);
    }, []);

    //parseFilter:
    const parseFilters = useMemo(() => {

        const { keyword, to_date, from_date } = filters;
        if (keyword) {
            var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
                .replace(/\!/g, "\\\\!")
                .replace(/\@/g, "\\\\@")
                .replace(/\#/g, "\\\\#")
                .replace(/\$/g, "\\\\\\$")
                .replace(/\%/g, "\\\\%")
                .replace(/\^/g, "\\\\^")
                .replace(/\&/g, "\\\\&")
                .replace(/\*/g, "\\\\*")
                .replace(/\)/g, "\\\\)")
                .replace(/\(/g, "\\\\(")
                .replace(/\[/g, "\\\\[")
                .replace(/\]/g, "\\\\]")
                .replace(/\;/g, "\\\\;")
                .replace(/\{/g, "\\\\{")
                .replace(/\}/g, "\\\\}")
                .replace(/\?/g, "\\\\?")
                .replace(/\,/g, "\\\\,");
        }
        return {
            ...keyword && { resourceName_matches: searchKeyword },
            ...from_date && { from_date },
            ...to_date && { to_date },
        };
    }, [filters]);

    //Filter functions:
    const handleClear = useCallback(() => {
        setFilters({});
        history.push("/analytics/resource");
    }, [history]);

    const handleFiltersSubmit = useCallback((fields) => {
        setPage(0)
        setFilters({ ...fields })
    }, []);

    // getResourcedataQuery:
    const { data: { getResourceAnalytics = [] } = {},
        loading, error, refetch
    } = useQuery(getResourceAnalyticsQuery, {
        variables: {
            params: {
                sort: sortData,
                limit: rowsPerPage,
                skip: page * rowsPerPage,
                ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
                    ? { where: parseFilters, "analyticType": "resourceDownload" }
                    : { "analyticType": "resourceDownload" }
                ),

            },
        }
    })


    // getResourceCountQuery:
    const { data: { countAFDBAnalytics: rowsInTotal } = {},
    } = useQuery(getAnalyticsCountQuery, {
        variables: {
            params: {
                where: {
                    ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
                        ? { ...parseFilters, "analyticType": "resourceDownload" }
                        : { "analyticType": "resourceDownload" }
                    )
                }
            }
        }
    })

    return (
        <Authenticated>
            <Layout title='Resource Analytics Data' head={
                <ResourceAnalyticFilterForm
                    filters={filters}
                    callback={handleFiltersSubmit}
                    handleClear={handleClear}
                />
            }>
                <Loader loading={loading} error={error}>
                    <Table
                        data={getResourceAnalytics?.map((item) => {
                            let userArr = item?.user?.map(item => item?.name)
                            userArr = new Set(userArr)
                            return {
                                resourceName: item?.resourceName || '',
                                downloadCount: item?.downloadCount || 0,
                                user: [...userArr].join(",") || ""
                            }

                        })}
                        labels={[
                            {
                                name: 'Resource Name',
                                field: 'resourceName',
                            },
                            {
                                name: "Download Count",
                                field: "downloadCount"
                            },
                            {
                                name: 'Downloaded By',
                                field: 'user',
                            },
                        ]}
                        sortFilter={sortFilter}
                    />
                    <Pagination
                        page={page}
                        count={rowsInTotal}
                        rowsPerPage={rowsPerPage}
                        callback={newPage => setPage(newPage)}
                        handleRowPerChange={handleRowPerChange}
                    />
                </Loader>
            </Layout>
        </Authenticated>
    )
}
