import React, { useEffect, useState } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";

import { useLazyQuery } from "@apollo/react-hooks";
import { getDynamicSurveyAnalyticsQuery } from "../../api";
import { CSVLink } from "react-csv";
import { Button, Select, MenuItem, FormControl } from "@material-ui/core";
import "./DynamicSurveyAnalytics.css";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Query = () => {
  return new URLSearchParams(useLocation().search);
};

const DynamicSurveyAnalytics = ({
  match: {
    params: { id }
  }
}) => {
  console.log("🚀 ~ DynamicSurveyAnalytics ~ id:", id);

  const query = Query();
  const title = query.get("title");

  const colorPalette = ["#2077B4", "#D62728", "#2CA02C", "#FF7F0E", "#9467BD"];

  const [chartType, setChartType] = useState("pie-chart");

  const getColor = bar => {
    const index = bar.index % colorPalette.length;
    return colorPalette[index];
  };

  //Query for survey answers data:

  const [
    analyticsData,
    {
      data: { getDynamicSurveyAnalytics: { analyticsData: result } = {} } = {},
      loading,
      error
    }
  ] = useLazyQuery(getDynamicSurveyAnalyticsQuery, {
    variables: { params: { id } },
    fetchPolicy: "network-only" // Ignores the cache and always makes a network request
  });

  useEffect(() => {
    analyticsData({
      variables: {
        params: {
          id
        }
      }
    });
    console.log("RUNN");
  }, [id, analyticsData]);

  //Transform data into Array of Object:
  const transformData = inputData => {
    if (!inputData || inputData.length === 0) {
      return [];
    }

    let transformed = [];

    Object.entries(inputData[0]).forEach(([questionKey, questionValue]) => {
      // Skip if any entry is a text-type question containing 'email'
      if (
        typeof questionValue === "object" &&
        Object.values(questionValue).some(subItem => subItem.email)
      ) {
        return; // Skip text-type questions
      }

      // Process non-text type questions
      if (typeof questionValue === "object" && !Array.isArray(questionValue)) {
        if (Object.values(questionValue).some(v => typeof v === "object")) {
          // Handling for complex structures like tabular data
          Object.entries(questionValue).forEach(
            ([subQuestionKey, subQuestionValue]) => {
              transformed.push({
                question: `${questionKey} - ${subQuestionKey}`,
                data: subQuestionValue
              });
            }
          );
        } else {
          // Regular non-tabular questions
          transformed.push({ question: questionKey, data: questionValue });
        }
      }
    });

    return transformed;
  };

  //Transform text type questions data for CSV download:
  const transforTextTypeData = inputData => {
    if (!inputData || inputData.length === 0) {
      return [];
    }

    let transformed = [];
    Object.entries(inputData[0]).forEach(([key, values]) => {
      if (
        typeof values === "object" &&
        Object.values(values).some(v => v.email)
      ) {
        // Each value should be an object with 'email' and 'answer' keys
        let responses = Object.values(values).map(v => ({
          email: v.email,
          answer: v.answer
        }));
        if (responses.length > 0) {
          transformed.push({
            question: key,
            responses
          });
        }
      }
    });
    return transformed;
  };

  const transformTextTypeDataForCSV = inputData => {
    console.log("🚀 ~ transformTextTypeDataForCSV ~ inputData:", inputData)
    if (!inputData || inputData.length === 0) {
      return [];
    }

    let csvData = [];
      // Getting all respose objects and creating an array of objects for CSV
      inputData.forEach(response => {
        response.responses.map(ele=>{
            csvData.push({
                Question: response.question,
                Email: ele.email,
                Answer: ele.answer
              });
        })
      });

    return csvData;
  };

  //Transform data into formate of the graph:
  const transformGraphData = data => {
    let graphdata;

    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) continue;
      graphdata = Object.entries(value).map(([option, count]) => {
        return {
          id: option,
          label: option,
          value: Math.round(count),
          color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)` // Random color
        };
      });
    }
    return graphdata;
  };

  //Export chart as a image:
  const exportChartsToImages = async chartIds => {
    const images = [];
    for (const id of chartIds) {
      const chartElement = document.getElementById(id);
      if (!chartElement) {
        continue;
      }
      const canvas = await html2canvas(chartElement, {
        scale: 3.5 // You can adjust this value to find the best balance between quality and performance
      });
      images.push(canvas.toDataURL("image/png"));
    }
    return images;
  };

  //Download all graph as a pdf:
  const downloadPDF = async chartIds => {
    const imageWidth = 180; // Adjust the width of the image
    const imageHeight = 130; // Adjust the height of the image
    const imagesPerPage = 2; // Number of images per page

    const images = await exportChartsToImages(chartIds);
    const pdf = new jsPDF();
    let imageCount = 0;

    images.forEach((image, index) => {
      const xPosition = 10; // Left margin
      let yPosition = 10 + imageCount * (imageHeight + 10); // Adjust vertical spacing

      if (imageCount >= imagesPerPage || index === 0) {
        if (index !== 0) pdf.addPage();
        imageCount = 0;
        yPosition = 10;
      }

      pdf.addImage(
        image,
        "PNG",
        xPosition,
        yPosition,
        imageWidth,
        imageHeight,
        undefined,
        "NONE"
      );
      imageCount++;
    });

    pdf.save(`Analytics_${title}.pdf`);
  };

  const allPieChartIds = transformData(result).map(
    (_, index) => `pie-chart-${index}`
  );
  const allBarChartIds = transformData(result).map(
    (_, index) => `bar-chart-${index}`
  );

  const formatQuestionTitle = questionString => {
    // Check if the string contains a sub-question
    if (questionString.includes(" - ")) {
      // Splitting the main question and sub-question parts
      const [mainPart, subPart] = questionString.split(" - ");

      // Further splitting the main question part to extract the EN title
      const mainQuestionEN = mainPart.split(",")[0]; // Takes the English part of the main question

      // Assuming sub-question is already in desired language (EN in this case)
      const subQuestionEN = subPart;

      return `Q - ${mainQuestionEN} & Sub Q - ${subQuestionEN}`;
    } else {
      // For strings without a sub-question, format directly
      const mainQuestionEN = questionString.split(",")[0]; // Assumes the string might still follow the "question EN,question FR" format
      return `Q - ${mainQuestionEN}`;
    }
  };

  return (
    <Authenticated>
      <Layout title="Dynamic Survey Analytics">
        <Loader loading={loading} error={error}>
          <h1>Analytics</h1>
          {!transformData(result).length == 0 && (
            <>
              {/* <label className='chart_type_label'>
                                Chart Type
                            </label> */}
              <FormControl fullWidth className="survey-graph-block">
                <Select
                  id="chartType"
                  name="Chart Type"
                  defaultValue={chartType}
                  label="Chart Type"
                  onChange={e => setChartType(e.target.value)}
                >
                  <MenuItem key="pie-chart" value="pie-chart">
                    Pie Chart
                  </MenuItem>
                  <MenuItem key="bar-chart" value="bar-chart">
                    Bar Chart
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                size="medium"
                variant="contained"
                type="button"
                style={{ marginBottom: 20 }}
                onClick={() =>
                  downloadPDF(
                    chartType == "bar-chart" ? allBarChartIds : allPieChartIds
                  )
                }
              >
                Download all charts as PDF
              </Button>
            </>
          )}
          {chartType == "bar-chart" ? (
            <div className="analytics-wrapper">
              {transformData(result).map((item, index) => {
                const chartId = `bar-chart-${index}`;
                return (
                  <>
                    {!Array.isArray(Object.values(item)[0]) && (
                      <>
                        <div id={chartId} className="analytics-block-item">
                          {/* <span className='question-title' >Question :- {Object.keys(item)[0].split(',')[0]}</span> */}
                          <span className="question-title">
                            {formatQuestionTitle(item.question)}
                          </span>
                          <div key={index} className="chart-wrapper">
                            <ResponsiveBar
                              data={transformGraphData(item)} // Adjust this to fit the data structure expected by ResponsiveBar
                              keys={["value"]} // Specify the key or keys to use from your data
                              indexBy="id" // Specify the index key in your data
                              margin={{
                                top: 50,
                                right: 130,
                                bottom: 50,
                                left: 60
                              }}
                              padding={0.3}
                              indexScale={{ type: "band", round: true }}
                              colors={getColor}
                              // Adjust the color scheme as needed
                              borderColor={{
                                from: "color",
                                modifiers: [["darker", 1.6]]
                              }}
                              axisTop={null}
                              axisRight={null}
                              valueScale={{ type: "linear", min: 0 }}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "option", // Adjust as needed
                                legendPosition: "middle",
                                legendOffset: 32
                              }}
                              axisLeft={{
                                tickValues: "every 1",
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "value", // Adjust as needed
                                legendPosition: "middle",
                                legendOffset: -40
                              }}
                              labelSkipWidth={12}
                              labelSkipHeight={12}
                              labelTextColor="white"
                              animate={true}
                              motionStiffness={90}
                              motionDamping={15}
                              theme={{
                                axis: {
                                  legend: {
                                    text: {
                                      fontSize: 18, // Adjust font size for axis legends
                                      fontWeight: 600
                                    }
                                  },
                                  ticks: {
                                    text: {
                                      fontSize: 16, // Adjust font size for axis ticks
                                      fontWeight: 600
                                    }
                                  }
                                },
                                labels: {
                                  text: {
                                    fontSize: 14, // Adjust font size for labels inside the bars
                                    fontWeight: 600
                                  }
                                },
                                legends: {
                                  text: {
                                    fontSize: 16, // Adjust font size for chart legends
                                    fontWeight: 600
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          ) : (
            <div className="analytics-wrapper">
              {transformData(result).map((item, index) => {
                const chartId = `pie-chart-${index}`;
                const graphData = transformGraphData(item);

                return (
                  <>
                    {!Array.isArray(Object.values(item)[0]) && (
                      <>
                        <div id={chartId} className="analytics-block-item">
                          {/* <span className='question-title' >Question :- {Object.keys(item)[0].split(',')[0]}</span> */}
                          <span className="question-title">
                            {formatQuestionTitle(item.question)}
                          </span>
                          <div key={index} className="chart-wrapper">
                            <ResponsivePie
                              data={graphData}
                              margin={{
                                top: 40,
                                right: 80,
                                bottom: 80,
                                left: 80
                              }}
                              innerRadius={0.5}
                              padAngle={0.7}
                              cornerRadius={3}
                              activeOuterRadiusOffset={8}
                              borderWidth={1}
                              colors={{ scheme: "category10" }}
                              borderColor={{
                                from: "color",
                                modifiers: [["darker", 0.2]]
                              }}
                              arcLinkLabelsSkipAngle={10}
                              arcLinkLabelsTextColor="#333333"
                              arcLinkLabelsThickness={2}
                              arcLinkLabelsColor={{
                                from: "color",
                                modifiers: []
                              }}
                              arcLabelsSkipAngle={10}
                              arcLabelsTextColor="white"
                              defs={[
                                {
                                  id: "dots",
                                  type: "patternDots",
                                  background: "inherit",
                                  color: "rgba(255, 255, 255, 0.3)",
                                  size: 4,
                                  padding: 1,
                                  stagger: true
                                },
                                {
                                  id: "lines",
                                  type: "patternLines",
                                  background: "inherit",
                                  color: "rgba(255, 255, 255, 0.3)",
                                  rotation: -45,
                                  lineWidth: 6,
                                  spacing: 10
                                }
                              ]}
                              legends={[
                                {
                                  anchor: "bottom",
                                  direction: "row",
                                  justify: false,
                                  translateX: 0,
                                  translateY: 56,
                                  itemsSpacing: 0,
                                  itemWidth: 100,
                                  itemHeight: 18,
                                  itemTextColor: "#999",
                                  itemDirection: "left-to-right",
                                  itemOpacity: 1,
                                  symbolSize: 18,
                                  symbolShape: "circle",
                                  effects: [
                                    {
                                      on: "hover",
                                      style: {
                                        itemTextColor: "#000"
                                      }
                                    }
                                  ]
                                }
                              ]}
                              theme={{
                                labels: {
                                  text: {
                                    fontSize: 18, // Adjust font size for labels
                                    fontWeight: 600
                                  }
                                },
                                legends: {
                                  text: {
                                    fontSize: 18, // Adjust font size for legend text
                                    fontWeight: 600 // Adjust font size for labels
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          )}

          {transforTextTypeData(result).map((item, index) => {
            {
              console.log(item, "IIII");
            }
            return (
              <div className="analytic-que-wrapper">
                <span className="question-title">
                  Question :- <p>{item.question.split(",")[0]}</p>{" "}
                </span>
                <CSVLink
                  headers={[
                    { label: "Question", key: "Question" },
                    { label: "Email", key: "Email" },
                    { label: "Answer", key: "Answer" }
                  ]}
                  data={transformTextTypeDataForCSV([item])} // This should be an array of objects
                  filename="Survey_Results.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    size="medium"
                    variant="contained"
                    style={{ marginLeft: 20, marginRight: 20 }}
                  >
                    Download Answers
                  </Button>
                </CSVLink>
              </div>
            );
          })}
          {transformData(result).length == 0 &&
            transforTextTypeData(result).length == 0 && (
              <div className="no-data-div">
                There are no survey responses or data available at the moment
              </div>
            )}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default DynamicSurveyAnalytics;
