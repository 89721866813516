import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Grid, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import OrganizationForm from '../forms/OrganizationForm'
import LanguageSwitch from '../components/LanguageSwitch'
import { getEsoBySlugQuery, _revokeEsoInvitationMutation, saveEsoMutation } from '../api'
import multivalues from '../settings/multivalues.json'
import InviteUserForm from '../forms/InviteUserForm'


const OrganizationEdit = ({ match: { params: { slug } } }) => {
  const [languageIndex, setLanguageIndex] = React.useState(0)
  const [nativeLanguage, setNativeLanguage] = React.useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const [revokeInvitation] = useMutation(_revokeEsoInvitationMutation)
  const [saveEso] = useMutation(saveEsoMutation)
  const {
    data: { getEsoBySlugAdmin: data = {} } = {},
    loading,
    error,
  } = useQuery(getEsoBySlugQuery, { variables: { slug }, skip: !slug })

  React.useEffect(() => {
    // Recognize native language
    if (data && data.language) {
      const languageIndex = multivalues.languages[data.language]
      setNativeLanguage(languageIndex)
      setLanguageIndex(languageIndex)
    }
  }, [data])

  const revokeOrganizationInvitation = id => {
    if (!id) return null
    revokeInvitation({
      variables: {
        esoId: id,
      }
    }).then(() => enqueueSnackbar(`Invitation has been successfully removed.`, { variant: 'success' }))
      .catch((e) => {
        enqueueSnackbar('Cannot revoke the mutation before expiration.', { variant: 'error' })
        console.log(e)
      })
    return null
  }

  const setNativeLangCallback = (index, code) => {
    setNativeLanguage(index)
    const { _id, slug, user, invited, registered, onboarded, __typename, registration_date, invitition_date, ...dataObject } = data
    saveEso({
      variables: {
        data: {
          ...dataObject,
          id: data._id,
          language: code
        },
      }
    })
  }

  return (
    <Authenticated>
      <Layout title='Edit ESO'>
        <Loader loading={loading} error={error}>
          <Grid container>
            <Grid item sm>
              <Typography variant='h4' gutterBottom>{data && data.name}</Typography>
            </Grid>
            <Grid item sm={4}>
              <InviteUserForm
                organizationId={data._id}
                organizationName={data.name}
                revokeOrganizationInvitation={revokeOrganizationInvitation}
                organizationNativeLanguage={data.language}
                invited={data.invited}
                registered={data.registered}
                userAccount={`${data.user && data.user._id && data.user.email ? `${data.user.email}` : ''}`}
                languageIndex={languageIndex}
              ></InviteUserForm>
            </Grid>
          </Grid>

          <LanguageSwitch
            languageIndex={languageIndex}
            setLanguageIndex={setLanguageIndex}
            languages={multivalues.languages}
            nativeLanguage={nativeLanguage}
            setNativeLangCallback={setNativeLangCallback}
          />

          <OrganizationForm
            languageIndex={languageIndex}
            languagesTotal={Object.keys(multivalues.languages).length}
            data={data}
            nativeLanguage={nativeLanguage}
            setNativeLanguage={lang => setNativeLanguage(multivalues.languages[lang])}
          />
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default OrganizationEdit
