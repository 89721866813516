import React from 'react'
import Table from './Table'
import { Link } from 'react-router-dom'
import ConfirmDelete from './ConfirmDelete'
import { Check, Block, People } from '@material-ui/icons'
import { Button, ButtonGroup } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faEnvelope, faEnvelopeCircleCheck, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import Loader from './Loader'
import Spinner from './Spinner'

const DynamicSurveyList = ({ data, deleteCallback, sortFilter, downloadAnalytics, sendNotification ,notificationProcessingId}) => {
  return (
    <Table
      data={data.map(item => ({
        title: item?.title && Array.isArray(item.title)
        ? item.title.filter((ele) => ele !== '').join(" | ")
          : item?.title || ''
        ,
        isPublic: item.isPublic ? <Check color='secondary' /> : <Block color='disabled' />,
        isActive: item.isActive ? <Check color='secondary' /> : <Block color='disabled' />,
        isAnsweredBy: <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >{item.isAnsweredBy}   <People /></div>,
        actions:
          <>
            <ButtonGroup size='small' variant='text'>
              <Button
                component={Link}
                to={`/dynamicSurvey/${item._id}`}
              >
                Edit
              </Button>
              <ConfirmDelete
                id={item._id}
                label='DynamicSurvey'
                name={item?.title}
                deleteCallback={deleteCallback}
              />
            </ButtonGroup>

          </>,
        analytics: <>
          {/* <ButtonGroup size='small' variant='text'> */}
          <Button
            variant='outlined'
            component={Link}
            to={`/dynamicSurveyAnalytics/${item._id}?title=${item?.title}`}
          >
            <FontAwesomeIcon icon={faChartPie} size='xl' />

          </Button>
          <Button
            variant='outlined'
            onClick={() => downloadAnalytics(item._id, item?.title)}
          >
            <FontAwesomeIcon icon={faFileArrowDown} size='xl' />
          </Button>
          {/* </ButtonGroup> */}
        </>,
        notification:notificationProcessingId === item._id ? <Loader loading={true} color={"#69BF35"} medium/> :  item?.isNotificationSent ? <FontAwesomeIcon icon={faEnvelopeCircleCheck} size='xl' /> : <Button type="button" onClick={() => sendNotification(item._id)} variant='outlined'><FontAwesomeIcon icon={faEnvelope} size='xl' /></Button>,


        createdAt: item?.createdAt
      }))}
      labels={[
        {
          name: 'Title',
          field: 'title',
        },
        {
          name: 'Is Public',
          field: 'isPublic',
        },
        {
          name: 'Is Active',
          field: 'isActive',
        },
        {
          name: 'Survey filled by',
          field: 'isAnsweredBy'
        },
        {
          name: 'Created At',
          field: 'createdAt',
        },
        {
          name: 'Analytics',
          field: 'analytics',
        },
        {
          name: 'Actions',
          field: 'actions',
        },
        {
          name: 'Notification',
          field: 'notification',
        },
      ]}
      sortFilter={sortFilter}
    />
  )
}

export default DynamicSurveyList