import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Modal as MuiModal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'


const Modal = ({
  isOpen: isOpenProp,
  trigger,
  onClose,
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(isOpenProp)
  const classes = useStyles()

  React.useMemo(() => {
    setIsOpen(isOpenProp)
  }, [isOpenProp])

  return (
    <>
      {trigger && <span onClick={() => setIsOpen(true)}>{trigger}</span>}
      <MuiModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
          onClose && onClose()
        }}
        disablePortal
        className={classes.root}
      >
        <div className={classes.content}>
          {title && <Typography variant='h5' gutterBottom>{title}</Typography>}
          {children}
        </div>
      </MuiModal>
    </>
  )
}

Modal.propTypes = {
  trigger: PropTypes.node,
  title: PropTypes.node,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: 600,
    maxWidth: '100vw',
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
    overflow: 'hidden',
    '&:focus': {
      outline: 'none',
    },
  },
}))

export default Modal
