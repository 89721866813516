import React from "react";
import * as Yup from "yup"
import { useSnackbar } from 'notistack'
import Random from "random-seed-generator";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";
import {
  getUsersEditQuery, inviteUserByAdmin, _s3SignMutation,
  editUserDetails, getUsersQuery, countUsersQuery
} from "../../api";
import { useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik"
import { makeStyles } from "@material-ui/core/styles";
import ImageUpload from "../../components/ImageUpload";
import Authenticated from "../../containers/Authenticated";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Box, Grid, Chip, Select, Button, MenuItem, TextField, CircularProgress,
  CardContent, CardActions, FormControl, FormHelperText,
} from "@material-ui/core"
import AFIRCA_COUNTRIES from "../../settings/enums/new-africa-iso-code.json";
import JOBS from '../../settings/enums/jobs.json'
import './userEdit.css'
// import { CloudUpload, Clear } from '@material-ui/icons';
// import  {
//   Input,  TextArea,  MultiSelect,
//   ImageUpload, FormButton, FormActions
// } from "react-standalone-form";
// import { getUsersEditQuery } from '../api'

const useStyles = makeStyles({
  root: {
    // input label when focused
    // "& label.Mui-focused": {
    //   color: "#00a651",
    //   fontFamily: "Arial",
    //   marginBottom: "10px",
    //   fontWeight: '500',
    //   fontSize: '20px'
    // },
    // "& .MuiInputLabel-animated": {
    //   color: "#00a651",
    //   fontFamily: "Arial",
    //   marginBottom: "10px",
    //   fontWeight: '500',
    //   fontSize: '20px'
    // },
    // "& .MuiFormLabel-root.Mui-focused": {
    //   color: "#00a651",
    //   fontFamily: "Arial",
    //   marginBottom: "10px",
    //   fontWeight: '500',
    //   fontSize: '20px'
    // },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#00a651",
      border: "1px solid #00a651"
    },
    "& .MuiInput-underline:hover": {
      borderColor: "#00a651",
      borderWidth: "1px"
    },
    "& .MuiInputBase-input": {
      border: "1px solid #00a651",
      paddingLeft: "1%"
    },
    '&:after': {
      // The MUI source seems to use this but it doesn't work
      borderBottom: '1px solid #00a651',
    },
    // "&.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled": {
    //   color: '#00a651',
    //   fontFamily: "Arial",
    //   fontSize: '20px',
    //   fontWeight: '500',
    // },
  },
  label: {
    color: '#00a651',
    fontSize: "14px",
    fontWeight: "normal",
    marginBottom: "10px",
    fontFamily: "Arial",
  },
  labelForSelect: {
    color: '#00a651',
    fontSize: "14px",
    fontWeight: "normal",
    marginBottom: "-6px",
    fontFamily: "Arial",
  },
  formControl: {
    margin: 0,
    minWidth: 120,
  },
});

const serviceIntrest = {
  incubator: {
    label: "Incubator programme",
    icon: "incubator",
    description: ""
  },
  accelerator: {
    label: "Accelerator programme",
    icon: "accelerator",
    description: ""
  },
  coworking: {
    label: "Co-working and facilities",
    icon: "co-working",
    description: "Co-working space with limited support"
  },
  virtual: {
    label: "Virtual platform",
    icon: "virtual-platform",
    description: ""
  },
  financial: {
    label: "Funding",
    icon: "access-to-finance",
    description:
      "Financial Institution providing Business Development Support services (Fund manager, Angel, VC, equity, impact / social investor, etc.)"
  },
  business: {
    label: "Advice",
    icon: "chat",
    description:
      "Business linkages, networking, value chain or marketing support organization"
  },
  networking: {
    label: "Networking",
    icon: "networking",
    description: ""
  },
  sme: {
    label: "Mentoring",
    icon: "mentoring",
    desription: "SME Business Support Mentoring"
  },
  training: {
    label: "Training",
    icon: "mentoring",
    desription: "Training workshop & bootcamp"
  },
  prototyping: {
    label: "Prototyping tools ",
    icon: "mentoring",
    desription: ""
  },
  events: {
    label: "Events",
    icon: "mentoring",
    desription: ""
  },
  other: {
    label: "Other",
    icon: "idea"
  }
};

const submissionFilter = (a, b) =>
  Object.fromEntries(
    Object.entries(b).filter(([key, val]) => {
      if (key in a) {
        if (!Array.isArray(a[key])) return a[key] !== val;
        return (
          a[key]
            .filter(x => !b[key].includes(x))
            .concat(b[key].filter(y => !a[key].includes(y))).length !== 0
        );
      }
      return false;
    })
  );

const socialObj = (itemsObject = {}) => {
  return Object.entries(itemsObject).map(([key, value]) => ({
    id: key,
    label: key,
    url: value
  }));
};

const parseUrl = url =>
  !url.includes("http://") && !url.includes("https://") ? `http://${url}` : url;

const UserEdit = () => {
  let _id = useParams()?.id;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountry, setSelectedCountry] = React.useState();
  const [s3Sign, { loading: s3Loading }] = useMutation(_s3SignMutation);
  const [userEdit, { loading: userEditLoading }] = useMutation(editUserDetails);
  const [inviteUser, { loading: inviteuserLoading }] = useMutation(inviteUserByAdmin);

  const classes = useStyles()
  let { data: { getUserById: user = {} } = {}, loading, error, refetch } = useQuery(
    getUsersEditQuery,
    { variables: { id: _id } }
  );

  React.useEffect(() => {
    if (!loading) {
      user.country && !selectedCountry && setSelectedCountry(user.country);
      // user.avatar && setSelectedAvatar(user.avatar)
    }
  }, [user, loading]);

  const jobs_role = Object.values(JOBS).reduce((arr, category) => {
    const categoryArr = Object.keys(category).map(role => ({
      label: category[role],
      value: role
    }));
    return arr.concat(categoryArr);
  }, []);

  //Data
  const initialValues = {
    first_name: user.first_name ? user.first_name : "",
    last_name: user.last_name ? user.last_name : "",
    gender: user.gender ? user.gender : "",
    avatar: user.avatar ? user.avatar : "",
    email: user.email ? user.email : "",
    phone: user.phone ? user.phone : "",
    age: user.age ? user.age : "",
    currentPosition: user.currentPosition ? user.currentPosition : "",
    country: user.country ? user.country : "",
    linkedin: user?.social?.find(item => item?.label === "linkedin")?.url || '',
    facebook: user?.social?.find(item => item?.label === "facebook")?.url || '',
    twitter: user?.social?.find(item => item?.label === "twitter")?.url || '',
    serviceInterests: user.serviceInterests ? user.serviceInterests : [],
  }

  const options = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" }
  ]

  const ageoptions = [
    { label: "under 18", value: "under 18" },
    { label: "between 18 and 35", value: "between 18 and 35" },
    { label: "above 35", value: "above 35" }
  ]

  //validation schema
  let validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string(),
    age: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    currentPosition: Yup.string(),
    country: Yup.string().required("Required"),
  })

  const handleInviteuser = () => {
    inviteUser({
      variables: {
        id: user._id
      },
      refetchQueries: [{
        query: getUsersEditQuery,
        variables: { id: _id }
      }, {
        query: getUsersQuery
      }, {
        query: countUsersQuery
      }]
    }).then((response) => {
      enqueueSnackbar('Invitation Sent Successfully!', { variant: 'success' })
      refetch();
    }).catch((e) => {
      console.error(e);
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    })
  }

  const onSubmit = async (fields) => {
    const {
      first_name,
      last_name,
      phone,
      age,
      country,
      city,
      avatar,
      currentPosition,
      personalInterests,
      serviceInterests,
      about,
      gender,
      birthDate,
      social
    } = user;

    const originalData = {
      _id,
      first_name,
      last_name,
      phone,
      age,
      country,
      city,
      avatar,
      currentPosition,
      personalInterests,
      serviceInterests,
      about,
      gender,
      birthDate,
      social
    };

    const fieldsData = {
      first_name: fields.first_name,
      last_name: fields.last_name,
      phone: fields?.phone,
      age: fields?.age,
      avatar: fields.avatar,
      country: fields.country,
      about: fields.about,
      city: fields.city ? fields.city.city : city,
      currentPosition: fields.currentPosition,
      personalInterests: fields.personalInterests,
      serviceInterests: fields.serviceInterests,
      gender: fields?.gender || null, // gender is enum and cannot be ''
      birthDate: fields.birthDate,
      social: socialObj({
        // need to transform before sending to backend
        linkedin: fields?.linkedin ? parseUrl(fields?.linkedin) : null,
        facebook: fields?.facebook ? parseUrl(fields?.facebook) : null,
        twitter: fields?.twitter ? parseUrl(fields?.twitter) : null
      })
    };
    const dataObject = submissionFilter(originalData, fieldsData);
    if (Object.keys(dataObject).length) {
      const hasNewImage =
        dataObject.avatar && typeof dataObject.avatar === "object";
      let url;
      if (hasNewImage) {
        const image = dataObject.avatar;
        const { dataFile, name: fileName } = image;
        const randomString = Random.string({
          length: 5,
          pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        });
        const id = user._id;
        const response = await s3Sign({
          variables: {
            filename: `media/user/${id}/avatar/${randomString}-${fileName
              .replace(/ /g, "-")
              .replace(/[{()}]/g, "")}`,
            filetype: image.type
          }
        });
        const { signedRequest, url: bucketUrl } = response.data._signS3;
        const options = {
          headers: {
            "Content-Type": image.type
          }
        };
        try {
          await fetch(signedRequest, {
            method: "PUT",
            headers: options.headers,
            body: dataFile
          });
          url = bucketUrl;
        } catch (e) {
          url = null;
          enqueueSnackbar('Cannot upload image.', { variant: 'error' })
        }
      }
      dataObject._id = _id;
      dataObject.first_name = fieldsData.first_name;
      dataObject.last_name = fieldsData.last_name;
      userEdit({
        variables: {
          data: {
            ...dataObject,
            ...(url && { avatar: url })
          }
        }
      })
        .then(response => {
          if (response?.data) {
            history.push(`/users/`);
            enqueueSnackbar('Profile save successfully', { variant: 'success' })
          }
        })
        .catch(e => {
          console.error(e);
          enqueueSnackbar('Could not save the profile.', { variant: 'error' })
        });
    }
  }

  return (
    <Authenticated>
      <Layout title="Edit user">
        <Loader loading={loading} error={error}>
          {user.invitationStatus === 'Pending' && user.createdByAdmin
            ? <div className="invitationStatus">
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleInviteuser}
                disabled={inviteuserLoading}
                className="edituser_save_btn"
              >
                {(inviteuserLoading) && <CircularProgress color='inherit'
                  size={26} style={{ padding: '4px' }} />}
                Invite User
              </Button>
            </div>
            : user.invitationStatus && user.createdByAdmin &&
            <div className="invitationStatus"> Invitation {user.invitationStatus} </div>
          }
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(fields) => onSubmit(fields)}>
            {({ setFieldValue, values, handleChange, handleBlur, errors }) => {
              return (
                <Form id="user_edit_form">
                  <CardContent>
                    <Grid item container spacing={2} alignItems="center" justifyContent="center">
                      <Grid container item xs={12} sm={12} md={12} >
                        {/* <FormControl fullWidth className={classes.formControl}> */}
                        <label className={classes.label}>First Name <span className="required_sign">*</span></label>
                        <TextField
                          fullWidth
                          className={classes.root}
                          // label="First Name"
                          name="first_name"
                          id="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                          InputLabelProps={{ shrink: true }}

                        />
                        <ErrorMessage name="first_name" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                        {/* </FormControl> */}
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        {/* <FormControl fullWidth> */}
                        <label className={classes.label}>Last Name <span className="required_sign">*</span></label>
                        <TextField
                          fullWidth
                          className={classes.root}
                          // label="Last Name"
                          name="last_name"
                          id="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{ shrink: true }}

                        />
                        <ErrorMessage name="last_name" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                        {/* </FormControl> */}
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        <FormControl fullWidth >
                          <label className={classes.labelForSelect}>
                            Gender <span className="required_sign">*</span>
                          </label>
                          <Select
                            native
                            id="gender"
                            name="gender"
                            label="Gender"
                            onBlur={handleBlur}
                            value={values.gender}
                            onChange={handleChange}
                            className={classes.root}
                          >
                            <option value="">Select</option>
                            {options.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage name="gender" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        <Box>
                          <label variant="subtitle1" className={classes.label}>Logo</label>
                          <ImageUpload
                            name={'avatar'}
                            value={values?.avatar}
                            setFieldValue={setFieldValue}
                          />
                          {/* <Box style={{ backgroundColor: "#f5f5f5", width: "160%", display: "grid", placeItems: 'center', minHeight: "100px" }}>
                            {!selectedAvatar && <Button variant="contained" component="label" style={{ backgroundColor: 'transparent', boxShadow: 'none' }} startIcon={<CloudUpload />}>
                              UPLOAD IMAGE
                              <input hidden accept="image/*" name='avatar' type="file" onChange={(e) => { selecAvatarFun(e.target.files[0], setFieldValue) }} />
                            </Button>}
                            {selectedAvatar && <>
                              <img style={{ marginTop: "10px" }} src={values?.avatar ? values?.avatar : selectedAvatar} alt="logo" width="200" height="200" />
                              <Button style={{ margin: "10px" }} onClick={() => {
                                setSelectedAvatar(null)
                                setFieldValue('avatar', '')
                              }} startIcon={<Clear />}>DELETE IMAGE</Button>
                            </>}
                          </Box> */}
                        </Box>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        {/* <FormControl fullWidth> */}
                        <label className={classes.label}>Email <span className="required_sign">*</span></label>
                        <TextField
                          fullWidth
                          className={classes.root}
                          // label="Email"

                          id="email"
                          name="email"
                          value={values.email}

                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{ shrink: true }}
                        />
                        <ErrorMessage name="email" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                        {/* </FormControl> */}
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        {/* <FormControl fullWidth> */}
                        <label className={classes.label}>Phone</label>
                        <TextField
                          fullWidth
                          className={classes.root}
                          // label="Phone"

                          id="phone"
                          name="phone"
                          value={values.phone}

                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{ shrink: true }}
                        />
                        <ErrorMessage name="phone" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                        {/* </FormControl> */}
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        <FormControl fullWidth >
                          <label className={classes.labelForSelect}>
                            Age <span className="required_sign">*</span>
                          </label>
                          <Select
                            native
                            id="age"
                            name="age"
                            value={values.age}
                            onBlur={handleBlur}
                            label="Age bracket"
                            onChange={handleChange}
                            className={classes.root}
                          >
                            <option value="">All</option>
                            {ageoptions.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage name="age" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        <FormControl fullWidth >
                          <label className={classes.labelForSelect}>
                            Position
                          </label>
                          <Select
                            native
                            id="currentPosition"
                            name="currentPosition"
                            label="Position"
                            // onBlur={handleBlur}
                            value={values.currentPosition}
                            onChange={handleChange}
                            className={classes.root}
                          >
                            <option value="">Select</option>
                            {jobs_role.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label.toUpperCase()}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage name="currentPosition" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        <FormControl fullWidth >
                          <label className={classes.labelForSelect}>
                            Country <span className="required_sign">*</span>
                          </label>
                          <Select
                            className={classes.root}
                            native
                            label="Country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.country}
                            name="country"
                            id="country"
                          >
                            <option value="">Select</option>
                            {Object.entries(AFIRCA_COUNTRIES).map(([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage name="country" >
                          {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        <FormControl fullWidth>
                          <label className={classes.labelForSelect}>Service Interests</label>
                          <Select
                            multiple
                            displayEmpty
                            className={classes.root}
                            name='serviceInterests'
                            onChange={handleChange}
                            error={errors.serviceInterests}
                            defaultValue={values?.serviceInterests}
                            renderValue={(selected) => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                {selected.length
                                  ? selected.map((value) => (
                                    <Chip key={value} label={serviceIntrest[value]?.label?.toUpperCase()} />
                                  ))
                                  : 'Select'
                                }
                              </Box>
                            )}
                          >
                            <MenuItem value='' disabled>Service Interests</MenuItem>
                            {Object.entries(serviceIntrest).map(([key, value]) => (
                              <MenuItem key={key} value={key} >
                                {value?.label?.toUpperCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        {/* <FormControl fullWidth> */}
                        <label className={classes.label}>Linkedin</label>
                        <TextField
                          fullWidth
                          className={classes.root}
                          // label="Linkedin"

                          id="linkedin"
                          name="linkedin"
                          value={values.linkedin}

                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{ shrink: true }}
                        />
                        {/* </FormControl> */}
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        {/* <FormControl fullWidth> */}
                        <label className={classes.label}>Facebook</label>
                        <TextField
                          fullWidth
                          className={classes.root}
                          // label="Facebook"
                          id="facebook"
                          name="facebook"
                          value={values.facebook}

                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputLabelProps={{ shrink: true }}
                        />
                        {/* </FormControl> */}
                      </Grid>
                      <Grid container item xs={12} sm={12} md={12} >
                        {/* <FormControl fullWidth> */}
                        <label className={classes.label}>Twitter</label>
                        <TextField
                          fullWidth
                          className={classes.root}
                          // label="Twitter"
                          id="twitter"
                          name="twitter"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.twitter}

                          InputLabelProps={{ shrink: true }}
                        />
                        {/* </FormControl> */}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid container spacing={4} justifyContent='flex-end'>
                      <Grid item>
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          CANCEL
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className="edituser_save_btn"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={userEditLoading || s3Loading}
                        >
                          {(userEditLoading || s3Loading) && <CircularProgress color='inherit'
                            size={26} style={{ padding: '4px' }} />}
                          SAVE
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Form>
              )
            }}
          </Formik>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default UserEdit;
