import React from 'react'
import { Formik, Form } from 'formik';
import { Button, Grid, InputLabel, TextField } from '@material-ui/core'

export const ResourceAnalyticFilterForm = (props) => {

  const {
    handleClear,
    callback = () => { },
  } = props

  return (
    <Formik
      initialValues={{ keyword: '', from_date: '', to_date: '' }}
      onSubmit={(values) => callback(values)}
    >
      {({
        values,
        resetForm,
        handleChange,
      }) => {
        return <Form name="filter_newsfeed" >
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="keyword"
                value={values.keyword}
                onChange={handleChange}
                label="Search Resource"
              />
            </Grid>
            <Grid item xs={4}>

              <InputLabel>From Date</InputLabel>
              <TextField
                fullWidth
                type="date"
                name='from_date'
                onChange={handleChange}
                value={values.from_date}
                InputLabelProps={{
                  shrink: true,
                }}
                // inputProps={{ max: new Date().toISOString().slice(0, 10) }}
              />
            </Grid>
            <Grid item xs={4}>

              <InputLabel>To Date</InputLabel>
              <TextField
                fullWidth
                type="date"
                name='to_date'
                onChange={handleChange}
                value={values.to_date}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <div style={{ marginTop: 25 }}>
            <Grid container spacing={4} justifyContent='flex-start'>
              <Grid item xs={3} sm={2}>
                <Button style={{ width: '100%' }} color="primary"
                  variant="contained" type='submit'>
                  APPLY
                </Button>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button style={{ width: '100%' }} type="reset"
                  color="inherit" variant="contained"
                  onClick={() => {
                    resetForm();
                    handleClear()
                  }}
                >
                  Clear Filter
                </Button>
              </Grid>
            </Grid>
          </div>
        </Form>
      }}
    </Formik>
  )
}
