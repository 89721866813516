import React, { useEffect, useState } from "react";
import Table from "../Table";
import { Link } from "react-router-dom";
import ConfirmDelete from "../ConfirmDelete";
import { saveNewsMutation } from "../../api";
import { useMutation } from "@apollo/react-hooks";
import { Check, Block, Star, StarBorder } from "@material-ui/icons";
import { Select, FormControl, Button, ButtonGroup } from "@material-ui/core";
import "./newslist.css";

const NewsList = ({ data, deleteCallback, sortFilter }) => {
  const [saveNews, { loading }] = useMutation(saveNewsMutation);
  const [approvedValue, setApprovedValue] = useState({
    id: null,
    isApproved: null,
    title: "",
    published: null
  });

  useEffect(() => {
    if (approvedValue.id) {
      updateApprovedStatus();
    }
  }, [approvedValue]);

  const updateApprovedStatus = () => {
    saveNews({
      variables: {
        data: approvedValue
      }
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const updateFeature = (data) => {
    saveNews({
      variables: {
        data: data
      }
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Table
      data={data.map(item => ({
        title: item.title,
        createdAt: item?.createdAt,
        languages:
          item.languages &&
          Array.isArray(item.languages) &&
          item.languages.join("|"),
        type: item.type || "standard", // Fallback to standard if there is no value provided.
        published: item.published ? (
          <Check color="secondary" />
        ) : (
          <Block color="disabled" />
        ),
        featured: item.featured ? (
          <Star color="primary" cursor={loading ? "not-allowed" : "pointer"}
            onClick={() => !loading && updateFeature({
              id: item?._id,
              featured: false,
              title: item.allTitle,
            })} />
        ) : (
          <StarBorder color="disabled" cursor={loading ? "not-allowed" : "pointer"}
            onClick={() => !loading && updateFeature({
              id: item?._id,
              featured: true,
              title: item.allTitle,
            })} />
        ),
        actions: (
          <div
            style={{ display: "flex", alignItems: "baseline" }}
            className="isApprovedWrapper"
          >
            <FormControl variant="outlined" className={item?.isApproved ? "approved" : "pending"}>
              <Select
                native
                disabled={loading}
                defaultValue={item?.isApproved ? item.isApproved : false}
                onChange={(e) => {
                  setApprovedValue({
                    id: item?._id,
                    isApproved: JSON.parse(e.target.value),
                    title: item?.allTitle,
                    published: item.published
                  })
                }}
              >
                <option value={true} key="Approved">Approved</option>
                <option value={false} key="Pending">Pending</option>
              </Select>
            </FormControl>
            <ButtonGroup size="small" variant="text">
              <Button component={Link} to={`/newsfeed/${item.slug}`}>
                Edit
              </Button>

              <ConfirmDelete
                id={item._id}
                name={item.title}
                deleteCallback={deleteCallback}
              />
            </ButtonGroup>
          </div>
        )
      }))}
      labels={[
        {
          name: "Title",
          field: "title"
        },
        {
          name: "Lang",
          field: "languages"
        },
        {
          name: "Type",
          field: "type"
        },
        {
          name: "Published",
          field: "published"
        },
        {
          name: "Featured",
          field: "featured"
        },
        {
          name: "Created At",
          field: "createdAt"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ]}
      sortFilter={sortFilter}
    />
  );
};

export default NewsList;
