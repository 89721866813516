import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import { useLazyQuery } from '@apollo/react-hooks'
import { getFAQByIdQuery } from '../api/_queries'
import multivalues from '../settings/multivalues.json'
import Authenticated from '../containers/Authenticated'
import LanguageSwitch from '../components/LanguageSwitch'
import FAQForm from '../forms/FAQForm'

const FAQEdit = ({ match: { params: { id } } }) => {

  const [languageIndex, setLanguageIndex] = React.useState(0)
  const [runFAQQuery, { data: { getFAQById: data } = {}, loading, error }] = useLazyQuery(getFAQByIdQuery)

  useEffect(() => {
    runFAQQuery({
      variables: {
        id: id
      }
    })
  }, [id])


  return (
    <Authenticated>
      <Layout title='Edit FAQ'>
        <Loader loading={loading} error={error}>
          {data &&
            <>
              <LanguageSwitch
                languageIndex={languageIndex}
                setLanguageIndex={setLanguageIndex}
                languages={multivalues.languages}
              />
              <FAQForm
                languageIndex={languageIndex}
                languagesTotal={Object.keys(multivalues.languages).length}
                data={data}
              />
            </>
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default FAQEdit
