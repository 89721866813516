import React from 'react'
import { Typography, Button } from '@material-ui/core'
import Modal from './Modal'

const CancelInvitation = ({
  id,
  name,
  callback,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleDelete = async () => {
    setLoading(true)
    try {
      callback(id, name)
      setIsOpen(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  return (
    <Modal
      trigger={<Button
        size='small'
        variant='contained'
        style={{ marginLeft: '10px' }}
        onClick={() => setIsOpen(true)}
      >Revoke invitation</Button>}
      isOpen={isOpen}
      title='Revoke invitation'
    >
      <Typography gutterBottom>
        Are you sure you want to revoke the invitation?
      </Typography>
      <Button
        variant='contained'
        color='primary'
        size='large'
        style={{ marginRight: '10px' }}
        onClick={handleDelete}
        loading={loading}
      >Revoke</Button>
      <Button
        variant='contained'
        size='large'
        onClick={() => setIsOpen()}
      >Cancel</Button>
    </Modal>
  )
}

export default CancelInvitation