import React, { useState, useMemo, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import history from '../history'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import { getLanguageQuery } from '../api'
import Pagination from '../components/Pagination'
import LanguageList from '../components/LanguageList'
import Authenticated from '../containers/Authenticated'
import LanguageFilterForm from '../forms/LanguageFilterForm'

const DynamicLanguage = () => {

    const [page, setPage] = React.useState(0)
    const [sortData, setSortData] = useState(['_id:desc']);
    const [filters, setFilters] = React.useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const parseFilters = useMemo(() => {
        const { keyword, isActive } = filters;

        if (keyword) {
            var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
                .replace(/\!/g, "\\\\!")
                .replace(/\@/g, "\\\\@")
                .replace(/\#/g, "\\\\#")
                .replace(/\$/g, "\\\\\\$")
                .replace(/\%/g, "\\\\%")
                .replace(/\^/g, "\\\\^")
                .replace(/\&/g, "\\\\&")
                .replace(/\*/g, "\\\\*")
                .replace(/\)/g, "\\\\)")
                .replace(/\(/g, "\\\\(")
                .replace(/\[/g, "\\\\[")
                .replace(/\]/g, "\\\\]")
                .replace(/\;/g, "\\\\;")
                .replace(/\{/g, "\\\\{")
                .replace(/\}/g, "\\\\}")
                .replace(/\?/g, "\\\\?")
                .replace(/\,/g, "\\\\,");
        }
        return {
            ...keyword && { langName_matches: searchKeyword },
            // ...createdAt && { createdAt: createdAt },
            ...isActive && (isActive === 'Active'
                ? { isActive: true }
                : isActive === 'Deactive'
                    ? { isActive: false } : {}
            ),
        };
    }, [filters]);

    const { data: { getLanguage: languageData = [], countLanguage: rowsInTotal } = {},
        loading, error, refetch } = useQuery(getLanguageQuery, {
            variables: {
                params: {
                    // authorDoesNotExists: true
                    sort: sortData,
                    limit: rowsPerPage,
                    skip: page * rowsPerPage,
                    ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
                        ? { where: parseFilters }
                        : {}
                    )
                },
            }
        })

    const handleRowPerChange = useCallback((value) => {
        setRowsPerPage(value)
    }, [])

    const handleClear = useCallback(() => {
        setFilters({});
        history.push("/language");
    }, [history]);

    const handleFiltersSubmit = useCallback((fields) => {
        setPage(0)
        setFilters({ ...fields })
    }, []);

    const sortFilter = useCallback((value, key) => {
        setSortData([`${value}:${key}`]);
    }, []);

    return (
        <Authenticated>
            <Layout
                title='Language'
                head={<LanguageFilterForm
                    handleClear={handleClear}
                    callback={handleFiltersSubmit}
                />}
            >
                <Loader loading={loading} error={error}>
                    <LanguageList
                        data={languageData}
                        sortFilter={sortFilter}
                    // deleteCallback={deleteLangCallback}
                    />
                    {rowsInTotal > rowsPerPage &&
                        <Pagination
                            page={page}
                            count={rowsInTotal}
                            rowsPerPage={rowsPerPage}
                            callback={newPage => setPage(newPage)}
                            handleRowPerChange={handleRowPerChange}
                        />
                    }
                </Loader>
            </Layout>
        </Authenticated>
    )
}

export default DynamicLanguage
