import React from "react";
import Table from "../Table";
import { Link } from "react-router-dom";
import ConfirmDelete from "../ConfirmDelete";
import { Button, ButtonGroup } from "@material-ui/core";
// import Form from "react-standalone-form";
// import { Select } from "react-standalone-form";

const FAQList = ({ data, deleteCallback, sortFilter }) => {
  return (
    <Table
      data={data?.map(item => ({
        question: item?.question && Array.isArray(item.question)
          ? item.question.filter((ele) => ele !== '').join(" | ")
          : item?.question || '',
        languages: item?.languages &&
          Array.isArray(item.languages) &&
          item.languages.join("|"),
        createdAt: item?.createdAt,
        actions:
          <div
            style={{ display: "flex", alignItems: "baseline" }}
            className="isActiveWrapper"
          >
            <ButtonGroup size='small' variant='text'>
              <Button
                component={Link}
                to={`/FAQ/${item._id}`}
              >
                Edit
              </Button>
              <ConfirmDelete
                id={item._id}
                label='faq'
                name={item?.question &&
                  Array.isArray(item.question)
                  ? item.question.join(" | ") : item?.question || ''}
                deleteCallback={deleteCallback}
              />
            </ButtonGroup>
          </div>,
      }))}
      labels={[
        {
          name: 'FAQ',
          field: 'question',
        },
        {
          name: "Lang",
          field: "languages"
        },
        {
          name: 'Created At',
          field: 'createdAt',
        },
        {
          name: 'Actions',
          field: 'actions',
        },
      ]}
      sortFilter={sortFilter}
    />
  );
};

export default FAQList;
