import React from 'react'
import ApolloCacheUpdater from 'apollo-cache-updater'
import { useSnackbar } from 'notistack'
import { Checkbox, Button } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import Pagination from '../components/Pagination'
import ArchivedResourceList from '../components/ArchivedResourceList'
import { getArchivedResourcesQuery, deleteArchivedResourceMutation, countArchivedResourcesQuery } from '../api'
import RESOURCE_STATUS from '../settings/enums/resource-status.json'
import history from '../history'

const STATUS = Object.keys(RESOURCE_STATUS).reduce((obj, status) => ({
  ...obj,
  [status]: status,
}), {})

const ArchivedResources = () => {
  const [page, setPage] = React.useState(0) // Page numeration starts at 0.
  const rowsPerPage = 10
  const [checkBoxFilters, setCheckboxFilters] = React.useState({
    status_pending: false,
    status_approved: false,
    status_rejected: false,
  });

  const [filters, setFilters] = React.useState({
    status: [],
    category: []
  })
  const { enqueueSnackbar } = useSnackbar()
  const [ deleteResource] = useMutation(deleteArchivedResourceMutation)
  const { data: { getArchivedResources: data = [], countArchivedResources = 1, loading, error } = {} } = useQuery(getArchivedResourcesQuery, {
    variables: {
      params: {
        sort: ['createdAt:desc'],
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        where: {
          ...filters.status.length && { status_in: filters.status },
          ...filters.category.length && { category_in: filters.category },
        }
      },
    }
  })

  const handleChange = (event) => {
    const newCheckbox = { ...checkBoxFilters, [event.target.name]: event.target.checked }
    setCheckboxFilters(newCheckbox);
    setFilters({
      ...filters,
      status: Object.entries(newCheckbox).filter(([k, v]) => k.includes('status') && !!v).map(([status]) => status.split('_')[1]),
      category: Object.entries(newCheckbox).filter(([k, v]) => k.includes('category') && !!v).map(([status]) => status.split('_')[1])
    })
  };

  const deleteResourceCallback = (payload) => {
    const { id, action } = payload || {}
    if (!id && !action) return null

    let status="";
    let hasToBeDeleted = false
    switch (action?.toLowerCase()) {
      case 'approve':
        status = STATUS.approved
        break;
      case 'reject':
        status = STATUS.rejected
        break;
      case 'delete':
        hasToBeDeleted = true
        break;
      default:
        break;
    }
    if (hasToBeDeleted) {
      return deleteResource({
        variables: {
          _id: id,
        },
        update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getArchivedResourcesQuery, countArchivedResourcesQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        if (update) enqueueSnackbar('The resource has been deleted.', { variant: 'success' })
      }
      })
    }

  }
  const goToResources = () => {
    history.push('/resources')
  }
  const rowsInTotal = countArchivedResources
  return (
    <Authenticated>
      <Layout title='Resources deleted by Eso after being reviewed by the Bank'>
        <Loader loading={loading} error={error}>
          <div style={{ fontWeight: 'bold' }}>Status before deletion:</div>
          <div style={{ display: 'flex'}}>
            <div style={{ marginLeft: 20 }}>Pending<Checkbox checked={checkBoxFilters.status_pending} onChange={handleChange} name="status_pending" /></div>
            <div style={{ marginLeft: 20 }}>Approved<Checkbox checked={checkBoxFilters.status_approved} onChange={handleChange} name="status_approved" /></div>
            <div style={{ marginLeft: 20 }}>Rejected<Checkbox checked={checkBoxFilters.status_rejected} onChange={handleChange} name="status_rejected" /></div>
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 30 }}>Category:</div>
          <div style={{ display: 'flex'}}>
            <div style={{ marginLeft: 20 }}>Business Resource<Checkbox checked={checkBoxFilters.category_business} onChange={handleChange} name="category_business" /></div>
            <div style={{ marginLeft: 20 }}>Funding Opportunity<Checkbox checked={checkBoxFilters.category_funding} onChange={handleChange} name="category_funding" /></div>
            <div style={{ marginLeft: 20 }}>New Product/Service<Checkbox checked={checkBoxFilters.category_product} onChange={handleChange} name="category_product" /></div>
          </div>
          <div>
            <Button variant="contained" color="primary" onClick={goToResources}>BACK</Button>
          </div>
          <div>
            <ArchivedResourceList
              data={data}
              callback={deleteResourceCallback}
            />
            <Pagination
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              callback={newPage => setPage(newPage)}
            />
            </div>
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default ArchivedResources
