import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Avatar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import initials from 'initials'


const User = ({ name, avatarUrl, big }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Avatar
        className={classNames([classes.avatar], { [classes.bigAvatar]: big })}
        src={avatarUrl}
      >
        {!avatarUrl && initials(name)}
      </Avatar>
      <Typography
        color='inherit'
        variant={big ? 'body2' : 'subheading'}
        className={classes.name}
      >{name}</Typography>
    </div>
  )
}

User.defaultProps = {
  big: false,
  avatarUrl: '',
}

User.propTypes = {
  name: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  big: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    color: theme.palette.grey[900],
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(),
    },
  },
  bigAvatar: {
    width: 56,
    height: 56,
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only('sm')]: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(2),
    }
  },
  name: {
    whiteSpace: 'nowrap',
  },
}))

export default User
