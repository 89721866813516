import React from 'react'

const Logo = () => (
  <img
    src='/logo-colour.png'
    srcSet='/logo-colour@2x.png 2x'
    alt='Ennova'
    height={40}
    title='Ennova'
  />
)

export default (Logo)
