import React from 'react'
import { Button, Tabs, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'


const LanguageSwitch = ({
  languageIndex,
  setLanguageIndex,
  languages,
  nativeLanguage,
  setNativeLangCallback,
}) => {
  const classes = useStyles()
  const languagesArray = Object.entries(languages)
  return (
    <>
      <Tabs
        value={languageIndex}
        onChange={(e, value) => {
          if (value !== languageIndex && window.confirm('You will loose all unsaved changes for the current language? Proceed?')) {
            setLanguageIndex(value)
          }
        }}
        className={classes.root}
        variant='scrollable'
      >
        {languagesArray.map(([key, value]) =>
          <Tab
            label={<div className={classes.label}>
              {key.toUpperCase()}
              {value === nativeLanguage
                ? ' - native'
                : setNativeLangCallback && value === languageIndex &&
                  <Button
                    size='small'
                    variant='outlined'
                    className={classes.button}
                    onClick={() => {
                      if (window.confirm('Are you sure that you want to set this language as native for this organization?')) {
                        setNativeLangCallback(value, key)
                      }
                    }}
                  >Set as native</Button>
              }
            </div>}
            value={value}
            key={key}
          />
        )}
      </Tabs>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
    borderBottomColor: theme.palette.grey[300],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginLeft: theme.spacing(1),
  }
}))

export default LanguageSwitch
