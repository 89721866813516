import React, { useCallback, useState } from 'react'
import * as Yup from 'yup'
import history from '../history'
import { toast } from "react-toastify";
import { useSnackbar } from 'notistack'
import { graphqlError } from '../utils'
import { Formik, Form, ErrorMessage } from 'formik'
import ImageUpload from './../components/ImageUpload'
import ApolloCacheUpdater from 'apollo-cache-updater'
import {
   Grid, Button, Checkbox, FormControl, FormControlLabel, FormHelperText,
   InputLabel, TextField, Typography, CircularProgress,
} from '@material-ui/core'
import { maxSize } from '../settings/max-file-size.json'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { saveCarouselMutation, _safeS3SignMutation } from '../api/_mutations'
import { getCarouselQuery, countCarouselQuery, _currentAdminQuery } from '../api'

const CarouselForm = (props) => {
   const { data, languageIndex } = props

   const { enqueueSnackbar } = useSnackbar()
   const [saveCarousel, { loading: carouselLoading }] = useMutation(saveCarouselMutation)
   const [safeS3Sign, { loading: s3Loading }] = useMutation(_safeS3SignMutation)
   const [loading, setLoading] = useState(false)

   const { data: { _currentAdmin: currentUser = { name: '' } } = {} } = useQuery(_currentAdminQuery)
   const isTranslatable = bool => languageIndex !== 0
      ? {
         disabled: !bool,
         helperText: bool
            ? "Enter translation for selected language."
            : "This field is not translatable."
      }
      : {};

   // validation schema
   const validationSchema = Yup.object().shape({
      image: Yup.mixed().test(
         'fileSize', 'Image file size must be less than 5MB', (value) => {
            if (typeof value === 'object') {
               return value.size <= 5242880;
            }
            return true;
         }).required('Image file is required'),
      title: Yup.array().of(Yup.string()).test('title-ele',
         'This field is required',
         function (value) {
            if (value[languageIndex]) {
               if (value[languageIndex].length < 10) {
                  return this.createError({ message: 'Title must be at least 10 characters' });
               } else if (value[languageIndex].length > 45) {
                  return this.createError({ message: 'Title must be at most 45 characters' });
               } else {
                  return true
               }
            } else return false
         }),
      btnTitle: Yup.array().of(Yup.string()).test('Button Title',
         'This field is required',
         function (value) {
            if (value[languageIndex]) {
               if (value[languageIndex].length > 20) {
                  return this.createError({ message: 'Button Title must be at most 20 characters' });
               } else {
                  return true
               }
            } else return false
         }),
      content: Yup.array().of(Yup.string()).test('content-ele',
         'This field is required',
         function (value) {
            if (value[languageIndex]) {
               if (value[languageIndex].length < 50) {
                  return this.createError({ message: 'Content must be at least 50 characters' });
               } else if (value[languageIndex].length > 200) {
                  return this.createError({ message: 'Content must be at most 200 characters' });
               } else {
                  return true
               }
            } else return false
         }),
      url: Yup.string().matches(
         /^(https?:\/\/)/,
         'URL should start with http:// or https://'
      ).matches(
         /^(https|http):\/\/?[a-z0-9]/,
         'This is not a valid URL.'
      ).required('This field is required.'),
   })

   const handleSubmit = useCallback(async (fields) => {
      const { isActive, title, content, url, image, btnTitle } = fields;
      const dataObject = {
         isActive,
         image,
         url,
         title,
         content,
         btnTitle,
         createdBy: currentUser.name
      }

      if (image) {
         const file = image
         let attachmentUrl = file || null
         if (attachmentUrl) {
            if (typeof attachmentUrl === 'string') {
               dataObject.image = attachmentUrl
            } else {
               const { dataFile, name: filename } = file
               const { size } = dataFile
               if (size > maxSize) {
                  return null
               }

               try {
                  setLoading(true)
                  const response = await safeS3Sign({
                     variables: {
                        filename,
                        filetype: file.type,
                     },
                  })
                  const { signedRequest, url: bucketUrl } = response.data._safeSignS3
                  const options = {
                     headers: {
                        'Content-Type': file.type,
                     },
                  }
                  try {
                     await fetch(signedRequest, {
                        method: 'PUT',
                        headers: options.headers,
                        body: dataFile,
                     })
                     attachmentUrl = bucketUrl
                     setLoading(false)
                  } catch (e) {
                     attachmentUrl = null
                     console.error(e)
                     setLoading(false)
                  }
               } catch (e) {
                  const errMsg = graphqlError(e, {
                     // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
                     // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
                  })
                  if (errMsg) {
                     toast.error(errMsg)
                  } else {
                     toast.error('Cannot uplaod file(s)')
                     console.error(e)
                  }
               }
               dataObject.image = attachmentUrl
            }
         }
      }

      try {
         saveCarousel({
            variables: {
               data: {
                  ...(data && data._id) ? { id: data._id } : {},
                  ...dataObject
               }
            },
            update: (proxy, { data: { saveCarousel = {} } }) => {
               if (!data || !data._id) {
                  const mutationResult = saveCarousel
                  ApolloCacheUpdater({
                     proxy,
                     ID: '_id',
                     operator: 'ANY',
                     searchVariables: { skip: 0 },
                     queriesToUpdate: [getCarouselQuery, countCarouselQuery],
                     mutationResult: { ...mutationResult },
                  })
               }
            },
         }).then(() => {
            enqueueSnackbar('The carousel has been saved.', { variant: 'success' })
            history.replace('/carousel')
         }).catch(e => {
            console.error(e)
            enqueueSnackbar('There was an error saving the carousel', { variant: 'error' })
         })
      } catch (error) {
         console.error(error);
         enqueueSnackbar('Could not save the carousel.', { variant: 'error' })
      }
   })

   return (
      <Formik
         enableReinitialize
         initialValues={{
            url: data?.url ? data.url : '',
            image: data?.image ? data.image : '',
            isActive: data?.isActive ? data.isActive : false,
            title: data?.title ? data.title : [],
            content: data?.content ? data.content : [],
            btnTitle: data?.btnTitle ? data.btnTitle : [],
         }}
         onSubmit={handleSubmit}
         validationSchema={validationSchema}
      >
         {({ values, handleChange, setFieldValue }) => {
            return <Form>
               <Grid container spacing={6} justifyContent='space-between'>
                  <Grid item md={6} lg={4} container direction='column' alignItems='flex-start'>
                     <FormControl fullWidth>
                        <Typography variant="subtitle1" style={{ color: "#000000", opacity: "0.5" }}>
                           Image<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <ImageUpload
                           name='image'
                           value={values?.image}
                           setFieldValue={setFieldValue}
                        />
                        <ErrorMessage name='image' >
                           {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                        </ErrorMessage>
                     </FormControl>
                     <FormControlLabel
                        control={
                           <Checkbox
                              name="isActive"
                              checked={values?.isActive}
                              {...isTranslatable(false)}
                           />
                        }
                        onChange={handleChange}
                        value={values?.isActive || false}
                        label="Active"
                     />
                     {isTranslatable(false)?.helperText && <FormHelperText>{isTranslatable(false).helperText}</FormHelperText>}
                  </Grid>
                  <Grid item md={6} lg={7} container direction='column' alignItems='flex-start'>
                     <InputLabel>Title <span style={{ color: 'red' }}>*</span></InputLabel>
                     <TextField
                        fullWidth
                        type="text"
                        name={`title[${languageIndex}]`}
                        onChange={handleChange}
                        value={(values?.title && Array.isArray(values.title)
                           && values.title[languageIndex]) || ''}
                        {...isTranslatable(true)}
                     />
                     <ErrorMessage name='title' >
                        {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                     </ErrorMessage>

                     <InputLabel>Content <span style={{ color: 'red' }}>*</span></InputLabel>
                     <TextField
                        fullWidth
                        multiline
                        minRows={5}
                        type="text"
                        name={`content[${languageIndex}]`}
                        onChange={handleChange}
                        value={(values?.content && Array.isArray(values.content)
                           && values.content[languageIndex]) || ''}
                        {...isTranslatable(true)}
                     />
                     <ErrorMessage name='content' >
                        {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                     </ErrorMessage>

                     <InputLabel>Button Title <span style={{ color: 'red' }}>*</span></InputLabel>
                     <TextField
                        fullWidth
                        type="text"
                        name={`btnTitle[${languageIndex}]`}
                        onChange={handleChange}
                        value={(values?.btnTitle && Array.isArray(values.btnTitle)
                           && values.btnTitle[languageIndex]) || ''}
                        {...isTranslatable(true)}
                     />
                     <ErrorMessage name='btnTitle'>
                        {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                     </ErrorMessage>

                     <InputLabel>URL <span style={{ color: 'red' }}>*</span></InputLabel>
                     <TextField
                        fullWidth
                        type="text"
                        name={`url`}
                        onChange={handleChange}
                        value={values?.url || ''}
                        {...isTranslatable(true)}
                     />
                     <ErrorMessage name='url'>
                        {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                     </ErrorMessage>
                  </Grid>
               </Grid>
               <Grid container spacing={4} justifyContent="flex-end">
                  <Grid container item sm={2} xs={3} >
                     <Button style={{ width: '100%' }} variant="contained" color="inherit"
                        onClick={() => history.goBack()}
                     >
                        Cancel
                     </Button>
                  </Grid>
                  <Grid container item sm={2} xs={3} >
                     <Button style={{ width: '100%' }} variant="contained" color='primary'
                        type={loading || s3Loading || carouselLoading ? 'button' : 'submit'}
                     >
                        {(loading || s3Loading || carouselLoading) &&
                           <CircularProgress color='secondary' size={28} style={{ padding: '2px' }} />
                        } Save
                     </Button>
                  </Grid>
               </Grid>
            </Form>
         }}
      </Formik>
   )
}

export default CarouselForm
