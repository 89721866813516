import React from 'react'
import * as Yup from 'yup'
import router from '../history'
import { useAuth } from '../utils'
import { ErrorMessage, Form, Formik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, TextField, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}))

const LoginForm = () => {
  const auth = useAuth();
  const classes = useStyles()

  const handleLogIn = fields => {
    auth.login({
      ...fields
    }, (e) => {
      if (e) {
        // toast.dismiss()
        // toast.error("Could not log in")
        // toast.dismiss()
        // enqueueSnackbar('Could not log in.', { variant: 'error' })
      } else {
        // toast.dismiss()
        // toast.success("Login successful")
        // toast.dismiss()

        // enqueueSnackbar('Login successful', { variant: 'success' })
        router.replace('/organizations')
      }
    })
  }

  let validationSchema = Yup.object().shape({
    username: Yup.string().min(1).required("This field is required."),
    password: Yup.string().min(1).required("This field is required."),
  })

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleLogIn(values);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <>
          <Form className={classes.root}>
            <Typography
              variant='h5'
              align='center'
              className={classes.heading}
            >ENNOVA Admin</Typography>
            <FormControl >
              <TextField
                name='username'
                label={<span>Username <span style={{ color: 'red' }}>*</span></span>}
                type='text'
                onChange={handleChange}
              />
              <ErrorMessage name='username'>{msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>

            </FormControl>
            <FormControl >
              <TextField
                name='password'
                label={<span>Password <span style={{ color: 'red' }}>*</span></span>}
                type='password'
                onChange={handleChange}
              />
              <ErrorMessage name='password'>{msg => <div className='form-error' style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>

            </FormControl>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={(val) => handleSubmit(val)}
              style={{ marginTop: 40 }}
            >Log in</Button>
          </Form>
        </>)}
    </Formik>
  )
}

export default LoginForm
