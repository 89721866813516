import React, { useEffect } from 'react';
import {
  RangeDatePicker,
} from "react-google-flight-datepicker";
import { useSnackbar } from 'notistack'
import "react-google-flight-datepicker/dist/main.css";
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Tooltip, Button, TextField, Badge, Select, MenuItem, Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TOC from '../settings/enums/toc-pillar-indicators.json'
import { addCustomIndicatorMutation, deleteCustomIndicatorMutation } from '../api/_mutations';
import { getCustomIndicatorsQuery } from '../api';
import ConfirmDelete from './ConfirmDelete'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(pillar, indicator, value, outcome) {
  return { pillar, indicator, value, outcome };
}

export default ({ data = {}, customIndicators = [], setFilteredRows = () => { }, pillarObj = {} }) => {
  const rows = Object.entries(data).filter(([key]) => key !== '__typename').reduce((arr, [key, value]) => [
    ...arr,
    ...Object.entries(value).filter(([key]) => key !== '__typename').reduce((a, [k, v]) => [
      ...a,
      createData(TOC[key].label, TOC[key].indicators[k].label, v, TOC[key].indicators[k].outcome)
    ], [])
  ], [])
  return <SimpleTable rows={rows} customIndicators={customIndicators} setFilteredRows={setFilteredRows} pillarObj={pillarObj} />
}

const SimpleTable = ({ rows = [], customIndicators = [], setFilteredRows = () => { }, pillarObj = {} }) => {
  const classes = useStyles();

  useEffect(() => {
    let filteredRows = rows.filter(row => {
      return row.value > 0
    })

    customIndicators.forEach(customIndicator => {
      let pillar = "";
      switch (customIndicator.pillar) {
        case "knowledge":
          pillar = pillarObj.knowledge.label;
          break;
        case "market":
          pillar = pillarObj.market.label;
          break;
        case "capacity":
          pillar = pillarObj.capacity.label;
          break;
        case "financial":
          pillar = pillarObj.financial.label;
          break;

        default:
          break;
      }

      const row = {
        pillar: pillar,
        indicator: customIndicator.name,
        value: customIndicator.value,
        outcome: customIndicator.outcome,
      }

      filteredRows.push(row)
    })

    setFilteredRows(filteredRows);
  }, [rows, setFilteredRows])

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>TOC Pillar</TableCell>
              <TableCell align="left">Indicator</TableCell>
              <TableCell align="center">Value</TableCell>
              <TableCell align="right">Outcome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.filter((row) => !!row.value).map((row) => (
              <TableRow key={row.indicator}>
                <TableCell component="th" scope="row">
                  {row.pillar}
                </TableCell>
                <TableCell align="left">{row.indicator}</TableCell>
                <TableCell align="center">{row.value}</TableCell>
                <TableCell align="right">{row.outcome}</TableCell>
              </TableRow>
            ))}
            <CustomIndicators rows={customIndicators} classes={classes} />
          </TableBody>
        </Table>
      </TableContainer>
      <CustomIndicatorForm rows={rows} classes={classes} />
    </>
  );
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: -5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const CustomIndicators = ({ rows = [], classes }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [deleteIndicator] = useMutation(deleteCustomIndicatorMutation)

  const removeIndicator = (id) => {
    deleteIndicator({
      variables: {
        id
      },
      refetchQueries: [
        { query: getCustomIndicatorsQuery }
      ]
    }).then(() => enqueueSnackbar('Indicator removed', { variant: 'success' }))
  }

  return (
    rows.filter((row) => !!row._id).map((row, key) => (
      <TableRow style={{ position: 'relative' }} key={row._id}>
        <TableCell component="th" scope="row">
          {TOC[row.pillar].label}
        </TableCell>
        <TableCell align="left"><Tooltip title={`[from] ${new Date(row.startDate).toDateString()} [to] ${new Date(row.endDate).toDateString()}`}><span style={{ cursor: 'pointer' }}>{row.name}</span></Tooltip></TableCell>
        <TableCell align="right">
          <StyledBadge badgeContent="custom" overlap='rectangular' color="primary">{row.value}</StyledBadge></TableCell>
        <TableCell align="right">
          <ConfirmDelete
            id={row._id}
            name={row.outcome}
            deleteCallback={() => removeIndicator(row._id)}
            customMessage={`Are you sure you want to delete "${row.name}"`}
          />
          {row.outcome}
        </TableCell>
      </TableRow>
    ))
    //     </TableBody>
    //   </Table>
    // </TableContainer>
  )
}

const CustomIndicatorForm = ({ rows, classes }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [addIndicator] = useMutation(addCustomIndicatorMutation)
  const tocOptions = Object.entries(TOC).map(([k, v]) => ({
    value: k,
    label: v.label
  }))
  const outcomeOptions = rows.reduce((arr, row) => {
    if (arr.find(o => o.value === row.outcome)) return arr
    return [
      ...arr,
      {
        value: row.outcome,
        label: row.outcome
      }
    ]
  }, [])

  const defaultValues = {
    pillar: tocOptions[0].value,
    outcome: outcomeOptions[0].value,
    name: '',
    value: 0,
    startDate: null,
    endDate: null
  }

  const [newIndicator, setNewIndicator] = React.useState(defaultValues)

  const handleChange = (obj) => {
    setNewIndicator({
      ...newIndicator,
      ...obj
    })
  }

  const addNewIndicator = () => {
    addIndicator({
      variables: {
        input: newIndicator
      },
      refetchQueries: [
        { query: getCustomIndicatorsQuery }
      ]
    }).then(() => {
      setNewIndicator(defaultValues)
      enqueueSnackbar('New indicator added', { variant: 'success' })
    })
  }

  const resetIndicator = () => {
    setNewIndicator(defaultValues)
  }


  const disableAddingIndicator = Object.values(newIndicator).filter(value => !value).length > 0

  return (
    <div style={{ marginTop: 20, padding: '20px 10px', borderRadius: 10, backgroundColor: '#F5F5F5' }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow key={11}>
              <TableCell component="th" scope="row">
                <Box style={{ minWidth: 120 }}>
                  <Select
                    labelId="toc-pillar-label"
                    id="toc-pillar-select"
                    value={newIndicator.pillar}
                    onChange={(e) => {
                      handleChange({
                        pillar: e.target.value
                      })
                    }}
                  >
                    {tocOptions.map(toc =>
                      <MenuItem key={toc.label} value={toc.value}>{toc.label}</MenuItem>
                    )}
                  </Select>
                </Box>
              </TableCell>
              <TableCell align="left">
                <TextField id="indicator-name" label="New Indicator name" value={newIndicator.name} onChange={(e) => {
                  handleChange({
                    name: e.target.value
                  })
                }} />
              </TableCell>
              <TableCell align="center">
                <TextField type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }} label="Value (number)" value={newIndicator.value} onChange={(e) => {
                  handleChange({
                    value: parseInt(e.target.value, 10)
                  })
                }} />
              </TableCell>
              <TableCell align="right">
                <Box style={{ minWidth: 120 }}>
                  <Select
                    labelId="outcome-label"
                    id="outcome-select"
                    value={newIndicator.outcome}
                    onChange={(e) => {
                      handleChange({
                        outcome: e.target.value
                      })
                    }}
                  >
                    {
                      outcomeOptions.map(outcome => <MenuItem key={outcome.label} value={outcome.value}>{outcome.label}</MenuItem>)
                    }

                  </Select>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', marginTop: "2%" }}>
        <div style={{ width: "60%" }}>
          <RangeDatePicker
            startDate={newIndicator.startDate || new Date()}
            endDate={newIndicator.endDate || new Date()}
            startDatePlaceholder="From"
            endDatePlaceholder="To"
            onChange={(startDate, endDate) => handleChange({
              startDate,
              endDate
            })}
          />
        </div>
        <div style={{ marginLeft: "2%", display: 'flex', alignItems: 'center', width: "15%", justifyContent: "space-between" }}>
          <div>
          </div>
          <Button variant="text" onClick={resetIndicator}>RESET</Button>
          <div>
          </div>
          <Button color='primary' variant="contained" onClick={addNewIndicator} disabled={disableAddingIndicator}>ADD</Button>
        </div>
      </div>
    </div>
  )
}
