import React from 'react'
import history from '../history'
import { useSnackbar } from 'notistack'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import Pagination from '../components/Pagination'
import ResourceList from '../components/ResourceList'
import ApolloCacheUpdater from 'apollo-cache-updater'
import { Checkbox, Button } from '@material-ui/core';
import ResourceFilter from '../forms/ResourceFilter';
import Authenticated from '../containers/Authenticated'
import { useQuery, useMutation } from '@apollo/react-hooks'
import RESOURCE_STATUS from '../settings/enums/resource-status.json'
import { getResourcesQuery, saveResourceMutation, deleteResourceMutation, countResourcesQuery } from '../api'

const STATUS = Object.keys(RESOURCE_STATUS).reduce((obj, status) => ({
  ...obj,
  [status]: status,
}), {})

const Resources = () => {
  const [page, setPage] = React.useState(0) // Page numeration starts at 0.
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [checkBoxFilters, setCheckboxFilters] = React.useState({
    status_pending: false,
    status_approved: false,
    status_rejected: false,
  });

  const [filters, setFilters] = React.useState({
    status: [],
    category: []
  })
  const [sortData, setSortData] = React.useState(['_id:desc']);
  const { enqueueSnackbar } = useSnackbar()
  const [updateResource] = useMutation(saveResourceMutation)
  const [deleteResource] = useMutation(deleteResourceMutation)
  if (filters.keyword) {
    var searchKeyword = filters?.keyword.replace(/\\/g, "\\\\\\\\")
    .replace(/\!/g, "\\\\!")
    .replace(/\@/g, "\\\\@")
    .replace(/\#/g, "\\\\#")
    .replace(/\$/g, "\\\\\\$")
    .replace(/\%/g, "\\\\%")
    .replace(/\^/g, "\\\\^")
    .replace(/\&/g, "\\\\&")
    .replace(/\*/g, "\\\\*")
    .replace(/\)/g, "\\\\)")
    .replace(/\(/g, "\\\\(")
    .replace(/\[/g, "\\\\[")
    .replace(/\]/g, "\\\\]")
    .replace(/\;/g, "\\\\;")
    .replace(/\{/g, "\\\\{")
    .replace(/\}/g, "\\\\}")
    .replace(/\?/g, "\\\\?")
    .replace(/\,/g, "\\\\,");
  }
  const { data: { getResources: data = [], countResources = 1 } = {}, loading, error } = useQuery(getResourcesQuery, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        where: {
          ...filters.status.length && { status_in: filters.status },
          ...filters.category.length && { category_in: filters.category },
          ...filters?.keyword && filters?.keyword?.length && { name_matches: filters?.keyword },
          ...filters?.countryCode && filters?.countryCode?.length && { country: filters?.countryCode }
        }
      },
    }
  })



  const handleChange = (event) => {
    const newCheckbox = { ...checkBoxFilters, [event.target.name]: event.target.checked }
    setCheckboxFilters(newCheckbox);
    setFilters({
      ...filters,
      status: Object.entries(newCheckbox).filter(([k, v]) => k.includes('status') && !!v).map(([status]) => status.split('_')[1]),
      category: Object.entries(newCheckbox).filter(([k, v]) => k.includes('category') && !!v).map(([status]) => status.split('_')[1])
    })
  };

  const deleteResourceCallback = (payload) => {
    const { id, action, name } = payload || {}
    if (!id && !action) return null

    let status
    let hasToBeDeleted = false
    switch (action?.toLowerCase()) {
      case 'approve':
        status = STATUS.approved
        break;
      case 'reject':
        status = STATUS.rejected
        break;
      case 'delete':
        hasToBeDeleted = true
        break;
      default:
        break;
    }
    if (hasToBeDeleted) {
      return deleteResource({
        variables: {
          _id: id,
        },
        update: (proxy) => {
          const update = ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getResourcesQuery, countResourcesQuery], // queries you want to automatically update
            searchVariables: {},
            operator: 'ANY',
            operation: 'REMOVE',
            mutationResult: { _id: id },
            ID: '_id',
          })
          if (update) enqueueSnackbar('The resource has been deleted.', { variant: 'success' })
        }
      })
    }

    updateResource({
      variables: {
        data: {
          _id: id,
          name,
          status
        }
      },
    })
      .then(() => { })
      .catch((e) => {
        enqueueSnackbar('Cannot update resource.', { variant: 'error' })
        console.log(e);
      })
  }
  const goToArchive = () => {
    history.push('/archived-resources')
  }

  const handleFiltersSubmit = fields => {
    setPage(0)
    setFilters({ ...filters, ...fields })
  };

  const handleClear = filters => {
    setFilters({
      status: [],
      category: []
    })
    history.push(`/resources`)
  };

  const rowsInTotal = countResources
  const handleRowPerChange = (value) => {
    setRowsPerPage(value)

  }

  const sortFilter = (value, key) => {
    setSortData([`${value}:${key}`]);
  };

  return (
    <Authenticated>
      <Layout title='Resources'
        head={
          <ResourceFilter
            filters={filters}
            callback={handleFiltersSubmit}
            data={data}
            handleClear={handleClear}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <div style={{ fontWeight: 'bold' }}>Status:</div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 20 }}>Pending<Checkbox checked={checkBoxFilters.status_pending} onChange={handleChange} name="status_pending" /></div>
            <div style={{ marginLeft: 20 }}>Approved<Checkbox checked={checkBoxFilters.status_approved} onChange={handleChange} name="status_approved" /></div>
            <div style={{ marginLeft: 20 }}>Rejected<Checkbox checked={checkBoxFilters.status_rejected} onChange={handleChange} name="status_rejected" /></div>
          </div>
          <div style={{ fontWeight: 'bold', marginTop: 30 }}>Category:</div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 20 }}>Business Resource<Checkbox checked={checkBoxFilters.category_business} onChange={handleChange} name="category_business" /></div>
            <div style={{ marginLeft: 20 }}>Funding Opportunity<Checkbox checked={checkBoxFilters.category_funding} onChange={handleChange} name="category_funding" /></div>
            <div style={{ marginLeft: 20 }}>New Product/Service<Checkbox checked={checkBoxFilters.category_product} onChange={handleChange} name="category_product" /></div>
          </div>
          <div style={{ padding: "25px 0" }}>
            <Button variant="contained" color="primary" onClick={goToArchive}>GO TO ARCHIVED RESULTS</Button>
          </div>
          <div>
            <ResourceList
              data={data}
              callback={deleteResourceCallback}
              sortFilter={sortFilter}
            />
            <Pagination
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              handleRowPerChange={handleRowPerChange}
              callback={newPage => setPage(newPage)}
            />
          </div>
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default Resources
