import React, { useCallback } from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { Formik, Form, ErrorMessage } from 'formik'
import ApolloCacheUpdater from 'apollo-cache-updater'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Button, CardContent, FormHelperText, CircularProgress,
  Grid, TextField, CardActions, FormControlLabel, Checkbox, InputLabel
} from '@material-ui/core'
import history from '../history'
import {
  _currentAdminQuery, countLanguageQuery,
  getLanguageQuery, saveLanguageMutation
} from '../api'
import { CSVLink } from "react-csv";
import './DynamicLangForm.css'

const samplecsvData = [
  [
    "Tags",
    "Translation"
  ],
  ['Support organisations', ''],
  ['About', ''],
  ['Country', ''],
  ['Enabling youth entrepreneurship in Africa', ''],
  [
    'Access tailored opportunities, resources and networks to take your business to the next level.',
    ''
  ],
  ['FIND SUPPORT ORGANISATIONS', ''],
  ['Support provided', ''],
  ['Support organisations can help you in many different ways', ''],
  ['Support organisations near you', ''],
  ['FIND OUT MORE', ''],
  ['Ennova', ''],
  [
    "Ennova is an initiative of the African Development Bank's Jobs for Youth in Africa Strategy to improve inclusive economic development and to accelerate investment in African youth. The platform aims to connect African entrepreneurs with the right support organisations they need to build stronger and more sustainable businesses that create jobs for young people.",
    ''
  ],
  ['KEY LINKS:', ''],
  ['privacy policy', ''],
  ['Help', ''],
  ['Contact us', ''],
  ['Sign up for updates', ''],
  ['SUBSCRIBE', ''],
  ['Business stages', ''],
  ['Sectors', ''],
  ['SERVICES', ''],
  ['Services offered', ''],
  ['Services of interest', ''],
  ['Sectors of interest', ''],
  ['What services are you interested in?', ''],
  ['categories', ''],
  ['Sector', ''],
  ['Sectors of interest', ''],
  ['Subcategories', ''],
  ['Stages', ''],
  ['Business stages', ''],
  ['Target stages', ''],
  ['FIND', ''],
  ['Keyword', ''],
  ['APPLY', ''],
  ['APPLY', ''],
  ['Save your search and more!', ''],
  [
    'Sign-up to find tailored opportunities, resources and networks',
    ''
  ],
  ['Sign up', ''],
  ['Sign up later', ''],
  ['Address', ''],
  ['Phone number', ''],
  ['Website and e-mail', ''],
  ['Contact', ''],
  ['Name', ''],
  ['E-mail', ''],
  ['Message', ''],
  [
    'I agree with terms and conditions. You can check the data privacy',
    ''
  ],
  ['SEND', ''],
  ['About us', ''],
  ['Overview', ''],
  ['Vision and mission', ''],
  ['Entrepreneurs', ''],
  ['Courses', ''],
  ['Incubator', ''],
  ['Sign in', ''],
  ['Forgot password?', ''],
  ['Sign out', ''],
  ["Don't have an account yet?", ''],
  ['Create it here', ''],
  ['Are you registered?', ''],
  ['Sign in here', ''],
  [
    'Sign-up to find tailored opportunities, resources and networks',
    ''
  ],
  [
    'Enter your e-mail so we can send you the password reset link.',
    ''
  ],
  ['Send a link', ''],
  ['Create your account', ''],
  ['Reset password', ''],
  ['Password', ''],
  ['GET STARTED', ''],
  ['Accelerator', ''],
  ['Co-working space', ''],
  ['Virtual platform', ''],
  ['Funding', ''],
  ['Business advice', ''],
  ['Networking', ''],
  ['Mentoring', ''],
  ['This platform is supported by the', ''],
  ['African Development Bank', ''],
  ['GDPR Policy', ''],
  ['Cookies Policy', ''],
  ['Terms & Conditions', ''],
  ['Phone number with country code', ''],
  ['Contact us for more information', ''],
  ['Where are you based?', ''],
  ['What is the stage of your business?', ''],
  ['Which services are you interested in?', ''],
  ['Could not send a form.', ''],
  [
    'Thank you for leaving a message! We will contact you shortly.',
    ''
  ],
  ['Thank you for leaving a message!', ''],
  ['There are no results that meet the searching criteria.', ''],
  ['City', ''],
  ['ZIP-Code', ''],
  ['Location', ''],
  ['Contact email', ''],
  ['Telephone', ''],
  ['Contact person', ''],
  ['Description', ''],
  ["Organisation's logo", ''],
  ['Describe your organisation', ''],
  ['Describe your business', ''],
  ['Save', ''],
  ['LISTING', ''],
  ['MAP', ''],
  ['Primary name', ''],
  ['Publication', ''],
  ['EDIT', ''],
  ['PUBLISH', ''],
  ['UNPUBLISH', ''],
  ['Your profile have been published', ''],
  ['Your profile is not public anymore', ''],
  ['About Ennova', ''],
  ['Account settings', ''],
  ['Change email address:', ''],
  ['Current email', ''],
  ['New email', ''],
  ['Change', ''],
  ['Change password', ''],
  ['Current password', ''],
  ['New password', ''],
  [
    'Password should be 8 chars long, contain a capital letter, a digit and a special character.',
    ''
  ],
  ['Profile saved', ''],
  ['Email changed to:', ''],
  ['Password changed', ''],
  ['This email is already in use', ''],
  ['The password is wrong', ''],
  ['You need to fill all password fields', ''],
  ['Language changed', ''],
  ['Next', ''],
  ['Back', ''],
  ['Complete', ''],
  ['This email is already in use', ''],
  ['Language', ''],
  ['Confirm your language', ''],
  ['Dashboard', ''],
  ['Newsfeed', ''],
  ['Sectors I am interested in', ''],
  ['What sectors are you interested in?', ''],
  ['Your name', ''],
  ['Profile image', ''],
  ['Current position', ''],
  ['Messages', ''],
  ['New message', ''],
  ['Back', ''],
  ['Send message', ''],
  ['All', ''],
  ['Support organisations', ''],
  ['Entrepreneurs', ''],
  ['Invitations', ''],
  ['New Message', ''],
  ['To:', ''],
  ['Type a name', ''],
  ['Reply to...', ''],
  ['Compose a new message...', ''],
  ['Attach a file:', ''],
  ['File is too big', ''],
  ['Cannot upload the file', ''],
  ['Could not search the users.', ''],
  ['Send a message', ''],
  ['Remove', ''],
  ['Are you sure you want to remove ', ''],
  ['Reject', ''],
  ['Are you sure you want to dismiss ', ''],
  ['Dismiss', ''],
  ['Accept', ''],
  ['Message sent!', ''],
  ['will be removed from the organisation. Are you sure?', ''],
  ['Resource Centre', ''],
  ['My network', ''],
  ['posted', ''],
  ['Filter', ''],
  ['Courses', ''],
  ['All Courses', ''],
  ['Events', ''],
  ['All events', ''],
  ['Please choose a country', ''],
  ['Company saved', ''],
  ['BACK TO THE LISTING', ''],
  ['here', ''],
  ['There are currently no events that may interest you.', ''],
  ['There are currently no news that may interest you.', ''],
  ['No recommendations available.', ''],
  ['Recommendations', ''],
  ['Delete', ''],
  ['Place of residence:', ''],
  ['My profile', ''],
  ['Create new', ''],
  ['Services I am interested in', ''],
  ['What content do you wish to share?', ''],
  ['Add', ''],
  ['Have something to share?', ''],
  ['Add news/event', ''],
  ['Comments', ''],
  ['Load more', ''],
  ['No comments added yet.', ''],
  ['Loading...', ''],
  ['Type of services:', ''],
  ['Unknown', ''],
  ['SHOW ON MAP', ''],
  ['Currently no one is here.', ''],
  ['Sectors', ''],
  ['for more information', ''],
  ['There is no one in your network', ''],
  ['Previous', ''],
  ['My contacts', ''],
  ['members', ''],
  ['Organisation', ''],
  ['Organisation type', ''],
  ['Business', ''],
  ['Search', ''],
  ['Show:', ''],
  [
    "Ennova is a flagship initiative of the African Development Bank's long-term strategy to improve inclusive economic development and to accelerate investment in the African Youth. Its vision is threefold:",
    ''
  ],
  [
    'To nurture a new generation of African innovators who launch the continent into an era of accelerated prosperity.',
    ''
  ],
  [
    'To leverage key relationships to foster a climate in which African entrepreneurs become engines of economic growth and job creation.',
    ''
  ],
  [
    'To empower young African entrepreneurs, and women in particular, to secure levels of funding similar to their peers in the industrialized world.',
    ''
  ],
  [
    'As part of the Lab, the Bank developed this AI-powered platform which aims at aggregating, connecting and enabling all actors in the African entrepreneurship ecosystem.',
    ''
  ],
  [
    'Aggregate: Access the largest up-to-date database of +1,000 support organisations and their services across the continent.',
    ''
  ],
  [
    'Connect: Network and message entrepreneurs, members of support organisations and other ecosystem actors.',
    ''
  ],
  [
    'Enable: Access funding opportunities and capacity building resources tailored to the needs of entrepreneurs and support organisations.',
    ''
  ],
  [
    'All maps on the Ennova website were prepared for use exclusively for this website. The names used and the borders shown do not imply on the part of the African Development Bank Group and its members any judgement concerning the legal status of a territory nor any approval or acceptance of these borders.',
    ''
  ],
  ['This platform is supported by the African Development Bank', ''],
  ['No description.', ''],
  ['Add description', ''],
  ['About me', ''],
  [
    "Currently you don't have any description. You can add it here.",
    ''
  ],
  ['My', ''],
  ['Add a new business', ''],
  ['This user is not associated with any business.', ''],
  ['Invite people', ''],
  ['Currently no one is here. Invite people to your network.', ''],
  ['Currently no one is here.', ''],
  ['Profile completeness:', ''],
  ['Complete', ''],
  ['Edit profile', ''],
  ['Send a new message:', ''],
  ['Invite to network:', ''],
  ['Invite', ''],
  ['Do you want to send an invitation to', ''],
  ['Edit my business', ''],
  ['Edit my organisation', ''],
  ['Edit my profile', ''],
  ['Primary info', ''],
  ['Add a short description about yourself.', ''],
  ['None', ''],
  ['Cancel', ''],
  ['We got a problem', ''],
  ['Welcome to the Resource Centre', ''],
  [
    'Find funding opportunities, programmes and trainings and downloadable business resources',
    ''
  ],
  ['SEARCH', ''],
  ['entrepreneur', ''],
  ['in', ''],
  ['as', ''],
  ['Top picks for', ''],
  ['FROM', ''],
  ['Last seen', ''],
  ['Funding Opportunities', ''],
  ['Business resources', ''],
  ['Mentoring & coaching', ''],
  ['Programmes and training', ''],
  ['Recommended organisations', ''],
  ['Recommended Events', ''],
  ['Resource details', ''],
  ['Contact information', ''],
  ['Subscribe to our newsletters', ''],
  ['Download', ''],
  ['Pricing from:', ''],
  ['Apply for funding', ''],
  ['CATEGORY', ''],
  ['SECTOR', ''],
  ['CONTENT TYPE', ''],
  ['DEADLINE', ''],
  ['LANGUAGE', ''],
  ['Type', ''],
  ['Filters', ''],
  ['Results', ''],
  ['Find Resources', ''],
  ['MY RESOURCES ONLY', ''],
  ['SERVICES', ''],
  ['SECTORS', ''],
  ['FUNCTIONS', ''],
  ['LANGUAGES', ''],
  ['PRICE', ''],
  ['Amount', ''],
  ['Start a new conversation…', ''],
  ['Write a comment', ''],
  ['Comment posted', ''],
  ['There was an error. Please try later', ''],
  ['Comment is too short', ''],
  ['Comment deleted', ''],
  ['More', ''],
  ['More information', ''],
  ['I am a fund manager', ''],
  ['Attend', ''],
  ['Thank you for signing up!', ''],
  [
    'Complete your individual profile and create your business profile to network better.',
    ''
  ],
  ['Complete your ESO profile & your individual profile.', ''],
  ['Gender', ''],
  ['Male', ''],
  ['Female', ''],
  ['Social media', ''],
  ['Tagline', ''],
  ['View profile', ''],
  ['View', ''],
  ['Change language', ''],
  ['My resources', ''],
  ['Status', ''],
  ['Date of birth', ''],
  ['Latest Resources', ''],
  ['Latest News', ''],
  ['Latest Events', ''],
  ['Download', ''],
  ['View', ''],
  ['Founding date', ''],
  ['Website', ''],
  ['Members of your business', ''],
  ['Add more members', ''],
  ['WORKING LANGUAGES(S)', ''],
  ['Add a new resource', ''],
  ['Main information', ''],
  ['Primary language of this business resource', ''],
  ['Resource information', ''],
  ['File to download', ''],
  ['Create', ''],
  ['CO-WORKING & FACILITIES', ''],
  ['ADVICE', ''],
  ['MENTORING', ''],
  ['INCUBATOR PROGRAMS', ''],
  ['ACCELERATOR PROGRAMS', ''],
  ['TRAINING WORKSHOPS & BOOTCAMPS', ''],
  ['PROTOTYPING TOOLS', ''],
  ['EVENTS', ''],
  ['Services of interest', ''],
  ['Resource Center', ''],
  ['Announcement', ''],
  ['Link', ''],
  ['Event', ''],
  ['news', ''],
  ['Partners', ''],
  ['Invitation Sent', ''],
  ['Invitation received', ''],
  ['View', ''],
  ['Are you sure you want to confirm the removal?', ''],
  ['...Uploading the file', ''],
  ['Business resources', ''],
  ['Programme & Training', ''],
  ['Fund manager', ''],
  ['Received', ''],
  ['Sent', ''],
  ['Read more', ''],
  ['DEADLINE', ''],
  ['LANGUAGE(S)', ''],
  ['DOWNLOAD', ''],
  [
    "We're still working on this section. Features will be coming soon.",
    ''
  ],
  ['Survey', ''],
  ['First', ''],
  ['Last', ''],
  ['Age', ''],
  ['Select', ''],
  ['Under', ''],
  ['Between', ''],
  ['and', ''],
  ['Above', ''],
  ['are you an ESO ?', ''],
  ['Confirmation', ''],
  ['Resend', ''],
  ['Upload', ''],
  ['Image', ''],
  ['Could not save the profile', ''],
  ['Something went wrong', ''],
  ['You have been registered successfully', ''],
  ['News Posted', ''],
  ['Resource saved.', ''],
  ['You have been logged out.', ''],
  ['Please verify Your account and Check your mail', ''],
  ['Highlight', ''],
  ['clear filter', ''],
  ['Find the questions', '']
];


const DynamicLangForm = (props) => {
  const { data = {} } = props


  const { enqueueSnackbar } = useSnackbar()
  const [saveLanguage, { loading: langLoading }] = useMutation(saveLanguageMutation)

  const { data: { _currentAdmin: currentUser = { name: '' } } = {}, loading } = useQuery(_currentAdminQuery)
  const [CSVData, setCSVData] = React.useState({ csvString: '', filename: data.translationFile })
  const hiddenFileInput = React.useRef(null);
  const uploadCSV = () => {
    hiddenFileInput.current.click();
  };

  const importCsv = async (event) => {
    const file = event.target.files[0];
    if (
      file.type === "text/csv" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      let fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = ev => {
        let csvdata = fileReader.result?.toString();
        setCSVData({ csvString: csvdata, filename: file.name })
      };
    }
  };


  const validationSchema = Yup.object().shape({
    langName: Yup.string().required("This field is required."),
    langCode: Yup.string().max(3, "Maximum 3 character long").required("This field is required.")
  })

  const onSubmit = useCallback(async (fields) => {
    const { isActive, langCode, langName } = fields;
    const dataObject = {
      isActive,
      langCode,
      langName,
      csvString: CSVData.csvString,
      translationFile: CSVData.filename
    }

    try {
      if (!CSVData.filename) {
        enqueueSnackbar('Please upload translation tags file', { variant: 'error' })
        return;
      }
      //save language mutation:
      saveLanguage({
        variables: {
          data: {
            ...(data && data._id)
              ? { id: data._id, updatedBy: currentUser.name }
              : { createdBy: currentUser.name },
            ...dataObject
          }
        },
        update: (proxy, { data: { saveLanguage = {} } }) => {
          if (!data || !data._id) {
            const mutationResult = saveLanguage
            ApolloCacheUpdater({
              proxy,
              ID: '_id',
              operator: 'ANY',
              searchVariables: { skip: 0 },
              queriesToUpdate: [getLanguageQuery, countLanguageQuery],
              mutationResult: { ...mutationResult },
            })
          }
        },
      }).then((response) => {
        if (response.data.saveLanguage.status === "304") {
          enqueueSnackbar(`${response.data.saveLanguage.message}, please check.`, { variant: 'error' })
        }
        else {
          enqueueSnackbar('The language has been saved, Please wait for a minute, changes are going to live', { variant: 'success', autoHideDuration: 9000 })
          history.replace('/language')
        }
      }).catch(e => {
        console.error(e)
        enqueueSnackbar('There was an error saving the language', { variant: 'error' })
      })
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Could not save the language.', { variant: 'error' })
    }

  })

  const onChange = useCallback((input) => {
    let processedString = input.toLowerCase();

    // Remove all spaces and special characters using regular expression
    processedString = processedString.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');
    return processedString;
  }, [])




  return (
    <Formik
      initialValues={{
        isActive: false,
        langCode: "",
        langName: "",
        ...data
      }}
      validationSchema={validationSchema}
      onSubmit={(fields) => onSubmit(fields)}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <Form id="user_edit_form">
            <CardContent>
              <Grid item container spacing={2} alignItems="center" justifyContent="center">
                <Grid container item xs={12} sm={12} md={12} >
                  <InputLabel>Title of Language <span className="required_sign">*</span></InputLabel>
                  <TextField
                    fullWidth
                    id="langName"
                    name="langName"
                    onChange={handleChange}
                    value={values.langName}
                    InputLabelProps={{ shrink: true }}
                  />
                  <ErrorMessage name="langName" >
                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                  </ErrorMessage>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} >
                  <InputLabel>Language code <span className="required_sign">*</span> {data._id ? null : "( It is not editable, so please make sure the code is correct. )"}</InputLabel>
                  <TextField
                    fullWidth
                    id="langCode"
                    name="langCode"
                    onChange={(e) => {
                      if (data._id) { }
                      else {
                        const value = onChange(e.target.value);
                        setFieldValue('langCode', value)
                      }
                    }}
                    disabled={data._id ? true : false}
                    value={values.langCode}
                    InputLabelProps={{ shrink: true }}
                  />
                  <ErrorMessage name="langCode" >
                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                  </ErrorMessage>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} >
                  <FormControlLabel
                    label="Active"
                    control={
                      <Checkbox
                        name="isActive"
                        checked={values?.isActive}
                      />
                    }
                    disabled={data && data.langIndex === 0 ? true : false}
                    onChange={handleChange}
                    value={values?.isActive || false}
                  />
                </Grid>
                <Grid container item xs={12} sm={12} md={12} >
                  <CSVLink
                    data={samplecsvData}
                    filename={"LANGUAGES_TAGS_CSV_format.csv"}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      size="medium"
                      variant="contained"
                      style={{ marginLeft: 20, marginRight: 20 }}
                    >
                      Sample TAGS CSV
                    </Button>
                  </CSVLink>
                  {CSVData.filename ? <> <p className='csv-filename'>{CSVData.filename.slice(0, 25)} <button className='cancle-btn' type='button' onClick={() => setCSVData({ filename: null })}>x</button></p></> : <Button
                    onClick={uploadCSV}
                    size="medium"
                    variant="contained"
                    style={{ marginLeft: 20, marginRight: 20 }}
                  >
                    IMPORT TRANSLATION TAGS CSV
                  </Button>}

                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref={hiddenFileInput}
                    onChange={importCsv}
                    onClick={(event) => {
                      event.target.value = null
                    }}
                    style={{ display: "none" }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={4} justifyContent='flex-end'>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    CANCEL
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={loading || langLoading}
                  >
                    {(loading || langLoading) && <CircularProgress color='inherit'
                      size={26} style={{ padding: '4px' }} />}
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Form>
        )
      }}
    </Formik>
  )
}

export default DynamicLangForm
