import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Wysiwyg = ({
  name,
  value,
  setValue,
  placeholder,
}) =>
  <ReactQuill
    value={value}
    onChange={value => setValue(name, value)}
    modules={{
      toolbar: [
        [{ 'header': [3, false] }],
        ['bold', 'italic'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
      ],
    }}
    placeholder={placeholder}
  />

Wysiwyg.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
}

Wysiwyg.displayName = 'Wysiwyg'

export default Wysiwyg