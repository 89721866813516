import gql from 'graphql-tag'
import {
  Esos,
  News,
  User,
  Resource,
  CustomIndicator,
  Survey,
  Carousel,
  FAQ,
  DynamicLanguage,
  DynamicSurvey,
  saveJobDataQuery,
  UserInteraction,
  cohortData
} from './_fragments'

// LOCAL
export const storeMutation = gql`
  mutation setStore($field: String!, $anotherField: String) {
    setStore(
      field: $field
      anotherField: $anotherField
    ) @client
  }
`

// REMOTE
export const loginMutation = gql`
  mutation login($userCredentials: userCredentials!) {
    login(input: $userCredentials)
  }
`

export const saveEsoMutation = gql`
  mutation saveEso($data: inputEsoData!) {
    saveEso(data: $data) {
      ...EsoData
    }
  }
  ${Esos.fragments.EsoData}
`
export const _s3SignMutation = gql`
  mutation($filename: String!, $filetype: String!) {
    _signS3(filename: $filename, filetype: $filetype) {
      url
      signedRequest
    }
  }
`
export const _safeS3SignMutation = gql`
  mutation($filename: String!, $filetype: String!) {
    _safeSignS3(filename: $filename, filetype: $filetype) {
      url
      signedRequest
    }
  }
`

export const importUser = gql`
  mutation saveBulkUser($data: String) {
    saveBulkUser(data: $data) {
      success
      response
    }
  }
`

export const _deleteUserMutation = gql`
  mutation _deleteUser($id: ID!) {
    _deleteUser(id: $id)
  }
`

export const _deleteEsoMutation = gql`
  mutation _deleteEso($id: ID!) {
    _deleteEso(id: $id)
  }
`

export const _inviteEsoMutation = gql`
  mutation _inviteEso($organizationId: ID! $email: EmailAddress! $message: String $language: LANGUAGE) {
    _inviteEso(esoId: $organizationId email: $email message: $message language: $language) {
      ...EsoData
    }
  }
  ${Esos.fragments.EsoData}
`

export const _revokeEsoInvitationMutation = gql`
  mutation _revokeEsoInvitation($esoId: ID!) {
    _revokeEsoInvitation(esoId: $esoId) {
      ...EsoData
    }
  }
  ${Esos.fragments.EsoData}
`

export const saveNewsMutation = gql`
  mutation saveNews($data: inputNewsData!) {
    saveNews(data: $data) {
      ...NewsData
    }
  }
  ${News.fragments.NewsData}
`

export const _deleteNewsMutation = gql`
  mutation _deleteNews($id: ID!) {
    _deleteNews(id: $id)
  }
`
export const saveSurveyMutation = gql`
  mutation saveSurvey($data: inputSurveyData!) {
    saveSurvey(data: $data) {
      ...SurveyData
    }
  }
  ${Survey.fragments.SurveyData}
`
export const _deleteSurveyMutation = gql`
  mutation _deleteSurvey($id: ID!) {
    _deleteSurvey(id: $id)
  }
`

export const _getNewsLinkContentMutation = gql`
  mutation __getNewsLinkContent($url: URL!) {
    _getNewsLinkContent(url: $url) {
      _id
      title
      content
      images
    }
  }
`


export const saveResourceMutation = gql`
  mutation saveResource($data: inputResourceData!) {
    saveResourceAdmin(data: $data) {
      ...ResourceListData
    }
  }
  ${Resource.fragments.ResourceListData}
`

export const deleteResourceMutation = gql`
  mutation deleteResource($_id: ID!) {
    deleteResourceAdmin(_id: $_id) {
      ...ResourceListData
    }
  }
  ${Resource.fragments.ResourceListData}
`

export const deleteArchivedResourceMutation = gql`
  mutation deleteArchivedResource($_id: ID!) {
    deleteArchivedResourceAdmin(_id: $_id) {
      ...ResourceListData
    }
  }
  ${Resource.fragments.ResourceListData}
`

export const addCustomIndicatorMutation = gql`
  mutation addCustomIndicator($input: InputCustomIndicator) {
    addCustomIndicator(input: $input) {
      ...CustomIndicatorData
    }
  }
  ${CustomIndicator.fragments.CustomIndicatorData}
`

export const deleteCustomIndicatorMutation = gql`
  mutation deleteCustomIndicator($id: ID!) {
    deleteCustomIndicator(id: $id)
  }
`

export const editUserDetails = gql`
  mutation editUserByAdmin($data: inputEntrepreneurData!) {
    editUserByAdmin(data: $data) {
      ...UsersListingData
    }
  }
  ${User.fragments.UsersListingData}
`

export const addUserDetails = gql`
  mutation addUserByAdmin($data: inputUserDataByAdmin!) {
    addUserByAdmin(data: $data) {
      ...UserDataAdmin
    }
  }
  ${User.fragments.UserDataAdmin}
`

export const inviteUserByAdmin = gql`
  mutation inviteUserByAdmin($id: ID!) {
    inviteUserByAdmin(id: $id){
      ...UsersListingData
    }
  }
  ${User.fragments.UsersListingData}
`

export const importESO = gql`
  mutation saveBulkEso($data: String) {
    saveBulkEso(data: $data) {
      success
      response
    }
  }
`
export const locationDetails = gql`
  mutation locatoinGet( $locationName: String) {
    locatoinGet(data: $locationName) {
      obj:obj
    }
  }
`
export const saveCarouselMutation = gql`
  mutation saveCarousel($data: inputCarouselData!) {
    saveCarousel(data: $data) {
      ...CarouselData
    }
  }
  ${Carousel.fragments.CarouselData}
`
export const _deleteCarouselMutation = gql`
  mutation _deleteCarousel($id: ID!) {
    _deleteCarousel(id: $id)
  }
`
export const saveFAQMutation = gql`
  mutation saveFAQ($data: inputFAQData!) {
    saveFAQ(data: $data) {
      ...FAQData
    }
  }
  ${FAQ.fragments.FAQData}
`
export const _deleteFAQMutation = gql`
  mutation _deleteFAQ($id: ID!) {
    _deleteFAQ(id: $id)
  }
`
export const saveLanguageMutation = gql`
  mutation saveLanguage($data: inputLanguageData!) {
    saveLanguage(data: $data) {
      ...DynamicLanguageData
    }
  }
  ${DynamicLanguage.fragments.DynamicLanguageData}
`
export const saveDynamicSurveyMutation = gql`
  mutation saveDynamicSurvey($data: inputDynamicSurveyData!) {
    saveDynamicSurvey(data: $data) {
      ...DynamicSurveyData
    }
  }
  ${DynamicSurvey.fragments.DynamicSurveyData}
`
export const _deleteDynamicSurveyMutation = gql`
  mutation _deleteDynamicSurvey($id: ID!) {
    _deleteDynamicSurvey(id: $id)
  }
`
export const exportDynamicSurveyAnalyticsMutation = gql`
mutation exportSurveyAnalytics($params: inputDynamicSurveyAnswerData) {
  exportSurveyAnalytics(params: $params) {
    ...DynamicSurveyAnalyticsData
  }
}
${DynamicSurvey.fragments.DynamicSurveyAnalyticsData}`

export const _sendNotificationSurveyMutation = gql`
  mutation _sendNotificationForSurvey($id: ID!) {
    _sendNotificationForSurvey(id: $id)
  }
`

export const saveJobQueriesData = gql`
  mutation saveJobData($data: inputJobData!) {
    saveJobData(data: $data) {
      ...jobQueryData
    }
  }
  ${saveJobDataQuery.fragments.jobQueryData}
`;

export const _deleteJobPostMutation = gql`
  mutation _deleteJobPost($id: ID!) {
    _deleteJobPost(id: $id)
  }
`

export const _s3SignDownloadMutation = gql`
  mutation($filename: String!) {
    _signS3Download(filename: $filename) {
      signedRequest
    }
  }
`



export const addInteractionMutation = gql`
  mutation addInteraction($interaction: inputInteraction!) {
    addInteraction(interaction: $interaction) {
      ...UserInteractionData
    }
  }
  ${UserInteraction.fragments.UserInteractionData}
`




export const saveCohortAdminData = gql`
  mutation saveCohortAdmin($data: inputCohortData!) {
    saveCohortAdmin(data: $data) {
      ...cohortQueryData
    }
  }
  ${cohortData.fragments.cohortQueryData}
`;



export const deleteCohortMutation = gql`
  mutation deleteCohortAdmin($id: ID!) {
    deleteCohortAdmin(_id: $id){
    _id
    }
  }
`