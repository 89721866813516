import React from 'react';
import GA4AnalyticsList from './GA4AnalyticsList';


const GA4AnalyticsDetails = ({ history }) => {



  return (
    <>
      <GA4AnalyticsList history={history} />

    </>
  )
}

export default GA4AnalyticsDetails