import { useMutation } from '@apollo/react-hooks'
import { addInteractionMutation } from '../api/_mutations'

export default () => {
  const [addInteraction, { loading, error }] = useMutation(addInteractionMutation)
  return [(resourceId, type) => {
    if (!resourceId || !type) {
      console.warn('Warn: [Add interaction]: "resourceId" and "type" are mandatory')
      return null
    }
    return addInteraction({
      variables: {
        interaction: {
          resourceId,
          type,
        },
      },
    }).catch(e => console.log(e))
  }, { loading, error }]
}
