import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import Loader from '../components/Loader'
import ResourceForm from '../forms/ResourceForm'
import ResourceView from '../forms/ResourceView'
import LanguageSwitch from '../components/LanguageSwitch'
import multivalues from '../settings/multivalues.json'
import { getResourceByIdQuery } from '../api'
import LANGUAGES from '../settings/enums/languages.json'

const ResourceEdit = ({ match: { params: { id } } }) => {
  const { data: { getResourceById: data } = {}, loading, error } = useQuery(getResourceByIdQuery, {
    variables: {
      id,
    },
    skip: !id
  })
  const [languageIndex, setLanguageIndex] = React.useState(0)
  const isAfDB = data?.author?.name === 'AfDB'

  return (
    <Authenticated>
      <Layout title='Edit Resource'>
        <Loader loading={loading} error={error}>
          <Typography variant='h4' gutterBottom>{data && data?.title && Array.isArray(data?.title) && data?.title.length > 0 && data?.title[0]}</Typography>
          {!loading &&
            <>
              {!isAfDB && data
                ? <>
                  <p>Resource Language: {data?.language?.toUpperCase()}</p>
                  <ResourceView
                    languageIndex={Object.keys(LANGUAGES).indexOf(data?.language)}
                    data={data}
                    languagesTotal={Object.keys(multivalues.languages).length}
                  />
                </>
                : <>
                  <LanguageSwitch
                    languageIndex={languageIndex}
                    setLanguageIndex={setLanguageIndex}
                    languages={multivalues.languages}
                  />
                  <ResourceForm
                    languageIndex={languageIndex}
                    data={data}
                    languagesTotal={Object.keys(multivalues.languages).length}
                  />
                </>
              }
            </>
          }
        </Loader>
      </Layout>
    </Authenticated>
  )
}

export default ResourceEdit
