import React from 'react'
import PropTypes from 'prop-types'
import { TablePagination } from '@material-ui/core'



const Pagination = ({ count, rowsPerPage, page, callback,handleRowPerChange }) =>
  <TablePagination
    component="div"
    count={count}
    rowsPerPage={rowsPerPage}
    rowsPerPageOptions={[10,20,50,100]}
    page={page}
    backIconButtonProps={{
      'aria-label': 'Previous Page',
    }}
    nextIconButtonProps={{
      'aria-label': 'Next Page',
    }}
    onRowsPerPageChange={(event)=>handleRowPerChange(+event.target.value)}
    onPageChange={(e, page) => callback(page)}
  />


Pagination.defaultProps = {
  rowsPerPage: 20,
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number,
  callback: PropTypes.func.isRequired,
}

export default Pagination