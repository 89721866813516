import { Button, Card, Divider } from '@material-ui/core'
import React from 'react'
import './AnalyticsList.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getAnalyticsCountQuery, getRecurringUsersCountQuery } from '../../api'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Loader from '../Loader'

const AnalyticsList = ({ history }) => {

  // getGa4CountQuery:
  const { data: { countAFDBAnalytics: ga4Count } = {},
  } = useQuery(getAnalyticsCountQuery, {
    variables: {
      params: {
        where: { "analyticType": "ga4" }
      },
    }
  })


 // getResourceCountQuery:
  const { data: { countAFDBAnalytics: ResourceCount } = {},
    loading, error, refetch
  } = useQuery(getAnalyticsCountQuery, {
    variables: {
      params: {
        where: { "analyticType": "resourceDownload" }
      },
    }
  })

    // getRecurringUsersCountQuery:
    const { data: { countRecurringUsers:RecurringUserCount } = {},
  } = useQuery(getRecurringUsersCountQuery, {
    variables: {
      params: {
        where: {}
      },
    }
  })
   // getRedirectLinkCountQuery:
   const { data: { countAFDBAnalytics: RedirectLinkCount } = {},
   
  } = useQuery(getAnalyticsCountQuery, {
    variables: {
      params: {
        where: { "analyticType": "redirectLink" }
      },
    }
  })


  return (
    <>
      <Loader loading={loading} error={error}>
        <div className='analytics_list_wrapper'>
          <div className='analytics_list'>Resource Analytic</div>
          <div className='analytics_data_count'>{ResourceCount}</div>
          <div className='analytics_info'><Link to={{
            pathname: "/analytics/resource"
          }}><Button variant="contained" color="primary">View Info</Button></Link></div>
        </div>
        <Divider style={{ margin: "10px 0px 10px 0px" }}></Divider>
        <div className='analytics_list_wrapper'>
          <div className='analytics_list'>GA4 Analytic</div>
          <div className='analytics_data_count'>{ga4Count}</div>
          <div className='analytics_info'><Link to={{
            pathname: "/analytics/ga4"
          }}><Button variant="contained" color="primary">View Info</Button></Link></div>
        </div>
        <Divider style={{ margin: "10px 0px 10px 0px" }}></Divider>
        <div className='analytics_list_wrapper'>
          <div className='analytics_list'>Recurring Users</div>
          <div className='analytics_data_count'>{RecurringUserCount}</div>
          <div className='analytics_info'><Link to={{
            pathname: "/analytics/recurringUsers"
          }}><Button variant="contained" color="primary">View Info</Button></Link></div>
        </div>
        <Divider style={{ margin: "10px 0px 10px 0px" }}></Divider>
        <div className='analytics_list_wrapper'>
          <div className='analytics_list'>Redirect Analytic</div>
          <div className='analytics_data_count'>{RedirectLinkCount}</div>
          <div className='analytics_info'><Link to={{
            pathname: "/analytics/redirectLink"
          }}><Button variant="contained" color="primary">View Info</Button></Link></div>
        </div>
      </Loader>
    </>
  )
}

export default AnalyticsList