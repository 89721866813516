import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles, Button, Dialog } from '@material-ui/core';
import { CloseSharp, CloseOutlined } from '@material-ui/icons';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

const useStyles = makeStyles((theme) => ({
   root: {
      padding: 20,
      maxWidth: 300,
      textAlign: 'center',
      backgroundColor: '#f5f5f5',
      borderRadius: theme.shape.borderRadius,
   },
   input: {
      display: 'none',
   },
   upload: {
      cursor: 'pointer',
      padding: '30px 20px',
      lineHeight: 'normal',
      border: `2px dashed #0000004d`,
      fontSize: theme.typography.fontSize,
   },
   delete: {
      border: 'none',
      fontSize: '14px',
      cursor: 'pointer',
      padding: '5px 8px',
      lineHeight: 'normal',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto',
   },
   image: {
      padding: 5,
      display: 'block',
      maxWidth: '100%',
      margin: '0 auto 10px',
      boxSizing: 'border-box',
      backgroundColor: 'white',
   },
   filename: {
      fontSize: 11,
      maxWidth: '100%',
      marginBottom: 10,
      overflow: 'hidden',
      textAlign: 'center',
      lineHeight: 'normal',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      '-webkit-box-orient': 'vertical',
   },
   errorText: {
      color: 'red',
   },
   cropContainer: {
      position: 'relative',
      width: '100%',
      height: 400,
   },
   cropButton: {
      marginTop: theme.spacing(2),
   },
   dialogContent: {
      padding: theme.spacing(4),
   },
   closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      cursor: 'pointer',
   },
}));

const ImageUpload = (props) => {
   const { name, value, setFieldValue, placeholder = 'image' } = props;
   const classes = useStyles();
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState('');
   const [openCrop, setOpenCrop] = useState(false);
   const [selectedImage, setSelectedImage] = useState(null);
   const imageRef = useRef(null);
   const cropperRef = useRef(null);

   const handleImageChange = (event) => {
      setIsLoading(true);
      const imageFile = event.target.files[0];
      if (imageFile) {
         const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'];
         if (!allowedTypes.includes(imageFile.type)) {
            setError('Invalid file type. Only PNG, JPG, JPEG, and SVG are allowed.');
            setIsLoading(false);
            return;
         }
         const reader = new FileReader();
         reader.readAsDataURL(imageFile);
         reader.onload = () => {
            setSelectedImage(reader.result);
            setOpenCrop(true);
            setIsLoading(false);
         };
      }
   };

   const handleApplyCrop = () => {
      if (cropperRef.current) {
         const croppedCanvas = cropperRef.current.getCroppedCanvas();
         croppedCanvas.toBlob((blob) => {
            const croppedImageUrl = URL.createObjectURL(blob);

            setFieldValue(name, {
               name: `cropped-${Date.now()}.jpg`,
               type: blob.type,
               size: blob.size,
               data: croppedImageUrl,
               dataFile: blob,
            });

            setOpenCrop(false);
         }, 'image/jpeg');
      }
   };

   const handleCloseCrop = () => {
      setOpenCrop(false);
      setSelectedImage(null);
      if (cropperRef.current) {
         cropperRef.current.destroy();
         cropperRef.current = null;
      }
   };

   return (
      <div className={classes.root}>
         {!value ? (
            <div>
               <input
                  accept='image/*'
                  className={classes.input}
                  id={name}
                  type='file'
                  onChange={handleImageChange}
               />
               <label htmlFor={name}>
                  {isLoading ? (
                     <CircularProgress />
                  ) : (
                     <div className={classes.upload}>+ Upload {placeholder}</div>
                  )}
               </label>
               {error && <div className={classes.errorText}>{error}</div>}
            </div>
         ) : (
            <div>
               <img src={value.data || value} className={classes.image} alt='Uploaded file' />
               <button
                  className={classes.delete}
                  onClick={() => setFieldValue(name, '')}
               >
                  <CloseSharp /> Delete {placeholder}
               </button>
            </div>
         )}

         {/* Cropper Modal */}
         <Dialog open={openCrop} onClose={handleCloseCrop} maxWidth="md" fullWidth>
            <div className={classes.dialogContent}>
               <div className={classes.closeButton} onClick={handleCloseCrop}>
                  <CloseOutlined />
               </div>
               <div className={classes.cropContainer}>
                  <img
                     ref={imageRef}
                     src={selectedImage}
                     alt="Crop preview"
                     style={{ display: 'none' }}
                     onLoad={() => {
                        if (imageRef.current) {
                           cropperRef.current = new Cropper(imageRef.current, {
                              aspectRatio: 0,
                              viewMode: 1,
                              background: false,
                              autoCropArea: 1,
                              zoomable: true,
                              scalable: true,
                           });
                        }
                     }}
                  />
               </div>
               <Button
                  onClick={handleApplyCrop}
                  color="primary"
                  variant="contained"
                  className={classes.cropButton}
               >
                  Apply Crop
               </Button>
            </div>
         </Dialog>
      </div>
   );
};

ImageUpload.propTypes = {
   name: PropTypes.string.isRequired,
   value: PropTypes.any,
   placeholder: PropTypes.node,
   setFieldValue: PropTypes.func.isRequired,
};

export default ImageUpload;
