import React from 'react'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import AnalyticsList from '../components/Analytics/AnalyticsList'
import { getResourceAnalyticsQuery } from '../api'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Loader from '../components/Loader'

const Analytics = () => {
  return (
    <Authenticated>
      <Layout title='Analytics Data'>
           <AnalyticsList  />
      </Layout>
    </Authenticated>
  )
}

export default Analytics