import React, { useState, useCallback, useMemo } from 'react'
import Authenticated from '../../../containers/Authenticated'
import Table from '../../Table'
import Layout from '../../Layout'
import { useQuery } from '@apollo/react-hooks';
import { getRecurringUserQuery } from '../../../api';
import Loader from '../../Loader';
import Pagination from '../../Pagination';
import RecurringUserFilterForm from '../../../forms/Analytics/RecurringUserFilterForm';

const RecurringUserList = ({ history }) => {

    //Pagination State:
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    //Sort State:
    const [sortData, setSortData] = useState(['_id:desc']);

    //Filter State:
    const [filters, setFilters] = React.useState({});

    //pagination function:
    const handleRowPerChange = useCallback((value) => {
        setRowsPerPage(value)
    }, [])

    //sort function:
    const sortFilter = useCallback((value, key) => {
        setSortData([`${value}:${key}`]);
    }, []);

    //parseFilter:
    const parseFilters = useMemo(() => {
        const { keyword} = filters;

        if (keyword) {
            var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
                .replace(/\!/g, "\\\\!")
                .replace(/\@/g, "\\\\@")
                .replace(/\#/g, "\\\\#")
                .replace(/\$/g, "\\\\\\$")
                .replace(/\%/g, "\\\\%")
                .replace(/\^/g, "\\\\^")
                .replace(/\&/g, "\\\\&")
                .replace(/\*/g, "\\\\*")
                .replace(/\)/g, "\\\\)")
                .replace(/\(/g, "\\\\(")
                .replace(/\[/g, "\\\\[")
                .replace(/\]/g, "\\\\]")
                .replace(/\;/g, "\\\\;")
                .replace(/\{/g, "\\\\{")
                .replace(/\}/g, "\\\\}")
                .replace(/\?/g, "\\\\?")
                .replace(/\,/g, "\\\\,");
        }
        return {
            ...keyword && { user_name: searchKeyword }
        };
    }, [filters]);

    //Filter functions:
    const handleClear = useCallback(() => {
        setFilters({});
        history.push("/analytics/recurringUsers");
    }, [history]);

    const handleFiltersSubmit = useCallback((fields) => {
        setPage(0)
        setFilters({ ...fields })
    }, []);

    // getRecurringUserQuery:
    const { data: { getRecurringUserAnalytics = [], countRecurringUsers: rowsInTotal } = {},
        loading, error, refetch
    } = useQuery(getRecurringUserQuery, {
        variables: {
            params: {
                sort: sortData,
                limit: rowsPerPage,
                skip: page * rowsPerPage,
                where: parseFilters,
            },
        }
    })

    return (
        <Authenticated>
            <Layout title='GA4 Analytics Data'
                head={
                    <RecurringUserFilterForm
                        filters={filters}
                        callback={handleFiltersSubmit}
                        handleClear={handleClear}
                    />
                }
            >
                <Loader loading={loading} error={error}>

                    <Table
                        data={getRecurringUserAnalytics?.map(item => ({
                            user_name: item?.user_name || '',
                            totalDownloads: item?.totalDownloads || 0,
                        }))}
                        labels={[
                            {
                                name: 'User Name',
                                field: 'user_name',
                            },
                            {
                                name: "Total Download Count",
                                field: "totalDownloads"
                            },
                        ]}
                        sortFilter={sortFilter}

                    />
                    <Pagination
                        page={page}
                        count={rowsInTotal}
                        rowsPerPage={rowsPerPage}
                        callback={newPage => setPage(newPage)}
                        handleRowPerChange={handleRowPerChange}
                    />
                </Loader>
            </Layout>
        </Authenticated>
    )
}

export default RecurringUserList