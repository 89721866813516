import React from 'react'
import { withRouter } from 'react-router-dom'
import ApolloCacheUpdater from 'apollo-cache-updater'
import { Button, Link, MenuItem, Select, ImageListItem, ImageList } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/react-hooks'
import * as Yup from "yup";
import history from '../history'
import { saveResourceMutation, getResourcesQuery, countResourcesQuery, deleteResourceMutation } from '../api'
import RESOURCE_STATUS from '../settings/enums/resource-status.json'
import { Formik, Form, ErrorMessage } from 'formik';
import Spinner from '../components/Spinner';

const transformValue = (k, v, langIndex = 0) => {
  if (k === 'description') return <div dangerouslySetInnerHTML={{ __html: v[langIndex] }}></div>
  if (k === 'author' && v) return v.name
  if (Array.isArray(v) && k !== 'description' && k !== 'images') return v.join(' | ')
  if (k === 'deadline') return (new Date(v)).toLocaleDateString()
  if (k === 'fileUrl') return <Link href={v.url}>{v.url.split('/').pop()}</Link>
  if (k === 'images' && k.length) {
    return <ImageList rowHeight={160} cols={1}>
      {v.map((tile) => (
        <ImageListItem key={tile} cols={1}>
          <img src={tile} alt={''} />
        </ImageListItem>
      ))}
    </ImageList>
  }
  return v
}

const allowedFields = [
  // 'content',
  'price',
  'name',
  'description',
  'sectors',
  'stages',
  'services',
  'category',
  'author',
  'countryName',
  'city',
  'address',
  'workingLanguages',
  'deadline',
  'website',
  'phone',
  'email',
  'contact',
  'images',
  'fileUrl',
  'targets',
  'functions']
const STATUS = Object.keys(RESOURCE_STATUS).reduce((obj, status) => ({
  ...obj,
  [status]: status,
}), {})
const fieldIsAllowed = field => allowedFields.includes(field)

const getActionLabel = (key) => {
  if (key === RESOURCE_STATUS.approved) return 'Approve'
  if (key === RESOURCE_STATUS.pending) return 'Make pending'
  if (key === RESOURCE_STATUS.rejected) return 'Reject'
  if (key === 'delete') return 'Delete'
}

const ResourceForm = ({
  data,
  languageIndex,
  languagesTotal
}) => {
  const firstUpdate = React.useRef(true)
  const [initialValues, setInitialValues] = React.useState()
  const [changingLanguage, setChangingLanguage] = React.useState()
  const [updateResource] = useMutation(saveResourceMutation)
  const [deleteResource, { loading }] = useMutation(deleteResourceMutation)
  const { enqueueSnackbar } = useSnackbar()


  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    }
    if (data) {
      setInitialValues(data)
    }
  }, [data, firstUpdate])

  React.useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        await setChangingLanguage(true)
        await setInitialValues({ ...data, ...initialValues })
        await setChangingLanguage(false)
      }
      handleAsync()
    }
  }, [languageIndex])



  const handleSubmit = (payload) => {
    const { action } = payload || {}
    const { _id, name } = data
    if (!_id && !action) return null

    let status
    let hasToBeDeleted = false
    switch (action?.toLowerCase()) {
      case 'approved':
        status = STATUS.approved
        break;
      case 'pending':
        status = STATUS.pending
        break;
      case 'rejected':
        status = STATUS.rejected
        break;
      case 'delete':
        hasToBeDeleted = true
        break;
      default:
        break;
    }
    if (hasToBeDeleted) {
      return deleteResource({
        variables: {
          _id,
        },
        update: (proxy) => {
          const update = ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getResourcesQuery, countResourcesQuery], // queries you want to automatically update
            searchVariables: {},
            operator: 'ANY',
            operation: 'REMOVE',
            mutationResult: { _id },
            ID: '_id',
          })
          if (update) {
            enqueueSnackbar('The resource has been deleted.', { variant: 'success' })
            history.push('/resources')
          }
        }
      })
    }

    updateResource({
      variables: {
        data: {
          _id,
          name,
          status
        }
      },
    })
      .then(() => history.push('/resources'))
      .catch((e) => {
        enqueueSnackbar('Cannot update resource.', { variant: 'error' })
        console.log(e);
      })
  }


  let rows = []
  if (data) {
    rows = Object.entries(data).filter(([k, v]) => !!v && fieldIsAllowed(k)).map(([k, v]) => ({
      name: k,
      value: transformValue(k, v, languageIndex)
    })
    )
  }
  return changingLanguage ? null :
    <div>
      <TableContainer style={{ marginBottom: 60 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p>STATUS: {data.status?.toUpperCase()}</p>
      <Formik
        initialValues={{
          action: ''
        }}
        validationSchema={Yup.object().shape({
          action: Yup.string().required("This field is required.")
        })}
        onSubmit={handleSubmit}
      >
        {({ handleChange }) => {
          return <Form>
            <Select
              name='action'
              label='Action'
              fullWidth
              onChange={handleChange}
              defaultValue={Object.keys({ ...RESOURCE_STATUS, delete: 'delete' }).filter((k) => k !== data.status)[0]}
            >
              {Object.entries({ ...RESOURCE_STATUS, delete: 'delete' }).filter(([k]) => k !== data.status).map(([key, value]) =>
                <MenuItem key={key} value={key}>
                  {getActionLabel(key)}
                </MenuItem>
              )}
            </Select>
            <ErrorMessage name='action'>{msg => msg}</ErrorMessage>

            <Button
              variant='contained'
              onClick={() => history.goBack()}
            >Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              type='submmit'
            >
              {loading
                ? <Spinner /> : 'Apply'
              }
            </Button>
          </Form>
        }}
      </Formik>
    </div>
}

ResourceForm.defaultProps = {
  languageIndex: 0,
}

export default withRouter(ResourceForm)
