import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from 'notistack'
import Authenticated from '../../containers/Authenticated'
import Layout from '../../components/Layout'
import Loader from '../../components/Loader'
import DynamicSurveyFilterForm from '../../forms/DynamicSurveyForms/DynamicSurveyFilterForm'
import DynamicSurveyList from '../../components/DynamicSurveyList'
import Pagination from '../../components/Pagination'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { _deleteDynamicSurveyMutation, getDynamicSurveyQuery, exportDynamicSurveyAnalyticsMutation, _sendNotificationSurveyMutation } from '../../api'



//download CSV function:
function downloadCSV(data, filename) {
  // Convert JSON to CSV
  const csvData = data.map(row => Object.values(row).join(",")).join("\n");

  // Create a blob and set its MIME type as CSV
  const blob = new Blob([csvData], { type: 'text/csv' });
                                                                                                                      
  // Create an anchor link and trigger a download
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const DynamicSurvey = ({ history }) => {



  const { enqueueSnackbar } = useSnackbar()

  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortData, setSortData] = useState(['_id:desc']);

  //Delete DynamicSurvey mutation:
  const [deleteDynamicSurvey] = useMutation(_deleteDynamicSurveyMutation, {})
  const [exportSurveyData] = useMutation(exportDynamicSurveyAnalyticsMutation, {})
  const [sendNotificationForSurvey] = useMutation(_sendNotificationSurveyMutation, {})
  const [notificationProcessingId, setNotificationProcessingId] = useState(null);


  //Parse filters Functions:
  const parseFilters = useMemo(() => {
    const { keyword, isPublic, language } = filters;
    if (keyword) {
      var searchKeyword = keyword.replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    }
    return {
      ...keyword && { title_matches: searchKeyword },
      ...isPublic && (isPublic === 'Yes'
        ? { isPublic: true }
        : isPublic === 'No'
          ? { isPublic: false } : {}
      ),
      ...language && { language }
    }
  }, [filters]);


  //Get DynamicSurvey data query:
  const { data: { getDynamicSurvey: DynamicSurvey = [], countDynamicSurvey: rowsInTotal } = {},
    loading, error
  } = useQuery(getDynamicSurveyQuery, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
          ? { where: parseFilters }
          : {}
        )
      },
    },
  })

  //Clear filter function:
  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/dynamicSurvey");
  }, [history]);

  //Apply filters function:
  const handleFiltersSubmit = useCallback((fields) => {
    setPage(0)
    setFilters({ ...fields })
  }, []);

  //Sorting function:
  const sortFilter = useCallback((value, key) => {
    setSortData([`${value}:${key}`]);
  }, []);

  //Pagination function:
  const handleRowPerChange = useCallback((value) => {
    setRowsPerPage(value)
  }, [])

  //Delete function:
  const handleDeleteCalllback = useCallback(async (id, name) => {
    deleteDynamicSurvey({
      variables: {
        id,
      },
      refetchQueries: [{
        query: getDynamicSurveyQuery,
        variables: {
          params: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            ...(filters && Object.keys(filters).length
              ? { where: parseFilters }
              : {}
            )
          },
        }
      }],
      // update: (proxy, { data: { _deleteEso: deleteEso = {} } }) => {
      //   const updateOne = ApolloCacheUpdater({
      //     proxy,
      //     queriesToUpdate: [getDynamicSurveyQuery], // queries you want to automatically update
      //     searchVariables: {},
      //     operator: 'ANY',
      //     operation: 'REMOVE',
      //     mutationResult: { _id: id },
      //     ID: '_id',
      //   })
      //   let updateTwo = true
      //   let userId
      //   if (deleteEso && typeof deleteEso === 'string') {
      //     userId = deleteEso.split('___').length > 1 ? deleteEso.split('___')[1] : null
      //   }

      //   if (userId) {
      //     updateTwo = ApolloCacheUpdater({
      //       proxy,
      //       queriesToUpdate: [getDynamicSurveyQuery], // queries you want to automatically update
      //       searchVariables: {},
      //       operator: 'ANY',
      //       operation: 'REMOVE',
      //       mutationResult: { _id: userId },
      //       ID: '_id',
      //     })
      //   }
      //   if (updateOne && updateTwo) enqueueSnackbar(`Survey ${name} has been deleted.`, { variant: 'success' })
      // }
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getDynamicSurveyQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        if (update) enqueueSnackbar(`Survey ${name} has been deleted.`, { variant: 'success' })
      }
    }).then(() => { }).catch((e) => {
      enqueueSnackbar('Cannot delete Survey.', { variant: 'error' })
    })
  }, [])

  //Export analytics data function:
  const handledownloadAnalytics = useCallback(async (id, name) => {
    exportSurveyData({
      variables: {
        params: {
          id
        }
      }
    }).then((result) => {
      let { data: { exportSurveyAnalytics: { analyticsData = [] } = {} } = {} } = result;
      downloadCSV(analyticsData, `${name}_analytics.csv`)
    })
  }, [])


  //Send  notification function:
  const handleSendNotification = useCallback(async (id) => {
    setNotificationProcessingId(id)
    sendNotificationForSurvey({
      variables: {
        id,
      },
      refetchQueries: [{
        query: getDynamicSurveyQuery,
        variables: {
          params: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            ...(filters && Object.keys(filters).length
              ? { where: parseFilters }
              : {}
            ),
          },
        },
      }],
      // No need for 'update' cache manipulation if you're simply refetching
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getDynamicSurveyQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        if (update) enqueueSnackbar('Notification sent!', { variant: 'success' })
      }
    })
      .then(() => {
      })
      .catch((e) => {
        enqueueSnackbar('Failed to send notification.', { variant: 'error' });
      }).finally(() => {
        setNotificationProcessingId(null)
      }
      );
  }, []);

  return (
    <Authenticated>
      <Layout
        title='Dynamic Survey'
        head={
          <DynamicSurveyFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <DynamicSurveyList
            data={DynamicSurvey} sortFilter={sortFilter}
            deleteCallback={handleDeleteCalllback}
            downloadAnalytics={handledownloadAnalytics}
            sendNotification={handleSendNotification}
            notificationProcessingId={notificationProcessingId}
          />
          {rowsInTotal > rowsPerPage &&
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          }
         
        </Loader>
      </Layout>
    </Authenticated>


  )
}

export default DynamicSurvey