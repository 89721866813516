import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Button,
  Typography
} from "@material-ui/core";
import AFIRCA_COUNTRIES from "../settings/enums/new-africa-iso-code.json";
import UnfoldMore from "@material-ui/icons/UnfoldMore";
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMoreIcon';
import { Add } from '@material-ui/icons'

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

let order = 'asc'
let value = 'name'

const Table = ({ labels, sortData, data, sortFilter }) => {

  const showDate = d => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    if (d) {
      const datestring =
        d.getDate() +
        " " +
        months[d.getMonth()] +
        " " +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        String(d.getMinutes()).padStart(2, '0');
      return datestring;
    }
  };

  const ascd = useCallback(key => {
    let _value, _order
    if (key === "esoName") {
      _value = 'eso'
    } else {
      _value = key
    }
    if (order === "asc" && _value === value) {
      _order = 'desc'
    } else {
      _order = 'asc'
    }
    sortFilter(_value, _order)
    order = _order
    value = _value
  });

  return (
    <>
      <MUITable>
        <TableHead>
          <TableRow>
            {labels.map((label, index) => (
              <TableCell key={index}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center', justifyContent: label.field === "actions" ? 'center' : ''
                }}>
                  {label.name}
                  <kbd
                    onClick={() => {
                      ascd(label.field);
                    }}
                  >
                    {label.field === "actions" ? null : <UnfoldMore />}
                  </kbd>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0
            ? data.map((row, index) => (
              <TableRow key={index}>
                {labels?.map((label, index) => {
                  if (label.field === "country") {
                    if (row["type"] === "eso") {
                      return (
                        <TableCell key={index}>
                          {row["eso"].countryName}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={index}>
                          {getKeyByValue(AFIRCA_COUNTRIES, row[label.field])}
                        </TableCell>
                      );
                    }
                  }

                  if (label.name === "serviceInterests") {
                    return (
                      <TableCell key={index}>{row[label.field] ?
                        <>
                          {row[label.field].slice(0, 2).join(', ')}
                          {row[label.field].length > 2 && <Tooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  {row[label.field].join(', ')}
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <Button><Add />{row[label.field].length - 2} more </Button>
                          </Tooltip>}
                        </>
                        : ''}</TableCell>
                    )
                  }
                  // Registration Date
                  if (label.name === "Registration Date") {
                    return (
                      <TableCell key={index}>
                        {row[label.field] ?
                          <>
                            {showDate(new Date(row[label.field]))}
                          </>
                          : '-'}</TableCell>
                    )
                  }
                  //  Date from and date to 
                  if (label.name === "From" || label.name === 'To') {
                    return (
                      <TableCell key={index}>
                        {row[label.field] ?
                          showDate(new Date(row[label.field]))
                          : '-'
                        }
                      </TableCell>
                    )
                  }
                  if (label.name === "Invitition Date") {
                    return (
                      <TableCell key={index}>
                        {row[label.field] ?
                          <>
                            {showDate(new Date(row[label.field]))}
                          </>
                          : '-'}</TableCell>
                    )
                  }
                  if (label.name === "Created At") {
                    return (
                      <TableCell key={index}>{row[label.field] ?
                        <>
                          {showDate(new Date(row[label.field]))}
                        </>
                        : ''}</TableCell>
                    )
                  }
                  return <TableCell key={index}>{row[label.field]}</TableCell>;
                })}
              </TableRow>
            ))
            : <TableRow>
              <TableCell colSpan={labels.length} >
                No Data Found
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </MUITable>
    </>
  );

};

Table.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

export default Table;
