import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik';
import { Button, FormHelperText, Grid,TextField } from '@material-ui/core'
import history from '../history'

const FAQFiltersForm = (props) => {
  const {
    handleClear,
    callback = () => { },
  } = props


  return (
    <Formik
      initialValues={{ keyword: '' }}
      onSubmit={(values) => callback(values)}
    >
      {({
        values,
        resetForm,
        handleChange,
      }) => {
        return <Form name="filter_newsfeed" >
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="keyword"
                value={values.keyword}
                onChange={handleChange}
                label="Search Questions"
              />
              <ErrorMessage name='keyword'>
                {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
              </ErrorMessage>
            </Grid>
          </Grid>
          <div style={{ marginTop: 25 }}>
            <Grid container spacing={4} justifyContent='flex-start'>
              <Grid item xs={3} sm={2}>
                <Button style={{ width: '100%' }} color="primary"
                  variant="contained" type='submit'>
                  APPLY
                </Button>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button style={{ width: '100%' }} type="reset"
                  color="inherit" variant="contained"
                  onClick={() => {
                    resetForm();
                    handleClear()
                  }}
                >
                  Clear Filter
                </Button>
              </Grid>
              <Grid item xs={3} sm={2}>
                <Button style={{ width: '100%' }} color="primary"
                  variant="contained" onClick={()=>history.replace('/FAQ/new')}>
                  ADD NEW FAQ
                </Button>
              </Grid>
            </Grid>
          </div>
        </Form>
      }}
    </Formik>
  );
};

export default FAQFiltersForm
