import gql from 'graphql-tag'
import {
  User,
  Admin,
  Esos,
  News,
  Survey,
  Resource,
  Carousel,
  CustomIndicator,
  FAQ,
  DynamicLanguage,
  DynamicSurvey,
  ResourceAnalytics,
  jobListing,
  saveJobDataQuery
} from './_fragments'
/* eslint-disable no-underscore-dangle */
// LOCAL
export const storeQuery = gql`
  query store {
    store @client {
      field
      anotherField
    }
  }
`

// REMOTE
export const connectionQuery = gql`
  query connection {
    connection
  }
`

export const authQuery = gql`
  query authenticate {
    _checkAuth
  }
`

export const currentUserQuery = gql`
  query currentUser {
    currentUser {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`

export const _currentAdminQuery = gql`
  query _currentAdmin {
    _currentAdmin {
      ...AdminData
    }
  }
  ${Admin.fragments.AdminData}
`
export const getEsosQuery = gql`
  query getEsos($params: inputSearchEso) {
    getEsos(params: $params) {
      ...EsosListingData
    }
    countEsos(params: $params)
  }
  ${Esos.fragments.EsosListingData}
`

export const getEsosExportQuery = gql`
  query getEsos($params: inputSearchEso) {
    getEsos(params: $params) {
      ...EsoData
    }
  }
  ${Esos.fragments.EsoData}
`
export const countEsosQuery = gql`
  query countEsos($params: inputSearchEso) {
    countEsos(params: $params)
  }
`

export const getEsoBySlugQuery = gql`
  query getEsoBySlug($slug: String!) {
    getEsoBySlugAdmin(slug: $slug) {
      ...EsoData
    }
  }
  ${Esos.fragments.EsoData}
`

export const countUsersQuery = gql`
  query countUsers($params: inputSearchUser) {
    countUsers(params: $params)
  }
`

export const countResourcesQuery = gql`
  query countResources($params: inputResourceQueryParams) {
    countResources(params: $params)
  }
`

export const countSurveyQuery = gql`
  query countSurvey($params: inputSurveyData) {
    countSurvey(params: $params)
  }
`

export const countArchivedResourcesQuery = gql`
  query countArchivedResources($params: inputResourceQueryParams) {
    countArchivedResources(params: $params)
  }
`

export const getUsersQuery = gql`
  query getUsers($params: inputSearchUser) {
    getUsers(params: $params) {
      ...UsersListingData
    }
    countUsers(params: $params)
  }
  ${User.fragments.UsersListingData}
`



export const getUsersEditQuery = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      ...UsersListingData
    }
  }
  ${User.fragments.UsersListingData}
`
export const getNewsQuery = gql`
  query getNews($params: inputNewsData) {
    getNews(params: $params) {
      ...NewsListingData
    }
    countNewsFeed(params: $params)
  }
  ${News.fragments.NewsListingData}
`

export const getNewsBySlugQuery = gql`
  query getNewsBySlug($slug: String!) {
    getNewsBySlugAdmin(slug: $slug) {
      ...NewsData
    }
  }
  ${News.fragments.NewsData}
`
export const getSurveyQuery = gql`
  query getSurvey($params: inputSurveyData) {
    getSurvey(params: $params) {
      ...SurveyListingData
    }
    countSurvey(params: $params)
  }
  ${Survey.fragments.SurveyListingData}
`
export const getSurveySlugQuery = gql`
  query getSurveyBySlug($slug: String!) {
    getSurveyBySlug(slug: $slug) {
      ...SurveyData
    }
  }
  ${Survey.fragments.SurveyData}`

export const getResourcesQuery = gql`
  query getResources($params: inputResourceQueryParams) {
    getResources(params: $params) {
      ...ResourceListData
    }
    countResources(params: $params)
  }
  ${Resource.fragments.ResourceListData}
`

export const getArchivedResourcesQuery = gql`
  query getArchivedResources($params: inputResourceQueryParams) {
    getArchivedResources(params: $params) {
      ...ResourceListData
    }
    countArchivedResources(params: $params)
  }
  ${Resource.fragments.ResourceListData}
`

export const getResourceByIdQuery = gql`
  query getResourceById($id: ID!) {
    getResourceById(id: $id) {
      ...ResourceData
    }
  }
  ${Resource.fragments.ResourceData}
`
//maintain sequence of user type
export const getMaeQuery = gql`
  query getMae($input: InputGetMae) {
    getMae(input: $input) {
      market {
        entrepreneurSignedUp
        entrepreneurOnline
        esoOnline
        usersOnline
        entrepreneurSignedUpMale
        entrepreneurSignedUpFemale
        entrepreneurAgeUnder18
        entrepreneurAge19To35
        entrepreneurAgeAbove35
        esoSignedUp
        esoProfileComplete
        esoThatSharedResources
      }
      knowledge {
        resourceProviders
        resourceProvided
        resourceDownloaded
      }
      capacity {
        trainingEvents
        coursesOffered
        esoStartupTrained
      }
      financial {
        fundManagersSignedUp
        fundingOpportunities
        applicationsForFunding
      }
    }
  }
  
`

export const getMaeQueryMonths = gql`
  query getMae1($input: InputGetMae) {
    getMae1(input: $input) {
       obj:obj 
    }
  }
`

export const getCustomIndicatorsQuery = gql`
  query getCustomIndicators($input: InputGetCustomIndicators) {
    getCustomIndicators(input: $input) {
      ...CustomIndicatorData
    }
  }
  ${CustomIndicator.fragments.CustomIndicatorData}
`
export const _s3SignMutationImg = gql`
  mutation _signS3($filename: String!, $filetype: String!) {
    _signS3(filename: $filename, filetype: $filetype) {
      url
      signedRequest
    }
  }
`

export const saveOwnUserMutation = gql`
  mutation saveOwnUser($data: inputEntrepreneurData!) {
    saveOwnUser(data: $data) {
      ...UsersListingData
    }
  }
  ${User.fragments.UsersListingData}
`
export const getCarouselQuery = gql`
query getCarousel($params: inputCarouselData) {
  getCarousel(params: $params) {
    ...CarouselListingData
  }
  countCarousel(params: $params)
}
${Carousel.fragments.CarouselListingData}
`

export const getCarouselByIdQuery = gql`
  query getCarouselById($_id: ID!) {
    getCarouselById(_id: $_id) {
      ...CarouselData
    }
  }
  ${Carousel.fragments.CarouselData}`

export const countCarouselQuery = gql`
  query countCarousel($params: inputCarouselData) {
    countCarousel(params: $params)
  }
`

export const getFAQQuery = gql`
query getFAQ($params: inputFAQData) {
  getFAQ(params: $params) {
    ...FAQListingData
  }
  countFAQ(params: $params)
}
${FAQ.fragments.FAQListingData}
`

export const getFAQByIdQuery = gql`
  query getFAQById($id: ID!) {
    getFAQById(id: $id) {
      ...FAQData
    }
  }
  ${FAQ.fragments.FAQData}`

export const countFAQQuery = gql`
  query countFAQ($params: inputFAQData) {
    countFAQ(params: $params)
  }
`

export const getLanguageQuery = gql`
  query getLanguage($params: inputLanguageData) {
    getLanguage(params: $params) {
      ...DynamicLanguageData
    }
    countLanguage(params: $params)
  }
  ${DynamicLanguage.fragments.DynamicLanguageData}
`

export const countLanguageQuery = gql`
  query countLanguage($params: inputLanguageData) {
    countLanguage(params: $params)
  }
`
export const getLanguageByIdQuery = gql`
  query getLanguageById($_id: ID!) {
    getLanguageById(_id: $_id) {
      ...DynamicLanguageData
    }
  }
  ${DynamicLanguage.fragments.DynamicLanguageData}`

export const getDynamicSurveyQuery = gql`
  query getDynamicSurvey($params: inputDynamicSurveyData) {
    getDynamicSurvey(params: $params) {
      ...DynamicSurveyListingData
    }
    countDynamicSurvey(params: $params)
  }
  ${DynamicSurvey.fragments.DynamicSurveyListingData}`

  export const getDynamicSurveyByIdQuery = gql`
  query getDynamicSurveyByID($id: ID!) {
    getDynamicSurveyByID(id: $id) {
      ...DynamicSurveyData
    }
  }
  ${DynamicSurvey.fragments.DynamicSurveyData}`

  export const getDynamicSurveyAnalyticsQuery = gql`
  query getDynamicSurveyAnalytics($params: inputDynamicSurveyAnswerData) {
    getDynamicSurveyAnalytics(params: $params) {
      ...DynamicSurveyAnalyticsData
    }
  }
  ${DynamicSurvey.fragments.DynamicSurveyAnalyticsData}`

//Resource Analytics:
export const getResourceAnalyticsQuery = gql`
query getResourceAnalytics($params: inputAnalyticsData) {
  getResourceAnalytics(params: $params) {
    ...ResourceAnalyticsListingData
  }
}
${ResourceAnalytics.fragments.ResourceAnalyticsListingData}`

//GA4 Analytics:
export const getGA4AnalyticsQuery = gql`
query getGA4ResourceAnalytics($params: inputAnalyticsData) {
  getGA4ResourceAnalytics(params: $params) {
    ...GA4AnalyticsListingData
  }
}
${ResourceAnalytics.fragments.GA4AnalyticsListingData}`

//Recurring Users:
export const getRecurringUserQuery = gql`
query getRecurringUserAnalytics($params: inputAnalyticsData) {
  getRecurringUserAnalytics(params: $params) {
    ...RecurringUserListingData
  }
  countRecurringUsers(params: $params)
}
${ResourceAnalytics.fragments.RecurringUserListingData}`

//Count Recurring Users:
export const getRecurringUsersCountQuery = gql`
  query countRecurringUsers($params: inputAnalyticsData) {
    countRecurringUsers(params: $params)
}
`

//RedirectLink Analytics:
export const getRedirectAnalyticsQuery = gql`
query getRedirectLinkAnalytics($params: inputAnalyticsData) {
  getRedirectLinkAnalytics(params: $params) {
    ...RedirectLinkAnalyticsListingData
  }
}
${ResourceAnalytics.fragments.RedirectLinkAnalyticsListingData}`

//Count Analytics:
export const getAnalyticsCountQuery = gql`
  query countAFDBAnalytics($params: inputAnalyticsData) {
    countAFDBAnalytics(params: $params)
}
`
export const getJobListingData = gql`
query getJob($params: inputJobData) {
  getJob(params: $params) {
    ...jobListingQueryData
  }
  countJob(params: $params)

}
${jobListing.fragments.jobListingQueryData}
`

export const getJobByIdQuery = gql`
  query getJobById($id: ID!) {
    getJobById(id: $id) {
      ...jobQueryData
    }
  }
  ${saveJobDataQuery.fragments.jobQueryData}
  `