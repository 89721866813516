import React, { useCallback, useState, useEffect } from "react";
import * as Yup from "yup";
import Loader from "../../components/Loader";
import { Formik, Form, ErrorMessage, FieldArray, Field } from "formik";
// import { Formik, Form, Field, FieldArray } from 'formik';

import { toast } from "react-toastify";
import ApolloCacheUpdater from "apollo-cache-updater";
import {
  Grid,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Chip,
  Modal
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import history from "../../history";
import ImageUpload from "../../components/ImageUpload";
import LANGUAGES from "../../settings/enums/languages.json";
import { Delete, Edit, DeleteOutlineOutlined } from "@material-ui/icons";
import { _currentAdminQuery, getDynamicSurveyQuery } from "../../api";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { _s3SignMutation } from "../../api/_mutations";
import "./DynamicSurveyForm.css";
import { saveDynamicSurveyMutation } from "../../api";
import { Rating } from "@material-ui/lab";
import DraggableListDynamicSurvey from "../../components/DragSurveyQuestions/DraggableListDynamicSurvey";
import Wysiwyg from "../Wysiwyg";

const styleGroupModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "55%",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "700px",
  overflowX: "hidden",
  borderRadius: "20px"
};
const styleAddQuestionModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "55%",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "700px",
  overflowX: "hidden",
  borderRadius: "20px"
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "55%",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "700px",
  overflowX: "hidden",
  borderRadius: "20px"
};
const options = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" }
];

const ageoptions = [
  { label: "under 18", value: "under 18" },
  { label: "between 18 and 35", value: "between 18 and 35" },
  { label: "above 35", value: "above 35" }
];
const serviceIntrest = {
  incubator: {
    label: "Incubator programme",
    icon: "incubator",
    description: ""
  },
  accelerator: {
    label: "Accelerator programme",
    icon: "accelerator",
    description: ""
  },
  coworking: {
    label: "Co-working and facilities",
    icon: "co-working",
    description: "Co-working space with limited support"
  },
  virtual: {
    label: "Virtual platform",
    icon: "virtual-platform",
    description: ""
  },
  financial: {
    label: "Funding",
    icon: "access-to-finance",
    description:
      "Financial Institution providing Business Development Support services (Fund manager, Angel, VC, equity, impact / social investor, etc.)"
  },
  business: {
    label: "Advice",
    icon: "chat",
    description:
      "Business linkages, networking, value chain or marketing support organization"
  },
  networking: {
    label: "Networking",
    icon: "networking",
    description: ""
  },
  sme: {
    label: "Mentoring",
    icon: "mentoring",
    desription: "SME Business Support Mentoring"
  },
  training: {
    label: "Training",
    icon: "mentoring",
    desription: "Training workshop & bootcamp"
  },
  prototyping: {
    label: "Prototyping tools ",
    icon: "mentoring",
    desription: ""
  },
  events: {
    label: "Events",
    icon: "mentoring",
    desription: ""
  },
  other: {
    label: "Other",
    icon: "idea"
  }
};
const DynamicSurveyForm = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [questionType, setQuestionType] = React.useState(
    data?.questionType || null
  );
  const [loading, setLoading] = useState(false);

  //Dynamic question state:
  const [questionsList, setQuestionsList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [editingIndex, setEditingIndex] = React.useState(null);

  //Skip Logic state:
  const [skipQuestionList, setSkipQuestionList] = React.useState([]);
  const [skipLogicEditData, setSkipLogicEditData] = React.useState({});
  const [skipQuestionopen, setSkipQuestionOpen] = React.useState(false);

  //Question Group state:
  const [questionGroupopen, setQuestionGroupopen] = React.useState(false);
  const [questionGroupList, setQuestionGroupList] = React.useState([]);
  const [editingGroupId, setEditingGroupId] = useState(null);

  //Preview form state:
  const [previewopen, setPreviewopen] = React.useState(false);

  useEffect(() => {
    if (data && data.questionType === "skip") {
      setSkipQuestionList(data?.questions);
    }
    if (data && data.questionType === "simple") {
      setQuestionsList(data?.questions);
      setQuestionGroupList(data?.questionGroup);
    }
  }, []);

  //save DynamicSurveyMutation:
  const [saveDynamicSurvey] = useMutation(saveDynamicSurveyMutation);

  //Get current user query:
  const { data: { _currentAdmin: currentUser = { name: "" } } = {} } = useQuery(
    _currentAdminQuery
  );

  //Store image in bucket Mutation:
  const [s3Sign] = useMutation(_s3SignMutation);

  //open & close modal functions:
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingIndex(null);
  };

  //open & close modal functions:
  const handleSkipQuestionModalOpen = () => {
    setSkipQuestionOpen(true);
  };
  const handleSkipQuestionModalClose = () => {
    setSkipQuestionOpen(false);
  };

  //open & close modal functions:
  const handleGroupModalOpen = () => {
    setQuestionGroupopen(true);
  };

  const handleGroupModalClose = () => {
    setQuestionGroupopen(false);
  };

  //Validation for survey schema:
  const surveyValidationSchema = Yup.object().shape({
    title: Yup.array()
      .of(
        Yup.string()
          // .trim() // Automatically trims leading and trailing spaces
          // .strict(true) // Ensures the trim validation error is thrown
          .required("Title is required") // Ensures the field is required
          .matches(
            /^\S.*\S$|^\S*$/,
            "No leading or trailing whitespace allowed"
          ) // Ensures no leading/trailing whitespace, allows empty string to pass for non-required validation
      )
      .min(1, "Title is required") // Ensures the array has at least one entry
      .required("Title is required"),

    // Yup.array().of(Yup.string().nullable()).min(1, 'This field is required')
    age: Yup.array()
      .min(1, "Please select atleast one option")
      .required("This field is required"),
    gender: Yup.array()
      .min(1, "Please select atleast one option")
      .required("This field is required")
  });

  //Validation for question schema:
  const questionValidationSchema = Yup.object().shape({
    question_en: Yup.string()
      .required("English question is required")
      .matches(/^\S.*\S$|^\S*$/, "No leading or trailing whitespace allowed"),
    question_fr: Yup.string()
      .required("French question is required")
      .matches(/^\S.*\S$|^\S*$/, "No leading or trailing whitespace allowed"),
    type: Yup.string().required("Type is required"),
    options: Yup.array().when("type", {
      is: type => ["radio", "checkbox"].includes(type),
      then: Yup.array()
        .of(
          Yup.object({
            value_en: Yup.string()
              .required("Option in English is required")
              .matches(
                /^\S.*\S$|^\S*$/,
                "No leading or trailing whitespace allowed"
              ),
            value_fr: Yup.string()
              .required("Option in French is required")
              .matches(
                /^\S.*\S$|^\S*$/,
                "No leading or trailing whitespace allowed"
              )
          })
        )
        .required("At least one option is required")
        .min(1, "At least one option is required"),
      otherwise: Yup.array().notRequired()
    }),
    rows: Yup.array().when("type", {
      is: type =>
        ["tabular", "tabular_checkbox", "tabular_text"].includes(type),
      then: Yup.array()
        .of(
          Yup.object().shape({
            value_en: Yup.string()
              .required("Row value in English is required")
              .matches(
                /^\S.*\S$|^\S*$/,
                "No leading or trailing whitespace allowed"
              ),
            value_fr: Yup.string()
              .required("Row value in French is required")
              .matches(
                /^\S.*\S$|^\S*$/,
                "No leading or trailing whitespace allowed"
              )
          })
        )
        .required("At least one row is required")
        .min(1, "At least one row is required"),
      otherwise: Yup.array().notRequired()
    }),
    columns: Yup.array().when("type", {
      is: type =>
        ["tabular", "tabular_checkbox", "tabular_text"].includes(type),
      then: Yup.array()
        .of(
          Yup.object().shape({
            value_en: Yup.string()
              .required("Column value in English is required")
              .matches(
                /^\S.*\S$|^\S*$/,
                "No leading or trailing whitespace allowed"
              ),
            value_fr: Yup.string()
              .required("Column value in French is required")
              .matches(
                /^\S.*\S$|^\S*$/,
                "No leading or trailing whitespace allowed"
              )
          })
        )
        .required("At least one column is required")
        .min(1, "At least one column is required"),
      otherwise: Yup.array().notRequired()
    }),
    groupId: Yup.string().required("Question group is required")
  });

  useEffect(() => {
    console.log(questionGroupList, "questionGroupListquestionGroupList");
  }, [questionGroupList]);

  //Validation for question group schema:
  const questionGroupValidationSchema = Yup.object().shape({
    groupName_en: Yup.string()
      .test(
        "no-leading-or-trailing-whitespace",
        "No leading or trailing whitespace allowed",
        value => !/^\s+|\s+$/g.test(value)
      )
      .required("Group name is required"),
    groupName_fr: Yup.string()
      .test(
        "no-leading-or-trailing-whitespace",
        "No leading or trailing whitespace allowed",
        value => !/^\s+|\s+$/g.test(value)
      )
      .required("Group name is required")
  });

  //Edit Dynamic Question Function:
  const handleEditQuestion = uniqueID => {
    const index = questionsList.findIndex(
      question => question.uniqueID === uniqueID
    );
    console.log("🚀 ~ handleEditQuestion ~ index:", index);

    if (index === -1) {
      console.error("Question not found");
      enqueueSnackbar("Question not found", { variant: "error" });
      return;
    }

    setEditingIndex(index);
    handleOpen(); // Open the modal for editing
  };

  //Edit Skip question Function:
  const handleEditSkipQuestionQuestion = data => {
    setSkipLogicEditData(data);
    handleSkipQuestionModalOpen();
  };

  //Delete Question function:
  const handleDeleteQuestion = uniqueIDToDelete => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      const updatedQuestions = questionsList.filter(
        question => question.uniqueID !== uniqueIDToDelete
      );

      // Reassign serial numbers within the same group
      const groupMap = updatedQuestions.reduce((acc, cur) => {
        acc[cur.groupId] = (acc[cur.groupId] || []).concat(cur);
        return acc;
      }, {});

      Object.values(groupMap).forEach(group => {
        group
          .sort((a, b) => a.serialNo - b.serialNo)
          .forEach((question, index) => {
            question.serialNo = index + 1;
          });
      });

      setQuestionsList(updatedQuestions.flat());
    }
  };

  //Dataobject modify Function:
  const buildDataObject = (type, fields, questions) => {
    const {
      title,
      description,
      goal,
      image,
      thumbnail_image,
      isActive,
      isPublic,
      time_required_for_survey,
      survey_start_from,
      age,
      gender,
      serviceInterests,
      isSurveyImage,
      isSurveyThumbnailImage
    } = fields;
    return {
      title,
      description,
      goal: typeof goal === "string" ? goal.split(",") : goal,
      image,
      thumbnail_image,
      isActive,
      isSurveyImage,
      isSurveyThumbnailImage,
      questionType: questionType,
      isPublic,
      time_required_for_survey,
      survey_start_from,
      targetDemographics: {
        age,
        gender,
        serviceInterests
      },
      createdBy: currentUser.name,
      questions: questions,
      questionGroup: questionGroupList
    };
  };

  //Upload survey image function:
  const uploadImage = async image => {
    const file = image;
    let attachmentUrl = file || null;
    if (attachmentUrl && typeof attachmentUrl !== "string") {
      const { dataFile, name: filename } = file;
      try {
        const response = await s3Sign({
          variables: {
            filename: `DynamicSurveyImages/${filename
              .replace(/ /g, "-")
              .replace(/[{()}]/g, "")}`,
            filetype: file.type
          }
        });
        const { signedRequest, url: bucketUrl } = response.data._signS3;
        const options = {
          headers: {
            "Content-Type": file.type
          }
        };
        await fetch(signedRequest, {
          method: "PUT",
          headers: options.headers,
          body: dataFile
        });
        attachmentUrl = bucketUrl;
      } catch (e) {
        console.error(e);
        toast.error("Cannot upload file(s)");
        attachmentUrl = null;
      }
    }
    return attachmentUrl;
  };

  //Submit question group function:
  const questionGroupSubmit = fields => {
    if (editingGroupId) {
      // Update existing group
      const updatedGroups = questionGroupList.map(group => {
        if (group.groupId === editingGroupId) {
          return {
            ...group,
            groupName: [fields.groupName_en, fields.groupName_fr]
          };
        }
        return group;
      });
      setQuestionGroupList(updatedGroups);
    } else {
      // Add new group
      const newGroupId = generateRandomId();
      const newQuestionGroup = {
        groupName: [fields.groupName_en, fields.groupName_fr],
        groupId: newGroupId
      };
      setQuestionGroupList(currentGroups => [
        ...currentGroups,
        newQuestionGroup
      ]);
      // Initialize questions for new group if necessary
      setQuestionsList(currentQuestions => [...currentQuestions]);
    }
    setQuestionGroupopen(false);
    setEditingGroupId(null); // Reset editing state
  };

  //Edit Dynamic Question Group Function:
  const handleEditQuestionGroup = groupId => {
    setEditingGroupId(groupId);
    setQuestionGroupopen(true);
  };

  //Save survey submit function:
  const handleSubmit = useCallback(async fields => {
    console.log(fields,"fields",fields.image)
    let dataObject;
    if (questionType === "simple") {
      dataObject = buildDataObject("simple", fields, questionsList);
      console.log("🚀 ~ handleSubmit ~ dataObject:", dataObject);
    } else if (questionType === "skip") {
      dataObject = buildDataObject("skip", fields, skipQuestionList);
    } else {
      // Handle any other question types or log error.
      enqueueSnackbar("Please add question", { variant: "error" });
      console.error("Invalid questionType");
      return;
    }
    if (fields.image) {
      try {
        setLoading(true);
        dataObject.image = await uploadImage(fields.image);
        dataObject.thumbnail_image = await uploadImage(fields.thumbnail_image);
        
       
      } catch (error) {
        console.error(error);
        toast.error("Image upload failed.");
        return;
      }
    }

    if (dataObject.image === "" || dataObject.image.length == 0) {
      dataObject.isSurveyImage = false;
    }
    if (dataObject.thumbnail_image === "" || dataObject.thumbnail_image.length == 0) {
      dataObject.isSurveyThumbnailImage = false;
    }
    try {
      await saveDynamicSurvey({
        variables: {
          data: {
            ...(data && data._id ? { id: data._id } : {}),
            ...dataObject,
            ...(dataObject.hasOwnProperty("time_required_for_survey")
              ? {
                  time_required_for_survey: dataObject.time_required_for_survey.toString()
                }
              : {})
          }
        },
        update: (proxy, { data: { saveDynamicSurvey = {} } }) => {
          if (!data || !data._id) {
            ApolloCacheUpdater({
              proxy,
              ID: "_id",
              operator: "ANY",
              searchVariables: { skip: 0 },
              queriesToUpdate: [getDynamicSurveyQuery],
              mutationResult: { ...saveDynamicSurvey }
            });
          }
        }
      });
      enqueueSnackbar("The survey has been saved.", { variant: "success" });
      history.replace("/DynamicSurvey");
    } catch (error) {
      console.error(error);
      enqueueSnackbar("There was an error saving the survey", {
        variant: "error"
      });
    } finally {
      setLoading(false);
    }
  });

  //Generate randomID function:
  function generateRandomId(length = 18) {
    if (length < 10 || length > 18) {
      length = Math.floor(Math.random() * (18 - 10 + 1)) + 10;
    }

    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  const getDefaultInitialValues = () => ({
    mainQuestion: {
      uniqueID: generateRandomId(), // Generate a new ID for add, keep empty for initial setup
      question_en: "",
      question_fr: "",
      isRequired: false,
      type: "radio", // default type
      options: [{ value_en: "", value_fr: "", nextQuestionID: "" }],
      subQuestions: []
    }
  });

  const transformSubQuestions = subQuestions => {
    return subQuestions.map(subQuestion => ({
      uniqueID: subQuestion.uniqueID || "",
      question_en: subQuestion.question[0] || "",
      question_fr: subQuestion.question[1] || "",
      question_en_title:
        subQuestion.questiontitle && subQuestion.questiontitle.length > 0
          ? subQuestion?.questiontitle[0] || ""
          : "",
      question_fr_title:
        subQuestion.questiontitle && subQuestion.questiontitle.length > 0
          ? subQuestion?.questiontitle[1] || ""
          : "",
      isOtherOption: subQuestion.isOtherOption || false,
      isRequired: subQuestion.isRequired || false,
      type: subQuestion.type || "radio",
      sectionName: subQuestion.sectionName,
      nextSection: subQuestion.nextSection,
      options: subQuestion.options.map(option => ({
        value_en: option.value_en || "",
        value_fr: option.value_fr || "",
        nextQuestionID: option.nextQuestionID || ""
      })),
      // Handle rows and columns for tabular question types
      rows: (subQuestion.rows || []).map(row => ({
        value_en: row.value_en || "",
        value_fr: row.value_fr || ""
      })),
      columns: (subQuestion.columns || []).map(column => ({
        value_en: column.value_en || "",
        value_fr: column.value_fr || ""
      })),
      // Assume subQuestions can have their own subQuestions (nested)
      subQuestions: subQuestion.subQuestions
        ? transformSubQuestions(subQuestion.subQuestions)
        : []
    }));
  };
  const transformDataToInitialValues = data => {
    const transformedQuestions = data.map(question => ({
      uniqueID: question.uniqueID,
      question_en: question.question[0],
      question_fr: question.question[1],

      isRequired: question.isRequired,
      type: question.type,
      options: question.options.map(option => ({
        value_en: option.value_en,
        value_fr: option.value_fr,
        nextQuestionID: option.nextQuestionID // Adjust according to your data structure
      })),
      subQuestions: transformSubQuestions(question.subQuestions || []),
      rows: (question.rows || []).map(row => ({
        value_en: row.value_en,
        value_fr: row.value_fr
      })),
      columns: (question.columns || []).map(column => ({
        value_en: column.value_en,
        value_fr: column.value_fr
      }))
    }));

    return {
      mainQuestion:
        transformedQuestions.length > 0
          ? transformedQuestions[0]
          : getDefaultInitialValues().mainQuestion
    };
  };

  //Skip question logic functions:
  const isEditMode = Object.keys(skipLogicEditData).length > 0;

  const skipQuestionInitialValues = isEditMode
    ? transformDataToInitialValues([skipLogicEditData])
    : getDefaultInitialValues();

  //Skip question Validation:
  const optionSchema = Yup.object().shape({
    value_en: Yup.string().required("Option value in English is required"),
    value_fr: Yup.string().required("Option value in French is required")
  });

  const rowColumnSchema = Yup.object().shape({
    value_en: Yup.string().required("Row/Column value in English is required"),
    value_fr: Yup.string().required("Row/Column value in French is required")
  });

  const subQuestionSchema = Yup.object().shape({
    question_en: Yup.string().required("Sub-question in English is required"),
    question_fr: Yup.string().required("Sub-question in French is required"),
    options: Yup.array().when("type", {
      is: type => type === "radio" || type === "checkbox",
      then: Yup.array()
        .of(optionSchema)
        .min(1, "At least one option is required"),
      otherwise: Yup.array().min(0)
    }),
    type: Yup.string().required("Type is required"),
    rows: Yup.array().when("type", {
      is: type =>
        ["tabular", "tabular_checkbox", "tabular_text"].includes(type),
      then: Yup.array()
        .of(rowColumnSchema)
        .required("At least one row is required")
        .min(1),
      otherwise: Yup.array().notRequired()
    }),
    columns: Yup.array().when("type", {
      is: type =>
        ["tabular", "tabular_checkbox", "tabular_text"].includes(type),
      then: Yup.array()
        .of(rowColumnSchema)
        .required("At least one column is required")
        .min(1),
      otherwise: Yup.array().notRequired()
    })
  });

  const skipQuestionValidationSchema = Yup.object().shape({
    mainQuestion: Yup.object().shape({
      question_en: Yup.string().required(
        "Main question in English is required"
      ),
      question_fr: Yup.string().required("Main question in French is required"),
      options: Yup.array()
        .of(optionSchema)
        .min(1, "At least one option is required"),
      subQuestions: Yup.array().of(subQuestionSchema)
    })
  });

  const handleSkipQuestionSubmit = values => {
    console.log(values, "values23333");
    const submissionData = {
      ...values.mainQuestion,
      question: [
        values.mainQuestion.question_en,
        values.mainQuestion.question_fr
      ],
      options:
        values.mainQuestion.options?.map(option => ({
          value_en: option.value_en,
          value_fr: option.value_fr,
          nextQuestionID: option.nextQuestionID
        })) ?? [],
      subQuestions:
        values.mainQuestion.subQuestions?.map(subQuestion => ({
          ...subQuestion,
          question: [subQuestion.question_en, subQuestion.question_fr],
          questiontitle: [
            subQuestion.question_en_title,
            subQuestion.question_fr_title
          ],
          isOtherOption: subQuestion.isOtherOption,
          options:
            subQuestion.options?.map(option => ({
              value_en: option.value_en,
              value_fr: option.value_fr,
              nextQuestionID: option.nextQuestionID
            })) ?? [],
          rows:
            subQuestion.rows?.map(row => ({
              value_en: row.value_en,
              value_fr: row.value_fr
            })) ?? [],
          columns:
            subQuestion.columns?.map(column => ({
              value_en: column.value_en,
              value_fr: column.value_fr
            })) ?? []
        })) ?? []
    };

    setSkipQuestionList([submissionData]);
    setQuestionType("skip");
    handleSkipQuestionModalClose();
  };

  const isSectionLinked = (
    sectionUniqueID,
    mainQuestionOptions,
    allSubQuestions
  ) => {
    if (
      mainQuestionOptions.some(opt => opt.nextQuestionID === sectionUniqueID)
    ) {
      return true;
    }
    for (let subQuestion of allSubQuestions) {
      if (
        subQuestion.options &&
        subQuestion.options.some(opt => opt.nextQuestionID === sectionUniqueID)
      ) {
        return true;
      }
    }
    return false;
  };

  //Preview survey form function:
  const PreviewSurveyForm = () => {
    setPreviewopen(true);
  };

  //Skiplogic question function:
  let surveyData = skipQuestionList[0];

  //Question Drag Function:
  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination || source.droppableId !== destination.droppableId) {
      return; // do nothing if dropped outside or not in the same group
    }

    // Find questions for the current group
    const currentGroupQuestions = questionsList.filter(
      question => question.groupId === source.droppableId
    );
    const restQuestions = questionsList.filter(
      question => question.groupId !== source.droppableId
    );

    const [reorderedItem] = currentGroupQuestions.splice(source.index, 1);
    currentGroupQuestions.splice(destination.index, 0, reorderedItem);

    // Update serial numbers within the group
    const updatedGroupQuestions = currentGroupQuestions.map((item, index) => ({
      ...item,
      serialNo: index + 1
    }));

    // Merge back updated questions with the rest
    setQuestionsList(
      [...updatedGroupQuestions, ...restQuestions].sort(
        (a, b) => a.groupId.localeCompare(b.groupId) || a.serialNo - b.serialNo
      )
    );
  };

  return (
    <>
      <Formik
        // enableReinitialize
        initialValues={{
          title: data?.title ? data.title : [],
          description: data?.description ? data.description : [],
          goal: data?.goal ? data?.goal : [],
          image: data?.image ? data.image : "",
          thumbnail_image: data?.thumbnail_image ? data.thumbnail_image : "",
          isActive: data?.isActive ? data.isActive : false,
          isSurveyImage: data?.isSurveyImage ? data.isSurveyImage : false,
          isSurveyThumbnailImage: data?.isSurveyThumbnailImage
            ? data.isSurveyThumbnailImage
            : false,
          isPublic: data?.isPublic ? data.isPublic : false,
          time_required_for_survey: data?.time_required_for_survey
            ? data?.time_required_for_survey
            : "",
          survey_start_from: data?.survey_start_from
            ? data?.survey_start_from
            : new Date(),
          age: data?.targetDemographics?.age
            ? data?.targetDemographics?.age
            : [],
          gender: data?.targetDemographics?.gender
            ? data?.targetDemographics?.gender
            : [],
          serviceInterests: data?.targetDemographics?.serviceInterests
            ? data?.targetDemographics?.serviceInterests
            : []
        }}
        onSubmit={handleSubmit}
        validationSchema={surveyValidationSchema}
      >
        {({ values, handleChange, setFieldValue }) => {


          return (
            <Form className="dynamic_survey_main_form">
              <Grid container spacing={6} justifyContent="space-between">
                <Grid
                  item
                  md={6}
                  lg={4}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <InputLabel>
                    Survey title (EN) <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    type="text"
                    name={`title[0]`}
                    onChange={handleChange}
                    value={
                      values.title &&
                      Array.isArray(values.title) &&
                      values.title[0]
                    }
                  />
                  <ErrorMessage name="title">
                    {msg => (
                      <FormHelperText style={{ color: "red" }}>
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                  <InputLabel>
                    Survey title (FR) <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    type="text"
                    name={`title[1]`}
                    onChange={handleChange}
                    value={
                      values.title &&
                      Array.isArray(values.title) &&
                      values.title[1]
                    }
                  />
                  <ErrorMessage name="title">
                    {msg => (
                      <FormHelperText style={{ color: "red" }}>
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                  <FormControl fullWidth style={{ marginBottom: "12%" }}>
                    <Wysiwyg
                      name={`description[0]`}
                      label="Description (EN)"
                      placeholder={"Description"}
                      setValue={setFieldValue}
                      onChange={handleChange}
                      value={
                        (Array.isArray(values.description) &&
                          values.description[0]) ||
                        ""
                      }
                    />
                    {/* <TextField
                      multiline
                      minRows={5}
                      type="text"
                      label="Description (EN)"
                      name={`description[0]`}
                      onChange={handleChange}
                      value={
                        Array.isArray(values.description) &&
                        values.description[0]
                      }
                    /> */}
                  </FormControl>
                  <FormControl fullWidth style={{ marginBottom: "12%" }}>
                    <Wysiwyg
                      name={`description[1]`}
                      label="Description (FR)"
                      placeholder={"Description"}
                      setValue={setFieldValue}
                      onChange={handleChange}
                      value={
                        (Array.isArray(values.description) &&
                          values.description[1]) ||
                        ""
                      }
                    />
                    {/* <TextField
                      multiline
                      minRows={5}
                      type="text"
                      label="Description (FR)"
                      name={`description[1]`}
                      onChange={handleChange}
                      value={
                        Array.isArray(values.description) &&
                        values.description[1]
                      }
                    /> */}
                  </FormControl>
                  {/* ------------------------------------------------ Goal of survey selection ------------------------------------------------ */}

                  <FormControl fullWidth>
                    <TextField
                      multiline
                      minRows={5}
                      type="text"
                      label="Goal of survey (EN)"
                      name={`goal[0]`}
                      onChange={handleChange}
                      value={Array.isArray(values.goal) && values.goal[0]}
                    />
                    <p>NOTE - Add multiple goals separated by commas (,)</p>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      minRows={5}
                      type="text"
                      label="Goal of survey (FR)"
                      name={`goal[1]`}
                      onChange={handleChange}
                      value={Array.isArray(values.goal) && values.goal[1]}
                    />
                    <p>NOTE - Add multiple goals separated by commas (,)</p>
                  </FormControl>

                  {/* ------------------------------------------------ Image Upload selection ------------------------------------------------ */}
                  {values?.image && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isSurveyImage"
                          checked={values?.isSurveyImage}
                        />
                      }
                      onChange={handleChange}
                      value={values?.isSurveyImage || false}
                      label="is survey image show on main page?"
                    />
                  )}

                  <Box style={{ marginBottom: "5%" }}>
                    <p style={{ fontWeight: "500" }}>Survey image:</p>
                    <ImageUpload
                      name="image"
                      value={values?.image}
                      setFieldValue={setFieldValue}
                      // setFieldValue={(field, value) => {
                      //   setFieldValue(value);
                      //   if (!value) {
                      //     setFieldValue("isSurveyImage", false);
                      //   }
                      // }}
                    />
                  </Box>
                  {values?.thumbnail_image && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isSurveyThumbnailImage"
                          checked={values?.isSurveyThumbnailImage}
                        />
                      }
                      onChange={handleChange}
                      value={values?.isSurveyThumbnailImage || false}
                      label="is survey thumbnail image show on main page?"
                    />
                  )}

                  <Box style={{ marginBottom: "5%" }}>
                    <p style={{ fontWeight: "500" }}>Survey thumbnail image:</p>
                    <ImageUpload
                      name="thumbnail_image"
                      value={values?.thumbnail_image}
                      setFieldValue={setFieldValue}
                      // setFieldValue={(field, value) => {
                      //   setFieldValue(value);
                      //   if (!value) {
                      //     setFieldValue("isSurveyThumbnailImage", false);
                      //   }
                      // }}
                    />
                  </Box>
                  {/* ------------------------------------------------ Checkboxes selection ------------------------------------------------ */}

                  <FormControlLabel
                    control={
                      <Checkbox name="isActive" checked={values?.isActive} />
                    }
                    onChange={handleChange}
                    value={values?.isActive || false}
                    label="Active"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox name="isPublic" checked={values?.isPublic} />
                    }
                    onChange={handleChange}
                    value={values?.isPublic || false}
                    label="Publicly Show"
                  />
                  <FormControl fullWidth>
                    <TextField
                      label="Time Required for Survey (in minutes)"
                      type="number"
                      name="time_required_for_survey"
                      fullWidth
                      onChange={handleChange}
                      value={values.time_required_for_survey}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Survey start from"
                      type="date"
                      name="survey_start_from"
                      fullWidth
                      onChange={handleChange}
                      value={values.survey_start_from}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  <h2>Survey notification for:</h2>
                  {/* ------------------------------------------------ Age selection ------------------------------------------------ */}
                  <FormControl fullWidth className="survey-age-block">
                    <label className="age_label">
                      Age <span className="required_sign">*</span>
                    </label>
                    <Select
                      multiple
                      displayEmpty
                      name="age"
                      onChange={handleChange}
                      defaultValue={values?.age}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {selected.length
                            ? selected.map(value => (
                                <Chip
                                  key={value}
                                  label={value?.toUpperCase()}
                                />
                              ))
                            : "Select"}
                        </Box>
                      )}
                    >
                      <MenuItem value="" disabled>
                        Age
                      </MenuItem>
                      {ageoptions.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ErrorMessage name="age">
                    {msg => (
                      <FormHelperText
                        className="form-error"
                        style={{ color: "red" }}
                      >
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                  {/* ------------------------------------------------ Gender selection ------------------------------------------------ */}
                  <FormControl fullWidth className="survey-gender-block">
                    <label className="gender_label">
                      Gender <span className="required_sign">*</span>
                    </label>
                    <Select
                      multiple
                      displayEmpty
                      name="gender"
                      onChange={handleChange}
                      defaultValue={values?.gender}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {console.log(selected, values?.gende, "yyy")}
                          {selected.length
                            ? selected.map(value => (
                                <Chip
                                  key={value}
                                  label={value?.toUpperCase()}
                                />
                              ))
                            : "Select"}
                        </Box>
                      )}
                    >
                      <MenuItem value="" disabled>
                        Gender
                      </MenuItem>
                      {options.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item?.value?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <ErrorMessage name="gender">
                    {msg => (
                      <FormHelperText
                        className="form-error"
                        style={{ color: "red" }}
                      >
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                  {/* ------------------------------------------------ Service Intrest selection ------------------------------------------------ */}

                  <FormControl fullWidth className="survey-service-block">
                    <label className="service_label">Service Interests</label>
                    <Select
                      multiple
                      displayEmpty
                      name="serviceInterests"
                      onChange={handleChange}
                      defaultValue={values?.serviceInterests}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {selected.length
                            ? selected.map(value => (
                                <Chip
                                  key={value}
                                  label={serviceIntrest[
                                    value
                                  ]?.label?.toUpperCase()}
                                />
                              ))
                            : "Select"}
                        </Box>
                      )}
                    >
                      <MenuItem value="" disabled>
                        Service Interests
                      </MenuItem>
                      {Object.entries(serviceIntrest).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={6}
                  lg={7}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <div className="add-que-btn">
                    <Button
                      style={{ width: "50%" }}
                      disabled={questionType === "skip" ? true : false}
                      variant="contained"
                      color="inherit"
                      type="button"
                      onClick={handleGroupModalOpen}
                    >
                      ADD GROUP
                    </Button>
                    <Button
                      style={{ width: "50%" }}
                      disabled={questionType === "skip" ? true : false}
                      variant="contained"
                      color="inherit"
                      type="button"
                      onClick={handleOpen}
                    >
                      ADD QUESTION
                    </Button>
                    <Button
                      style={{ width: "50%" }}
                      disabled={
                        (questionType === "skip" &&
                          skipQuestionList.length > 0) ||
                        questionType === "simple"
                          ? true
                          : false
                      }
                      variant="contained"
                      color="inherit"
                      type="button"
                      onClick={handleSkipQuestionModalOpen}
                    >
                      ADD SKIP LOGIC QUESTIONS
                    </Button>
                  </div>
                  {/* ------------------------------------------------ Questions & Group map section ------------------------------------------------ */}

                  <DraggableListDynamicSurvey
                    questionGroupList={questionGroupList}
                    questionsList={questionsList}
                    onDragEnd={onDragEnd}
                    handleEditQuestion={handleEditQuestion}
                    handleDeleteQuestion={handleDeleteQuestion}
                  />

                  {(data?.questionType === "skip" ||
                    skipQuestionList.length > 0) && (
                    <div className="question-list-wrapper">
                      {skipQuestionList?.map((item, index) => {
                        return (
                          <div className="question-list-section">
                            <div className="question-list-block">
                              <h4>Question</h4>
                              <p>{item.question}</p>
                            </div>
                            <div>
                              <span
                                className="edit-btn"
                                type="button"
                                onClick={() =>
                                  handleEditSkipQuestionQuestion(item)
                                }
                              >
                                <Edit />
                              </span>
                              {/* {!data && <span className='delete-btn' type='button' onClick={() => handleDeleteQuestion(index)}><DeleteOutlineOutlined /></span>} */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={4} justifyContent="flex-end">
                <Grid container item sm={2} xs={3}>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="inherit"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container item sm={2} xs={3}>
                  <Button
                    disabled={
                      questionsList.length > 0 || skipQuestionList.length > 0
                        ? false
                        : true
                    }
                    style={{ width: "100%" }}
                    variant="contained"
                    color="inherit"
                    onClick={PreviewSurveyForm}
                  >
                    Preview
                  </Button>
                </Grid>
                <Grid container item sm={2} xs={3}>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <Loader loading={loading} small>
                      Save
                    </Loader>
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {/* ------------------------------------------------ Modal for add questionsList ------------------------------------------------ */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAddQuestionModal}>
          <div className="question_add_modal_title">
            <h1 className="modal_title_heading">
              {editingIndex !== null ? "EDIT QUESTION" : "ADD QUESTION"}
            </h1>
            <button onClick={() => handleClose()}>X</button>
          </div>
          <div className="question_add_from_wrapper">
            <Formik
              initialValues={{
                question_en:
                  editingIndex !== null
                    ? questionsList[editingIndex].question[0]
                    : "",
                question_fr:
                  editingIndex !== null
                    ? questionsList[editingIndex].question[1]
                    : "",

                type:
                  editingIndex !== null
                    ? questionsList[editingIndex].type
                    : "text",
                options:
                  editingIndex !== null
                    ? questionsList[editingIndex].options
                    : [{ value_en: "", value_fr: "" }],

                isRequired:
                  editingIndex !== null
                    ? questionsList[editingIndex].isRequired
                    : false,

                isOtherOption:
                  editingIndex !== null
                    ? questionsList[editingIndex].isOtherOption
                    : false,
                groupId:
                  editingIndex !== null
                    ? questionsList[editingIndex].groupId
                    : "",
                rows:
                  editingIndex !== null
                    ? questionsList[editingIndex].rows
                    : [{ value_en: "", value_fr: "" }], // Assuming starting with one empty row
                columns:
                  editingIndex !== null
                    ? questionsList[editingIndex].columns
                    : [{ value_en: "", value_fr: "" }]
              }}
              enableReinitialize={true}
              validationSchema={questionValidationSchema}
              onSubmit={(values, { resetForm, setValues, setFieldValue }) => {
                setQuestionType("simple");
                // Common logic for all question types
                let questionData = {
                  question: [values.question_en, values.question_fr],
                  isRequired: values.isRequired,
                  isOtherOption: values.isOtherOption,
                  type: values.type,
                  groupId: values.groupId,
                  uniqueID:
                    editingIndex !== null
                      ? questionsList[editingIndex].uniqueID
                      : generateRandomId()
                };

                // Conditional handling based on question type
                if (values.type === "text") {
                  // For text type, ensure options are cleared
                  questionData.options = [];
                } else if (
                  values.type === "tabular" ||
                  values.type === "tabular_checkbox" ||
                  values.type === "tabular_text"
                ) {
                  // For tabular type, structure rows and columns data
                  questionData.rows = values.rows.map(row => ({
                    value_en: row.value_en,
                    value_fr: row.value_fr
                  }));
                  questionData.columns = values.columns.map(column => ({
                    value_en: column.value_en,
                    value_fr: column.value_fr
                  }));
                } else {
                  // For radio, checkbox, and other types that use options
                  questionData.options = values.options.map(option => ({
                    value_en: option.value_en,
                    value_fr: option.value_fr
                  }));
                }

                // Handle updating or adding new question
                if (editingIndex !== null) {
                  const updatedQuestions = [...questionsList];
                  updatedQuestions[editingIndex] = {
                    ...questionData,
                    serialNo: editingIndex + 1
                  };
                  setQuestionsList(updatedQuestions);
                } else {
                  const nextSerial = questionsList.length + 1;
                  questionData = { ...questionData, serialNo: nextSerial };
                  setQuestionsList(prevQuestions => [
                    ...prevQuestions,
                    questionData
                  ]);
                }

                // Close modal or reset form based on action
                const submitButtonName = document.activeElement.name;
                if (submitButtonName !== "saveAndAddNew") {
                  handleClose(); // Close the modal for single save action
                } else {
                  // Reset form fields but keep the modal open for adding another question
                  resetForm({
                    values: {
                      question_en: "",
                      question_fr: "",
                      options: [{ value_en: "", value_fr: "" }],
                      isRequired: false,
                      type: "text",
                      groupId: "",
                      rows: [{ value_en: "", value_fr: "" }], // Reset rows for tabular
                      columns: [{ value_en: "", value_fr: "" }] // Reset columns for tabular
                    }
                  });
                }

                setEditingIndex(null); // Reset editing index
              }}
            >
              {({ values, handleChange, setFieldValue, errors }) => {
                console.log("🚀 ~ DynamicSurveyForm ~ errors:", errors);

                return (
                  <Form className="dynamic_survey_simplequestion_form">
                    <div className="question-modal">
                      {/*--------------------------- Group Name Select Field ---------------------------  */}
                      <div className="question-form-group">
                        <label className="input_labels">Question Group</label>
                        <div className="question-select-block">
                          <FormControl fullWidth>
                            <Select
                              error={errors.groupId ? true : false}
                              name="groupId"
                              displayEmpty
                              renderValue={selected => {
                                if (selected === "") {
                                  return <em>Select Group</em>; // Placeholder text
                                }
                                return (
                                  questionGroupList
                                    .find(item => item.groupId === selected)
                                    ?.groupName[0].toUpperCase() || ""
                                );
                              }}
                              onChange={handleChange}
                              value={values.groupId || ""}
                              variant="outlined"
                              className="custom-select-control"
                            >
                              <MenuItem value="" disabled>
                                Group
                              </MenuItem>
                              {questionGroupList.map(item => (
                                <MenuItem
                                  key={item.groupId}
                                  value={item.groupId}
                                >
                                  {item?.groupName[0]?.toUpperCase()}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="question-form-group">
                        <label className="input_labels">Question (EN)</label>
                        <div className="question-input-block">
                          <div className="input-wrapper">
                            <input
                              className={
                                errors.question_en
                                  ? "question-input-error"
                                  : "question-input"
                              }
                              type="text"
                              autoComplete="off"
                              name="question_en"
                              label="Question"
                              onChange={handleChange}
                              value={values.question_en}
                            />
                            {values.question_en && (
                              <button
                                type="button"
                                className="clear-icon"
                                onClick={() => setFieldValue("question_en", "")}
                              >
                                ×
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="question-form-group">
                        <label className="input_labels">Question (FR)</label>
                        <div className="question-input-block">
                          <div className="input-wrapper">
                            <input
                              className={
                                errors.question_fr
                                  ? "question-input-error"
                                  : "question-input"
                              }
                              type="text"
                              autoComplete="off"
                              name="question_fr"
                              label="Question"
                              onChange={handleChange}
                              value={values.question_fr}
                            />
                            {values.question_fr && (
                              <button
                                type="button"
                                className="clear-icon"
                                onClick={() => setFieldValue("question_fr", "")}
                              >
                                ×
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="check-form-label">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isRequired"
                                checked={values.isRequired}
                                onChange={handleChange}
                              />
                            }
                            label="Answer is required"
                          />
                        </div>
                      </div>

                      <div className="question-form-group">
                        <label>Question Type</label>
                        <div className="question-select-block">
                          <select
                            name="type"
                            onChange={handleChange}
                            value={values.type}
                            disabled={editingIndex !== null}
                          >
                            <option value="text">Text</option>
                            <option value="radio">Radio</option>
                            <option value="checkbox">Checkbox</option>
                            <option value="rating">Rating</option>
                            <option value="tabular">Tabular Formate</option>
                            <option value="tabular_checkbox">
                              Tabular Checkbox Formate
                            </option>
                            <option value="tabular_text">
                              Tabular Text Field Formate
                            </option>
                          </select>
                        </div>
                      </div>
                      {values.type === "checkbox" && (
                        <div className="check-form-label">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isOtherOption"
                                checked={values.isOtherOption}
                                onChange={handleChange}
                              />
                            }
                            label="Is Other Option?"
                          />
                        </div>
                      )}
                      {(values.type === "radio" ||
                        values.type === "checkbox") && (
                        <div className="option-group">
                          {/* <label>Add More</label> */}
                          <button
                            className="add-btn"
                            onClick={() => {
                              setFieldValue("options", [
                                ...values.options,
                                { value_en: "", value_fr: "" }
                              ]);
                            }}
                          >
                            Add Option +
                          </button>
                        </div>
                      )}
                      <div className="option-block">
                        {(values.type === "radio" ||
                          values.type === "checkbox") &&
                          values.options.map((option, index) => {
                            const handleOptionChange = (
                              event,
                              index,
                              language
                            ) => {
                              const newOptions = [...values.options];
                              if (language === "en") {
                                newOptions[index].value_en = event.target.value;
                              } else if (language === "fr") {
                                newOptions[index].value_fr = event.target.value;
                              }
                              setFieldValue("options", newOptions);
                            };

                            return (
                              <div
                                className="main_checbox_radio_wrapper"
                                key={index}
                              >
                                <div className="option-form-group">
                                  <div className="question-option-input-block">
                                    <input
                                      placeholder={`Option-${index + 1} (EN)`}
                                      className={
                                        errors.options &&
                                        errors.options[index]?.value_en
                                          ? "option-input-error"
                                          : "option-input"
                                      }
                                      type="text"
                                      name={`options[${index}].value_en`}
                                      value={option.value_en} // this is where the change is made
                                      onChange={e =>
                                        handleOptionChange(e, index, "en")
                                      }
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="option-form-group">
                                  <div className="question-option-input-block">
                                    <input
                                      placeholder={`Option-${index + 1} (FR)`}
                                      className={
                                        errors.options &&
                                        errors.options[index]?.value_fr
                                          ? "option-input-error"
                                          : "option-input"
                                      }
                                      type="text"
                                      name={`options[${index}].value_fr`}
                                      value={option.value_fr} // this is where the change is made
                                      onChange={e =>
                                        handleOptionChange(e, index, "fr")
                                      }
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="delete_option_btn">
                                  {values.options.length > 1 && (
                                    <button
                                      type="button"
                                      className="remove-btn"
                                      onClick={() => {
                                        const newOptions = [...values.options];
                                        newOptions.splice(index, 1);
                                        setFieldValue("options", newOptions);
                                      }}
                                    >
                                      <DeleteOutlineOutlined />
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {(values.type === "tabular" ||
                        values.type === "tabular_checkbox" ||
                        values.type === "tabular_text") && (
                        <>
                          <FieldArray name="rows">
                            {({ push, remove }) => (
                              <>
                                <h2>Rows (Criteria)</h2>
                                {values.rows.map((row, index) => (
                                  <div
                                    className="tabuar_row_wrapper"
                                    key={index}
                                  >
                                    <Field
                                      className={
                                        errors.rows &&
                                        errors.rows[index]?.value_en
                                          ? "option-input-error"
                                          : "option-input"
                                      }
                                      name={`rows[${index}].value_en`}
                                      placeholder="English"
                                    />
                                    <Field
                                      className={
                                        errors.rows &&
                                        errors.rows[index]?.value_fr
                                          ? "option-input-error"
                                          : "option-input"
                                      }
                                      name={`rows[${index}].value_fr`}
                                      placeholder="French"
                                    />
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteOutlineOutlined />
                                    </button>
                                  </div>
                                ))}
                                <div className="option-group">
                                  <button
                                    className="add-btn"
                                    type="button"
                                    onClick={() =>
                                      push({ value_en: "", value_fr: "" })
                                    }
                                  >
                                    Add Row
                                  </button>
                                </div>
                              </>
                            )}
                          </FieldArray>

                          <FieldArray name="columns">
                            {({ push, remove }) => (
                              <>
                                <h2>Columns (Values)</h2>
                                {values.columns.map((column, index) => (
                                  <div
                                    className="tabuar_column_wrapper"
                                    key={index}
                                  >
                                    <Field
                                      className={
                                        errors.columns &&
                                        errors.columns[index]?.value_en
                                          ? "option-input-error"
                                          : "option-input"
                                      }
                                      name={`columns[${index}].value_en`}
                                      placeholder="English"
                                    />
                                    <Field
                                      className={
                                        errors.columns &&
                                        errors.columns[index]?.value_fr
                                          ? "option-input-error"
                                          : "option-input"
                                      }
                                      name={`columns[${index}].value_fr`}
                                      placeholder="French"
                                    />
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteOutlineOutlined />
                                    </button>
                                  </div>
                                ))}
                                <div className="option-group">
                                  <button
                                    className="add-btn"
                                    type="button"
                                    onClick={() =>
                                      push({ value_en: "", value_fr: "" })
                                    }
                                  >
                                    Add Column
                                  </button>
                                </div>
                              </>
                            )}
                          </FieldArray>
                        </>
                      )}
                      <div className="add-question-btn">
                        <button type="submit">
                          {editingIndex !== null ? "SAVE" : "ADD QUESTION"}
                        </button>
                        {editingIndex == null && (
                          <button type="submit" name="saveAndAddNew">
                            SAVE & ADD NEW QUESTION
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Box>
      </Modal>
      {/* ------------------------------------------------ Modal for skip question ------------------------------------------------ */}
      <Modal
        open={skipQuestionopen}
        onClose={handleSkipQuestionModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="add_skip_que_title_div">
            <h1 className="modal_title_heading">
              {Object.keys(skipLogicEditData).length > 0
                ? "EDIT QUESTION"
                : "ADD QUESTION"}
            </h1>
            <button onClick={() => handleSkipQuestionModalClose()}>X</button>
          </div>
          <div className="skip_question_add_from_wrapper">
            <Formik
              enableReinitialize
              validationSchema={skipQuestionValidationSchema}
              initialValues={skipQuestionInitialValues}
              onSubmit={handleSkipQuestionSubmit}
            >
              {({ values, setFieldValue, errors, handleChange }) => {
                return (
                  <Form className="skip-form-wrapper">
                    <div className="skip-flex-block">
                      <label>Main Question (EN):</label>
                      <div className="input-wrapper-skip-question">
                        <Field
                          className={`${
                            errors.mainQuestion?.question_en ? "error" : ""
                          }`}
                          type="text"
                          name="mainQuestion.question_en"
                          placeholder="Enter your main question"
                        />
                        {values.mainQuestion?.question_en && (
                          <button
                            type="button"
                            className="clear-icon-skip-question"
                            onClick={() =>
                              setFieldValue("mainQuestion.question_en", "")
                            }
                          >
                            ×
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="skip-flex-block">
                      <label>Main Question (FR):</label>
                      <div className="input-wrapper-skip-question">
                        <Field
                          className={`${
                            errors.mainQuestion?.question_fr ? "error" : ""
                          }`}
                          type="text"
                          name="mainQuestion.question_fr"
                          placeholder="Enter your main question"
                        />
                        {values.mainQuestion?.question_fr && (
                          <button
                            type="button"
                            className="clear-icon-skip-question"
                            onClick={() =>
                              setFieldValue("mainQuestion.question_fr", "")
                            }
                          >
                            ×
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="skip-question-type-flex-block">
                      <label>Question Type:</label>
                      <Field
                        className
                        as="select"
                        name="mainQuestion.type"
                        disabled
                      >
                        <option value="radio">Radio</option>
                      </Field>
                    </div>

                    {values.mainQuestion.type === "radio" && (
                      <FieldArray name="mainQuestion.options">
                        {({ remove, push }) => (
                          <div>
                            {values.mainQuestion.options.map(
                              (option, index) => (
                                <>
                                  <label>{`${"Option" +
                                    " " +
                                    [index + 1]} (EN):`}</label>
                                  <div
                                    key={index}
                                    className="skip-section-wrapper"
                                  >
                                    <Field
                                      className={
                                        errors.mainQuestion?.options &&
                                        errors.mainQuestion.options[index] &&
                                        errors.mainQuestion.options[index]
                                          .value_en
                                          ? "error"
                                          : ""
                                      }
                                      name={`mainQuestion.options.${index}.value_en`}
                                      placeholder={`Option ${index + 1}`}
                                    />

                                    <Field
                                      as="select"
                                      name={`mainQuestion.options.${index}.nextQuestionID`}
                                    >
                                      <option value="">Link to Section</option>
                                      {values.mainQuestion.subQuestions.map(
                                        (subQuestion, sqIndex) => (
                                          <option
                                            key={sqIndex}
                                            value={subQuestion.uniqueID}
                                          >
                                            {subQuestion.sectionName}
                                          </option>
                                        )
                                      )}
                                    </Field>
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                    >
                                      <Delete />
                                    </button>
                                  </div>
                                  <label>{`${"Option" +
                                    " " +
                                    [index + 1]} (FR):`}</label>
                                  <div
                                    key={index + 1}
                                    className="skip-section-wrapper"
                                  >
                                    <Field
                                      className={
                                        errors.mainQuestion?.options &&
                                        errors.mainQuestion.options[index] &&
                                        errors.mainQuestion.options[index]
                                          .value_fr
                                          ? "error"
                                          : ""
                                      }
                                      name={`mainQuestion.options.${index}.value_fr`}
                                      placeholder={`Option ${index + 1}`}
                                    />
                                    {/* {errors.mainQuestion?.options && errors.mainQuestion.options[index] && errors.mainQuestion.options[index].value &&
                                                            <div className="error">{errors.mainQuestion.options[index].value}</div>} */}
                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                    >
                                      <Delete />
                                    </button>
                                  </div>
                                </>
                              )
                            )}
                            <div className="skip-add-option">
                              <button
                                type="button"
                                className="skip-option-btn"
                                onClick={() =>
                                  push({
                                    value_en: "",
                                    value_fr: "",
                                    nextQuestionID: ""
                                  })
                                }
                              >
                                Add Option +
                              </button>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    )}

                    <FieldArray name="mainQuestion.subQuestions">
                      {({ remove, push }) => (
                        <div>
                          {values.mainQuestion.subQuestions.map(
                            (subQuestion, index) => (
                              <>
                                <div className="skip-question-section-main-wrapper">
                                  <h3 className="skip-title-flex">
                                    {subQuestion.sectionName}
                                    {isSectionLinked(
                                      subQuestion.uniqueID,
                                      values.mainQuestion.options,
                                      values.mainQuestion.subQuestions
                                    ) ? (
                                      " (Linked with option)"
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <DeleteOutlineOutlined />
                                      </button>
                                    )}
                                  </h3>

                                  <div
                                    key={index}
                                    className="skip-section-main-wrapper"
                                  >
                                    <div className="skip-sub-section-item">
                                      <label>Question Title (EN):</label>
                                      <Field
                                        className={
                                          errors.mainQuestion?.subQuestions &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ] &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ].question_en_title
                                            ? "error"
                                            : ""
                                        }
                                        type="text"
                                        name={`mainQuestion.subQuestions.${index}.question_en_title`}
                                        placeholder="Enter your question title"
                                      />
                                    </div>
                                    <div className="skip-sub-section-item">
                                      <label>Question (EN):</label>
                                      <Field
                                        className={
                                          errors.mainQuestion?.subQuestions &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ] &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ].question_en
                                            ? "error"
                                            : ""
                                        }
                                        type="text"
                                        name={`mainQuestion.subQuestions.${index}.question_en`}
                                        placeholder="Enter your question"
                                      />
                                    </div>
                                    <div className="skip-sub-section-item">
                                      <label>Question Title (FR):</label>
                                      <Field
                                        className={
                                          errors.mainQuestion?.subQuestions &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ] &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ].question_fr_title
                                            ? "error"
                                            : ""
                                        }
                                        type="text"
                                        name={`mainQuestion.subQuestions.${index}.question_fr_title`}
                                        placeholder="Enter your question title"
                                      />
                                    </div>
                                    <div className="skip-sub-section-item">
                                      <label>Question (FR):</label>
                                      <Field
                                        className={
                                          errors.mainQuestion?.subQuestions &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ] &&
                                          errors.mainQuestion.subQuestions[
                                            index
                                          ].question_fr
                                            ? "error"
                                            : ""
                                        }
                                        type="text"
                                        name={`mainQuestion.subQuestions.${index}.question_fr`}
                                        placeholder="Enter your question"
                                      />
                                    </div>
                                    <div className="skip-sub-section-item">
                                      <label>Type:</label>
                                      <Field
                                        as="select"
                                        name={`mainQuestion.subQuestions.${index}.type`}
                                      >
                                        <option value="text">Text</option>
                                        <option value="radio">Radio</option>
                                        <option value="checkbox">
                                          Checkbox
                                        </option>
                                        <option value="rating">Rating</option>
                                        <option value="tabular">
                                          Tabular Formate
                                        </option>
                                        <option value="tabular_checkbox">
                                          Tabular Checkbox Formate
                                        </option>
                                        <option value="tabular_text">
                                          Tabular Text Field Formate
                                        </option>
                                      </Field>
                                    </div>
                                    {subQuestion.type === "checkbox" && (
                                      <div className="check-form-label">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name={`mainQuestion.subQuestions.${index}.isOtherOption`}
                                              checked={
                                                values.mainQuestion
                                                  .subQuestions[index]
                                                  ?.isOtherOption
                                              }
                                              onChange={handleChange}
                                            />
                                          }
                                          label="Is Other Option?"
                                        />
                                      </div>
                                    )}
                                    {(subQuestion.type === "radio" ||
                                      subQuestion.type === "checkbox") && (
                                      <FieldArray
                                        name={`mainQuestion.subQuestions.${index}.options`}
                                      >
                                        {({ remove, push }) => (
                                          <div>
                                            {subQuestion.options.map(
                                              (option, optIndex) => (
                                                <div
                                                  key={optIndex}
                                                  className="skip-section-wrapper"
                                                >
                                                  <Field
                                                    className={
                                                      errors.mainQuestion
                                                        ?.subQuestions &&
                                                      errors.mainQuestion
                                                        .subQuestions[index]
                                                        ?.options &&
                                                      errors.mainQuestion
                                                        .subQuestions[index]
                                                        .options[optIndex] &&
                                                      errors.mainQuestion
                                                        .subQuestions[index]
                                                        .options[optIndex]
                                                        .value_en
                                                        ? "error"
                                                        : ""
                                                    }
                                                    name={`mainQuestion.subQuestions.${index}.options.${optIndex}.value_en`}
                                                    placeholder={`Option ${optIndex +
                                                      1} (EN)`}
                                                  />
                                                  <Field
                                                    className={
                                                      errors.mainQuestion
                                                        ?.subQuestions &&
                                                      errors.mainQuestion
                                                        .subQuestions[index]
                                                        ?.options &&
                                                      errors.mainQuestion
                                                        .subQuestions[index]
                                                        .options[optIndex] &&
                                                      errors.mainQuestion
                                                        .subQuestions[index]
                                                        .options[optIndex]
                                                        .value_fr
                                                        ? "error"
                                                        : ""
                                                    }
                                                    name={`mainQuestion.subQuestions.${index}.options.${optIndex}.value_fr`}
                                                    placeholder={`Option ${optIndex +
                                                      1} (FR)`}
                                                  />

                                                  {subQuestion.type ===
                                                    "radio" && (
                                                    <Field
                                                      as="select"
                                                      name={`mainQuestion.subQuestions.${index}.options.${optIndex}.nextQuestionID`}
                                                    >
                                                      <option value="">
                                                        Link to Section
                                                      </option>
                                                      {values.mainQuestion.subQuestions.map(
                                                        (sq, sqIndex) => (
                                                          <option
                                                            key={sqIndex}
                                                            value={sq.uniqueID}
                                                          >
                                                            {sq.sectionName}
                                                          </option>
                                                        )
                                                      )}
                                                    </Field>
                                                  )}
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      remove(optIndex)
                                                    }
                                                  >
                                                    <Delete />
                                                  </button>
                                                </div>
                                              )
                                            )}
                                            <button
                                              type="button"
                                              className="skip-option-btn"
                                              onClick={() =>
                                                push({
                                                  value: "",
                                                  nextQuestionID: ""
                                                })
                                              }
                                            >
                                              Add Option +
                                            </button>
                                          </div>
                                        )}
                                      </FieldArray>
                                    )}
                                    {(subQuestion.type === "tabular" ||
                                      subQuestion.type === "tabular_checkbox" ||
                                      subQuestion.type === "tabular_text") && (
                                      <>
                                        <FieldArray
                                          name={`mainQuestion.subQuestions.${index}.rows`}
                                        >
                                          {({ push, remove }) => (
                                            <>
                                              <h2>Rows (Criteria)</h2>
                                              {(subQuestion.rows ?? []).map(
                                                (row, rowIndex) => (
                                                  <div
                                                    className="tabular_row_wrapper"
                                                    key={rowIndex}
                                                  >
                                                    <Field
                                                      className={
                                                        errors.mainQuestion
                                                          ?.subQuestions &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          ?.rows &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          .rows[rowIndex]
                                                          ?.value_en
                                                          ? "option-input-error"
                                                          : "option-input"
                                                      }
                                                      name={`mainQuestion.subQuestions.${index}.rows[${rowIndex}].value_en`}
                                                      placeholder="English"
                                                    />
                                                    <Field
                                                      className={
                                                        errors.mainQuestion
                                                          ?.subQuestions &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          ?.rows &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          .rows[rowIndex]
                                                          ?.value_fr
                                                          ? "option-input-error"
                                                          : "option-input"
                                                      }
                                                      name={`mainQuestion.subQuestions.${index}.rows[${rowIndex}].value_fr`}
                                                      placeholder="French"
                                                    />
                                                    <button
                                                      type="button"
                                                      aria-label="Remove row"
                                                      onClick={() =>
                                                        remove(rowIndex)
                                                      }
                                                    >
                                                      <DeleteOutlineOutlined />
                                                    </button>
                                                  </div>
                                                )
                                              )}
                                              <div className="option-group">
                                                <button
                                                  type="button"
                                                  aria-label="Add row"
                                                  onClick={() =>
                                                    push({
                                                      value_en: "",
                                                      value_fr: ""
                                                    })
                                                  }
                                                >
                                                  Add Row
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </FieldArray>

                                        <FieldArray
                                          name={`mainQuestion.subQuestions.${index}.columns`}
                                        >
                                          {({ push, remove }) => (
                                            <>
                                              <h2>Columns (Values)</h2>
                                              {(subQuestion.columns ?? []).map(
                                                (column, columnIndex) => (
                                                  <div
                                                    className="tabular_column_wrapper"
                                                    key={columnIndex}
                                                  >
                                                    <Field
                                                      className={
                                                        errors.mainQuestion
                                                          ?.subQuestions &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          ?.columns &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          .columns[columnIndex]
                                                          ?.value_en
                                                          ? "option-input-error"
                                                          : "option-input"
                                                      }
                                                      name={`mainQuestion.subQuestions.${index}.columns[${columnIndex}].value_en`}
                                                      placeholder="English"
                                                    />
                                                    <Field
                                                      className={
                                                        errors.mainQuestion
                                                          ?.subQuestions &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          ?.columns &&
                                                        errors.mainQuestion
                                                          .subQuestions[index]
                                                          .columns[columnIndex]
                                                          ?.value_fr
                                                          ? "option-input-error"
                                                          : "option-input"
                                                      }
                                                      name={`mainQuestion.subQuestions.${index}.columns[${columnIndex}].value_fr`}
                                                      placeholder="French"
                                                    />
                                                    <button
                                                      type="button"
                                                      aria-label="Remove column"
                                                      onClick={() =>
                                                        remove(columnIndex)
                                                      }
                                                    >
                                                      <DeleteOutlineOutlined />
                                                    </button>
                                                  </div>
                                                )
                                              )}
                                              <div className="option-group">
                                                <button
                                                  type="button"
                                                  aria-label="Add column"
                                                  onClick={() =>
                                                    push({
                                                      value_en: "",
                                                      value_fr: ""
                                                    })
                                                  }
                                                >
                                                  Add Column
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </FieldArray>
                                      </>
                                    )}

                                    <div className="skip-sub-section-item">
                                      <label>Next Section:</label>
                                      <Field
                                        as="select"
                                        name={`mainQuestion.subQuestions.${index}.nextSection`}
                                      >
                                        <option value="">Select</option>
                                        <option value="submit">
                                          Submit Form
                                        </option>
                                        {values.mainQuestion.subQuestions.map(
                                          (sq, sqIndex) =>
                                            sqIndex !== index ? ( // Exclude the current section
                                              <option
                                                key={sqIndex}
                                                value={sq.uniqueID}
                                              >
                                                {sq.sectionName}
                                              </option>
                                            ) : null
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          )}

                          <button
                            type="button"
                            className="skip-add-section"
                            onClick={() =>
                              push({
                                uniqueID: generateRandomId(),
                                sectionName: `Section-${values.mainQuestion
                                  .subQuestions.length + 1}`,
                                type: "text",
                                question: "",
                                isRequired: false,
                                options: [{ value: "", nextQuestionID: "" }]
                              })
                            }
                          >
                            Add Section
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <div className="skip-save-block">
                      <button type="submit" className="skip-save-button">
                        SAVE
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Box>
      </Modal>
      {/* ------------------------------------------------ Preview Modal ------------------------------------------------ */}
      <Modal
        open={previewopen}
        onClose={() => setPreviewopen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{ ...style, bgcolor: "#E7E6E6", maxWidth: "50%" }}
          className="skip-box-modal"
        >
          <div className="add_skip_que_title_div">
            <h1>Your survey form look like this!</h1>
            <button onClick={() => setPreviewopen(false)}>X</button>
          </div>
          <div className="question-form-title">
            <h2>{data?.title ? data?.title : "Ennova Dynamic Survey Title"}</h2>
            <div className="survey-image">
              <img
                src={data?.image ? data.image : "https://fakeimg.pl/650x400/"}
                alt="Survey"
              />
            </div>
            <p>
              {data?.description
                ? data?.description
                : "This is your survey description"}
            </p>

            <div className="survey-goal-list">
              {data?.goal?.length > 0 ? (
                data.goal.map((item, index) => (
                  <p>
                    {index + 1}. {item}
                  </p>
                ))
              ) : (
                <>
                  <p>1. 1st goal is show here</p>
                  <p>2. 2nd goal is show here</p>
                  <p>3. 3rd goal is show here</p>
                </>
              )}
            </div>
          </div>
          <div className="question-form-details">
            <label>
              Email <span className="question-requried-label">*</span>
            </label>
            <input type="text" name="email" />
          </div>
          {questionType == "simple" ? (
            questionsList.map((question, index) => (
              <>
                <div className="question-form-item" key={question.uniqueID}>
                  <h4>
                    {question.question[0]}{" "}
                    <span className="required_sign">
                      {question.isRequired == true ? "*" : null}
                    </span>
                  </h4>
                  {question.type === "text" && (
                    <>
                      <textarea></textarea>
                    </>
                  )}

                  {question.type === "rating" && (
                    <div className="rating">
                      <Rating
                        size="large"
                        // emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      />
                    </div>
                  )}

                  {question.type === "radio" && (
                    <>
                      {question.options.map((option, index) => (
                        <div key={index} className="radio-label">
                          <input
                            type="radio"
                            id={`${question.uniqueID}_${option}`}
                            name={question.uniqueID}
                            value={option.value}
                          />
                          <label
                            htmlFor={`${question.uniqueID}_${option.value}`}
                          >
                            {option.value_en}
                          </label>{" "}
                          {/* Use option.value here */}
                        </div>
                      ))}
                    </>
                  )}

                  {question.type === "checkbox" &&
                    question.options.map((option, index) => (
                      <div key={index} className="checkbox-label">
                        <input
                          type="checkbox"
                          name={question.uniqueID}
                          value={option.value}
                          id={`${question.uniqueID}_${option}`}
                        />
                        <label htmlFor={`${question.uniqueID}_${option.value}`}>
                          {option.value_en}
                        </label>
                      </div>
                    ))}

                  {question.type === "tabular" && (
                    <table>
                      <thead>
                        <tr>
                          <th>Criteria</th>
                          {question.columns.map((column, index) => (
                            <th key={index}>{column.value_en}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {question.rows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td>{row.value_en}</td>
                            {question.columns.map((column, columnIndex) => (
                              <td key={columnIndex}>
                                <input
                                  type="radio"
                                  id={`${row.value_en}_${columnIndex}`}
                                  name={row.value_en}
                                  value={column.value_en}
                                />
                                {/* <Field type="radio" name={row.value_en} /> */}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ))
          ) : (
            <>
              {skipQuestionList?.map(item => (
                <div className="question-form-item" key={item.uniqueID}>
                  <h4>{item.question[0]}</h4>
                  {item.type === "radio" && (
                    <>
                      {item.options.map((option, index) => (
                        <div key={index} className="radio-label">
                          <input
                            type="radio"
                            id={`${item.uniqueID}_${option}`}
                            name={item.uniqueID}
                            value={option.value}
                          />
                          <label htmlFor={`${item.uniqueID}_${option.value}`}>
                            {option.value_en}
                          </label>{" "}
                          {/* Use option.value here */}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}{" "}
              <div className="next-btn">
                <button type="button">Next</button>
              </div>
            </>
          )}
          {questionType == "simple" && (
            <div className="submit-btn">
              <button type="submit">Submit</button>
            </div>
          )}
        </Box>
      </Modal>
      {/* ------------------------------------------------ Question Group Modal ------------------------------------------------ */}
      <Modal
        open={questionGroupopen}
        onClose={() => setQuestionGroupopen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleGroupModal, maxWidth: "45%" }}>
          <div className="group_modal_title">
            <h1 className="modal_title_heading">
              {editingGroupId !== null ? "EDIT GROUP" : "ADD GROUP"}
            </h1>
            <button onClick={() => handleGroupModalClose()}>X</button>
          </div>
          <div className="group_add_from_wrapper">
            <Formik
              enableReinitialize
              initialValues={{
                groupName_en: editingGroupId
                  ? questionGroupList.find(
                      group => group.groupId === editingGroupId
                    )?.groupName[0]
                  : "",
                groupName_fr: editingGroupId
                  ? questionGroupList.find(
                      group => group.groupId === editingGroupId
                    )?.groupName[1]
                  : ""

                // groupName: data?.questionGroup?.groupName ? data.questionGroup.groupName : '',
              }}
              onSubmit={questionGroupSubmit}
              validationSchema={questionGroupValidationSchema}
            >
              {({ values, handleChange, errors }) => {
                return (
                  <Form className="dynamic_survey_group_form">
                    <Grid container spacing={12} justifyContent="space-between">
                      <InputLabel className="input_labels">
                        Group Name (EN)<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        error={errors.groupName_en ? true : false}
                        type="text"
                        name="groupName_en"
                        onChange={handleChange}
                        value={
                          (values?.groupName_en && values.groupName_en) || ""
                        }
                        variant="outlined"
                        className="custom-textfield"
                      />
                      <InputLabel className="input_labels">
                        Group Name (FR)<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        error={errors.groupName_fr ? true : false}
                        type="text"
                        name="groupName_fr"
                        onChange={handleChange}
                        value={
                          (values?.groupName_fr && values.groupName_fr) || ""
                        }
                        variant="outlined"
                        className="custom-textfield"
                      />

                      <Grid container item sm={2} xs={3}>
                        <Button
                          style={{ width: "80%", padding: "10px" }}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          <Loader loading={loading} small>
                            Add
                          </Loader>
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DynamicSurveyForm;
