import React from 'react'
import { Formik, Form } from 'formik';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import LANGUAGES from '../../settings/enums/languages.json'
import history from '../../history';

const DynamicSurveyFilterForm = (props) => {

    const {
        handleClear,
        callback = () => { },
    } = props
    return (
        <Formik
            initialValues={{ keyword: '', isPublic: '', language: '' }}
            onSubmit={(values) => callback(values)}
        >
            {({
                values,
                handleChange,
                resetForm
            }) => {
                return <Form name="filter_newsfeed" >
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                name="keyword"
                                value={values.keyword}
                                onChange={handleChange}
                                label="Search Survey by Title"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel>Is Public</InputLabel>
                                <Select
                                    name='isPublic'
                                    value={values.isPublic}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel>Language</InputLabel>
                                <Select
                                    name='language'
                                    value={values.language}
                                    onChange={handleChange}
                                >
                                    {Object.entries(LANGUAGES).map(([key, value]) =>
                                        (<MenuItem value={key} key={key} >{value.toUpperCase()}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: 25 }}>
                        <Grid container spacing={4} justifyContent='flex-start'>
                            <Grid item xs={3} sm={2}>
                                <Button style={{ width: '100%' }} color="primary"
                                    variant="contained" type='submit'>
                                    APPLY
                                </Button>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <Button style={{ width: '100%' }} type="reset"
                                    color="inherit" variant="contained"
                                    onClick={() => {
                                        resetForm();
                                        handleClear()
                                    }}
                                >
                                    Clear Filter
                                </Button>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <Button style={{ width: '100%' }} type="button"
                                    color="primary" variant="contained"
                                    onClick={()=>history.push('/dynamicSurvey/new')}
                                >
                                   Add New Survey
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Form>
            }}
        </Formik>
    )
}

export default DynamicSurveyFilterForm