import React from 'react'
import {
    Button, Grid, Select, Input, FormControl,
    MenuItem, Box, Chip, TextField, InputLabel
} from '@material-ui/core'
import { Formik, Form } from 'formik';
import AFRICA_ISO_CODE from '../settings/enums/new-africa-iso-code.json'

const NewsFeddFilter = ({
    filters,
    handleClear,
    callback = () => { },
    data,
}) => {

    return (
        <>
            <Formik
                initialValues={{ keyword: '', type: '', countryCode: [] }}
                onSubmit={(values) => {
                    callback({
                        ...values,
                        countryCode: values.countryCode.map((item) => AFRICA_ISO_CODE[item])
                    })
                }}
            >
                {({ values, handleChange, handleSubmit, resetForm }) => (
                    <Form name="filter_newsfeed" >
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    name="keyword"
                                    label="Search Feed by Title"
                                    value={values.keyword}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    name="type"
                                    label="Search Feed by Type"
                                    value={values.type}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        multiple
                                        fullWidth
                                        displayEmpty
                                        id="countrySelect"
                                        name="countryCode"
                                        className="select-row"
                                        onChange={handleChange}
                                        labelId="countrylabelID"
                                        value={values?.countryCode ? values?.countryCode : []}
                                        input={<Input id="select-multiple-chip" label="Chip" />}
                                        renderValue={selected => (
                                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                                {selected.length >0&& selected?.map((value) => <Chip key={value} label={value} />)}                                            </Box>
                                        )}
                                    >
                                        <MenuItem disabled value=''>Country</MenuItem>
                                        {Object.entries(AFRICA_ISO_CODE).map(([key, value]) =>
                                            (<MenuItem value={key} key={key}>{key}</MenuItem>)
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <div style={{ marginTop: 25 }}>
                            <Grid container spacing={4} justifyContent='flex-start'>
                                <Grid item xs={3} sm={2}>
                                    <Button style={{ width: '100%' }} color="primary" variant="contained" type='button' onClick={() => handleSubmit()}>
                                        APPLY
                                    </Button>
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <Button style={{ width: '100%' }} color="inherit" variant="contained" onClick={() => {
                                        resetForm()
                                        handleClear(filters)
                                    }} type="reset">
                                        Clear Filter
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                )}
            </Formik >
        </>
    );
};

export default NewsFeddFilter;
