import React from 'react'
import { useSnackbar } from 'notistack'
import TopBar from '../components/TopBar'
import { useAuth } from '../utils'
import router from '../history'
import { JWT } from '../apollo/_config'

const TopBarContainer = ({
  drawerIsOpen,
  setDrawerIsOpen,
  title,
}) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar()


  const handleLogout = () => {
    auth.logout((e) => {
      if (!e) {
        enqueueSnackbar('You have been logged out', { variant: 'success' })
        localStorage.removeItem(JWT.LOCAL_STORAGE.TOKEN.NAME)
        localStorage.removeItem(JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME)
        router.replace('/login')

      }
    })
  }
  return (
    <TopBar
      onMenuClick={() => setDrawerIsOpen(!drawerIsOpen)}
      onLogoutClick={() => handleLogout()}
      title={title || 'Admin dashboard'}
    />
  )
}

export default TopBarContainer
