import React from 'react'
import { Formik, Form } from 'formik';
import SERVICES from '../settings/enums/categories.json'
import SECTORS from '../settings/enums/target-sectors.json'
import PERSONAL_INTERESTS from '../settings/enums/personal-interest.json'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'

const SurveyFilterForm = (props) => {
  const {
    handleClear,
    callback = () => { },
  } = props


  const addLabel = obj => Object.entries(obj).reduce((o, [k, v]) => ({
    ...o, [k]: { label: v }
  }), {})

  // ...BUSINESS_STAGE
  const CATEGORIES = {
    ...addLabel(PERSONAL_INTERESTS),
    ...SERVICES,
    ...addLabel(SECTORS),
  }

  return (
    <Formik
      initialValues={{ keyword: '', category: '', published: '', dateFrom: '', createdAt: '' }}
      onSubmit={(values) => callback(values)}
    >
      {({
        values,
        handleChange,
        resetForm,
        setFieldValue
      }) => {
        return <Form name="filter_newsfeed" >
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="keyword"
                value={values.keyword}
                onChange={handleChange}
                label="Search Survey by Title"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Categories</InputLabel>
                <Select
                  name='category'
                  value={values.category}
                  onChange={handleChange}
                >
                  {Object.entries(CATEGORIES).map(([key, value]) =>
                    (<MenuItem value={key} key={key} >{value?.label}</MenuItem>)
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <FormControl fullWidth>
                <InputLabel>Published</InputLabel>
                <Select
                  name='published'
                  value={values.published}
                  onChange={handleChange}
                >
                  <MenuItem value="" key="">None</MenuItem>
                  {["Published", "Unpublished", "All"].map((key) =>
                    <MenuItem value={key} key={key} >{key}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <InputLabel>Published date</InputLabel>
              <TextField
                fullWidth
                type="date"
                name='dateFrom'
                onChange={handleChange}
                value={values.dateFrom}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <InputLabel>Created At</InputLabel>
              <TextField
                fullWidth
                type="date"
                name='createdAt'
                onChange={handleChange}
                value={values.createdAt}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: new Date().toISOString().slice(0, 10) }}
              />
            </Grid>
          </Grid>

          <div style={{ marginTop: 25 }}>
            <Grid container spacing={4} justifyContent='flex-start'>
              <Grid item xs={3} sm={2}>
                <Button style={{ width: '100%' }} color="primary"
                  variant="contained" type='submit'>
                  APPLY
                </Button>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button style={{ width: '100%' }} type="reset"
                  color="inherit" variant="contained"
                  onClick={() => {
                    resetForm();
                    handleClear()
                  }}
                >
                  Clear Filter
                </Button>
              </Grid>
            </Grid>
          </div>
        </Form>
      }}
    </Formik>
  );
};

export default SurveyFilterForm