import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveRadar } from '@nivo/radar'


const buildBarData = (data, TOC) => Object.entries(data).filter(([key]) => key !== '__typename').reduce((arr, [key, value]) => [
  ...arr,
  ...Object.values(Object.entries(value).filter(([key]) => key !== '__typename').reduce((obj, [k, v]) => {
    const pillar = TOC[key]?.label
    const existingPillar = obj[pillar]
    let data = {
      pillar: TOC[key]?.label,
      [TOC[key]?.indicators[k]?.label.replace(/Number/, '#')]: v,
      [TOC[key]?.indicators[k]?.label.replace(/Number/, '#') + 'Color']: `hsl(${Math.round(Math.random() * 200)}, ${Math.round(Math.random() * 100)}%, 50%)`,
    }
    if (existingPillar) {
      return {
        ...obj,
        [pillar]: {
          ...obj[pillar],
          [TOC[key]?.indicators[k]?.label.replace(/Number/, '#')]: v,
          [TOC[key]?.indicators[k]?.label.replace(/Number/, '#') + 'Color']: `hsl(${Math.round(Math.random() * 200)}, ${Math.round(Math.random() * 100)}%, 50%)`,
        }
      }
    }
    return {
      ...obj,
      [pillar]: data,
    }
  }, {}))
], [])

const buildDonutData = (data, TOC) => Object.entries(data).filter(([key]) => key !== '__typename').reduce((arr, [key, value]) => [
  ...arr,
  ...Object.values(Object.entries(value).filter(([key]) => key !== '__typename').reduce((arr, [k, v]) => {
    const label = TOC[key]?.indicators[k]?.label
    let data = {
      id: label,
      label,
      value: v,
      color: `hsl(${Math.round(Math.random() * 200)}, ${Math.round(Math.random() * 100)}%, 50%)`,
    }

    return [
      ...arr,
      data,
    ]
  }, []))
], [])

const buildRadarData = (data, keys = [], TOC) => Object.entries(data).filter(([key]) => key !== '__typename').reduce((arr, [key, value]) => {
  const outcomes = Object.values(Object.entries(value).filter(([key]) => key !== '__typename').reduce((obj, [k, v]) => {
    const outcome = TOC[key]?.indicators[k]?.outcome || 'General'
    const pillar = TOC[key]?.label
    let data = {
      [outcome]: {
        outcome,
        [pillar]: v,
      }
    }
    return {
      ...obj,
      [outcome]: data,
    }
  }, {}))
  return [
    ...arr, ...outcomes
  ]
}, []).reduce((a, outcome) => {
  const outcomeExists = a.find(o => outcome[o.outcome] && o.outcome === outcome[o.outcome].outcome)

  if (outcomeExists) {
    return [
      ...a.map(o => {
        if (o.outcome === outcomeExists.outcome) {
          return {
            ...o,
            ...Object.values(outcome).reduce((obj, oc) => {
              return {
                ...obj,
                ...oc
              }
            }, {}),
          }
        }
        return o
      })
    ]
  }
  return [
    ...a,
    {
      ...Object.values(outcome).reduce((obj, oc) => {
        return {
          ...obj,
          ...oc
        }
      }, {}),
    }
  ]
}, []).reduce((arr, outcome) => {
  const data = keys.reduce((obj, key) => {
    return {
      ...obj,
      ...outcome,
      [key]: outcome[key] || 0,
    }
  }, {})
  return [
    ...arr,
    data
  ]
}, [])


export default ({ data: serverData, type = 'bar', TOC = {}, arrayForIndicators, filterIndicatorLabel }) => {



  const radarChartKeys = ['General', ...Object.values(TOC).map(({ label }) => label)]
  const radarData = buildRadarData(serverData, radarChartKeys, TOC).filter((obj) => Object.values(obj).reduce((sum, value) => typeof value === 'number' ? sum + value : sum, 0) > 0)
  const donutData = buildDonutData(serverData, TOC).filter(({ value }) => value > 0)
  const barData = buildBarData(serverData, TOC).filter((obj) => Object.values(obj).reduce((sum, value) => typeof value === 'number' ? sum + value : sum, 0) > 0)


  const barChartKeys = barData.map(item => Object.keys(item).filter(key => !['pillar', 'Color'].find(v => key.includes(v)))).flatMap(key => key) 
  if (!Object.keys(serverData || {}).length) return <div>Loading...</div>
  if (type === 'barformonth') return <BarforMonth data={serverData} chartKeys={filterIndicatorLabel} />
  if (type === 'bar') return <Bar data={barData} chartKeys={barChartKeys} />
  if (type === 'donut') return <Donut data={donutData} />
  if (type === 'radar') return <Radar data={radarData} chartKeys={radarChartKeys} />
  return null
}

const Bar = ({ data, chartKeys }) => {
  return (
    <div style={{ height: 600, marginTop: 60 }}>
      <span style={{ fontWeight: 700 }}>TOC COMPARISON</span>
      <ResponsiveBar
        data={data}
        keys={chartKeys}
        indexBy="pillar"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        valueFormat={{ format: '', enabled: false }}
        colors={{ scheme: 'nivo' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'TOC',
          legendPosition: 'middle',
          legendOffset: 42
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'number',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          // {
          //     dataFrom: 'keys',
          //     anchor: 'top-right',
          //     direction: 'column',
          //     justify: false,
          //     translateX: 120,
          //     translateY: -70,
          //     itemsSpacing: 50,
          //     itemWidth: 130,
          //     itemHeight: 60,
          //     itemDirection: 'bottom-to-top',
          //     itemOpacity: 0.85,
          //     symbolSize: 20,                
          //     effects: [
          //         {
          //             on: 'hover',
          //             style: {
          //                 itemOpacity: 1
          //             }
          //         }
          //     ]
          // }
        ]}
      />
    </div>
  )
}

const BarforMonth = ({ data, chartKeys }) => {
  return (
    <div style={{ height: 600, marginTop: 60 }}>
      <span style={{ fontWeight: 700 }}>TOC COMPARISON</span>
      <ResponsiveBar
        data={data}
        keys={chartKeys}
        indexBy="pillar"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        valueFormat={{ format: '', enabled: false }}
        colors={{ scheme: 'nivo' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'TOC',
          legendPosition: 'middle',
          legendOffset: 42
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'number',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          // {
          //     dataFrom: 'keys',
          //     anchor: 'top-right',
          //     direction: 'column',
          //     justify: false,
          //     translateX: 120,
          //     translateY: -70,
          //     itemsSpacing: 50,
          //     itemWidth: 130,
          //     itemHeight: 60,
          //     itemDirection: 'bottom-to-top',
          //     itemOpacity: 0.85,
          //     symbolSize: 20,                
          //     effects: [
          //         {
          //             on: 'hover',
          //             style: {
          //                 itemOpacity: 1
          //             }
          //         }
          //     ]
          // }
        ]}
      />
    </div>
  )
}

const Donut = ({ data }) => {
  return (
    <div style={{ height: 600, marginTop: 60 }}>
      <span style={{ fontWeight: 700 }}>INDICATORS TOTALS</span>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
      />
    </div>
  )
}

const Radar = ({ data, chartKeys = [] }) => {
  return (

    <div style={{ height: 600, marginTop: 60 }}>
      <span style={{ fontWeight: 700 }}>OUTCOME PERFORMANCE</span>
      <ResponsiveRadar
        data={data}
        keys={chartKeys}
        indexBy="outcome"
        maxValue="auto"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        curve="linearClosed"
        borderWidth={2}
        borderColor={{ from: 'color' }}
        gridLevels={5}
        gridShape="circular"
        gridLabelOffset={36}
        enableDots={true}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color' }}
        enableDotLabel={true}
        dotLabel="value"
        dotLabelYOffset={-12}
        colors={{ scheme: 'nivo' }}
        fillOpacity={0.25}
        blendMode="multiply"
        animate={true}
        motionConfig="wobbly"
        isInteractive={true}
        legends={[
          {
            anchor: 'top-left',
            direction: 'column',
            translateX: -50,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: '#999',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    </div>
  )
}