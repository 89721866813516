import React, { useEffect, useMemo, useState } from 'react'
import {
  SingleDatePicker,
} from "react-google-flight-datepicker"
import { useQuery } from '@apollo/react-hooks'
import METable from '../components/METable'
import MECharts from '../components/MECharts'
import Loader from '../components/Loader'
import { Button, Grid, InputLabel, MenuItem, FormHelperText, FormControl } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import CSVDownload from '../components/CustomDownloadLink'
import { getMaeQuery, getCustomIndicatorsQuery } from '../api'
import TOC from '../settings/enums/toc-pillar-indicators.json'
import { getMaeQueryMonths } from '../api/_queries'

const yearsArray = [2019, 2020, 2021, 2022,2023]

const arrayForIndicators = []
Object.values(TOC).forEach((item) => {
  Object.entries(item?.indicators).forEach(([key, val], index) => {
    arrayForIndicators.push({ slug: key, label: val.label })
  })
})




const transformData = data => {
  let csvData = data.reduce((arr, item) => {
    arr[0] = Object.entries(item).reduce((obj, [key, value]) => ({
      ...obj,
      ...(!obj[key]) && {
        [key]: Object.keys(obj).length
      }
    }), {})
    let shell = Array(Object.keys(item).length).join(".").split(".") // array of empty strings
    Object.entries(item).forEach(function ([key, value]) {
      shell[arr[0][key]] = value
    })
    return [
      ...arr,
      shell
    ]
  }, [{}])
  let shell = Array(Object.keys(csvData[0]).length)
  Object.entries(csvData[0]).forEach(function ([key, value]) {
    shell[csvData[0][key]] = key
  })
  csvData[0] = [...shell]
  return csvData
}

const pillarObj = { ...TOC }

export default () => {
  // let finalIndicatorArray = []
  // let count = 0
  const [range, setRange] = React.useState({
    from: undefined,
    to: undefined
  })
  const currentDate = new Date();
  const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const [filteredData, setFilteredData] = React.useState([]);
  const [indicator, setIndicator] = React.useState(arrayForIndicators[0].slug);
  const [year, setYear] = React.useState(yearsArray[yearsArray.length - 1]);
  const [finalmonthsArray, setFinalmonthsArray] = useState([])
  const [filterIndicatorLabel, setFilterIndicatorLabel] = useState([])
  const [combinedStats, setCombinedStats] = React.useState()
  const [combinedTOC, setCombinedTOC] = React.useState()
  const [csvData, setCSVData] = React.useState([])
  const [exportReady, setExportReady] = React.useState(0)
  const [fromDate, setFromDate] = React.useState(firstDateOfMonth)
  const [toDate, setToDate] = React.useState(new Date())

  const handleChangeforIndicator = (event) => {
    setIndicator(event.target.value);
  };



  const { data: { getMae1 } = {}, loading: loadingStatsForMonths, error: errorStatsForMonths } = useQuery(getMaeQueryMonths, {
    variables: {
      input: {
        "year": year,
        "indicator": indicator,
        "from": "2023-05-29T18:30:00.000Z",
        "to": toDate
      }
    },
    fetchPolicy: 'network-only'
  })

  const { data: { getMae } = {}, loading: loadingStats, error: errorStats } = useQuery(getMaeQuery, {
    variables: {
      ...(range.from || range.to) && {
        input: {
          from: range.from,
          to: range.to
        }
      }
    },
    fetchPolicy: 'network-only'
  })

  const { data: { getCustomIndicators } = {}, loading: loadingCustom, error: errorCustom } = useQuery(getCustomIndicatorsQuery, {
    variables: {
      ...(range.from || range.to) && {
        input: {
          from: range.from,
          to: range.to
        }
      }
    }
  })

  const loading = loadingCustom || loadingStats || loadingStatsForMonths
  const error = errorCustom || errorStats || errorStatsForMonths

  React.useEffect(() => {
    if ((!combinedStats && getMae && getCustomIndicators) || (combinedStats && getMae && getCustomIndicators && customIndicatorsLenghts.current.stats !== getCustomIndicators.length) || (combinedStats && getMae && getCustomIndicators && customIndicatorsLenghts.current.stats === getCustomIndicators.length && customIndicatorsLenghts.current.rangeMAE !== `${range.from}___${range.to}`)) {
      if (!loading) {
        customIndicatorsLenghts.current.stats = getCustomIndicators?.length

        customIndicatorsLenghts.current.rangeMAE = `${range.from}___${range.to}`

        const combined = { ...getMae }
        getCustomIndicators.forEach(indicator => {
          combined[indicator.pillar] = {
            ...combined[indicator.pillar],
            [indicator.name]: indicator.value
          }
        });
        setCombinedStats(combined)
        // console.log('RANGE MAE', customIndicatorsLenghts.current, range, getMae, combined)
      }
    }
  }, [getMae, getCustomIndicators, range, combinedStats, loading])

  React.useEffect(() => {
    if ((!combinedTOC && getCustomIndicators) || (combinedTOC && getCustomIndicators?.length !== customIndicatorsLenghts.current.toc) || (combinedTOC && getCustomIndicators?.length === customIndicatorsLenghts.current.toc && customIndicatorsLenghts.current.rangeTOC !== `${range.from}___${range.to}`)) {
      if (!loading) {
        customIndicatorsLenghts.current.toc = getCustomIndicators?.length
        customIndicatorsLenghts.current.rangeTOC = `${range.from}___${range.to}`
        const combined = { ...TOC }
        getCustomIndicators.forEach(indicator => {
          combined[indicator.pillar] = {
            ...combined[indicator.pillar],
            indicators: {
              ...combined[indicator.pillar].indicators,
              [indicator.name]: {
                label: indicator.name,
                outcome: indicator.outcome
              }
            }
          }
        });
        setCombinedTOC(combined)
        // console.log('RANGE TOC', customIndicatorsLenghts.current, range)
      }

    }
  }, [getCustomIndicators, range, combinedTOC, loading])


  const customIndicatorsLenghts = React.useRef({
    stats: undefined,
    toc: undefined,
    rangeMAE: `${range.from}___${range.to}`,
    rangeTOC: `${range.from}___${range.to}`
  })

  const computeExport = () => {
    setExportReady(exportReady + 1)
  }

  const resetExport = () => {
    setExportReady(0);
  }
  const enableExport = filteredData.length > 0
  const exportText = 'EXPORT'

  const setFilteredRows = (filtered) => {
    setFilteredData(filtered);
  }

  useEffect(() => {
    if (filteredData.length > 0 && enableExport && exportReady) {
      setCSVData(transformData(filteredData?.map(item => {
        const { __typename, location, logo, ...rest } = item
        return rest
      })))
    }
  }, [filteredData, enableExport, exportReady])

  useEffect(() => {
    if (getMae1 && indicator && arrayForIndicators) {

      const filterdArray = arrayForIndicators.find((item) => {
        return item.slug === indicator
      })
      const finalmonthsArray = []
      let indica = filterdArray?.label.replace('Number', '#').trim()

      setFilterIndicatorLabel(indica?.split(","))


      function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj)?.map(key => {
          const newKey = newKeys[key] || key;
          return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
      }

      Object.entries(getMae1?.obj).forEach(([key, val]) => {
        var temp1 = indica
        let obj1 = {
          pillar: key,
          text: val
        }
        let x1 = renameKeys(obj1, { text: temp1 });
        finalmonthsArray.push(x1)
      })

      if (finalmonthsArray.length > 0) {
        setFinalmonthsArray(finalmonthsArray)
      }

    }
  }, [getMae1, indicator])

  const METableMemo = useMemo(() => <METable data={getMae} customIndicators={getCustomIndicators} setFilteredRows={setFilteredRows} pillarObj={pillarObj} />, [getMae, getCustomIndicators])

  return <Loader loading={loading} error={error}>
    <div>
      <div style={{ display: 'flex', paddingBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid item sm={10} xs={10}>
            <DateRangeFilter range={range} setRange={setRange} resetExport={resetExport} />
          </Grid>
          <Grid item sm={2} xs={6}>
            <ExportButton
              style={{ display: "flex", verticalAlign: "center" }}
              computeExport={computeExport}
              enableExport={enableExport}
              exportText={exportText}
              exportReady={exportReady}
              setExportReady={setExportReady}
              csvData={csvData} />
          </Grid>
        </Grid>
      </div>

      {METableMemo}
     { console.log(combinedTOC, TOC,"combinedTOC || TOC")}
      <MECharts key='bar' data={combinedStats || getMae} TOC={combinedTOC || TOC} />
      <MECharts key='donut' data={combinedStats || getMae} type="donut" TOC={combinedTOC || TOC} />
      <MECharts key='radar' data={combinedStats || getMae} type="radar" TOC={combinedTOC || TOC} />

      <div style={{ marginTop: 60 }}>
        {/* <h3>Indicator Yearly Comparison</h3> */}
        <InputLabel><b style={{ color: '#000000de' }}>INDICATOR YEARLY COMPARISION</b></InputLabel>
        <br />
        <br />
        <div className='filter-indicator'>
          <InputLabel><b style={{ color: '#000000de' }}>FILTER WITH </b></InputLabel>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={year}
              label={year.toString()}
              onChange={(e) => setYear(e.target.value)}
            >

              {yearsArray?.map((item, index) => (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))}

            </Select>
            <FormHelperText>&nbsp; &nbsp; &nbsp; &nbsp;</FormHelperText>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-helper-label">Indicators</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={indicator}
              label={indicator}
              onChange={handleChangeforIndicator}
            >

              {arrayForIndicators?.map((item, index) => (
                <MenuItem key={index} value={item.slug}>{item.label}</MenuItem>
              ))}

            </Select>
            <FormHelperText>&nbsp; &nbsp; &nbsp; &nbsp;</FormHelperText>
          </FormControl>
          {indicator === "usersOnline" &&
            <>
              <SingleDatePicker
                singleCalendar={true}
                dateFormat="DD/MM/YYYY"
                startDate={fromDate}
                maxDate={toDate || new Date()}
                startDatePlaceholder="From date"
                onChange={(startDate) => setFromDate(new Date(startDate).toISOString())}
              />
              <SingleDatePicker
                startDate={toDate}
                minDate={fromDate}
                maxDate={new Date()}
                singleCalendar={true}
                dateFormat="DD/MM/YYYY"
                startDatePlaceholder="To date"
                onChange={(toDate) => setToDate(new Date(toDate).toISOString())}
              />
              <Button variant="text" onClick={() => { setFromDate(); setToDate(); }}
                disabled={!fromDate && !toDate}>
                RESET DATES
              </Button>
            </>}
        </div>
      </div>
      <MECharts key='barformonth' data={finalmonthsArray ? finalmonthsArray : []}
        type="barformonth" TOC={combinedTOC || TOC}
        arrayForIndicators={arrayForIndicators} filterIndicatorLabel={filterIndicatorLabel}
      />

    </div>
  </Loader>
}
const ExportButton = ({ computeExport, enableExport, exportText, exportReady, csvData, setExportReady }) => {

  return <div style={{ marginTop: 16, marginBottom: 16 }}>
    <Button
      onClick={computeExport}
      size='medium'
      variant='contained'
      disabled={!enableExport}
      style={{ marginLeft: 20, marginRight: 20 }}
    >{exportText}</Button>
    {exportReady > 0 && enableExport &&
      <Grid key={exportReady} item xs={12}>
        <CSVDownload
          filename={`data_export_on_${new Date().toDateString().replace(/ /g, '_')}.csv`}
          data={csvData}
          target='_blank'
          style={{ marginRight: 20 }}
          setExportReady={setExportReady}
        />
      </Grid>
    }
  </div>
}

const DateRangeFilter = ({ range, setRange, resetExport = () => { } }) => {
  const sixMonthsAgo = new Date()
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)


  const handleChange = ({ from, to }) => {
    setRange({
      ...range,
      ...from && { from },
      ...to && { to }
    })

    resetExport();
  }

  const resetRangeFilter = () => {
    setRange({
      from: undefined,
      to: undefined
    })

    resetExport();
  }

  const now = new Date()

  return <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'flex-end' }}>
    <span style={{ fontWeight: 700, width: "15%" }}>FILTER BY DATE</span>
    <div style={{ width: "30%" }}>
      <SingleDatePicker
        startDate={range.from}
        startDatePlaceholder="From date"
        dateFormat="DD/MM/YYYY"
        monthFormat="MMM YYYY"
        maxDate={range.to || now}
        onChange={(startDate) => handleChange({
          from: startDate
        })}
        singleCalendar={true}
      />
    </div>
    <div style={{ width: "30%" }}>
      <SingleDatePicker
        startDate={range.to}
        minDate={range.from || new Date(sixMonthsAgo)}
        startDatePlaceholder="To date"
        dateFormat="DD/MM/YYYY"
        monthFormat="MMM YYYY"
        onChange={(startDate) => handleChange({
          to: startDate,
        })}
        singleCalendar={true}
      />
    </div>
    <div style={{ width: "10%" }}>
      <Button variant="text" onClick={resetRangeFilter} disabled={!range.to && !range.from}>RESET DATES</Button>
    </div>
  </div>
}
