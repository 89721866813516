import React from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CSVDownload from "../components/CustomDownloadLink";
import AFRICAN_ISO from "../settings/enums/africa-iso-codes.json";
import BUSINESS_STAGES from "../settings/enums/business-stages.json";
import CATEGORIES from "../settings/enums/categories.json";
import SERVICES from "../settings/enums/services.json";
import TARGET_GROUPS from "../settings/enums/target-groups.json";
import TARGET_SECTORS from "../settings/enums/target-sectors.json";
import LANGUAGES from "../settings/enums/languages.json";
import LEGAL from "../settings/enums/legal.json";
import GEO from "../settings/enums/geo-active.json";
import OUTREACH from "../settings/enums/outreach.json";
import OUTREACH_SOCIALMEDIA from "../settings/enums/outreach-socialmedia.json";
import FINANCIAL from "../settings/enums/financial-support-type.json";
import FOUNDER from "../settings/enums/founder.json";
import FUNDING from "../settings/enums/funding-sources.json";
import CHALLENGES from "../settings/enums/program-challenges.json";
import SOURCE from "../settings/enums/sources.json";
import SUPPORT from "../settings/enums/support-needed.json";
import WOMEN from "../settings/enums/women-staff.json";
import COSTS from "../settings/enums/operational-costs.json";
import { getEsosQuery, importESO } from "../api";
import { useMutation } from "@apollo/react-hooks";
import { CSVLink } from "react-csv";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

const OrganizationFiltersForm = ({
  exportText,
  importText,
  exportReady,
  csvData,
  computeExport,
  enableExport,
  filters,
  refetch,
  handleClear,
  setExportReady,
  callback = () => { }
}) => {
  const [moreFilters, setMoreFilters] = React.useState();
  const [importeso] = useMutation(importESO);
  const { enqueueSnackbar } = useSnackbar();

  const basicFilters = [
    "country",
    "service",
    "category",
    "keyword",
    "published",
    "featured"
  ];
  const advancedFiltersInUse = Object.entries(filters)
    .filter(([key, value]) => !!value)
    .filter(([key, value]) => !basicFilters.includes(key)).length;
  const hiddenFileInput = React.useRef(null);

  const uploadCSV = () => {
    hiddenFileInput.current.click();
  };

  const importCsv = async (event) => {
    const file = event.target.files[0];
    if (
      file.type === "text/csv" ||
      file.type === "application/vnd.ms-excel" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      let fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = ev => {
        let csvdata = fileReader.result?.toString();
        importeso({
          variables: {
            data: csvdata
          },
          refetchQueries: [
            { query: getEsosQuery }
          ]
        })
          .then(async (result) => {
            const { success, response } = result.data.saveBulkEso
            if (success) {
              enqueueSnackbar("ESO CSV Import SuccessFully", { variant: 'success' })
            } else {
              enqueueSnackbar("Failed to Upload CSV. Check the download file", { variant: 'error' })
              const linkSource = "data:text/csv;base64," + response;
              const downloadLink = document.createElement("a");
              const fileName = "Failed-to-upload-ESO.csv";
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.style.display = "none";
              downloadLink.click();
              downloadLink.remove();
            }
            await refetch()
          })
          .catch(async (e) => {
            console.log(e, "error");
            enqueueSnackbar("Error on Import ESO CSV", { variant: 'error' })
          });
      };
    }
  };


  const samplecsvData = [
    [
      "name",
      "email",
      "phone",
      "website",
      "contact",
      "countryName",
      "city",
      "latitude",
      "longitude",
      "description",
      "services",
      "categories",
      "twitter",
      "linkedin",
      "facebook"
    ],
    ["I&F entrepreneuriat", "contact@if-entrepreneuriat.org", "+243 859 717 259", "http://if-entrepreneuriat.org/", "Co-founder & CEOCo-founderHub manager", "Democratic Republic of the Congo", "Kinshasa", "12.54854", "-89.56445",
      "General description", "training,advisory", "coworking", "https://twitter.com", "https://in.linkedin.com/", "https://www.facebook.com/"],
  ];

  // initialvalue for formik
  let initialFormValue = {
    country: filters.country ? filters.country : '',
    service: filters.service ? filters.service : '',
    category: filters.category ? filters.category : '',
    keyword: filters.keyword ? filters.keyword : '',
    published: filters.published ? filters.published : '',
    featured: filters.featured ? filters.featured : '',
    partner: filters.partner ? filters.partner : '',
    stage: filters.stage ? filters.stage : '',
    targetGroups: filters.targetGroups ? filters.targetGroups : '',
    targetSectors: filters.targetSectors ? filters.targetSectors : '',
    financialSuportProvided: filters.financialSuportProvided ? filters.financialSuportProvided : '',
    founder: filters.founder ? filters.founder : '',
    language: filters.language ? filters.language : '',
    legalEntity: filters.legalEntity ? filters.legalEntity : '',
    geoActive: filters.geoActive ? filters.geoActive : '',
    outreachStrategy: filters.outreachStrategy ? filters.outreachStrategy : '',
    outreachSocialMediaStrategy: filters.outreachSocialMediaStrategy ? filters.outreachSocialMediaStrategy : '',
    fundingSources: filters.fundingSources ? filters.fundingSources : '',
    source: filters.source ? filters.source : '',
    programChallenges: filters.programChallenges ? filters.programChallenges : '',
    supportNeeded: filters.supportNeeded ? filters.supportNeeded : '',
    womenStaff: filters.womenStaff ? filters.womenStaff : '',
    directedByWoman: filters.directedByWoman ? filters.directedByWoman : '',
    operationalCosts: filters.operationalCosts ? filters.operationalCosts : '',
  }

  return (
    <>
      <Formik
        initialValues={initialFormValue}
        onSubmit={(fields) => {
          callback(fields)
        }}
      >
        {({ values, handleChange, handleSubmit, resetForm }) => (
          <Form>
            <Grid container spacing={2} alignItems="baseline">
              <Grid item sm={4}>
                <FormControl fullWidth >
                  <InputLabel>Country</InputLabel>
                  <Select
                    fullWidth
                    name='country'
                    value={values.country}
                    onChange={handleChange}
                  >
                    {Object.entries(AFRICAN_ISO).map(([key, value]) => {
                      return (<MenuItem value={value} key={key} >{key}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Services</InputLabel>
                  <Select
                    name='service'
                    value={values.service}
                    onChange={handleChange}
                  >
                    <MenuItem >NONE</MenuItem>
                    {Object.entries(SERVICES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Categories</InputLabel>
                  <Select
                    name='category'
                    value={values.category}
                    onChange={handleChange}
                  >
                    {Object.entries(CATEGORIES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value?.label}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <TextField
                  fullWidth
                  label="Search ESO by Name"
                  name='keyword'
                  type="text"
                  defaultValue={values.keyword}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Published</InputLabel>
                  <Select
                    name='published'
                    value={values.published}
                    onChange={handleChange}
                  >
                
                    <MenuItem value="" key="">None</MenuItem>
                    {["Published", "Unpublished", "All"].map((key) => <MenuItem value={key} key={key} >{key}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Featured</InputLabel>
                  <Select
                    name='featured'
                    value={values.featured}
                    onChange={handleChange}
                  >
                   
                    <MenuItem value="" key="">None</MenuItem>
                    {["Featured", "Unfeatured", "All"].map((key) => <MenuItem value={key} key={key} >{key}</MenuItem>)}

                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {moreFilters && (<br />)}
            <Grid
              container
              spacing={2}
              alignItems="baseline"
              style={moreFilters ? {} : { display: "none" }}
            >
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Partner</InputLabel>
                  <Select
                    name='partner'
                    value={values.partner}
                    onChange={handleChange}
                  >
                    {Object.entries(CATEGORIES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value?.label}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Business stage</InputLabel>
                  <Select
                    name='stage'
                    value={values.stage}
                    onChange={handleChange}
                  >
                    {Object.entries(BUSINESS_STAGES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value?.label}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Target Groups</InputLabel>
                  <Select
                    name='targetGroups'
                    value={values.targetGroups}
                    onChange={handleChange}
                  >
                    {Object.entries(TARGET_GROUPS).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Target Sectors</InputLabel>
                  <Select
                    name='targetSectors'
                    value={values.targetSectors}
                    onChange={handleChange}
                  >
                    {Object.entries(TARGET_SECTORS).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Financial Support Provided</InputLabel>
                  <Select
                    name='financialSuportProvided'
                    value={values.financialSuportProvided}
                    onChange={handleChange}
                  >
                    {Object.entries(FINANCIAL).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Founder</InputLabel>
                  <Select
                    name='founder'
                    value={values.founder}
                    onChange={handleChange}
                  >
                    {Object.entries(FOUNDER).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Language</InputLabel>
                  <Select
                    name='language'
                    value={values.language}
                    onChange={handleChange}
                  >
                    <MenuItem value='' >None</MenuItem>
                    {Object.entries(LANGUAGES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Legal Entity</InputLabel>
                  <Select
                    name='legalEntity'
                    value={values.legalEntity}
                    onChange={handleChange}
                  >
                    {Object.entries(LEGAL).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Geo Active</InputLabel>
                  <Select
                    name='geoActive'
                    value={values.geoActive}
                    onChange={handleChange}
                  >
                    {Object.entries(GEO).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Outreach Strategy</InputLabel>
                  <Select
                    name='outreachStrategy'
                    value={values.outreachStrategy}
                    onChange={handleChange}
                  >
                    {Object.entries(OUTREACH).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>SocialMedia Strategy</InputLabel>
                  <Select
                    name='outreachSocialMediaStrategy'
                    value={values.outreachSocialMediaStrategy}
                    onChange={handleChange}
                  >
                    {Object.entries(OUTREACH_SOCIALMEDIA).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Funding Sources</InputLabel>
                  <Select
                    name='fundingSources'
                    value={values.fundingSources}
                    onChange={handleChange}
                  >
                    {Object.entries(FUNDING).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Source</InputLabel>
                  <Select
                    name='source'
                    value={values.source}
                    onChange={handleChange}
                  >
                    {Object.entries(SOURCE).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Program Challenges</InputLabel>
                  <Select
                    name='programChallenges'
                    value={values.programChallenges}
                    onChange={handleChange}
                  >
                    {Object.entries(CHALLENGES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Support Needed</InputLabel>
                  <Select
                    name='supportNeeded'
                    value={values.supportNeeded}
                    onChange={handleChange}
                  >
                    {Object.entries(SUPPORT).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>% of Female Employees</InputLabel>
                  <Select
                    name='womenStaff'
                    value={values.womenStaff}
                    onChange={handleChange}
                  >
                    {Object.entries(WOMEN).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Directed by Woman</InputLabel>
                  <Select
                    name='directedByWoman'
                    value={values.directedByWoman}
                    onChange={handleChange}
                  >
                    {Object.entries({ "Yes": true, "No": false },
                    ).map(([key, value]) => {
                      return (<MenuItem value={value} key={key} >{key}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Operational Costs</InputLabel>
                  <Select
                    name='operationalCosts'
                    value={values.operationalCosts}
                    onChange={handleChange}
                  >
                    {Object.entries(COSTS).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <div className="filter-button" style={{ marginTop: 25 }}>
              <Button
                variant='contained'
                color='primary'
                type='button'
                onClick={() => handleSubmit()}>
                APPLY
              </Button>
              <Button
                size='medium'
                variant='contained'
                style={{ marginLeft: 20, marginRight: 20 }}
                type='reset'
                onClick={() => {
                  resetForm()
                  handleClear(filters)
                }}
              >
                Clear Filter
              </Button>
              <Button
                onClick={computeExport}
                size="medium"
                variant="contained"
                disabled={!enableExport}
                style={{ marginLeft: 20, marginRight: 20 }}
              >
                {exportText}
              </Button>
              <Button
                onClick={uploadCSV}
                size="medium"
                variant="contained"
                disabled={!enableExport}
                style={{ marginLeft: 20, marginRight: 20 }}
              >
                {importText}
              </Button>
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref={hiddenFileInput}
                onChange={importCsv}
                onClick={(event) => {
                  event.target.value = null
                }}
                style={{ display: "none" }}
              />
              <CSVLink
                data={samplecsvData}
                filename={"ESO_CSV_format.csv"}
                style={{ textDecoration: "none" }}
              >
                <Button
                  size="medium"
                  variant="contained"
                  style={{ marginLeft: 20, marginRight: 20 }}
                >
                  Sample ESO CSV
                </Button>
              </CSVLink>
              <Button
                download
                size="medium"
                target="_blank"
                rel="noreferrer"
                variant="contained"
                href={`ESO_CSV_format.xlsx`}
                style={{ marginLeft: 20, marginRight: 20 }}
              >
                Sample ESO XLSX
              </Button>
              {exportReady > 0 && enableExport && (
                <Grid key={exportReady} item sm={12}>
                  <CSVDownload
                    filename={`data_export_on_${new Date()
                      .toDateString()
                      .replace(/ /g, "_")}.csv`}
                    data={csvData}
                    target="_blank"
                    style={{ marginRight: 20 }}
                    setExportReady={setExportReady}
                  />
                </Grid>
              )}
              <div style={{ float: "right" }}>
                <Button onClick={() => setMoreFilters(!moreFilters)} size="medium">
                  {moreFilters ? (
                    <>
                      <KeyboardArrowUp /> Hide
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDown /> Show
                    </>
                  )}{" "}
                  advanced filters
                  {advancedFiltersInUse && !moreFilters
                    ? ` (${advancedFiltersInUse} active)`
                    : ""}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrganizationFiltersForm;
