import React from "react";
import { Formik, Form } from "formik";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import AFRICAN_ISO from "../../settings/enums/africa-iso-codes.json"


const JobFilterForm = props => {
  const { handleClear, callback = () => { } } = props;
  return (
    <Formik
      initialValues={{ keyword: "",  country: "" }}
      onSubmit={values => callback(values)}
    >
      {({ values, handleChange, resetForm }) => {
        return (
          <Form name="filter_newsfeed">
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="keyword"
                  value={values.keyword}
                  onChange={handleChange}
                  label="Search Survey by Title"
                />
              </Grid>
              
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                  >
                    {Object.entries(AFRICAN_ISO).map(
                      ([countryName, countryCode]) => {
                        return (
                          <MenuItem value={countryName} key={countryCode}>
                            {countryName}
                          </MenuItem>
                        );
                      }
                    )}
                    <MenuItem value="others" >
                      Other
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ marginTop: 25 }}>
              <Grid container spacing={4} justifyContent="flex-start">
                <Grid item xs={3} sm={2}>
                  <Button
                    style={{ width: "100%" }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    APPLY
                  </Button>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Button
                    style={{ width: "100%" }}
                    type="reset"
                    color="inherit"
                    variant="contained"
                    onClick={() => {
                      resetForm();
                      handleClear();
                    }}
                  >
                    Clear Filter
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default JobFilterForm;
