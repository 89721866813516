import React from 'react'
import SERVICES from "../../settings/enums/categories.json";
import AFRICAN_ISO from "../../settings/enums/africa-iso-codes.json"
import { Form, Formik } from 'formik';
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

const CohortFilterForm= props => {
    const { handleClear, callback = () => {} } = props;
  return (
    <Formik
      initialValues={{ name: "", services: "", country: "" }}
      
      onSubmit={values =>{ callback(values)}}
    >
      {({ values, handleChange, resetForm }) => {
        return (
          <Form name="filter_cohort_list">
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Search Cohort by name"
                />
              </Grid>
              <Grid item xs={4}>
              <FormControl fullWidth>
                  <InputLabel>Services</InputLabel>
                  <Select
                      
                      fullWidth
                      name="services"
                      label="Services offered"
                      onChange={handleChange}
                      value={values?.services }
                    
                    >
                      {Object.entries(SERVICES).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                   
                  {/* <Select
                    name='services'
                    value={values.services}
                    onChange={handleChange}
                  >
                    <MenuItem >NONE</MenuItem>
                    {Object.entries(SERVICES).map(([key, value]) => {
                      return (<MenuItem value={key} key={key} >{value}</MenuItem>)
                    })}
                  </Select> */}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                  >
                    {Object.entries(AFRICAN_ISO).map(
                      ([countryName, countryCode]) => {
                        return (
                          <MenuItem value={countryName} key={countryCode}>
                            {countryName}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ marginTop: 25 }}>
              <Grid container spacing={4} justifyContent="flex-start">
                <Grid item xs={3} sm={2}>
                  <Button
                    style={{ width: "100%" }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    APPLY
                  </Button>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Button
                    style={{ width: "100%" }}
                    type="reset"
                    color="inherit"
                    variant="contained"
                    onClick={() => {
                      resetForm();
                      handleClear();
                    }}
                  >
                    Clear Filter
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}

export default CohortFilterForm