import React from 'react'
import { Box, Typography, Button, CircularProgress } from '@material-ui/core'
import Modal from './Modal'

const ConfirmDelete = ({
  id,
  label,
  name,
  deleteCallback,
  buttonLabel = 'Delete',
  customMessage
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteCallback(id, name)
      setTimeout(() => {
        setLoading(false)
        setIsOpen(false)
      }, 1000)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <Modal
      trigger={<Button
        size='small'
        variant='text'
        onClick={() => setIsOpen(true)}
      >
        <Box color='error.main'>{buttonLabel}</Box>
      </Button>}
      open={isOpen}
      isOpen={isOpen}
      title={`${buttonLabel} ${label || ''}`}
    >
      <Typography gutterBottom>
        {customMessage || `Are you sure you want to permanently delete ${name}?`}
      </Typography>
      <Button
        variant='contained'
        type='button'
        size='large'
        onClick={() => setIsOpen(false)}
        style={{ marginRight: '20px', marginTop: '20px' }}
      >Cancel</Button>
      <Button
        size='large'
        color='primary'
        variant='contained'
        onClick={loading  ? () => { } : handleDelete}
        // disabled={loading ? true : false}
        style={{ marginLeft: '20px', marginTop: '20px' }}
      >
        {(loading ) &&
          <CircularProgress color='secondary' size={28} style={{ padding: '2px' }} />}
        {buttonLabel}
      </Button>
    </Modal>
  )
}

export default ConfirmDelete