import React from "react";
import * as Yup from "yup"
import { useSnackbar } from 'notistack'
import hashString from "../../utils/hash";
import Random from "random-seed-generator";
import Layout from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Formik, Form, ErrorMessage } from "formik"
import ApolloCacheUpdater from 'apollo-cache-updater'
import { makeStyles } from "@material-ui/core/styles";
import ImageUpload from "../../components/ImageUpload";
import {
   Box, Grid, Chip, Select, Button, MenuItem, TextField, CircularProgress,
   CardContent, CardActions, FormControl, FormHelperText,
} from "@material-ui/core"
import USER_TYPES from "../../settings/user-types.json";
import Authenticated from "../../containers/Authenticated";
import { _s3SignMutation, addUserDetails, countUsersQuery, getUsersQuery } from "../../api";
import TARGET_SECTORS from '../../settings/enums/target-sectors.json'
import PERSONAL_INTEREST from '../../settings/enums/personal-interest.json'
import JOBS from '../../settings/enums/jobs.json'
import AFIRCA_COUNTRIES from "../../settings/enums/new-africa-iso-code.json";
import '../UserEdit/userEdit.css'
import { useState } from "react";

const useStyles = makeStyles({
   root: {
      // focused color for input with variant='standard'
      "& .MuiInput-underline:before": {
         display: "none",
         borderBottom: "none"
      },
      "& .MuiInput-underline:after": {
         display: "none",
         // borderBottomColor: "#00a651",
         // border: "1px solid #00a651"
      },
      "& .MuiInput-underline:hover": {
         borderColor: "#00a651",
         borderWidth: "1px"
      },
      "& .MuiInputBase-input": {
         border: "1px solid #00a651",
         paddingLeft: "1%"
      },
      '&:after': {
         // The MUI source seems to use this but it doesn't work
         borderBottom: '1px solid #00a651',
      },
   },
   label: {
      color: '#00a651',
      fontSize: "14px",
      fontWeight: "normal",
      marginBottom: "10px",
      fontFamily: "Arial",
   },
   labelForSelect: {
      color: '#00a651',
      fontSize: "14px",
      fontWeight: "normal",
      marginBottom: "-6px",
      fontFamily: "Arial",
   },
   formControl: {
      margin: 0,
      minWidth: 120,
   },
});

const serviceIntrest = {
   incubator: {
      label: "Incubator programme",
      icon: "incubator",
      description: ""
   },
   accelerator: {
      label: "Accelerator programme",
      icon: "accelerator",
      description: ""
   },
   coworking: {
      label: "Co-working and facilities",
      icon: "co-working",
      description: "Co-working space with limited support"
   },
   virtual: {
      label: "Virtual platform",
      icon: "virtual-platform",
      description: ""
   },
   financial: {
      label: "Funding",
      icon: "access-to-finance",
      description:
         "Financial Institution providing Business Development Support services (Fund manager, Angel, VC, equity, impact / social investor, etc.)"
   },
   business: {
      label: "Advice",
      icon: "chat",
      description:
         "Business linkages, networking, value chain or marketing support organization"
   },
   networking: {
      label: "Networking",
      icon: "networking",
      description: ""
   },
   sme: {
      label: "Mentoring",
      icon: "mentoring",
      desription: "SME Business Support Mentoring"
   },
   training: {
      label: "Training",
      icon: "mentoring",
      desription: "Training workshop & bootcamp"
   },
   prototyping: {
      label: "Prototyping tools ",
      icon: "mentoring",
      desription: ""
   },
   events: {
      label: "Events",
      icon: "mentoring",
      desription: ""
   },
   other: {
      label: "Other",
      icon: "idea"
   }
};

const personalInetrest = { ...TARGET_SECTORS, ...PERSONAL_INTEREST }

const socialObj = (itemsObject = {}) => {
   return Object.entries(itemsObject).map(([key, value]) => ({
      id: key,
      label: key,
      url: value
   }));
};

const parseUrl = url => !url.includes("http://") && !url.includes("https://")
   ? `http://${url}` : url;

const UserNew = () => {
   const history = useHistory();
   const { enqueueSnackbar } = useSnackbar();
   const [loading, setLoading] = useState(false)
   const [s3Sign, { loading: s3Loading }] = useMutation(_s3SignMutation);
   const [addUser, { loading: adduserLoading }] = useMutation(addUserDetails);

   const classes = useStyles()

   const options = [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" }
   ]

   const ageoptions = [
      { label: "under 18", value: "under 18" },
      { label: "between 18 and 35", value: "between 18 and 35" },
      { label: "above 35", value: "above 35" }
   ]

   const jobs_role = Object.values(JOBS).reduce((arr, category) => {
      const categoryArr = Object.keys(category).map(role => ({
         label: category[role],
         value: role
      }));
      return arr.concat(categoryArr);
   }, []);

   //validation schema
   let validationSchema = Yup.object().shape({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      phone: Yup.string(),
      age: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      birthDate: Yup.date(),
      currentPosition: Yup.string(),
      country: Yup.string().required("Required"),
   })

   const onSubmit = async (fields) => {

      const dataObject = {
         first_name: fields.first_name,
         last_name: fields.last_name,
         phone: fields?.phone,
         age: fields?.age,
         type: fields?.type,
         avatar: fields.avatar,
         country: fields.country,
         about: fields.about,
         email: fields.email,
         currentPosition: fields.currentPosition,
         personalInterests: fields.personalInterests,
         serviceInterests: fields.serviceInterests,
         gender: fields?.gender || null, // gender is enum and cannot be ''
         birthDate: fields.birthDate ? fields.birthDate : null,
         social: socialObj({
            // need to transform before sending to backend
            linkedin: fields?.linkedin ? parseUrl(fields?.linkedin) : null,
            facebook: fields?.facebook ? parseUrl(fields?.facebook) : null,
            twitter: fields?.twitter ? parseUrl(fields?.twitter) : null
         })
      };
      if (Object.keys(dataObject).length) {
         const hasNewImage = dataObject.avatar && typeof dataObject.avatar === "object";
         let url;
         if (hasNewImage) {
            const image = dataObject.avatar;
            const { type, dataFile, name: fileName } = image;
            const randomString = Random.string({
               length: 5,
               pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
            });
            const response = await s3Sign({
               variables: {
                  filename: `media/user/avatar/${randomString}-${fileName
                     .replace(/ /g, "-")
                     .replace(/[{()}]/g, "")}`,
                  filetype: image.type
               }
            });
            const { signedRequest, url: bucketUrl } = response.data._signS3;
            const options = {
               headers: {
                  "Content-Type": image.type
               }
            };
            try {
               setLoading(true)
               await fetch(signedRequest, {
                  method: "PUT",
                  headers: options.headers,
                  body: dataFile
               });
               url = bucketUrl;
               setLoading(false)
            } catch (e) {
               url = null;
               setLoading(false)
               enqueueSnackbar('Cannot upload image.', { variant: 'error' })
            }
         }
         addUser({
            variables: {
               data: {
                  ...dataObject,
                  ...(url && { avatar: url }),
                  password: hashString('Ennova@123').digest
               }
            },
            update: (proxy, { data: { addUser = {} } }) => {
               const mutationResult = addUser
               ApolloCacheUpdater({
                  proxy,
                  ID: '_id',
                  operator: 'ANY',
                  searchVariables: { skip: 0 },
                  queriesToUpdate: [getUsersQuery, countUsersQuery],
                  mutationResult: { ...mutationResult },
               })
            },
         }).then(response => {
            if (response?.data) {
               enqueueSnackbar("Profile added successfully", { variant: 'success' })
               history.push(`/users/`);
            }
         }).catch(e => {
            console.error(e);
            enqueueSnackbar("Could not add the profile.", { variant: 'error' })
         });
      }
   }

   return (
      <Authenticated>
         <Layout title="Create user">
            <Formik
               validationSchema={validationSchema}
               initialValues={{
                  city: '',
                  address: '',
                  age: '',
                  type: '',
                  email: '',
                  about: '',
                  phone: '',
                  gender: '',
                  avatar: '',
                  twitter: '',
                  country: '',
                  linkedin: '',
                  facebook: '',
                  birthDate: '',
                  last_name: '',
                  first_name: '',
                  currentPosition: 'other',
                  serviceInterests: [],
                  personalInterests: [],
               }}
               onSubmit={(fields) => onSubmit(fields)}>
               {({ setFieldValue, values, handleChange, handleBlur, errors }) => {
                  return (
                     <Form id="user_edit_form">
                        <CardContent>
                           <Grid item container spacing={2} alignItems="center" justifyContent="center">
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>First Name <span className="required_sign">*</span></label>
                                 <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    // onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={classes.root}
                                    value={values.first_name}
                                    InputLabelProps={{ shrink: true }}
                                 />
                                 <ErrorMessage name="first_name" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Last Name <span className="required_sign">*</span></label>
                                 <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    // onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={classes.root}
                                    value={values.last_name}
                                    InputLabelProps={{ shrink: true }}

                                 />
                                 <ErrorMessage name="last_name" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <FormControl fullWidth >
                                    <label className={classes.labelForSelect}>
                                       User Type <span className="required_sign">*</span>
                                    </label>
                                    <Select
                                       native
                                       displayEmpty
                                       id="type"
                                       name="type"
                                       value={values.type}
                                       // onBlur={handleBlur}
                                       onChange={handleChange}
                                       className={classes.root}
                                    >
                                       <option value="">Select</option>
                                       {[{ label: 'Admin', value: USER_TYPES.ADMIN },
                                       { label: 'Entrepreneur', value: USER_TYPES.ENTREPRENEUR },
                                       { label: 'Ecosystem-Player', value: USER_TYPES.ECOSYSTEMPLAYER }]
                                          .map((item) => (
                                             <option key={item.value} value={item.value}>
                                                {item.label}
                                             </option>
                                          ))}
                                    </Select>
                                 </FormControl>
                                 <ErrorMessage name="type" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <FormControl fullWidth >
                                    <label className={classes.labelForSelect}>
                                       Gender <span className="required_sign">*</span>
                                    </label>
                                    <Select
                                       native
                                       id="gender"
                                       name="gender"
                                       label="Gender"
                                       // onBlur={handleBlur}
                                       value={values.gender}
                                       onChange={handleChange}
                                       className={classes.root}
                                    >
                                       <option value="">Select</option>
                                       {options.map((item) => (
                                          <option key={item.value} value={item.value}>
                                             {item.label}
                                          </option>
                                       ))}
                                    </Select>
                                 </FormControl>
                                 <ErrorMessage name="gender" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <Box>
                                    <label variant="subtitle1" className={classes.label}>Logo</label>
                                    <ImageUpload
                                       name={'avatar'}
                                       value={values?.avatar}
                                       setFieldValue={setFieldValue}
                                    />
                                 </Box>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Email <span className="required_sign">*</span></label>
                                 <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    // onBlur={handleBlur}
                                    value={values.email}
                                    className={classes.root}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                 />
                                 <ErrorMessage name="email" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Phone</label>
                                 <TextField
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    // onBlur={handleBlur}
                                    value={values.phone}
                                    className={classes.root}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                 />
                                 <ErrorMessage name="phone" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Date of birth</label>
                                 <TextField
                                    fullWidth
                                    type="date"
                                    name='birthDate'
                                    // onBlur={handleBlur}
                                    id="birthDateField"
                                    onChange={handleChange}
                                    className={classes.root}
                                    value={values?.birthDate}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ max: new Date().toISOString().slice(0, 10) }}
                                 />
                                 <ErrorMessage name='birthDate' >
                                    {msg => <FormHelperText style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <FormControl fullWidth >
                                    <label className={classes.labelForSelect}>
                                       Age <span className="required_sign">*</span>
                                    </label>
                                    <Select
                                       native
                                       id="age"
                                       name="age"
                                       value={values.age}
                                       // onBlur={handleBlur}
                                       label="Age bracket"
                                       onChange={handleChange}
                                       className={classes.root}
                                    >
                                       <option value="">All</option>
                                       {ageoptions.map((item) => (
                                          <option key={item.value} value={item.value}>
                                             {item.label}
                                          </option>
                                       ))}
                                    </Select>
                                 </FormControl>
                                 <ErrorMessage name="age" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <FormControl fullWidth >
                                    <label className={classes.labelForSelect}>
                                       Position
                                    </label>
                                    <Select
                                       native
                                       id="currentPosition"
                                       name="currentPosition"
                                       label="Position"
                                       // onBlur={handleBlur}
                                       value={values.currentPosition}
                                       onChange={handleChange}
                                       className={classes.root}
                                    >
                                       <option value="">Select</option>
                                       {jobs_role.map((item) => (
                                          <option key={item.value} value={item.value}>
                                             {item.label.toUpperCase()}
                                          </option>
                                       ))}
                                    </Select>
                                 </FormControl>
                                 <ErrorMessage name="currentPosition" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Brief Description About Myself</label>
                                 <TextField
                                    multiline
                                    fullWidth
                                    minRows={5}
                                    maxRows={5}
                                    name='about'
                                    value={values?.about}
                                    onChange={handleChange}
                                    className={classes.root}
                                    InputLabelProps={{ shrink: true }}
                                 />
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <FormControl fullWidth >
                                    <label className={classes.labelForSelect}>
                                       Country <span className="required_sign">*</span>
                                    </label>
                                    <Select
                                       native
                                       id="country"
                                       name="country"
                                       label="Country"
                                       // onBlur={handleBlur}
                                       value={values.country}
                                       onChange={handleChange}
                                       className={classes.root}
                                    >
                                       <option value="">Select</option>
                                       {Object.entries(AFIRCA_COUNTRIES).map(([key, value]) => (
                                          <option key={key} value={value}>
                                             {key}
                                          </option>
                                       ))}
                                    </Select>
                                 </FormControl>
                                 <ErrorMessage name="country" >
                                    {msg => <FormHelperText className='form-error' style={{ color: 'red' }}>{msg}</FormHelperText>}
                                 </ErrorMessage>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>City </label>
                                 <TextField
                                    fullWidth
                                    id="city"
                                    name="city"
                                    value={values.city}
                                    // onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={classes.root}
                                    InputLabelProps={{ shrink: true }}
                                 />
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Address </label>
                                 <TextField
                                    fullWidth
                                    id="address"
                                    name="address"
                                    // onBlur={handleBlur}
                                    value={values.address}
                                    onChange={handleChange}
                                    className={classes.root}
                                    InputLabelProps={{ shrink: true }}
                                 />
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <FormControl fullWidth>
                                    <label className={classes.labelForSelect}>Service Interests</label>
                                    <Select
                                       multiple
                                       displayEmpty
                                       name='serviceInterests'
                                       onChange={handleChange}
                                       className={classes.root}
                                       error={errors.serviceInterests}
                                       defaultValue={values?.serviceInterests}
                                       renderValue={(selected) => (
                                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                             {selected.length
                                                ? selected.map((value) => (
                                                   <Chip key={value} label={serviceIntrest[value]?.label?.toUpperCase()} />
                                                ))
                                                : 'Select'
                                             }
                                          </Box>
                                       )}
                                    >
                                       <MenuItem value='' disabled>Service Interests</MenuItem>
                                       {Object.entries(serviceIntrest).map(([key, value]) => (
                                          <MenuItem key={key} value={key} >
                                             {value?.label?.toUpperCase()}
                                          </MenuItem>
                                       ))}
                                    </Select>
                                 </FormControl>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <FormControl fullWidth>
                                    <label className={classes.labelForSelect}>Sectors of Interest</label>
                                    <Select
                                       multiple
                                       displayEmpty
                                       name='personalInterests'
                                       onChange={handleChange}
                                       className={classes.root}
                                       error={errors.personalInterests}
                                       defaultValue={values?.personalInterests}
                                       renderValue={(selected) => (
                                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                             {selected.length
                                                ? selected.map((value) => (
                                                   <Chip key={value} label={personalInetrest[value]} />
                                                ))
                                                : 'Select'
                                             }
                                          </Box>
                                       )}
                                    >
                                       <MenuItem value='' disabled>Sectors of Interest</MenuItem>
                                       {Object.entries(personalInetrest).map(([key, value]) => {
                                          return <MenuItem key={key} value={key} >
                                             {value}
                                          </MenuItem>
                                       })}
                                    </Select>
                                 </FormControl>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Linkedin</label>
                                 <TextField
                                    fullWidth
                                    id="linkedin"
                                    name="linkedin"
                                    // onBlur={handleBlur}
                                    value={values.linkedin}
                                    onChange={handleChange}
                                    className={classes.root}
                                    InputLabelProps={{ shrink: true }}
                                 />
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Facebook</label>
                                 <TextField
                                    fullWidth
                                    className={classes.root}
                                    id="facebook"
                                    name="facebook"
                                    value={values.facebook}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    InputLabelProps={{ shrink: true }}
                                 />
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} >
                                 <label className={classes.label}>Twitter</label>
                                 <TextField
                                    fullWidth
                                    className={classes.root}
                                    id="twitter"
                                    name="twitter"
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value={values.twitter}
                                    InputLabelProps={{ shrink: true }}
                                 />
                              </Grid>
                           </Grid>
                        </CardContent>
                        <CardActions>
                           <Grid container spacing={4} justifyContent='flex-end'>
                              <Grid item>
                                 <Button
                                    variant="contained"
                                    type="button"
                                    onClick={() => history.goBack()}
                                 >
                                    CANCEL
                                 </Button>
                              </Grid>
                              <Grid item>
                                 <Button
                                    className="edituser_save_btn"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={loading || s3Loading || adduserLoading}
                                 >
                                    {(s3Loading || loading || adduserLoading) && <CircularProgress color='inherit'
                                       size={26} style={{ padding: '4px' }} />}
                                    ADD
                                 </Button>
                              </Grid>
                           </Grid>
                        </CardActions>
                     </Form>
                  )
               }}
            </Formik>
         </Layout>
      </Authenticated>
   );
};

export default UserNew;
