import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import ApolloCacheUpdater from "apollo-cache-updater";
import { Button, Typography } from "@material-ui/core";
import Authenticated from "../containers/Authenticated";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { getUsersQuery, countUsersQuery, _deleteUserMutation } from "../api";
import AFIRCA_COUNTRIES from "../settings/enums/new-africa-iso-code.json";
import { parse, stringify } from "querystringify";
import UserFilter from "../forms/userFilter";
import UserTable from "../components/UserTable";
const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

const transformData = data => {
  let csvData = data.reduce(
    (arr, item) => {
      arr[0] = Object.entries(item).reduce(
        (obj, [key, value]) => ({
          ...obj,
          ...(!obj[key] && {
            [key]: Object.keys(obj).length
          })
        }),
        {}
      );
      let shell = Array(Object.keys(item).length)
        .join(".")
        .split("."); // array of empty strings
      Object.entries(item).forEach(function ([key, value]) {
        shell[arr[0][key]] = value;
      });
      return [...arr, shell];
    },
    [{}]
  );
  let shell = Array(Object.keys(csvData[0]).length);
  Object.entries(csvData[0]).forEach(function ([key, value]) {
    shell[csvData[0][key]] = key;
  });
  csvData[0] = [...shell];
  return csvData;
};

const ExportButton = ({
  computeExport,
  enableExport,
  exportReady,
  csvData
}) => {
  return (
    <div style={{ marginTop: 16, marginBottom: 16 }}>
      <Button
        onClick={computeExport}
        size="medium"
        variant="contained"
        disabled={!enableExport}
        style={{ marginLeft: 20, marginRight: 20 }}
      >
        Export
      </Button>
    </div>
  );
};

const Users = ({ history, location: { search } }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0); // Page numeration starts at 0.
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = React.useState();
  const [exportReady, setExportReady] = React.useState(0);
  const [csvData, setCSVData] = React.useState([]);
  const [esoDropdownData, setEsoDropdownData] = useState([]);
  const [sortData, setSortData] = useState(['_id:desc']);
  const computeExport = () => {
    setExportReady(exportReady + 1);
  };

  const [deleteUser] = useMutation(_deleteUserMutation);


  const parseFilters = () => {
    const { country, service, keyword, eso, email, userType } = filters;
    /* eslint-disable camelcase */
    return {
      ...(country && { country: country }),
      ...(service && { serviceInterests_in: service }),
      ...(keyword && { name_matches: keyword }),
      ...(eso && { eso: eso }),
      ...(email && { email_matches: email }),
      ...(userType && { type_in: userType })
    };
  };

  const sortFilter = (value, key) => {
    setSortData([`${value}:${key}`]);
  };

  const {
    data: { getUsers: data = [], countUsers = 1 } = {},
    loading,
    error, refetch
  } = useQuery(getUsersQuery, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters && {
          where: parseFilters()
        })
      }
    },
  });

  const { data: { getUsers: users = [] } = {} } = useQuery(getUsersQuery);

  const showDate = d => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    if (d) {
      const datestring =
        d.getDate() +
        " " +
        months[d.getMonth() + 1] +
        " " +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes();
      return datestring;
    }
    return "-";
  };

  const transformUsersCSV = users => {
    return users.map(user => {
      let countryName = "";
      if (user.country === null) {
        countryName = user.eso?.countryName;
      } else {
        countryName = getKeyByValue(AFIRCA_COUNTRIES, user.country);
      }
      let esoName = "";
      if (user.eso !== null) {
        esoName = user.eso.name;
      }
      const csvUser = {
        _id: user?._id,
        Name: user?.name,
        Email: user?.email,
        Phone: user?.phone,
        Age: user?.age,
        Gender: user?.gender,
        Country: countryName,
        Type: user?.type,
        LastLogin: user?.loginDate,
        "Current Position": user?.currentPosition,
        ESO: esoName,
        "Service Interests":
          Array.isArray(user?.serviceInterests)
            ? user?.serviceInterests.toString()
            : "",
        "Linkedin URL":
          user?.social == null
            ? " "
            : user?.social[0]?.url === null
              ? " "
              : user?.social[0]?.url,
        "Facebook URL":
          user?.social == null
            ? " "
            : user?.social[1]?.url === null
              ? " "
              : user?.social[1]?.url,
        "Twitter URL":
          user?.social == null
            ? " "
            : user?.social[2]?.url === null
              ? " "
              : user?.social[2]?.url,
        "Creted Date & Time": showDate(new Date(user?.createdAt))
      };
      return csvUser
    });
  };

  const enableExport = users.length > 0;

  const deleteUserCallback = (id, email) => {
    deleteUser({
      variables: {
        id
      },
      update: proxy => {
        const updates = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getUsersQuery, countUsersQuery], // queries you want to automatically update
          searchVariables: {},
          operator: "ANY",
          operation: "REMOVE",
          mutationResult: { _id: id },
          ID: "_id"
        });
        if (updates)
          enqueueSnackbar(`User ${email} has been deleted.`, {
            variant: "success"
          });
      }
    })
      .then(() => { })
      .catch(e => {
        enqueueSnackbar("Cannot delete the user.", { variant: "error" });
        console.log(e);
      });
  };

  React.useEffect(() => {
    if (users) {
      let esodata = [];
      users.forEach(item => {
        if (item?.eso) {
          esodata.push({
            _id: item?.eso?._id,
            name: item?.eso?.name
          });
        }
      });
      setEsoDropdownData(esodata);
    }

    if (users.length > 0 && enableExport && exportReady) {
      const csvTypeUsers = transformUsersCSV(users);
      setCSVData(
        transformData(
          csvTypeUsers.map(item => {
            const { __typename, location, logo, ...rest } = item;
            return rest;
          })
        )
      );
    }
  }, [enableExport, exportReady, filters]);

  const rowsInTotal = countUsers;

  React.useEffect(() => {
    const parsedFilters = parse(search);
    if (parsedFilters) {
      setFilters(parsedFilters);
    }
  }, [search]);

  const exportText =
    filters &&
      data.length > 0 &&
      typeof filters === "object" &&
      Object.keys(filters).filter(key => !!filters[key]).length > 0
      ? "EXPORT RESULTS"
      : "EXPORT DB";

  const handleFiltersSubmit = fields => {
    const newFiltersQueryString = stringify(fields);
    setExportReady(0);
    setPage(0);
    setCSVData([]);
    history.push(`/users?${newFiltersQueryString}`);
  };

  const handleClear = filters => {
    setFilters();
    history.push("/users");
  };
  const handleRowPerChange = (value) => {
    setRowsPerPage(value)

  }
  return (
    <Authenticated>
      <Layout
        title="Users"
        head={
          <Loader loading={loading} error={error}>
            <UserFilter
              filters={filters}
              callback={handleFiltersSubmit}
              enableExport={enableExport}
              exportText={exportText}
              computeExport={() => {
                setExportReady(exportReady + 1);
              }}
              refetch={refetch}
              handleClear={handleClear}
              exportReady={exportReady}
              setExportReady={setExportReady}
              csvData={csvData}
              data={data}
              esoDropdownData={esoDropdownData}
            />
          </Loader>
        }
      >
        <Loader loading={loading} error={error}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Export all users</Typography>
            <ExportButton
              computeExport={computeExport}
              enableExport={enableExport}
              exportReady={exportReady}
              csvData={csvData}
            />
          </div>
          <div className="user-table-set">
        
            <UserTable
              data={data}
              refetch={refetch}
              sortFilter={sortFilter}
              deleteUserCallback={deleteUserCallback}
            />
            <Pagination
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              handleRowPerChange={handleRowPerChange}
              page={page}
              callback={newPage => setPage(newPage)}
            />
          </div>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default Users;
