import React from 'react'
import Authenticated from '../containers/Authenticated'
import Layout from '../components/Layout'
import NewsForm from '../forms/NewsForm'
import LanguageSwitch from '../components/LanguageSwitch'
import multivalues from '../settings/multivalues.json'


const NewsfeedNew = () => {
  const [languageIndex, setLanguageIndex] = React.useState(0)
  return (
    <Authenticated>
      <Layout title='Create News'>
        <LanguageSwitch
          languageIndex={languageIndex}
          setLanguageIndex={setLanguageIndex}
          languages={multivalues.languages}
        />
        <NewsForm
          languageIndex={languageIndex}
          languagesTotal={Object.keys(multivalues.languages).length}
        />
      </Layout>
    </Authenticated>
  )
}

export default NewsfeedNew
