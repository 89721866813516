import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup } from '@material-ui/core'
import { Check, Block, HourglassEmpty, Star, StarBorder } from '@material-ui/icons'
import Table from './Table'
import ConfirmDelete from './ConfirmDelete'
import RESOURCE_STATUS from '../settings/enums/resource-status.json'
import { saveResourceMutation } from '../api'
import { useMutation } from "@apollo/react-hooks";

const customMessage = 'Are you sure you want to continue?'

const STATUS = Object.keys(RESOURCE_STATUS).reduce((obj, status) => ({
  ...obj,
  [status]: status,
}), {})

const buildActionButtons = (actions) => actions.map((action, index) => <ConfirmDelete
  key={`${action.item._id}_${index}`}
  id={action.item._id}
  name={action.item.title}
  deleteCallback={action.callback}
  buttonLabel={action.buttonLabel}
  label={action.label}
  customMessage={customMessage}
/>)

const determineAction = (item) => {
  if (item.status === STATUS.pending && item.author?.name !== 'AfDB') return item.second ? 'reject' : 'approve'
  if (item.second) return null
  return 'delete'
}


const ResourceList = ({ data, callback, sortFilter }) => {
  console.log("🚀 ~ file: ResourceList.js:36 ~ ResourceList ~ data:", data)
  const [saveResource, { loading }] = useMutation(saveResourceMutation);

  const updateFeature = (data) => {
    saveResource({
      variables: {
        data: data
      }
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Table
      data={data.map((item) => ({
        author: item.author?.name,
        name: item.name,
        category: item.category,
        language:item.language,
        status: item.status === STATUS.pending
          ? <HourglassEmpty color="action" />
          : item.status === STATUS.approved
            ? <Check color='secondary' />
            : <Block color='disabled' />,
        isHighlight: item.isHighlight
          ? <Star color="primary" cursor={loading ? "not-allowed" : "pointer"}
            onClick={() => !loading && updateFeature({
              _id: item?._id,
              isHighlight: false,
              name: item.name
            })} />
          : <StarBorder color="disabled" cursor={loading ? "not-allowed" : "pointer"}
            onClick={() => !loading && updateFeature({
              _id: item?._id,
              isHighlight: true,
              name: item.name
            })} />,
        actions: < ButtonGroup size='small' variant='text' >
          <Button
            component={Link}
            to={`/resources/${item._id}`}
          >{item.author?.name === 'AfDB' ? 'Edit' : 'View'}</Button>
          {
            buildActionButtons([
              {
                item,
                label: item.name,
                callback: () => {
                  callback({ id: item._id, name: item.name, action: determineAction(item) })
                },
                buttonLabel: determineAction(item),
              },
              {
                item,
                label: item.name,
                callback: () => {
                  const value = { ...item, second: true }
                  const action = determineAction(value)
                  if (action) return callback({ id: value._id, name: value.name, action: determineAction(value) })
                  return null
                },
                buttonLabel: determineAction({
                  ...item,
                  second: true
                })
              }
            ])}
        </ButtonGroup >,
        createdAt: item?.createdAt
      }))}
      labels={
        [
          {
            name: 'Name',
            field: 'name',
          },
          {
            name: 'Category',
            field: 'category',
          },
          {
            name: 'Author',
            field: 'author',
          },
          {
            name:'Language',
            field:'language'
          },
          {
            name: 'Status',
            field: 'status',
          },
          {
            name: "Highlight",
            field: "isHighlight"
          },
          {
            name: 'Created At',
            field: 'createdAt',
          },
          {
            name: 'Actions',
            field: 'actions',
          },
        ]}
      sortFilter={sortFilter}
    />
  )
}

export default ResourceList
