import React from "react"
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
// import { FormThemeProvider } from 'react-standalone-form'
import { apolloClient } from './apollo'
import Routes from './Routes'
import theme from './theme'
// import formTheme from './formTheme'

class App extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <ApolloHooksProvider client={apolloClient}>
            <CssBaseline />
            {/* <FormThemeProvider theme={formTheme}> */}
              <Routes />
            {/* </FormThemeProvider> */}
          </ApolloHooksProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    )
  }
}

export default App
