import { useQuery } from '@apollo/react-hooks'
import React, { Suspense } from 'react'
import { authQuery } from '../api'
import Spinner from './../components/Spinner'


const ConnectionResult = ({ children }) => {
  useQuery(authQuery, {
    suspend: true,
    options: { fetchPolicy: 'network-only' },
  })
  return children
}

const Authenticated = props => <Suspense fallback={<Spinner big />}>
  <ConnectionResult {...props} />
</Suspense>

export default Authenticated