import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  IconButton,
  Hidden,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Menu as MenuIcon } from '@material-ui/icons'
import Logo from './Logo'


const TopBar = ({ onMenuClick, title }) => {
  const classes = useStyles()

  return (
    <AppBar position='static' color='default' className={classes.root}>
      <Toolbar variant='regular'  className={classes.toolBar}>
        <Hidden xsDown mdUp>
          <IconButton
            color='inherit'
            aria-label='Menu'
            onClick={onMenuClick}
            className={classes.menuIcon}
          ><MenuIcon /></IconButton>
        </Hidden>
        <div className={classes.logo}>
          <Logo />
        </div>
        <Typography
          variant='subtitle1'
          color='inherit'
          className={classes.title}
        >{title}</Typography>
        <Hidden smUp>
          <IconButton
            color='inherit'
            aria-label='Menu'
            onClick={onMenuClick}
          ><MenuIcon /></IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

TopBar.defaulProps = {
  title: '',
}

TopBar.propTypes = {
  title: PropTypes.string,
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[3],
  },
  toolBar: {
    marginLeft: theme.spacing(-3),
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: 'white',
    width: theme.sidebarWidth.sm - theme.spacing(6),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: theme.sidebarWidth.md,
    },
  },
  menuIcon: {
    color: 'inherit',
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(4),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'white',
  },
}))

export default TopBar
