import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'


const getErrorContent = code => {
  const errorCode = code.substr(0, 3)
  const errorFamily = code.substr(0, 2)
  const errorObj = {
    statusCode: errorCode,
    content: 'Something went wrong',
  }
  switch (errorFamily) {
    case '40':
      if (errorCode === '403') {
        errorObj.content = 'The requested resource is Forbidden'
      } else {
        errorObj.content = 'The requested resource could not be found but may be available again in the future.'
      }
      break
    case '50':
      errorObj.content = 'An unexpected condition was encountered. Our service team has been dispatched to bring it back online.'
      break
    default:
      break
  }
  return errorObj
}

const ErrorPage = ({ match }) => {
  const { statusCode, content } = getErrorContent(match.params.error)
  return (
    <div className='cover'>
      <h1>We got a problem</h1>
      <h2>Error {statusCode}</h2>
      <p className='lead'>{content}</p>
      <p>
        <Link href='/' to='/'>Home</Link> or try <Link href='/login' to='/login'>Loggin in</Link> again.
      </p>
      <style jsx>{`
        .cover {
          background-color: steelblue;
          padding: 50px 50px;
          color: #eee;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          box-sizing: border-box;
        }
        .lead {
          font-size: large;
        }
      `}
      </style>
    </div>
  )
}

ErrorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      error: PropTypes.string,
    }),
  }),
}

ErrorPage.defaultProps = {
  match: {
    params: {
      error: '404',
    },
  },
}

export default ErrorPage