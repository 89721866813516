import React from 'react'
import {
  Grid,
  Card,
  CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LoginForm from '../forms/LoginForm'


const Login = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={classes.root}
    >
      <Card raised className={classes.card}>
        <CardContent>
          <LoginForm />
        </CardContent>
      </Card>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url('/bg-pattern.svg')`,
    backgroundSize: 'cover',
  },
  card: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
  },
}))

export default Login
