import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'


const SideMenu = ({ links, location }) => {
  const classes = useStyles()

  return (
    <MenuList>
      {links.map((link, index) => (
        <MenuItem
          component={Link}
          to={link.url}
          className={classNames(classes.menuItem, {
            [classes.menuItemActive]: location.pathname.startsWith(link.url),
          })}
          key={index}
        >
          <ListItemIcon className={classes.icon}>
            {link.icon}
          </ListItemIcon>
          <ListItemText
            className={classes.text}
            classes={{ primary: classes.primary }}
            inset
            primary={link.label}
          />
        </MenuItem>
      ))}
    </MenuList>
  )
}


SideMenu.propTypes = {
  links: PropTypes.array.isRequired,
}


const useStyles = makeStyles(theme => ({
  menuItem: {
    '& $primary, & $icon': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
    },
  },
  menuItemActive: {
    backgroundColor: theme.palette.grey[800],
  },
  text: {
    paddingLeft: 0,
  },
  primary: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.fontSize,
    [theme.breakpoints.only('sm')]: {
      whiteSpace: 'normal',
    },
  },
  icon: {
    color: 'inherit',
  },
}))

export default withRouter(SideMenu)
